import axios from "axios";
import { getArtistDataApi } from '../../_constants/api.constant'
// import { getSelfToken } from '../../_helpers/auth/secureToken'
export async function getCreaterData() {
    const response = await axios.get(
        getArtistDataApi.GET, 
        {
            // headers: {
            //     Authorization: 'Token ' + getSelfToken(),
            // },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        // handle error


        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}