import React from 'react';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const ClearSelectionIcon = ({ name, onClear }) => (
    <IconButton onClick={() => onClear(name)}>
        <CloseIcon className='fs-6' />
    </IconButton>
);


export default ClearSelectionIcon;
