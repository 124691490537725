import { combineReducers } from 'redux'
import loader from './loader/loaderReducer'
import userData from './user/userDataReducer'
import userDetail from './loginUser/loginUserReducer'
import snackBar from './snackBar/snackBarReducer'
import releaseSongList from './songRelease/songReleaseReducer'
import creatorAssociatesStore from './creatorAssociates/creatorAssociatesDataReducer'
import royaltyDataReducer from "./royalty/royaltyDataReducer"
import { USER_LOGOUT } from './logout/logoutTypes'
import releaseAlbumReducer from './releaseAlbum/releaseAlbumDataReducer'
import payeeBankReducer from "./payoutBankDetail/payoutBankDataReducer"
import musicCategorgyReducer from "./musicalCategory/musicCategoryReducer"
const appReducer = combineReducers({
    loader:loader,
    userData:userData,
    userDetail:userDetail,
    snackBar:snackBar,
    releaseSongList:releaseSongList,
    creatorAssociatesStore:creatorAssociatesStore,
    royaltyDataReducer:royaltyDataReducer,
    releaseAlbumReducer:releaseAlbumReducer,
    payeeBankReducer:payeeBankReducer,
    musicCategorgyReducer:musicCategorgyReducer

})
const rootReducer = (state, action) => {
    if (action.type === USER_LOGOUT) {
      // Reset the state to its initial values on user logout
      state = undefined;
    }
    return appReducer(state, action);
  };

export default rootReducer
