import * as React from 'react';
import Box from '@mui/material/Box';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography, MenuItem, Select, InputLabel, InputAdornment, IconButton } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import { handleInputPhoneChange } from './helperComponent';
import { useSelector } from 'react-redux';

export default function BankDetail({ payoutData, setPayoutData, payoutDataErrors, handleInputChange }) {
  const [showAccountNo, setShowAccountNo] = useState('');
  const countryData = useSelector((state) => state.royaltyDataReducer.countryData);
  const accountType = [{ id: 1, name: 'Checking' }, { id: 2, name: 'Saving' },
  { id: 3, name: 'NRE' }, { id: 4, name: 'NRO' }, { id: 5, name: 'Other' }]

  const handleShowPassword = (name) => {
    setShowAccountNo(name);
  };

  return (
    <Box>
      <Box className='d-flex gap-2'>
        <Typography variant='h6'>Payment Method:</Typography>
        <Typography variant='h6'>Bank Transfer</Typography>
      </Box>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item container xs={12} direction={'row'} sx={{ margin: '0.5rem' }}>

          <Grid item xs={12} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='payment_currency'
              noValidate
              autoComplete="off"
              fullWidth={true}>
              <TextField
                name={`payment_currency`} label="Payment Currency" value={"INR"} fullWidth size='small' disabled />
            </FormControl>
          </Grid>

          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='account_holder_name'
              noValidate
              autoComplete="off"
              fullWidth={true}>
              <TextField
                onChange={handleInputChange}
                value={payoutData?.account_holder_name}
                error={payoutDataErrors.account_holder_name}
                helperText={payoutDataErrors.account_holder_name ? 'Please enter valid Account Holder Name' : ''}
                // helperText={payoutDataErrors.account_holder_nameErr}
                name={`account_holder_name`} label="Name on Account" fullWidth size='small' required />
            </FormControl>
          </Grid>
          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='account_number'
              noValidate
              autoComplete="off"
              fullWidth={true}>
              <TextField
                size='small'
                onBlur={handleInputChange}
                onChange={handleInputChange}
                value={payoutData?.account_number}
                error={payoutDataErrors.bank_number}
                helperText={payoutDataErrors.bank_numberErr}
                name={`account_number`} label="Account Number"
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='ifsc_code'
              noValidate
              autoComplete="off"
              fullWidth={true}>
              <TextField
                onBlur={handleInputChange}
                onChange={handleInputChange}
                value={payoutData?.ifsc_code}
                error={payoutDataErrors.ifsc}
                helperText={payoutDataErrors.ifscErr}
                name={`ifsc_code`}
                label="IFSC Code" fullWidth size='small'
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='bank_name'
              noValidate
              autoComplete="off"
              fullWidth={true}>
              <TextField
                onChange={handleInputChange}
                value={payoutData?.bank_name}
                error={payoutDataErrors.bank_name}
                helperText={payoutDataErrors.bank_name ? "Please enter valid bank name" : ""}
                // helperText={payoutDataErrors.bank_nameErr}
                name={`bank_name`} label="Bank Name" fullWidth size='small' required />
            </FormControl>
          </Grid>
          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='branch_name'
              noValidate
              autoComplete="off"
              fullWidth={true}>
              <TextField
                onChange={handleInputChange}
                value={payoutData?.branch_name}
                error={payoutDataErrors.branch_name}
                helperText={payoutDataErrors.branch_name ? "Please enter valid branch name" : ""}
                // helperText={payoutDataErrors.branch_nameErr}
                name={`branch_name`} label="Branch Name" fullWidth size='small' required />
            </FormControl>
          </Grid>
          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='account_type'
              noValidate
              autoComplete="off"
              fullWidth={true}>
              <InputLabel id="demo-simple-select-label">Account Type *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Account Type"
                size='small'
                name='account_type'
                onChange={handleInputChange}
                value={payoutData?.account_type}
              >
                {accountType.map((d, i) => (<MenuItem key={d.id} value={d.name}>{d.name}</MenuItem>))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='swift'
              noValidate
              autoComplete="off"
              fullWidth={true}>
              <TextField
                onChange={handleInputChange}
                error={payoutDataErrors.swift}
                helperText={payoutDataErrors.swiftErr}
                value={payoutData?.swift}
                name={`swift`}
                label="Swift" fullWidth size='small' />
            </FormControl>
          </Grid>

          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='bank_address'
              noValidate
              autoComplete="off"
              fullWidth={true}>
              <TextField
                onChange={handleInputChange}
                value={payoutData?.bank_address}
                error={payoutDataErrors.bank_address}
                helperText={payoutDataErrors.bank_addressErr}
                name={`bank_address`} label="Bank Address" fullWidth size='small' required />
            </FormControl>
          </Grid>

          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='bank_city'
              noValidate
              autoComplete="off"
              fullWidth={true}>
              <TextField
                onChange={handleInputChange}
                value={payoutData?.bank_city}
                error={payoutDataErrors.bank_city}
                helperText={payoutDataErrors.bank_city ? "Please enter valid bank city name" : ""}
                // helperText={payoutDataErrors.bank_cityErr}
                name={`bank_city`} label="Bank City" fullWidth size='small' required />
            </FormControl>
          </Grid>

          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='bank_pincode'
              noValidate
              autoComplete="off"
              fullWidth={true}>
              <TextField
                onChange={handleInputChange}
                value={payoutData?.bank_pincode}
                name={`bank_pincode`} label="Bank PIN Code" fullWidth size='small' />
            </FormControl>
          </Grid>

          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='bank_phone_number'
              noValidate
              autoComplete="off"
              fullWidth={true}>
              <PhoneInput
                country={'in'}
                name="bank_phone_number"
                inputProps={{ maxLength: 15 }}
                value={payoutData?.bank_phone_number}
                onChange={(event) => handleInputPhoneChange(event, payoutData, setPayoutData)}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='bank_country'
              noValidate
              fullWidth={true}>
              <InputLabel id="demo-simple-select-label">Country *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Country"
                size='small'
                required
                onChange={handleInputChange}
                value={payoutData?.country}
                name='bank_country'
                disabled
              >
                {countryData?.data?.length && countryData.data.map((d, i) => (
                  <MenuItem value={d.id} key={d.id}>
                    {d.country_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} >
            <FormControl margin='dense' sx={{ paddingLeft: '5px' }}>
              <FormLabel id="demo-radio-buttons-group-label">Set Default *</FormLabel>
              <RadioGroup
                row
                onChange={handleInputChange}
                value={payoutData?.status == "True" ? 'True' : 'False'}
                aria-labelledby="demo-radio-buttons-group-label"
                name="status"
              >
                <FormControlLabel value='True' control={<Radio />} label="Yes" />
                <FormControlLabel value='False' control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}