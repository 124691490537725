import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Box, CircularProgress, FormControlLabel, Link, Paper, Radio, Skeleton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip, useMediaQuery } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from 'react-redux';
import useRazorpay from "react-razorpay";
import { useLocation, useNavigate } from 'react-router-dom';
import ApiService from '../../_services/ApiService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useIsRTL } from 'react-bootstrap/esm/ThemeProvider';
import { useEffect } from 'react';
import { setLoader } from '../../redux';
import { useDispatch } from 'react-redux';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { getPlanList } from '../../_services/Plan/getPlanList.service';
import razorPay from "../../img/general/razor-pay.png"
import iciciGateway from "../../img/general/icici-gatweway.png"

function createData(release, track, studio, price) {
  return { release, track, studio, price };
}


export default function PayForDistribution() {
  const userData = useSelector((state) => state.userData.userData);
  const [Razorpay, isLoaded] = useRazorpay();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useNavigate()
  const gstPercentage = 18;

  const mdScreen = useMediaQuery('(max-width:768px)');
  const xmScreen = useMediaQuery('(max-width:300px)');
  const smScreen= useMediaQuery('(max-width:600px)');

  const [selectPlan, setSelectedPlan] = React.useState(499)
  const [planList, setPlanList] = React.useState([])
  const [planId, setPlanId] = React.useState(1)
  const [totalPayment, setTotalPayment] = React.useState()
  const [gatewayList, setGatewayList] = React.useState([{'key':"razorpay", 'label':"Razor Payment Gatway", 'img':razorPay, 'disabled':false},
                                                        {'key':"icicipay", 'label':"ICICI Payment Gatway", 'img':iciciGateway, 'disabled':true}])

  const [isPaymentAuthorized, setIsPaymentAuthorized] = React.useState(false)
  const [selectGateway, setSelectedGateway] = React.useState('razorpay')



  const paramsData = location?.state
  // const [Razorpay] = useRazorpay();
  const handleRazorPayment = React.useCallback(async () => {
    dispatch(setLoader(true))
    try {
      // Create the order on your server
      const formData = new FormData();
      formData.append("amount", totalPayment*100);
      formData.append("PlanID", planId);
      formData.append("studio", paramsData?.userData?.studio_id)
      formData.append("album", paramsData?.release?.album_id)
      formData.append('name', paramsData?.userData?.studio_name)
      formData.append('email', paramsData?.userData?.email)

      const response = await ApiService.post("generateOrderId", formData);
      if (!response.data.id) {
        throw new Error("Failed to create order");
      } else {
        dispatch(setLoader(false))
      }

      const order = response?.data?.id;

      // Configure Razorpay options
      const options = {
        key: process.env.REACT_APP_RAZOR_PAY_KEY, // Test Key
        amount: totalPayment*100, // Amount in paise
        currency: "INR",
        name: "GNTunes",
        description: "Paying for Release Distribution",
        image: process.env.REACT_APP_SONGAPIURL_MEDIA+"/media/media/logoGntunes.svg",
        order_id: order,
        callback_url: `${process.env.REACT_APP_CALLBACK_URL}?album=${paramsData?.release?.album_id}&planId=${planId}`,
        redirect: true,
        allow_rotation: true,
        prefill: {
          name: userData.studio_name,
          email: userData.email,
          contact: userData.phone_number,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#C61414",
        },
        modal: {
          ondismiss: function () {
            // Handle payment dismissal (optional)
            console.log("Payment modal dismissed");
          },
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    } catch (error) {
      console.error("Error processing payment:", error);
      // Handle payment errors gracefully (e.g., display an error message to the user)
    }
  }, [Razorpay, selectPlan, planId, totalPayment]);


  const handleICICIPayment = React.useCallback(async () => {

    console.debug("handleICICIPayment:handleICICIPayment gateway khulega" )

  }, [])


  const handleInputChange = (e) => {
    const parsedValue = parseInt(e.target.value);
    if (isNaN(parsedValue)) {
      console.error("Invalid input value for selectPlan");
      return;
    }
    setSelectedPlan(parsedValue);
    setPlanId(e.target.id)
    const totalAmount = getGstAddedGSTAmount(parsedValue, paramsData?.songList.length )
    setTotalPayment(totalAmount)
    // setTotalPayment(parsedValue * paramsData?.songList.length)

  }

  const handleGatewayChange = (e) => {
    console.debug("handleGatewayChange:handleGatewayChange", e.target.value)

    setSelectedGateway(e.target.value);
    // setPlanId(e.target.id)
    // const totalAmount = getGstAddedGSTAmount(parsedValue, paramsData?.songList.length )
    // setTotalPayment(totalAmount)
    // // setTotalPayment(parsedValue * paramsData?.songList.length)

  }

  const handleRouteBack = () => {

    history('/addrelease/' + paramsData?.release?.album_id)

  }

  function getGstAddedGSTAmount(amount, songCount){
    const gstAmount = (amount * songCount * gstPercentage) / 100;
    const totalprice = amount * songCount + gstAmount;
    return Math.ceil(totalprice)
  }

  useEffect(() => {
    getPlanList().then(res => {
      let response = res.data.data
      if (response) {
        setPlanList(response)
      }
    }).catch(function (error) {
      dispatch(setLoader(false))
      console.log(error);
      console.log(error.response);
      return error.response;
    })

    const totalAmount = getGstAddedGSTAmount(selectPlan, paramsData?.songList.length )

    setTotalPayment(totalAmount)
  }, [paramsData])

  useEffect(() => {
    const isAuthorized = location.state?.isAuthorized;
    setIsPaymentAuthorized(isAuthorized);

    // Handle browser back button navigation (optional)
    const onPopState = (event) => {
      if (!event.state?.isAuthorized) {
        history.replace('/releasealbum'); // Redirect to a secure page (e.g., home)
      }
    };

    window.addEventListener('popstate', onPopState);

    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, [history, location]);

  if (!location.state || !location.state.release || !location.state.userData || !location.state.songList) {
    // Redirect to home if accessed directly or without proper state
    return history('/releasealbum')
  }


  const background = ['rgba(233, 248, 241, 1)','rgba(209, 226, 255, 1)','rgba(196, 236, 255, 1)']
  const rows = [
    createData((<div className='d-flex'>
      <img src={process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' +paramsData?.release?.cover_image} className='mx-2' style={{width:'46px', borderRadius:'8px'}}/> <Typography className='payeeDistrbutecell pt-3'>{paramsData?.release?.album_name}</Typography></div>),
       paramsData?.songList.map((s, index)=> <Typography className='trackDistrbutecell trackPaymentDesign px-2 py-1 mb-1' style={{ backgroundColor: background[index % background.length] }}>{s.song.song_name}</Typography>),
       paramsData?.userData?.studio_name , selectPlan),

  ];



  return (
    <div>
      <button className="icononly-btn w-100 d-flex align-items-center justify-content-start" onClick={handleRouteBack}><ArrowBackIcon className='mb-3 mx-1' /><p className='cardheading mb-3'>Payment</p></button>
      <Box className='mb-5' sx={{
        border: '1px solid rgba(0, 0, 0, 0.12)',
        background: '#ffff',
        borderRadius: '10px',
        boxShadow: '0px 2px 10px 0px rgba(76, 78, 100, 0.22)'
      }}>
        <div className='row mt-3 px-4'>
          <div className="form-group col-md-12 ">
            <p className='m-0 mx-2'>Select Plan *</p>
            {planList.length > 0 ? (
              planList.map(p => (
                <FormControlLabel
                key={p.id}
                control={
                  <Radio
                    onChange={handleInputChange}
                    value={p.price.toString()}
                    id={p.id}
                    checked={selectPlan == p.price}
                    name='plan'
                    inputProps={{ 'aria-label': '0' }}
                  />
                }
                label={
                  <Link href='https://gntunes.com/pricing' target='_blank' sx={{ color: 'black', textDecoration: 'none' }}>
                  {p.name} <CurrencyRupeeIcon sx={{ fontSize: '15px' }} /> {p.price}
                </Link>
                  // <Typography>
                  //   {p.name} <CurrencyRupeeIcon sx={{ fontSize: '15px' }} /> {p.price} 
                  // </Typography>
                }
              />
              ))
            ) :  <Skeleton  animation="wave" height={70}/>}
          </div>
        </div>
        <Card
          variant="outlined"
          className='mt-3'
          sx={{
            maxHeight: 'max-content',
            maxWidth: '100%',
            mx: 'auto',
            borderRadius: "10px",
            // to make the demo resizable
            overflow: 'auto',
            width: "95%",
            resize: 'horizontal',
          }}
        >
          <Box className='d-flex p-3 px-4'>
            <CreditCardIcon />
            <Typography className='mx-2' sx={{ fontWeight: '500', fontSize: '16px' }} startDecorator={<InfoOutlined />}>
              DISTRIBUTION PAYMENT
            </Typography>
          </Box>
          <Divider inset="none" />
          <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead sx={{background: 'rgba(245, 245, 247, 1)'}}>
          <TableRow>
            <TableCell>RELEASE</TableCell>
            <TableCell align="left">TRACK</TableCell>
            <TableCell align="left">STUDIO</TableCell>
            <TableCell align="left">{xmScreen ||smScreen ?'PER/TRACK':'PRICE PER TRACK'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.release}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell align="left">
            
            {row.release}
              </TableCell>
              <TableCell align="left">{row.track}</TableCell>
              <TableCell align="left">{row.studio}</TableCell>
              <TableCell align="left"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} /> {row.price}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter sx={{ backgroundColor: 'rgba(245, 245, 247, 1)' }}>
                        <TableRow >
                        <TableCell> <Typography className='payDistrbuteFooterTitle  mb-2'>No.Song Total + Gst</Typography>
                        <Typography className='payDistrbuteFooterTitle  mb-2'>Total</Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell><Typography className='payDistrbuteFooterTitle  mb-2'>{paramsData?.songList.length } {xmScreen|| smScreen?'+ 18%':'songs + 18%'}</Typography>
                        <Typography className='payDistrbuteFooterTitle  mb-2'><CurrencyRupeeIcon sx={{ fontSize: '15px' }} /> {totalPayment}</Typography>
                        </TableCell>

                        </TableRow>
                    </TableFooter>
      </Table>
    </TableContainer>
                  </Card>
                  <div className='row mt-3 px-4'>
          <div className="form-group col-md-12 ">
            <p className='m-0 mx-2'>Select Gateway *</p>
            {gatewayList.length > 0 ? (
              gatewayList.map(p => (
                <FormControlLabel
                key={p.key}
                control={
                  <Radio
                    onChange={handleGatewayChange}
                    value={p.key.toString()}
                    id={p.key}
                    checked={selectGateway == p.key}
                    name='gatway'
                    inputProps={{ 'aria-label': '0' }}
                    // disabled={p.disabled}
                  />
                }
                label={
                  <Link href='https://gntunes.com/pricing' target='_blank' sx={{ color: 'black', textDecoration: 'none' }}>
                  {p.label} 
                  <img className='mx-2' src={p.img} width={100} height={25}/>
                </Link>
               
                  // <Typography>
                  //   {p.name} <CurrencyRupeeIcon sx={{ fontSize: '15px' }} /> {p.price} 
                  // </Typography>
                }
              />
              ))
            ) :  <Skeleton  animation="wave" height={70}/>}


          </div>
        </div>
        <div className="form-group col-md-12 mt-3 mb-3 " style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {/* <Typography className='payeeDistrbutecell'><ShoppingCartIcon sx={{ fontSize: '22px' }} /> Total Price: <CurrencyRupeeIcon sx={{ fontSize: '15px' }} /> {totalPayment}</Typography> */}
          <Tooltip title="" arrow>
            <span className=''>
              <button onClick={selectGateway == "razorpay"? handleRazorPayment:handleICICIPayment} className='nxt_actionbutton mx-3 py-1 px-4 fs14' >PAY NOW</button>
            </span>
          </Tooltip>
        </div>
      </Box>
    </div>
  );
}
