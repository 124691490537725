import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';

const WarningModal = React.forwardRef(({confirmHandler, type},ref) => {
const [open, setOpen] = React.useState(false);
const handleClose = () => setOpen(false);
const mediumViewport = useMediaQuery('(max-width:720px)');
const handleOpen = () =>setOpen(true)

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: mediumViewport?'95%':'50%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius:'8px',
    boxShadow: 24,
    p: 4,
    maxWidth:600,
    zIndex:2
};

/* React.useImperativeHandle(ref, () => ({
    handleOpen(){setOpen(true)}
  })); */
  React.useImperativeHandle(ref, () => ({
    handleOpen: handleOpen,
    handleClose:handleClose
  }));

return (
    <div>
    <Modal
        open={open}
        /* onClose={handleClose} */
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
    >
        <Box sx={style}>
            <div className="bg-white">
                <section className="header mb-4">
                    <h4 style={{fontWeight:'900'}}>Warning</h4>
                </section>
                <section className="body">
                        <p style={{fontWeight: '500',marginBottom:'0.5rem'}}>Are you sure? Please review all the information carefully. </p>
                       {type =="release"?<p style={{color: '#3333337a',fontWeight: '500'}}>Note*: Please verify all details before submitting for distribution. Charges will be re-applied, in case of redelivery/ redistribution, take down, or any updates to the track. </p>:

                        <p style={{color: '#3333337a',fontWeight: '500'}}>You will have the opportunity to edit the {type} later, but please note that once our team finalizes the {type}, further edits will not be possible.</p>}
                </section>
                <section className="footer d-flex justify-content-end">
                        <button className='gn-actionbtn outlined dark me-3' onClick={handleClose}>Cancel</button>
                        <button rea-label="Confirm" title="Confirm" className='gn-actionbtn' onClick={()=>confirmHandler()}>Confirm</button>
                </section>
            </div>
        </Box>
    </Modal>
    </div>
);
}) //forward ref ends here

export default WarningModal
