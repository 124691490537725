import React, { useEffect, useState } from 'react';
import { Button, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCreatorAssociates, fetchLoginUser, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { creatorAssociation } from '../../_services/creatorAssociation/creatorAssociation.service';
import { verifyRequestUID } from '../../_services/creatorAssociation/verifyuid.service';
import { formSnackbar } from '../../_constants/snackbar.constant';
import AddCreatorModal from './AddCreatorModal';
import ArtistTable from './ArtistTable';
import ArtistCard from './artistCard';
import { default_limit } from '../../_constants/recordLimitSet.constant';
import SnackbarContainer from '../Snackbar';
import { regexValidator } from '../../_helpers/reusablefunctions/regexValidator';
import { associateArtistSearch } from '../../_services/AssociateArtistSearch/associateArtist.service';
import { SearchList } from '../../_services/Artist/search.service';
import ResponsiveCardList from '../dashboard/DashboardListLayout';
import { Add } from '@mui/icons-material';
import SwitcherBtn from '../../components/reusableComponents/SwitcherBtn';
import SearchBox from '../../components/reusableComponents/SearchBox';


function ArtistList() {
  const [layoutComponent, setLayoutComponent] = useState(true);
  const [viewBtn, setViewBtn] = useState('none');
  const [open, setOpen] = useState(false);
  const associatesData = useSelector(state => state.creatorAssociatesStore.creatorAssociatesStore)

  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  const [associatedCreatorList, setAssociatedCreatorList] = useState(associatesData ? associatesData.results : []);
  const [requestUID, setRequestUID] = useState(null);
  const [requestUIDError, setRequestUIDError] = useState(false)
  const [requestUIDHelperText, setRequestUIDHelperText] = useState('')
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(default_limit);
  const [searchValue, setSearchValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleOpen = () => { setOpen(true); setSearchValue('') };
  const handleClose = () => { setOpen(false); setSearchValue(''); setRequestUID(null) }
  const [totalCount, setTotalCount] = useState(associatesData ? associatesData.total_count : 0)
  const [quickSearch, setQuickSearch] = useState('')
  const smallScreen = useMediaQuery('(max-width: 768px)');

  useEffect(function () {
    dispatch(setLoader(true))
    if (userData.studio_id) {
      dispatch(fetchLoginUser(userData.studio_id));
    }
  }, [userData.studio_id]);

  useEffect(() => {
    if (userData.studio_id && !quickSearch) {
      //to fetch the data immediately
      if (userData.role === 1) {
        dispatch(fetchCreatorAssociates(userData.studio_id, rowsPerPage, page))
      } else {
        if (userData.hasOwnProperty('associated_studio'))
          dispatch(fetchCreatorAssociates(userData.associated_studio, rowsPerPage, page))
      }

      const getStudioAssociated = setInterval(() => {

        if (userData.role === 1) {
          dispatch(fetchCreatorAssociates(userData.studio_id, rowsPerPage, page))
        } else {
          if (userData.hasOwnProperty('associated_studio'))
            dispatch(fetchCreatorAssociates(userData.associated_studio, rowsPerPage, page))
        }
      }, 30000);
      return () => clearInterval(getStudioAssociated);
    }
    if (quickSearch && quickSearch.length >= 1) {
      quickSearchApiHandler(quickSearch)
    }
  }, [userData.studio_id, quickSearch]);

  useEffect(() => {
    const getResult = setTimeout(() => {
      if (searchValue === '') {
        setIsDropdownOpen(false);
        setFilteredOptions([]);
      } else {
        if (requestUID === null)
          getSearchData(searchValue)
      }
    }, 400)
    return () => clearTimeout(getResult)
  }, [searchValue])

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setLoader(true));
    setPage(newPage);
  };

  const getSearchData = (value) => {
    dispatch(setLoader(true));
    associateArtistSearch({ searchValue: value }, 'SEARCH')
      .then(function (response) {
        dispatch(setLoader(false));
        if (response.status === 200) {
          setFilteredOptions(response.data.data);
        } else {
          dispatch(setSnackBar(true));
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
          dispatch(setSnackBarVariant('error'));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  useEffect(() => {
    // Update the associatedStudioList state whenever associatesData changes in the Redux store
    setAssociatedCreatorList(associatesData ? associatesData.results : []);
    setTotalCount(associatesData ? associatesData.total_count : 0);
  }, [associatesData]);


  const fetchAssociatedCreatorsList = (id, limit, offset) => {
    dispatch(fetchCreatorAssociates(id, limit, offset));
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const requestUIDChangeHandler = (e, newValue) => {
    setRequestUID(newValue)
    if (e.target.value.length === 0) {
      setRequestUIDError(false)
      setRequestUIDHelperText('')
    }
    else if (e.target.value.length > 0) {
      const isValid = regexValidator(e.target.value, 'alphanumeric')
      if (!isValid) {
        setRequestUIDError(true)
        setRequestUIDHelperText(formSnackbar.connectedCreators.invalidGNIDHelper)
      }
    }
  };

  const verifyUID = (value) => {
    return new Promise((resolve, reject) => {
      verifyRequestUID({ uid: value })
        .then(function (response) {
          if (response.status === 200) {
            if (response && response.data && response.data.data === true)
              resolve(true);
            else {
              resolve(false);
              dispatch(setLoader(false));
              dispatch(setSnackBar(true));
              dispatch(setSnackBarVariant('error'));
              dispatch(setSnackBarMessage(formSnackbar.connectedCreators.InvalidGNID));
              setTimeout(() => dispatch(setSnackBar(false)), 3000);
            }
          } else {
            dispatch(setLoader(false));
            dispatch(setSnackBar(true));
            dispatch(setSnackBarVariant('error'));
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
            reject(new Error('API call failed'));
          }
        })
        .catch(function (err) {
          dispatch(setLoader(false));
          dispatch(setSnackBar(true));
          dispatch(setSnackBarVariant('error'));
          dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
          reject(err);
        });
    });
  };

  const createConnection = async () => {
    dispatch(setLoader(true))
    const isValid = await verifyUID(requestUID.creator_gnid);
    if (!isValid) {
      return;
    } else {
      //current logged in user id
      const postData = {
        receiver_id: requestUID.ID,
        sender_type: 'studio',
        receiver_type: 'creator',
        status: 'pending',
      };

      if (userData.role === 1) {
        const senderId = parseInt(userData.studio_id);
        postData['sender_id'] = senderId;
      } else {
        if (userData.hasOwnProperty('associated_studio')) {
          const associatedID = parseInt(userData.associated_studio);
          postData['sender_id'] = associatedID;
        }
      }
      creatorAssociation(postData, 'POST')
        .then(function (response) {
          if (response.status === 200 || response.status === 201) {
            if (response && response.data && response.data.hasOwnProperty('isConnected')) {
              dispatch(setSnackBarVariant('warning'));
              dispatch(setSnackBarMessage(formSnackbar.connectedCreators.duplicateEntry));
              dispatch(setSnackBar(true));
              setTimeout(() => dispatch(setSnackBar(false)), 3000);
              handleClose();
              setRequestUID(null)
              dispatch(setLoader(false));
              return;
            }
            dispatch(setSnackBarVariant('success'));
            dispatch(setSnackBarMessage(formSnackbar.connectedCreators.Connection));
            dispatch(setSnackBar(true));
            setTimeout(() => dispatch(setSnackBar(false)), 2000);
            handleClose();
            setRequestUID(null)
            if (userData.role === 1) {
              fetchAssociatedCreatorsList(userData.studio_id, rowsPerPage, page)
            } else {
              if (userData.hasOwnProperty('associated_studio'))
                fetchAssociatedCreatorsList(userData.associated_studio, rowsPerPage, page)
            }

            dispatch(setLoader(false));
          }
          else {
            dispatch(setLoader(false));
            dispatch(setSnackBarVariant('error'));
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleLayoutComponent = (currentComp) => {
    setLayoutComponent(currentComp);
  }

  const handleUnlink = (senderId, receiverId) => {
    dispatch(setLoader(true))
    const postData = {
      sender_id: senderId,
      receiver_id: receiverId,
      status: "unlinked",
    };

    creatorAssociation(postData, 'DELETE').then(function (response) {
      if (response.status === 200 || response.status === 201) {
        if (userData.role === 1) {
          fetchAssociatedCreatorsList(userData.studio_id, rowsPerPage, page)
        } else {
          if (userData.hasOwnProperty('associated_studio'))
            fetchAssociatedCreatorsList(userData.associated_studio, rowsPerPage, page)
        }

        dispatch(setLoader(false))

      }
      else {
        dispatch(setLoader(false))
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
      }
    })
  };

  const quickSearchApiHandler = (quickSearch) => {
    if (quickSearch && quickSearch.length !== '') {
      const postData = {
        studio: userData.studio_id,
        search: quickSearch,
      };
      const fixedLimit = default_limit; // Fixed limit value
      const fixedOffset = 0; // Fixed offset value
      setRowsPerPage(default_limit)
      setPage(0)
      dispatch(setLoader(true));
      SearchList(postData, 'SEARCH', fixedLimit, fixedOffset)
        .then(function (response) {
          dispatch(setLoader(false));
          if (response.status === 200) {
            setAssociatedCreatorList(response.data.results);
            setTotalCount(response.data.total_count);
          } else {
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleSearchInput = (value) => {
    setQuickSearch(value)
  };

  return (
    <div >
      <h2>Connected Creators</h2>
      <div className={`d-flex justify-content-between align-items-center`}>
        <div className="flex-grow-1" style={{ paddingRight: '1.5rem', maxWidth: '18rem' }}>
          <SearchBox data={associatedCreatorList} handleSearchInput={handleSearchInput} />
        </div>
        <div className='d-flex align-items-center gap-3'>
          {userData.role !== 3 ? <button className="gn-actionbtn mx-1 d-flex" onClick={handleOpen} size='small'>
            <div><Add /></div> <div>Associate</div>
          </button> : ''}
          <SwitcherBtn layoutComponent={layoutComponent} handleLayoutComponent={handleLayoutComponent} />
        </div>
      </div>
      <div className='mt-3 d-flex justify-content-between align-items-center'>

        {/* <Typography className='mx-2' style={{ fontSize: '0.8rem' }}>Showing all the data</Typography> */}
        <Button className='linkBtn clearFilter' endIcon={<CloseIcon />} disableRipple /*onClick={clearFilter}*/ sx={{ display: viewBtn }}><Typography className='linkBtnTxt' sx={{ textTransform: 'capitalize' }}>Clear Filter</Typography></Button>
      </div>
      <div>
        {layoutComponent ? (

          <ArtistTable
            data={associatedCreatorList}
            totalCount={totalCount}
            fetchAssociatedCreatorsList={fetchAssociatedCreatorsList}
            handleUnlink={handleUnlink}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            userRole={userData.role}
          />
        )
          : (
            <ArtistCard
              data={associatedCreatorList}
              fetchAssociatedCreatorsList={fetchAssociatedCreatorsList}
              handleUnlink={handleUnlink}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              totalCount={totalCount}
            />
          )}
      </div>
      <AddCreatorModal
        open={open}
        handleClose={handleClose}
        requestUID={requestUID}
        createConnection={createConnection}
        requestUIDChangeHandler={requestUIDChangeHandler}
        requestUIDError={requestUIDError}
        requestUIDHelperText={requestUIDHelperText}
        filteredOptions={filteredOptions}
        searchValue={searchValue}
        handleSearch={handleSearch}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        setRequestUID={setRequestUID}

      />
    </div>
  )
}

export default ArtistList;
