import React, { useEffect } from 'react';

const CircleSpinner = ({ progress, audioStatus, audioPausedTime }) => {
  const spinnerSize = 68; // Define the size of the spinner
  const circumference = 2 * Math.PI * (spinnerSize / 2);
  let progressOffset = circumference - (progress / 100) * circumference;

  useEffect(() => {
    if (!audioStatus) {
      progressOffset = (
        circumference - ((progress / 100) * circumference - audioPausedTime)
      );
    } else {
      const currentTime = Date.now();
      const deltaTime = currentTime - audioPausedTime;
      progressOffset = (progressOffset - deltaTime * (progress / 100));
    }
  }, [audioStatus, progress, audioPausedTime, progressOffset]);

  return (
    <svg width={spinnerSize} height={spinnerSize}>
      <circle
        r={spinnerSize / 2 - 5}
        cx={spinnerSize / 2}
        cy={spinnerSize / 2}
        fill="transparent"
        stroke="#F08F21"
        strokeWidth="4"
        opacity={0.5}
      />
      <circle
        r={spinnerSize / 2 - 5}
        cx={spinnerSize / 2}
        cy={spinnerSize / 2}
        fill="transparent"
        stroke="#ED3237"
        strokeWidth="4"
        strokeDasharray={circumference}
        strokeDashoffset={progressOffset}
        strokeLinecap="round"
        style={{
          transform: 'rotate(-90deg)', // Rotate the circle to start from the top
          transformOrigin: 'center', // Rotate around the center
        }}
      />
    </svg>
  );
};

export default CircleSpinner;
