import { Menu as MenuIcon, } from '@mui/icons-material';
import { AppBar, IconButton, styled, Toolbar, useMediaQuery } from '@mui/material';
import User from '../../components/utils/user';
import gnLogo from '../../img/header/logoGntunes.svg';

const drawerWidth = 300;

const StyledAppBar = styled(AppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
  }));


function Header({open,onSidebarOpen }) {
return (
    <>
      <StyledAppBar position="fixed" open={open} className="bg-white shadow-none border-bottom" >
        <Toolbar className="justify-content-between p-0">
		<IconButton
              className="text-black imgBtn"
              aria-label="open drawer"
              onClick={onSidebarOpen}
              edge="start"
			  disableRipple
			  sx={{marginLeft:'0.5rem'}}>
              <MenuIcon />
            </IconButton>
            <User />
        </Toolbar>
      </StyledAppBar>
	  {/* <Sidebar open={open} />   */}
    </>  
  );
}

export default Header;
