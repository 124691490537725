import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, Typography, IconButton, LinearProgress, Box, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteModal from '../../components/reusableComponents/DeleteModal';

function LinearProgressWithLabel({ value, error }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center',width:'100%' }}>
      <Box sx={{ flexGrow:1, mt: 1,alignItems:'left' }}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box sx={{ ml:1, }}>
      {value === 100 && (
        <>
          {error ? (
            <ErrorSharpIcon fontSize='medium' color='error' />
          ) : (
            <CheckCircleIcon fontSize='medium' color='success' />
          )}
        </>
      )}
      </Box>
    </Box>
  );
}

const FileUploadCard = ({ agreementId,file, fileDetails,handlePreview ,removePdf, progressHandler, error,isAgreementSubmitted }) => {
  const [selectedFile, setSelectedFile] = useState(file);
  const [uploadProgress, setUploadProgress] = useState(0);
  const ref = useRef();

  useEffect(() => {
    setSelectedFile(file);
    const timer = setInterval(() => {
      setUploadProgress((prevProgress) => {
        const newProgress = prevProgress + 10;
        return newProgress >= 100 ? 100 : newProgress;
      });
    }, 200);

    return () => {
      clearInterval(timer);
    };
  }, [file]);

  useEffect(() => {
    if (uploadProgress > 100) {
      progressHandler(100);
    } else {
      progressHandler(uploadProgress);
    }
  }, [uploadProgress, progressHandler]);

  const handleFileClick = () => {
   handlePreview(fileDetails);
  };

  return (
    
        selectedFile !== null && (
          isAgreementSubmitted  ? 
            <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, width: '100%' }}>
            <Box sx={{ mt: 1 }}>
              <UploadFileIcon fontSize="large" color="primary" />
            </Box>
            <Box sx={{ ml: 2, alignItems: 'left', flexGrow: 1 }}>
              
                <Typography variant="h6" onClick={handleFileClick} color="primary" sx={{ cursor: 'pointer', fontSize: '1rem', '@media (max-width: 600px)': { fontSize: '0.875rem' } }}>
                  {fileDetails.name}
                </Typography>
              
              <Typography variant="subtitle1" component="span" sx={{ fontSize: '0.875rem', '@media (max-width: 600px)': { fontSize: '0.75rem' } }}>
                {`${(fileDetails.size / 1024).toFixed(2)} KB`} |
              </Typography>
              <Typography variant="body2" component="span" color="text.secondary" sx={{ textAlign: 'center', fontSize: '0.875rem', '@media (max-width: 600px)': { fontSize: '0.75rem' } }}>
                File uploaded successfully!
              </Typography>
            </Box>
            <Box sx={{ flexShrink: 0 }}>
              <IconButton className="p-0" onClick={() => ref.current.handleOpen()} disableRipple disableFocusRipple disableTouchRipple>
                <CloseIcon />
              </IconButton>
              <DeleteModal cardId={agreementId && agreementId} ref={ref} deleteHandler={removePdf} />
            </Box>
          </Box>

            </>:
            <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 ,width:'100%'}}>
              <Box sx={{  mt: 1, }}>
                 <UploadFileIcon fontSize='large' color='primary' />
              </Box>
              <Box sx={{ml: 2,alignItems:'left',flexGrow:1 }}>
                { uploadProgress === 100 ? <Typography variant="h6" onClick={handleFileClick} color='primary' style={{ cursor: 'pointer',fontSize:'1rem'  }}>{fileDetails.name}</Typography>
                : <Typography variant="h6" style={{fontSize:'1rem'  }}>{fileDetails.name}</Typography>}
                <Typography variant="subtitle1" component="span"sx={{fontSize:'0.875rem'}}>{`${(fileDetails.size / 1024).toFixed(2)} KB`} | </Typography>
                  
                {error ? uploadProgress < 100 ?(
                    <Typography variant="body2" component="span" color="text.secondary" sx={{ textAlign: 'center' }}>
                      Loading...
                    </Typography>) : (
                  <Typography variant="body2" component="span" color="error" sx={{ textAlign: 'center' }}>
                    Invalid File!
                  </Typography>
                ) : (
                  uploadProgress < 100 ?(
                    <Typography variant="body2" component="span" color="text.secondary" sx={{ textAlign: 'center' }}>
                      Loading...
                    </Typography>) :(
                    <Typography variant="body2" component="span" color="text.secondary" sx={{ textAlign: 'center',fontSize:'0.875rem' }}>
                      File uploaded successfully!
                    </Typography>
                  )
                )}
              </Box>  
              <Box>
                <IconButton className='p-0' onClick={()=>ref.current.handleOpen()} disableRipple disableFocusRipple disableTouchRipple>
                  <CloseIcon />
                </IconButton>
                <DeleteModal cardId={agreementId && agreementId} ref={ref} deleteHandler={removePdf}/>
              </Box>
              
            </Box>
            
           
            <LinearProgressWithLabel value={uploadProgress}  error={error} />
           
          </>
        )
     
  );
};

export default FileUploadCard;
