import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, FormControl, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ApiService from '../../_services/ApiService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch } from 'react-redux';
import { fetchMusicCategoryDetail, setLoader } from '../../redux';
import { todaysDate } from '../../_helpers/reusablefunctions/dateSetter';

const SongPitchInformation = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const songDataParams = location?.state || ''
    const rejectValue = songDataParams?.songDataParams?.pitching_status == "Reject"
    const genre = useSelector((state) => state.musicCategorgyReducer?.genreData ? state.musicCategorgyReducer?.genreData.data : []);
    const language = useSelector((state) => state.musicCategorgyReducer?.languageData ? state.musicCategorgyReducer?.languageData.data : []);
    const userData = useSelector((state) => state.userData.userData);
    const [errors, setErrors] = useState({});
    const [songMetaList, setSongMetaList] = useState([]);
    const [songData, setSongData] = useState([]);
    const [pitchData, setPitchData] = useState({
        label: songDataParams?.songDataParams?.label || '',
        singer: songDataParams?.songDataParams?.singer || '',
        lyricist: songDataParams?.songDataParams?.lyricist || '',
        music: songDataParams?.songDataParams?.music || '',
        song_description: '',
        artist_overview: '',
        drive_link: songDataParams?.songDataParams?.drive_link || '',
        artist_drive_link: songDataParams?.songDataParams?.artist_drive_link || '',
        song_id: songDataParams?.songDataParams?.song_id || '',
        song_name: songDataParams?.songDataParams?.song_name || '',
        release_date: songDataParams?.songDataParams?.release_date || '',
        album_name: songDataParams?.songDataParams?.album_name || '',
        language: songDataParams?.songDataParams?.language || '',
        genre: songDataParams?.songDataParams?.genre || ''
    })
    const pitchnote = [{ defaultValue: 'Fill in all the required details asked for like: Release date, title album, language, singer, music, label name, genre, lyricist code and more.' },
    { defaultValue: 'Attach artist’s image in .jpg, .png, or .jpeg format. ' }, { defaultValue: 'Cover art in (.jpg, .jpeg or .png)format. ' }, { defaultValue: 'Cover art PSD file is a must.  ' },
    { defaultValue: 'Add about the song description in not more than 500 characters (including spaces)' }, { defaultValue: 'Add about the artist description in not more than 500 characters (including spaces) ' }
    ]
    const pitchNoteStyle = {
        fontSize: '16px',
        color: '#545454'
    }

    useEffect(() => {
        if (userData.studio_id) {
            getSongMeta()
            if (genre == undefined || genre.length == 0) {
                dispatch(fetchMusicCategoryDetail("genreData", `GENRE`))
            }
            if (language == undefined || language.length == 0) {
                dispatch(fetchMusicCategoryDetail('languageData', `LANGUAGE`))
            }
        }
    }, [userData.studio_id])

    function getSongMeta() {
        ApiService.get('songMeta', {}, userData.studio_id).then(res => {
            if (res.status == 200) {
                const removeDraft = res.data.results.filter(d => d.song_status !== "draft")
                setSongMetaList(removeDraft)
            }
        }).catch(function (error) {
            console.log("error", error)
        })
    }

    function onPitchHandler(e) {
        const { name, value } = e.target;
        const newErrors = {};

        if (name == "song_id") {
            const filteredData = songMetaList.find(d => d.song_id == value)
            setSongData(filteredData || {});
            setPitchData({
                ...pitchData,
                [name]: value,
                'song_name': filteredData?.song_name,
                'release_date': filteredData?.release_date,
                'album_name': filteredData?.album?.[0]?.album_name,
                'language': language?.find(item => item.id === filteredData?.language)?.language || "",
                'genre': genre?.find(item => item.id === filteredData?.genre)?.genre,
                'isrc_code': filteredData?.isrc_code || '',
                'upc_code': filteredData?.upc_code || '',
            });
        } else {
            setPitchData({
                ...pitchData,
                [name]: value
            });
        }

    }

    const SelectField = [
        { width: 'my-2 w-50', id: "song", label: "Select Song*", value: pitchData.song_id || songDataParams?.songDataParams?.song_id || '', onChange: onPitchHandler, require: true, name: 'song_id', listSong: songMetaList, disabled: rejectValue ? true : false },
    ]

    const fields = [
        { width: 'my-2 w-50', id: "type", label: "Pitch Type", value: rejectValue ? "RePitch" : "New" || "", onChange: onPitchHandler, name: 'pitching_type', disabled: true },
        { width: 'my-2 w-100', id: "url", label: "Sharable Google Drive Link of PSD file of Poster/Artwork", value: pitchData.drive_link || songDataParams?.songDataParams?.drive_link || '', onChange: onPitchHandler, require: true, name: 'drive_link', disabled: rejectValue ? true : false },
        { width: 'my-2 w-100', id: "photourl", label: "Artist Photos Drive Link", value: pitchData.artist_drive_link || songDataParams?.songDataParams?.artist_drive_link || '', onChange: onPitchHandler, require: true, name: 'artist_drive_link', disabled: rejectValue ? true : false },
        { width: 'my-2 w-50', id: "release-date", label: "Release Date", value: songData?.release_date || songDataParams?.songDataParams?.release_date || '', disabled: true, },
        { width: 'my-2 w-50', id: "title-album", label: "Title/Album", value: songData?.album?.[0]?.album_name || songDataParams?.songDataParams?.album_name || '', disabled: true, },
        { width: 'my-2 w-50', id: "label-name", label: "Label Name", value: pitchData.label || songDataParams?.songDataParams?.label || '', onChange: onPitchHandler, name: 'label', require: false },
        { width: 'my-2 w-50', id: "genre", label: "Genre", value: genre?.find(item => item.id === songData?.genre)?.genre || songDataParams?.songDataParams?.genre || '', disabled: true, },
        { width: 'my-2 w-100', id: "language", label: "Language", value: language?.find(item => item.id === songData?.language)?.language || songDataParams?.songDataParams?.language || '', disabled: true, },
        { width: 'my-2 w-50', id: "singer", label: "Singer", value: pitchData.singer || songDataParams?.songDataParams?.singer || '', onChange: onPitchHandler, require: true, name: 'singer', disabled: rejectValue ? true : false },
        { width: 'my-2 w-50', id: "lyricist", label: "Lyricist", value: pitchData.lyricist || songDataParams?.songDataParams?.lyricist || '', onChange: onPitchHandler, require: true, name: 'lyricist', disabled: rejectValue ? true : false },
        { width: 'my-2 w-100', id: "music-producer", label: "Music Producer", value: pitchData.music || songDataParams?.songDataParams?.music || '', onChange: onPitchHandler, require: true, name: 'music', disabled: rejectValue ? true : false },
        { width: 'my-2 w-100', id: "about-song", label: "About The Song", value: pitchData.song_description || '', onChange: onPitchHandler, require: true, name: 'song_description', multiline: true, rows: 4 },
        { width: 'my-2 w-100', id: "artist-overview", label: "Artist Overview", value: pitchData.artist_overview || "", onChange: onPitchHandler, require: true, name: 'artist_overview', multiline: true, rows: 4 },
    ];


    const validateFields = () => {
        const newErrors = {};
        const driveLinkRegex = /https:\/\/drive\.google\.com\/(file\/d\/|drive\/folders\/|open\?id=|uc\?id=)[\w-]+/;
        SelectField.forEach(field => {
            if (field.require && !pitchData[field.name]) {
                newErrors[field.name] = `${field.label} is required`;
            } else if (field.name === 'drive_link' && !driveLinkRegex.test(pitchData[field.name])) {
                newErrors[field.name] = `${field.label} is not a valid Google Drive link`;
            } else if (field.name === 'artist_drive_link' && !driveLinkRegex.test(pitchData[field.name])) {
                newErrors[field.name] = `${field.label} is not a valid Google Drive link`;
            }
        });
        fields.forEach(field => {
            if (field.require && !pitchData[field.name]) {
                newErrors[field.name] = `${field.label} is required`;
            } else if (field.name === 'drive_link' && !driveLinkRegex.test(pitchData[field.name])) {
                newErrors[field.name] = `${field.label} is not a valid Google Drive link`;
            } else if (field.name === 'artist_drive_link' && !driveLinkRegex.test(pitchData[field.name])) {
                newErrors[field.name] = `${field.label} is not a valid Google Drive link`;
            }
        });
        return newErrors;
    };

    function handleSubmit(e) {
        e.preventDefault();
        const repitch_status = songDataParams?.songDataParams?.pitching_status == "Reject" ? "True" : "False"
        const pitchingDate = todaysDate()
        const pitchingStatus = rejectValue ? "RePitch" : "New"
        const newErrors = validateFields();
        if (Object.keys(newErrors).length === 0) {
            setErrors({});
            dispatch(setLoader(true))
            ApiService.post('pitchSong', { ...pitchData, pitching_type: pitchingStatus, repitch_status: repitch_status, pitching_date: pitchingDate, publisher_type: 'studio', publisher_id: userData.studio_id, }).then((res) => {
                console.log("error", res)
                if (res.status == 200 || res.status == 201) {
                    dispatch(setLoader(false))
                    navigate('/songpitch')
                }
            }).catch((error) => {
                console.log("error", error)
                dispatch(setLoader(false))
            })
        } else {
            setErrors(newErrors);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className='mb-3'>
                <button className="icononly-btn" onClick={() => navigate(-1)}><ArrowBackIcon /> Back</button>
            </div>
            <Card className='mb-5'>
                <CardContent>
                    <p className='cardheading'>Fill the form for playlist pitching:</p>
                    <p className='cardheading mt-2'>Details Required:</p>
                    <List>
                        {pitchnote.map((d, i) => (
                            <ListItem key={i} className='p-0'>
                                <ListItemText className='d-flex align-items-baseline gap-2 m-0' primary={<Typography sx={pitchNoteStyle}>{i + 1}.</Typography>} secondary={<Typography sx={pitchNoteStyle}>{d.defaultValue}</Typography>} />
                            </ListItem>
                        ))}
                    </List>
                    {SelectField.map((d, i) => (
                        <FormControl className={d.width} key={d.id} >
                            <InputLabel id="demo-simple-select-label">{d.label}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id={d.id}
                                name={d.name}
                                label={d.label}
                                value={d.value}
                                onChange={d.onChange}
                                disabled={d.disabled}
                                required
                            >
                                {d?.listSong.map(v => <MenuItem key={v.song_id} value={v.song_id}>{v.song_name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    ))}

                    {fields.map((field) => (
                        <TextField
                            className={field.width}
                            key={field.id}
                            id={field.id}
                            name={field.name}
                            label={field.label}
                            value={field?.value}
                            defaultValue={field?.value}
                            onChange={field.onChange}
                            onBlur={field.onChange}
                            disabled={field.disabled}
                            multiline={field.multiline}
                            rows={field.rows}
                            inputProps={{
                                maxLength: (field.id == 'about-song' || field.id == 'artist-overview') && 500,
                                minLength: (field.id == 'about-song' || field.id == 'artist-overview') && 100,
                            }}
                            required={field.require}
                            error={!!errors[field.name]}
                            helperText={errors[field.name]}
                        />
                    ))}
                </CardContent>
                <CardActions className='justify-content-end pe-3 pt-0'>
                    <Button type="submit" sx={{ backgroundColor: '#E41116 !Important', color: '#ffffff' }} className='px-3 pt-2'>Submit</Button>
                </CardActions>
            </Card>
        </form>
    )
}

export default SongPitchInformation;