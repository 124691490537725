import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton, useMediaQuery } from '@mui/material';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
// import ErrorIcon from '@mui/icons-material/Error';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const PaymentSuccessDialog =React.forwardRef(({data, paymentFail},ref) => {

    const [open, setOpen] = React.useState(false);
const handleClose = () => setOpen(false);
const mediumViewport = useMediaQuery('(max-width:720px)');
const handleOpen = () =>setOpen(true)

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: mediumViewport?'95%':'40%',
    height:mediumViewport?'100%':'65%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius:'8px',
    boxShadow: 24,
    p: 2,
    maxWidth:320,
    zIndex:2
};

/* React.useImperativeHandle(ref, () => ({
    handleOpen(){setOpen(true)}
  })); */

    React.useImperativeHandle(ref, () => ({
        handleOpen: handleOpen
      }));
  return (
    <div>
    <Modal
        open={open}
        /* onClose={handleClose} */
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
    >
        <Box sx={style}>
            <div className="bg-white">
                <div className='text-end'>
                <CloseIcon  onClick={handleClose} />
                </div>
                <section className="header mb-3 text-center">
               {paymentFail == true?<ReportProblemIcon color="error" sx={{width:'50px !important',height:'50px !important'}}/> :<CheckCircleIcon color="success" sx={{width:'50px !important',height:'50px !important'}}/>}
                    {/* <h4 style={{fontWeight:'900'}}>Warning</h4> */}
                </section>
              
                
                <section className="body text-center">
                {paymentFail == true? <p style={{fontWeight: '500',marginBottom:'0.5rem'}}>Oh  no, Payment Failed! </p> :<p style={{fontWeight: '500',marginBottom:'0.5rem'}}>Payment successful! 🎉</p>}
                {paymentFail == true? <p style={{color: '#3333337a',fontWeight: '500'}}> Your transaction has failed. Please go back and try again </p>   : <p style={{color: '#3333337a',fontWeight: '500'}}>Your transaction has been completed. </p>}
                {paymentFail == true?<p style={{color: '#3333337a',fontWeight: '500'}}> Don't worry you can try your payment after sometime... </p>: <p style={{color: '#3333337a',fontWeight: '500'}}>Release will be sent to our team for review. Any changes suggested by our team can be made later, but after submission, you won't able to edit further .</p>}
        
          </section>
                <section className="footer d-flex justify-content-center">
                        
                        <button rea-label="Confirm" title="Confirm" className='gn-actionbtn' onClick={handleClose}>OK</button>
                </section>
            </div>
        </Box>
    </Modal>
    </div>
  );
});

export default PaymentSuccessDialog;
