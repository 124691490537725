import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import ProfileImg from '../../img/header/profileImage.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import Person2Icon from '@mui/icons-material/Person2';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';
import { Badge, Divider, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_constants/snackbar.constant';
import { notificationRequestHandler } from '../../_services/notifications/notifications.service';
import { creatorAssociation } from '../../_services/creatorAssociation/creatorAssociation.service';
import NotificationComponent from '../../module/notification/Notifications';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';


function User(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const [notification, setNotification] = React.useState([]);
  const [notificationBox, setNotificationBox] = React.useState(null);
  const [notificationCount, setNotificationCount] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      maxWidth: '13.9rem',
    },
  }));

  useEffect(() => {
    if (userData.studio_id) {
      const getData = setInterval(() => {
        notificationData(userData.studio_id)
      }, 30000)
      return () => clearInterval(getData)
    }
  }, [userData.studio_id])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeNotificationMenu = () => {
    setNotificationBox(null);
  };

  function notificationData(id) {
    notificationRequestHandler({ 'id': id }, 'GET').then(function (response) {
      setNotification(response.data.notifications);
      setNotificationCount(response.data.unread_notifications_count);
    }).catch(function (error) {
      console.log(error);
      console.log(error.response);
      return error.response;
    })
  }

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <NotificationComponent />
        <Tooltip>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, px: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disableRipple
          >
            <BootstrapTooltip title='Account Settings' ransitioncomponent={Zoom} >
              <Avatar src={userData.avatar ? process.env.REACT_APP_BASEURL_MEDIA + '/media/' + userData.avatar : ProfileImg}>
              </Avatar>
            </BootstrapTooltip>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            width: '200px',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <Typography variant="h6" className='text-decoration-none text-black mx-3' sx={{ fontSize: '1rem', padding: '0.5rem', color: 'rgba(0, 0, 0, 0.6)', fontWeight: 600 }}>{userData.role !== 3 ? userData.studio_name : userData.name}</Typography>
        <Typography variant="h6" className='text-decoration-none text-black mx-4' sx={{ fontSize: '1rem', color: 'rgba(0, 0, 0, 0.6)', fontWeight: 600 }}>GNID:{userData.studio_gnid}</Typography>

        <Divider sx={{ marginLeft: '10px', marginRight: '10px' }} />
        {userData.role !== 3 ? <Link to="/account" className='text-decoration-none text-black '>
          <MenuItem>
            <ListItemIcon>
              <Person2Icon fontSize="small" />
            </ListItemIcon>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>Account</Typography>
          </MenuItem>
        </Link> : ''}
        <Link to="/FAQs" className='text-decoration-none text-black '>
          <MenuItem>
            <ListItemIcon>
              <LiveHelpIcon fontSize="small" />
            </ListItemIcon>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>FAQs</Typography>
          </MenuItem>
        </Link>
        <Link to="/logout" className='text-decoration-none text-black'>
          <MenuItem>
            <ListItemIcon>
              <Logout fontSize="small" className='flip-horizontal' />
            </ListItemIcon>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>Logout</Typography>
          </MenuItem>
        </Link>
      </Menu>
    </div>

  );
}
// }
export default User;