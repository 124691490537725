import { Add } from '@mui/icons-material'
import React from 'react'
import SearchBox from '../../components/reusableComponents/SearchBox'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { associatedArtistsRequestHandler } from '../../_services/associatedArtists/associatedArtist.service'
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux'
import { formSnackbar } from '../../_constants/snackbar.constant'
import { Box, Button, Grid, TablePagination, useMediaQuery } from '@mui/material'
import NoResults from '../../components/reusableComponents/NoResults'
import ClickableCardLayout from '../../components/reusableComponents/ClickableCardLayout'
import Loader from '../../components/utils/Loader'
import { default_limit, record_limits } from '../../_constants/recordLimitSet.constant'
import { useTheme } from '@emotion/react'


function AssociateArtistList(props) {
  const userData = useSelector(state=>state.userData.userData)
  const [associateList, setAssociateList] =useState([])
  const dispatch = useDispatch()
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let medScreen = useMediaQuery('(max-width:768px)')
  const[searchInput, setSearchInput] = useState(null)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(default_limit);
  const [totalCount,setTotalCount]=useState()
  const isNextButtonDisabled = associateList.length < rowsPerPage || (page + 1) >= Math.ceil(totalCount / rowsPerPage);
  const isPreviousButtonDisabled = page === 0;
  useEffect(() => {
    if (userData.studio_id) {
      if(searchInput && searchInput.length>0){
        // When the component mounts or userData.studio_id changes, reset the page to 0
        if(userData.role === 1){
          getSearchList(searchInput,userData.studio_id)
       } else{
         if(userData.hasOwnProperty('associated_studio') ){ 
          getSearchList(searchInput,userData.associated_studio)
        }    
       }
         }
      else
      {
        if(userData.role === 1){
          getAssociatedArtist(userData.studio_id, page, rowsPerPage);
       } else{
         if(userData.hasOwnProperty('associated_studio') ) 
          getAssociatedArtist(userData.associated_studio, page, rowsPerPage);
       }
         }
    }
  }, [userData.studio_id, page,rowsPerPage]);


  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    // Ensure that the new page is still within the valid range after changing rowsPerPage
    const newPage = Math.min(Math.floor((page * rowsPerPage) / newRowsPerPage), Math.ceil(1000 / newRowsPerPage) - 1);
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // const newOffset = newPage ;
    // getAssociatedArtist(userData.id, newPage, rowsPerPage, newOffset);
  };


  const getAssociatedArtist = (userId, page, rowsPerPage) => {
    const limit = rowsPerPage?rowsPerPage:default_limit;
    const offset = page?page:0;
    dispatch(setLoader(true));
    associatedArtistsRequestHandler({ userId: userId }, 'GET', limit, offset)
      .then((response) => {
        dispatch(setLoader(false));
        setAssociateList(response.data.results)
        setTotalCount(response.data.total_count)
      })
      .catch((err) => {
        dispatch(setLoader(false));
        dispatch(setSnackBar(true));
        setTimeout(() => dispatch(setSnackBar(false), 2000));
        dispatch(setSnackBarVariant('error'));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
      });
  };

const getSearchList = (searchText ,userId, limit, offset) =>{
    dispatch(setLoader(true))
    associatedArtistsRequestHandler({'userId':userId,searchData:searchText},'SEARCH',rowsPerPage, page)
    .then(response=>{
        dispatch(setLoader(false))
        setAssociateList(response.data.data)
    })
    .catch(err=>{
       dispatch(setLoader(false)) 
       dispatch(setSnackBar(true));
       setTimeout(()=>dispatch(setSnackBar(false),2000));
       dispatch(setSnackBarVariant('error'))
       dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
    })
}

const handleSearchInput = (value)=>{
    setSearchInput(value)
  }
  useEffect(() => {
    const getData = setTimeout(() => {
      if(page>0)
        setPage(0)
      else  
        searchInput!=null && searchInput.length>0?(
        userData.role === 1?(
            getSearchList(searchInput,userData.studio_id)
        ):(userData.hasOwnProperty('associated_studio') &&
            getSearchList(searchInput,userData.associated_studio))
      ):(userData.studio_id && 
        userData.role === 1 ?(
          getAssociatedArtist(userData.studio_id, page, rowsPerPage)   
        ):(userData.hasOwnProperty('associated_studio')&& 
          getAssociatedArtist(userData.associated_studio, page, rowsPerPage)      
        ))
    }, 400)

    return () => clearTimeout(getData)
  }, [searchInput])
  //get sets the list data  

  return (
    <div >
        <h2 >Associated Artist</h2>
        <div className='d-flex justify-content-between'>
            <div className='flex-grow-1' style={{maxWidth:'18rem'}}>
              <SearchBox handleSearchInput={handleSearchInput} value={searchInput}/>
            </div>
        </div>
        <div className='mt-3 d-flex justify-content-between'>
            <div>
            {userData.role !==3 ?  <button className='gn-actionbtn' onClick={props.handleChange} size='small'><Add />Add Artist</button>:''}
            </div>
        </div>
        {/* {loading ? <Loader size={40} /> : */}
        <div className="mt-3">
        {associateList && associateList.length > 0 ? (
        !isSmallScreen ? (
          <>
          <Box className="card-container">
            {associateList.map((item, index) => (
              <div key={item.artist_id} >
                <ClickableCardLayout cardData={item} moduleName={'associateList'} dataList={associateList} />
              </div>
            ))}
           </Box>
            <TablePagination
            rowsPerPageOptions={record_limits}
            colSpan={3}
            sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px', marginBottom: '2rem' }}
            className="cardPagination"
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          nextIconButtonProps={{ disabled: isNextButtonDisabled }}
        />
        </>
        ) :(
          <React.Fragment>
            <Box className='card-container'>
            {associateList.map((item, index) => (
              <div key={item.id} > {/* Apply grid styles to individual cards */}
                <ClickableCardLayout
                  cardData={item} 
                  moduleName={'associateList'} 
                  dataList={associateList} 
                 />  
              </div>
            ))}
           </Box>
           <div className="d-grid pagination-container mb-5"
            style={{ width: '100%', marginTop: '20px' }} >
            <div>
                <button onClick={() => handleChangePage(null, page - 1)}
                    disabled={isPreviousButtonDisabled}
                    className={`pagination-button gn-actionbtn ${isPreviousButtonDisabled ? 'disabled' : 'enabled'}`}
                    size='small'>
                    Previous
                </button>
            </div>
            
            <div>
                <button
                onClick={() => handleChangePage(null, page + 1)}
                disabled={isNextButtonDisabled}
                className={`pagination-button gn-actionbtn ${isNextButtonDisabled ? 'disabled' : 'enabled'}`}
                size='small'>
                Next
                </button>
            </div>
            </div>
          </React.Fragment>

        )):(
        
          <NoResults />
        )}
        
    </div>
    {/* } */}
    </div>
  )
}
export default AssociateArtistList