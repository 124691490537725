
export function getDateAsPerFormat(cDate, dateFormat) {
  const localDate = new Date(cDate); // Convert date to local timezone
  const dayOfTheMonth = localDate.getDate();
  const month = localDate.getMonth();
  const year = localDate.getFullYear();

  switch (dateFormat) {
    case 'dd/MM/yyyy':
      return `${dayOfTheMonth}/${month + 1}/${year}`;
    case 'MM/dd/yyyy':
      return `${month + 1}/${dayOfTheMonth}/${year}`;
    case 'DD Mon, YYYY':
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      const monthName = months[month];
      return `${dayOfTheMonth} ${monthName}, ${year}`;
    default:
      return `${dayOfTheMonth}/${month + 1}/${year}`;
  }
}

export function getTime(cDate) {
  const timestamp = cDate;
  const localTime = new Date(timestamp).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
  const gmtTime = new Date(timestamp).toUTCString();
  const time = new Date(timestamp).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
  return time;
}

export function todaysDate() {
  const todaydate = new Date();
  const year = todaydate.getFullYear();
  const month = String(todaydate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(todaydate.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}