import { Password } from '@mui/icons-material'
import {Box, Divider, Grid,Modal,Paper } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { fetchLoginUser } from '../../redux/loginUser/loginUserActions';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { setSelfToken } from '../../_helpers/auth/secureToken'
import { login } from '../../_services/Login'
import { activateUserEmail } from '../../_services/Register/ActivateUsingEmail'
import LoginHeader from '../header/AdminHeader'
import LoginForm from './loginForm'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserDetail, setLoader } from '../../redux'
import SnackbarContainer from '../../module/Snackbar'
import { user } from '../../config/auth/AppConstants';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius:3,
  boxShadow: 24,
  p: 3
};


const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root:{
          backgroundColor: '#FFFFFF',
          padding: '1rem',
          border: '1px solid rgba(0, 0, 0, 0.2);',
          boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.11)',
          borderRadius: '6px',
          
        },
      },
    },
  },
});
const Login = () => {
const navigate = useNavigate();
let {tokenKey,tokenValue} = useParams();
const [open, setOpen] = React.useState(false); //to show and hide the modal
const handleClose=()=>setOpen(false)
const dispatch = useDispatch();
const userData = useSelector((state) => state.userData.userData);
const userDetail = useSelector((state) => state.userDetail.userDetail);
const [modalText, setModalText] = useState('')
const [credError, setCredError] = useState('')
useEffect(() => {
  const token = localStorage.getItem('token');
  if (token) {
    navigate('/dashboard');
  }
}, []);

useEffect(()=>{
  if(tokenKey && tokenValue){
   let fullURL = tokenKey+'/'+tokenValue
    activateUserEmail(fullURL)
    .then(function (response) {
          if(response.data.data[0].message == 'Your account has been confirmed.'){
          setOpen(true)
          console.debug("activateUserEmail:activateUserEmail:response", response.data.data[0].message)
          setModalText(response.data.data[0].message)
          setSelfToken(response.data.data[0].token);
          // localStorage.setItem('token',response.data.data[0].token)
          // localStorage.setItem(user.studioUserId,response.data.data[0].users.studio_id)
          dispatch(fetchUserDetail(response.data.data[0].users.studio_id));
          setTimeout(()=>setOpen(false),2000)
          navigate('/dashboard');
          }else{
          setOpen(true)
          setModalText(response.data)
          setTimeout(()=>setOpen(false),2000)
          }
        //  }
        // else
        // {
        //     setOpen(true)
        //     setModalText('Some error occured while activating your account. Please try again later')
        // }
    }).catch(function (error) {
       return error.response;
    })
  }
},[])


const handleLogin = (username,password) => {
 dispatch(setLoader(true))
 try {
    // Request an access token using the OAuth 2.0 flow
    const formData = new FormData();
    let cred ={username,password}
    Object.entries(cred).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        formData.append('username', username);
        formData.append('password', password);
      } else {
        formData.append(key, value);
      }
    });
    login(formData)
    .then(function (response) {
    if (response.status === 200) {
      setSelfToken(response.data.token);
      if (response.data.data[0].studio_id) {
        dispatch(fetchUserDetail(response.data.data[0].studio_id));
      }
      // Redirect to the dashboard
      navigate("/dashboard");
      dispatch(setLoader(false))
     } else {
      // Show an error message
      //alert("Invalid username or password.");
      dispatch(setLoader(false))
      setCredError("Invalid username or password.");
    }
  }) 
  } catch (error) {
    dispatch(setLoader(false))
    console.error("Error:", error);
  }
 };
return (
  <Box sx={{ flexGrow: 1, height: '100vh' }}>
  <Grid container justifyContent="center" alignItems="start" sx={{ height: '100%' }} className="loginFormSection">
    <Grid item xs={12} sx={{ height: '10%' }}>
      <LoginHeader />
      <Divider /> 
    </Grid>
    <Grid item container xs={12} justifyContent="center" sx={{ height: '90%',padding: '1rem 0rem'  }}>
      <Grid item container spacing={4} xs={12} sm={10} md={8} lg={6} direction="column" margin='0.5rem'>
        <div style={{ textAlign: 'center', paddingBottom: '1rem', marginTop: { xs: '0.5rem', sm: '1rem' } }}>
          <h2 className="fw-bold" style={{fontSize:'2.25rem'}}>Sign In</h2>
        </div>
        <ThemeProvider theme={theme}>
          <Paper sx={{ padding: { xs: '0.5rem', sm: '0.5rem',maxWidth:'50rem',width:'100%',alignSelf:'center' } }}>
            <Grid item xs={12} sx={{ padding: { xs: '0rem', sm: '0.5rem' }, textAlign: 'center' }}>
              <h5 className="fw-bold" style={{fontSize:'1.25rem',color:'rgba(0, 0, 0, 0.7)'}}>Welcome back to GNTunes Studios</h5>
            </Grid>
            <Grid item xs={12} sx={{ padding: { xs: '0rem', sm: '0.5rem' }, textAlign: 'center' }}>
              <h6 className="fw-normal" style={{fontSize:'1.125rem'}}>Have a great day</h6>
            </Grid>
            <LoginForm handleLogin={handleLogin} error={credError} />
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <h6>{modalText}.</h6>
              </Box>
            </Modal>
          </Paper>
        </ThemeProvider>
      </Grid>
      <SnackbarContainer />
    </Grid>
  </Grid>
</Box>
)
}

export default Login