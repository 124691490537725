import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Songtable from './SongListTable';
import SubmitSongCard from './SongListCard';
import AddIcon from '@mui/icons-material/Add';
import SearchBox from '../../components/reusableComponents/SearchBox';
import SwitcherBtn from '../../components/reusableComponents/SwitcherBtn';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMusicCategoryDetail, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant, fetchSongReleaseList } from '../../redux';
import { formSnackbar } from '../../_constants/snackbar.constant';
import { submitSong } from '../../_services/submitAsong/submitAsong.service';
import { useMediaQuery } from '@mui/material';
import SongFilters from './SongFilters';
import Chip from '@mui/material/Chip';
import { default_limit } from '../../_constants/recordLimitSet.constant.js';
import { fetchReleaseAlbum } from '../../redux/releaseAlbum/releaseAlbumDataActions';
import { associatedArtistsRequestHandler } from '../../_services/associatedArtists/associatedArtist.service';
import { artistCode } from '../songList/associatedArtist/artistStaticCodes';
import { handleArtistData } from '../songList/helperComponent/helperComponent';
import AlbumCard from '../album/AlbumCard.js';
import { useMemo } from 'react';
import CreateAlbumModal from '../album/CreateAlbumModal.js';
import ReleaseCard from '../album/ReleaseCard.js';
import ApiService from '../../_services/ApiService.js';


function SongList(props) {
  let history = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const smScreen = useMediaQuery('(max-width:768px)')
  //to get the search input value
  const [searchInput, setSearchInput] = useState(null)
  const [layoutComponent, setLayoutComponent] = useState(true)
  const [songList, setSongList] = useState([])
  const [renderComponent, setRenderComponent] = useState()
  /* const [open, setOpen] = useState(false) */
  const [assoArtistList, setAssoArtistList] = useState([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isFilterIniated, setIsFilterInitiated] = useState(false)
  const [filterPayload, setFilterPayload] = useState(null)
  const [primaryArtList, setPrimaryArtList] = React.useState([])
  const [producerList, setProducerList] = React.useState([])
  const genre = useSelector((state) => state.musicCategorgyReducer?.genreData ? state.musicCategorgyReducer?.genreData.data : []);
  const subGenreList = useSelector((state) => state.musicCategorgyReducer?.subGenreData ? state.musicCategorgyReducer?.subGenreData.data : []);
  const [albumList, setAlbumList] = useState([]);
  const albumData = useSelector((state) => state.releaseAlbumReducer?.releaseAlbumData)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    if (genre == undefined || genre.length == 0) {
      dispatch(fetchMusicCategoryDetail("genreData", `GENRE`))
    }
    if (subGenreList == undefined || subGenreList.length == 0) {
      dispatch(fetchMusicCategoryDetail("subGenreData", `SUBGENRE`))
    }
  }, []);

  useEffect(() => {
    if (userData.studio_id != undefined) {
      dispatch(setLoader(true))
      dispatch(fetchReleaseAlbum(userData.studio_id, { limit: rowsPerPage, offset: page }, [], null));
      getAssociatedArtist();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [userData.studio_id, rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

  };

  const handleClick = (e, data) => {
    e.stopPropagation()
    e.preventDefault()
    history(`/addrelease/${data.album_id}`);
  }

  const deleteAlbum = (e, data) => {
    e.stopPropagation()
    e.preventDefault()
    ApiService.delete('deleteAlbum', {}, data.album_id).then(res => {
      if (res.status == 200 || res.status == '201') {
        let newAlbum = albumList.results.filter(d => d.album_id !== data.album_id)
        setAlbumList({ results: newAlbum })
        dispatch(setSnackBar(true))
        dispatch(setSnackBarMessage('Album deleted successfully.'))
        dispatch(setSnackBarVariant('success'))
        setTimeout(() => dispatch(setSnackBar(false)), 2000);
      }
    }).catch(function (error) {
      console.log(error);
      console.log(error.response);
    });

  }

  const renderAlbumCard = () => {
    return (
      <ReleaseCard albumListData={albumList}
        subGenreList={subGenreList}
        genre={genre}
        assoArtistList={assoArtistList}
        getArtistName={getArtistName}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        handleClick={handleClick}
        deleteAlbum={deleteAlbum} />
    )
  }

  const renderAlbumGrid = () => {
    return (
      <Songtable albumListData={albumList}
        subGenreList={subGenreList}
        genre={genre}
        assoArtistList={assoArtistList}
        getArtistName={getArtistName}
        page={page}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        handleClick={handleClick}
        deleteAlbum={deleteAlbum} />
    )
  }
  useEffect(() => {
    if (albumList.results) {
      layoutComponent && setRenderComponent(renderAlbumGrid());
    }
  }, [albumList, layoutComponent]);

  useEffect(() => {
    if (albumData.results) {
      dispatch(setLoader(false)); // Check if albumList is empty before setting
      setAlbumList(albumData);
    }
  }, [albumData]);
  const getAssociatedArtist = (name, artistData, isNewArtist) => {
    let postData = {}
    if (userData.role === 1) {
      postData['userId'] = userData.studio_id
    } else {
      if (userData.hasOwnProperty('associated_studio')) {
        postData['userId'] = userData.associated_studio
      }
    }
    associatedArtistsRequestHandler(postData, 'GET')
      .then(response => {
        //set the associated artist list
        if (response.data) {
          setAssoArtistList(response.data.results)
          isNewArtist && artistSelectChangeHandler(name, artistData, isNewArtist)  //call the handler when new artist has beed added
        }
      })
      .catch(err => {
        dispatch(setSnackBar(true));
        setTimeout(() => dispatch(setSnackBar(false), 2000));
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      })
  }
  const artistSelectChangeHandler = (name, artistData, isNewArtist) => {
    switch (name) {
      case artistCode.primary_artist:
        handleArtistData(name, primaryArtList, setPrimaryArtList, 'primary_artists', isNewArtist, artistData, artistCode);
        break;
      case artistCode.producer:
        handleArtistData(name, producerList, setProducerList, 'producers', isNewArtist, artistData, artistCode);
        break;

      default:
        break;
    }
  }
  const setAssodata = (d) => {
    let data = {
      'id': d,
      'artistCode': `studio${d}`
    }
    return data
  }
  function getArtistName(a) {
    let assoPrimary = a.map(p => setAssodata(p))
    if (a) {
      const matchingArtists = assoArtistList.length > 0 && assoArtistList.filter(artist => assoPrimary.find(item => item.artistCode == artist.artist_code))
    }

  }

  useEffect(() => {
    if (!layoutComponent) {
      setRenderComponent(renderAlbumCard())
      setLayoutComponent(true)
    }
  }, [smScreen])

  //to get the searchbox input and filter the data
  //to hit the search and display the result
  const getSearchSongData = (value, userId) => {
    const offset = page;
    const limit = rowsPerPage;
    dispatch(setLoader(true))
    submitSong({ 'searchValue': value, publisherType: 'studio', publisherId: userId }, 'SEARCH', limit, offset).then(function (response) {
      dispatch(setLoader(false))
      if (response.status === 200) {
        setAlbumList(response.data)
        setRenderComponent(renderAlbumGrid())
        copyHandleLayoutRerender(layoutComponent, response.data)
      }
      else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
      }
    }).catch(function (error) {
      dispatch(setLoader(false))
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return error.response;
    })
  }
  const handleSearchInput = (value) => {
    if (value) {
      setSearchInput(value)
    } else {
      dispatch(fetchReleaseAlbum(userData.studio_id, { limit: rowsPerPage, offset: page }, [], null));
    }
    //getSearchSongData(value) //call the search to filter the data
  }
  useEffect(() => {
    const getData = setTimeout(() => {
      //searchInput!=null && getSearchSongData(searchInput,userData.studio_id)  //commented the code to avoid multiple calls of same 
      if (page > 0)
        page > 0 && setPage(0)//to set offeset to 0 on every fresh search
      else
        searchInput != null && getSearchSongData(searchInput, userData.studio_id)
    }, 400)

    return () => clearTimeout(getData)
  }, [searchInput])
  //get sets the list data

  //advance search function
  const handleAdvanceFilter = (payload) => {
    if (page > 0) {
      setIsFilterInitiated(true)
      setPage(0)
      setFilterPayload(payload)
    }
    else {
      applyAdvanceFilters(payload)
    }
  }

  const applyAdvanceFilters = (payload) => {
    const offset = page;
    const limit = rowsPerPage;
    dispatch(setLoader(true))
    let dataObj = { publisher_id: userData.studio_id, publisher_type: 'studio', ...payload }
    submitSong(dataObj, 'ADVANCESEARCH', limit, offset).then(function (response) {
      dispatch(setLoader(false))
      if (response.status === 200) {
        setIsFilterApplied(true)
        setAlbumList(response.data)
        setRenderComponent(renderAlbumGrid())
        copyHandleLayoutRerender(layoutComponent, response.data)
      }
      else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
      }
    }).catch(function (error) {
      dispatch(setLoader(false))
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return error.response;
    })
  }
  const removeFilters = () => {
    setIsFilterApplied(false)
    getSearchSongData(undefined, userData.studio_id)
  }



  //to set the current display component card or table view
  const handleLayoutComponent = (currentComp) => {
    setLayoutComponent(currentComp)
    currentComp ? setRenderComponent(renderAlbumGrid()) : setRenderComponent(renderAlbumCard())
  }
  //to render the component on search
  const copyHandleLayoutRerender = (currentComp, songData) => {
    setLayoutComponent(currentComp)
    currentComp ? setRenderComponent(renderAlbumGrid()) : setRenderComponent(renderAlbumCard())

  }

  const openDrawer = () => {
    // history("/addrelease")
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };


  return (
    <div >
      <h2>  Release Lists</h2>
      <div className={`d-flex justify-content-between`}>
        <div className='flex-grow-1' style={{ maxWidth: '18rem' }}><SearchBox handleSearchInput={handleSearchInput} /></div>
        <div className='d-flex justify-content-end align-items-center'>
          <button className='gn-actionbtn' onClick={openDrawer} ><AddIcon />New Release</button>
          <div className=''><SongFilters isFilterApplied={isFilterApplied} applyAdvanceFilters={handleAdvanceFilter} /></div>
          <div>
            <SwitcherBtn layoutComponent={layoutComponent} handleLayoutComponent={handleLayoutComponent} />
          </div>
        </div>
      </div>
      <div className='my-3 d-flex justify-content-between'>
        <div>
          {isFilterApplied ? <Chip label="Clear all filters" onDelete={removeFilters} /> : null}
        </div>
      </div>
      {renderComponent}
      <CreateAlbumModal isOpen={isDrawerOpen}
        onClose={closeDrawer}
        genre={genre}

      />
    </div>
  )
}

export default SongList