import React, { useEffect } from 'react';
import { Button, Checkbox, CircularProgress, ListItemIcon, Menu, MenuItem, MenuList, Paper, Typography, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from 'react-router-dom'
import CircleSpinner from './CircleSpinner';
import AudioPlayer from '../../components/reusableComponents/customAudioPlayer';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter';

function DemoCardList({ cardData, handleDeleteItem }) {
  const navigate = useNavigate()
  const smScreen = useMediaQuery('(max-width:600px)');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [audioPausedTime, setAudioPausedTime] = useState(0); // Time spent on pause
  const [audioLength, setAudioLength] = useState(0); // Audio length in seconds
  const [audioProgress, setAudioProgress] = useState(0); // Current playback progress
  const [audioStatus, setAudioStatus] = useState(false); // Current playback progress
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const routeChangeHandler = () => {
    const route = '/demoReceiver/' + cardData.id;
    navigate(route, { state: cardData });
  }
  useEffect(() => {
    if (audioStatus) {
      const audio = new Audio();
      const handleMetadataLoaded = () => {
        setAudioLength(Math.floor(audio.duration)); // Set audio duration when metadata is loaded
      };

      if (cardData?.demo_file) {
        audio.src = `${process.env.REACT_APP_BASEURL}gndemo/media/${cardData.demo_file}`;
        audio.addEventListener('loadedmetadata', handleMetadataLoaded);
      }
      return () => {
        audio.removeEventListener('loadedmetadata', () => { }); // Clean up event listener
        audio.src = ''; // Clear the audio src to prevent memory leaks
      };
    }
  }, [audioStatus]);

  // Rest of your components remain unchanged

  useEffect(() => {
    const interval = setInterval(() => {
      if (audioProgress < audioLength && audioStatus) {
        setAudioProgress((prevProgress) => prevProgress + 1);
      }
      if (audioProgress == audioLength && audioProgress != 0 && audioLength != 0) {
        setAudioProgress(0)
        setAudioLength(0)
        setAudioPausedTime(0)
        setAudioStatus(false)
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [audioProgress, audioLength, audioStatus]);

  return (
    <div className="col-md-12 my-2 col-sm-12 col-12 border bg-white" style={{ borderRadius: '1rem' }} >
      <div style={{ position: 'relative', borderRadius: '100px' }}>
        <div className='d-flex p-2 justify-content-between'>
          <div className='d-flex align-items-center'>
            {/* <Checkbox {...label} style={{
              color: '#ADD8E6', // Border color
              '&.Mui-checked': {
                color: '#ADD8E6', // Color when checkbox is checked
              },
            }} /> */}

            <div className="mobile-buttons">
              {cardData.audio_clip !== null && (cardData.external_link === null || cardData.external_link === '' || cardData.external_link === undefined) && (cardData.video_link === null || cardData.video_link === '' || cardData.video_link === undefined) ?
                <div className='ms-1 me-2 position-relative'>
                  <div className='position-absolute circle-spinner-container'>
                    <AudioPlayer setAudioStatus={setAudioStatus}
                      audioStatus={audioStatus} setAudioPausedTime={setAudioPausedTime}
                      type="x-small"
                      audioTitle={cardData.demo_name}
                      audioDescription={cardData.description}
                      audioSrc=
                      {cardData.demo_file ?
                        `${process.env.REACT_APP_BASEURL}gndemo/media/${cardData.demo_file}`
                        : ''}
                    />
                  </div>
                  <div >
                    <CircleSpinner audioPausedTime={audioPausedTime} audioStatus={audioStatus} progress={(audioProgress / audioLength) * 100} />
                  </div>
                </div> :
                (
                  <div className='ms-1 me-2 ' >
                    <Button
                      size="small"
                      onClick={() => window.open((cardData.external_link || cardData.video_link), '_blank')}
                      className='text-danger rounded-circle'
                      style={{
                        padding: 0,

                      }}
                    >
                      <div
                        style={{
                          width: '54px',
                          height: '54px',
                          borderRadius: '50%',
                          background: '#6C6C6C',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          margin: 0,
                          '&:hover': { backgroundColor: '#6C6C6C8c' }
                        }}

                      >
                        <OpenInNewIcon style={{ color: 'white', padding: 0 }} />
                      </div>
                    </Button>
                  </div>
                )
              }
            </div>
            <div className='mt-2 mb-2 text-truncate'>
              <div className='d-flex '>
                <div onClick={routeChangeHandler} style={{ cursor: 'pointer' }}>
                  <strong>
                    {smScreen ?
                      <span className='text-capitalize' title={cardData.demo_name}>
                        {cardData.demo_name && cardData.demo_name.length > 25 ? `${cardData.demo_name.slice(0, 10)}...` : cardData.demo_name}
                      </span> :
                      <span className='text-capitalize' title={cardData.demo_name}>
                        {cardData.demo_name && cardData.demo_name.length > 25 ? `${cardData.demo_name.slice(0, 50)}...` : cardData.demo_name}
                      </span>
                    }
                  </strong>
                </div>
                <div>
                  <button className='border-0 rounded-1 ms-2 d-flex justify-content-center align-items-center'
                    style={{
                      background: '#DBEAFE',
                      color: '#1E40AF',
                      fontSize: '0.8rem',
                    }}
                  >
                    {cardData.demo_type === false ? 'Final' : 'Scratch'}
                  </button>

                </div>
              </div>
              <div className={`mt-0 ${smScreen ? 'text-truncate-sm' : ''}`}>
                <p title={cardData.demo_description} style={{ fontSize: '0.95rem' }} className='text-secondary detailText m-0'> {cardData.demo_description && cardData.demo_description.length > 25 ? `${cardData.demo_description.slice(0, 70)}...` : cardData.demo_description}</p>
              </div>
              <div>
                {
                  cardData.genre.map((genre) => (
                    <span
                      key={genre.id}
                      style={{
                        border: '1px solid #ccc',
                        padding: '1px 8px',
                        borderRadius: '4px',
                        marginRight: '5px', // Add spacing between genres
                        fontSize: '0.8rem'
                      }}
                    >
                      {genre.genre}
                    </span>
                  ))}
              </div>
            </div>
          </div>
          <div className='d-flex align-items-end d-flex flex-column justify-content-end'>
            <div className={`align-items-end d-flex ${smScreen ? '' : 'me-4'}`} >
              {smScreen ? '' : <strong>Submit Date</strong>}
              <span style={{ fontSize: '0.8rem', marginBottom: '3px', marginLeft: '10px' }}>
                {cardData.created_at && getDateAsPerFormat(cardData.created_at, "dd/MM/yyyy")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DemoCardList;
