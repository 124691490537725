
// API To register studio
export const registerApi = {
    REGISTER: process.env.REACT_APP_BASEURL + 'studio/register',//to changed to gateway
}

//Studio login api
export const loginApi = {
    LOGIN: process.env.REACT_APP_BASEURL + 'studio/login',
}
export const logoutApi = {
    LOGOUT: process.env.REACT_APP_BASEURL + 'studio/logout',
}
//get studio by ID
export const loginUserApi = {
   VIEW: process.env.REACT_APP_BASEURL + 'studio/studios/',
} 

/*Show all artist api without Studio filter*/
export const getArtistDataApi = {
    GET: process.env.REACT_APP_BASEURL + 'studio_info/user_auth/showAll/', //'https://content_creater_gnapi.eela.tech/api/v1/category',
    USRHANDLE: process.env.REACT_APP_BASEURL + 'studio_info/user_auth/find-userHandle'
 }
 export const getArtistDataByUsrHandle = {
   GET: process.env.REACT_APP_BASEURL + 'studio_info/user_auth/find-userHandle',
   GETBYID: process.env.REACT_APP_BASEURL + 'studio_info/user_auth/users'

}
// Activate studio account
export const  activateUserAccount = {
   GET: process.env.REACT_APP_BASEURL + 'studio/activate/',
}

export const resetPasswordApi = {
   RESET:process.env.REACT_APP_BASEURL + 'studio/reset-password',
   CHANGE: process.env.REACT_APP_BASEURL + 'studio/passwordreset/',//https://studioapidev.gncreators.com/studio/change-password/
}
export const ordersApi = {
   ORDERS: process.env.REACT_APP_BASEURL + 'api/order',
}
export const userInfoApi = {
   // VIEW: 'https://content_creater_gnapi.eela.tech/api/v1/user/1',
   VIEW: process.env.REACT_APP_BASEURL + 'user_auth/users',//'http://gncreatorsdev.gncreators.com/user_auth/users' //process.env.REACT_APP_BASEURL+'api/login-user',
}
export const userTypeApi = {
   VIEW: process.env.REACT_APP_BASEURL + '/studio_info/userType/' //'https://content_creater_gnapi.eela.tech/api/v1/userType',
   // VIEW: process.env.REACT_APP_BASEURL+'api/login-user',
}
export const updateUserInfoApi = {
   UPDATE:  process.env.REACT_APP_BASEURL + 'user_auth/users/edit',//'studio_info/user',//'https://content_creater_gnapi.eela.tech/api/v1/user/',
   // VIEW: process.env.REACT_APP_BASEURL+'api/login-user',
}

export const userApi = {
   LIST: process.env.REACT_APP_BASEURL + 'api/v1/users',
}

// ___________________ PROFILE API'S________________________
// export const socialApi = {
//    LIST: 'https://content_creater_gnapi.eela.tech/api/v1/link/',
// }
export const userProfileApi = {
   UPDATE: process.env.REACT_APP_BASEURL +'user_auth/users/edit',//'https://content_creater_gnapi.eela.tech/api/v1/user/', to update the user profile data
   UPDATEPROFILE:process.env.REACT_APP_BASEURL +'user_auth/user/detail'
}
export const getCategoryApi = {
   GET: process.env.REACT_APP_BASEURL + 'studio_info/category/', //'https://content_creater_gnapi.eela.tech/api/v1/category',
}

export const updateCategoryApi = {
   ADD: process.env.REACT_APP_BASEURL + 'studio_info/category', //'https://content_creater_gnapi.eela.tech/api/v1/category/',
   UPDATE: process.env.REACT_APP_BASEURL + 'studio_info/category'
}
export const getGenreApi = {
   GET: process.env.REACT_APP_BASEURL + 'studio_info/genre/'//'https://content_creater_gnapi.eela.tech/api/v1/genre',
}
export const getExpertiseApi = {
   GET: process.env.REACT_APP_BASEURL + 'studio_info/expertiseLevel/', //'https://content_creater_gnapi.eela.tech/api/v1/expertiseLevel',
   UPDATE:process.env.REACT_APP_BASEURL + 'studio_info/expertiseLevel'
}
export const getExperienceApi = {
   GET: process.env.REACT_APP_BASEURL + 'studio_info/experience/user',//'https://content_creater_gnapi.eela.tech/api/v1/experience',
   POST:process.env.REACT_APP_BASEURL + 'studio_info/experience',
}
export const deleteExperienceApi = {
   DELETE: process.env.REACT_APP_BASEURL + 'studio_info/experience',//'https://content_creater_gnapi.eela.tech/api/v1/experience/',
}
export const userHandleApi = {
   GET: process.env.REACT_APP_BASEURL + 'user_auth/check-userHandle/',//https://content_creater_gnapi.eela.tech/api/v1/check-userHandle'
}

export const userSocialTypeApi = {
   GET: process.env.REACT_APP_BASEURL + 'studio_info/social-type/' //'https://content_creater_gnapi.eela.tech/api/v1/social-type'
}

export const userSocialLinkApi = {
   GET: process.env.REACT_APP_BASEURL + 'studio_info/social-link/user/' //'https://content_creater_gnapi.eela.tech/api/v1/social-link'
}
export const userPostSocialLinkApi = {
   POST: process.env.REACT_APP_BASEURL + 'studio_info/social',//'https://studioapidev.gncreators.com/studio_info/social,
   GET: process.env.REACT_APP_BASEURL + 'studio_info/social-link/user/',//'https://studioapidev.gncreators.com/studio_info/social-link'
   UPDATE:  process.env.REACT_APP_BASEURL + 'studio_info/social',
}

export const userDeleteSocialLinkApi = {
   DELETE: process.env.REACT_APP_BASEURL + 'studio_info/social-link',//'https://content_creater_gnapi.eela.tech/api/v1/social-link/'
}

export const createrLink= {
   GET: process.env.REACT_APP_BASEURL + 'studio_info/link/user/', //"https://content_creater_gnapi.eela.tech/api/v1/link/"
   POST: process.env.REACT_APP_BASEURL + 'studio_info/link'
}

export const getThumbnailLink = {
   GET:process.env.REACT_APP_BASEURL  + 'studio_info/thumbnail/', //"http://gncreatorsdev.gncreators.com/studio_info/thumbnail/"
}
export const updateSequenceLink = {
   PUT:process.env.REACT_APP_BASEURL  + 'studio_info/update-sequence/', //"http://gncreatorsdev.gncreators.com/studio_info/thumbnail/"
}

// export const commonMusicLink = {
//    GETALL:process.env.REACT_APP_BASEURL + 'releases/getSongList/',
//    UPDATE:process.env.REACT_APP_BASEURL + 'releases/createSongList',
//    GETSINGLE:process.env.REACT_APP_BASEURL + 'releases/createSongList',
//    POST:process.env.REACT_APP_BASEURL + 'releases/createSongList',
//    DELETE:process.env.REACT_APP_BASEURL + 'releases/createSongList',
//    GETBYUSER:process.env.REACT_APP_BASEURL + 'releases/getsongList/user',
//    SETSONGSEQUENCE:process.env.REACT_APP_BASEURL + 'releases/song-sequence/',

// }

export const tagsListURL = {
   GET:process.env.REACT_APP_BASEURL + 'studio_info/tags/',
   POST:process.env.REACT_APP_BASEURL + 'studio_info/tags',
}

export const streamingPlatformURL = {
   GET:process.env.REACT_APP_BASEURL + 'releases/streamingPlatforms/',
}

// export const songRelatedPlatformURL = {
//    POST:process.env.REACT_APP_BASEURL + 'releases/relatedStreamingPlatforms',
//    GETALL:process.env.REACT_APP_BASEURL + 'releases/streamingPlatforms/',
//    GETBYSONG:process.env.REACT_APP_BASEURL + 'releases/getrelatedStreamingPlatforms/song',
//    PUT:process.env.REACT_APP_BASEURL + 'releases/relatedStreamingPlatforms',
//    DELETE:process.env.REACT_APP_BASEURL + 'releases/relatedStreamingPlatforms',
// }
/* export const getUserTypesLink = {
   GET:"http://gncreatorsdev.gncreators.com/studio_info/userType/"
} */

export const verifyArtistURL = {
   //POST:process.env.REACT_APP_BASEURL + 'studio_info/verifiedUser',
   POST:process.env.REACT_APP_BASEURL+'user/artistVerificationDetails',
   PUT:process.env.REACT_APP_BASEURL + 'studio_info/verifiedUser',
}
export const artistEnquiryURL = {
   GET:process.env.REACT_APP_BASEURL + 'studio_info/enquiry/user/',
   DELETE:process.env.REACT_APP_BASEURL + 'studio_info/enquiry',
   POST:process.env.REACT_APP_BASEURL + 'studio_info/enquiry/delete_multiple/',


} 
// export const submitAsongURL={
//    POST:process.env.REACT_APP_BASEURL + 'releases/song_meta_data',
//    GET:process.env.REACT_APP_BASEURL + 'releases/songMeta/user/',
//    DELETE:process.env.REACT_APP_BASEURL + 'releases/songMeta',
//    GETSEARCH:process.env.REACT_APP_BASEURL + 'releases/song_meta/',
//    UPDATE:process.env.REACT_APP_BASEURL + 'releases/song_meta_data'
// }

// for song service
export const submitAsongURL={
   POST:process.env.REACT_APP_GNSONGURL+'studio_info/releases/songmeta',
   GET:process.env.REACT_APP_GNSONGURL+'studio_info/releases/songmeta/',
   DELETE:process.env.REACT_APP_GNSONGURL+'studio_info/releases/songmetadelete/',
   GETFORSPECIFC:process.env.REACT_APP_GNSONGURL+'studio_info/releases/songmeta/studio/',
   UPDATE:process.env.REACT_APP_GNSONGURL+'studio_info/releases/songmeta/',
   GETFORSEPECICARTIST:process.env.REACT_APP_GNSONGURL+'studio_info/releases/songmetaderived/studio/',
   SEARCH:process.env.REACT_APP_GNSONGURL+'studio_info/releases/release_search',
   // SEARCH:process.env.REACT_APP_GNSONGURL+'studio_info/releases/songmetaderived',
   GETASSOCIATEDARTISTINFO:process.env.REACT_APP_GNSONGURL+'studio_info/releases/associatedArtist/',
   // ADVANCESEARCH:process.env.REACT_APP_GNSONGURL+'studio_info/releases/songsearch',
   ADVANCESEARCH:process.env.REACT_APP_GNSONGURL+'studio_info/releases/releaseList_search',
   DELETETRACK:process.env.REACT_APP_GNSONGURL+'studio_info/releases/api/delete_file/',
   GETSINGLESONG:process.env.REACT_APP_GNSONGURL+'studio_info/releases/songmetaId/studio',
   GETSINGLESONGDISPLAY:process.env.REACT_APP_GNSONGURL+'studio_info/releases/songSpecificId/studio',
}

export const contentTypeURL = {
   GET:process.env.REACT_APP_BASEURL + 'studio_info/releases/content_type/',
}

export const recordLabelURL={
   GET:process.env.REACT_APP_BASEURL+'studio_info/releases/record_label/'
}

export const submitDemoURL={
   POST:process.env.REACT_APP_BASEURL + 'studio_info/submitDemo',
   GET:process.env.REACT_APP_BASEURL + 'studio_info/submitdemo',
   DELETE:process.env.REACT_APP_BASEURL + 'studio_info/submitDemo',
}
export const receiverDemoURL={
   GET:process.env.REACT_APP_DEMO_URL + 'get_demo_by_submission_option/',
}
export const moodURL={
   GET:process.env.REACT_APP_BASEURL + 'studio_info/releases/mood/',
}
export const subgenreURL={
   GET:process.env.REACT_APP_BASEURL + 'studio_info/releases/sub_genre/',
}

export const submitAgreement={
   POST:process.env.REACT_APP_BASEURL + 'studio_info/agreement',
   GET: process.env.REACT_APP_BASEURL + 'studio_info/agreementStudio',
   DELETE: process.env.REACT_APP_BASEURL +'studio_info/agreement'
 
}
export const getServicesApi={
   GET:process.env.REACT_APP_BASEURL + 'studio_info/services/',
}
export const updateServicesApi = {
   UPDATE: process.env.REACT_APP_BASEURL +  'studio_info/service', 
}
export const updateGeneralApi = {
   UPDATE: process.env.REACT_APP_BASEURL +  'studio/studioedit/', 
}
export const updateImageApi = {
   UPDATE: process.env.REACT_APP_BASEURL +  'studio_info/social-link', 
}
export const socialPlatformApi={
   GET:process.env.REACT_APP_BASEURL+'studio_info/social-type/',
   UPDATE: process.env.REACT_APP_BASEURL +  'studio_info/social-type', 
}
export const getLanguageApi = {
   GET: process.env.REACT_APP_BASEURL + 'studio_info/language/',
}

export const updateLanguageApi = {
   UPDATE: process.env.REACT_APP_BASEURL + 'studio_info/language',
}
export const getCityApi= {
   GET: process.env.REACT_APP_BASEURL + 'studio_info/city/' ,
}
export const getCountryApi= {
   GET: process.env.REACT_APP_BASEURL + 'studio_info/country/',
}
export const getStateApi= {
   GET: process.env.REACT_APP_BASEURL + 'studio_info/state/country_code/', 
}
export const getAddressApi= {
   GET: process.env.REACT_APP_BASEURL + 'studio_info/address', 
}
export const updateAddressApi= {
   INSERT: process.env.REACT_APP_BASEURL + 'studio_info/address',
   UPDATE: process.env.REACT_APP_BASEURL + 'studio_info/address',
   SPECIFICGET : process.env.REACT_APP_BASEURL + 'studio_info/address',
}
  /*Show all genre data for filter*/
  export const getGenreDataApi = {
   GET: process.env.REACT_APP_BASEURL + 'studio_info/genre/', 

}
export const updateGenreApi = {
   UPDATE: process.env.REACT_APP_BASEURL +'studio_info/genre', 
}

export const userRoleApi = {
   POST: process.env.REACT_APP_BASEURL + 'studio_info/user_role',//https://studioapidev.gncreators.com/studio/user/?search=64
   GET: process.env.REACT_APP_BASEURL + 'studio/user/',//https://gnapidev.gncreators.com/studios/user/?search=1
   USRROLES: process.env.REACT_APP_BASEURL + 'studio_info/roles/',//https://studioapidev.gncreators.com/studio_info/roles/
   SEARCH: process.env.REACT_APP_BASEURL + 'studio/user-role/'
}

export const assoicatedArtistURL={
   GET:process.env.REACT_APP_BASEURL + 'associated_artist/artists',
   SPECIFICGET:process.env.REACT_APP_BASEURL+'associated_artist/artist',
   POST:process.env.REACT_APP_BASEURL + 'associated_artist/artist',
   UPDATE:process.env.REACT_APP_BASEURL + 'associated_artist/artist',
   DELETE:process.env.REACT_APP_BASEURL + 'associated_artist/artist',//https://gncreatorsdev.gncreators.com/studio_info/artist/337/
   SEARCH:process.env.REACT_APP_BASEURL + 'associated_artist/artistname',
   //https://studioapidev.gncreators.com/associated_artist/artists?search=2
}

export const creatorAssociationURL={
   POST:process.env.REACT_APP_BASEURL + 'studio_info/studio_connection',
   UPDATE:process.env.REACT_APP_BASEURL + 'studio_info/studio_connectionedit/',
   DELETE:process.env.REACT_APP_STUDIOURL + 'studio_info/studio_connectionunlink/',
   GETLIST:process.env.REACT_APP_GNCREATORSURLS + 'studio_info/creator_associatelist/',
   VERIFY_UID:process.env.REACT_APP_GNCREATORSURLS  + 'studio_info/creator_uid/',
}


export const notificationsURL = {
   GET:process.env.REACT_APP_BASEURL + 'studio_info/notifications/',
   ALL:process.env.REACT_APP_BASEURL + 'studio_info/studio_notification',
   MARK_ALL_READ:process.env.REACT_APP_BASEURL + 'studio_info/studio_notification/mark_all_as_read/',
}
export const SearchAssociateArtist ={
   SEARCH:process.env.REACT_APP_GNCREATORSURLS+'studio_info/searchcreator_associates/',
}
// to do a wild search on the connected creators list
export const SearchArtistList ={
   SEARCH:process.env.REACT_APP_GNCREATORSURLS+'studio_info/searchcreator_associatelist/',
}
export const SearchAllAssociateArtist={
   SEARCH:process.env.REACT_APP_BASEURL+'studio_info/user_auth/getAllCreators'
}
export const AlbumEndpoint = {
   POST: process.env.REACT_APP_BASEURL + 'studio_info/releases/albummeta',
   GETALL: process.env.REACT_APP_BASEURL + 'studio_info/playlist/',
   GETALLOFUSER: process.env.REACT_APP_BASEURL + 'studio_info/releases/albummeta/studio/',
   DELETE_ALBUM: process.env.REACT_APP_BASEURL + 'studio_info/releases/albummetadelete/',
   GET_SINGLE_ALBUM: process.env.REACT_APP_BASEURL + 'studio_info/releases/albummetaId/studio/',
   UPDATE_ALBUM: process.env.REACT_APP_BASEURL + 'studio_info/releases/albummeta/',
   GET_ALL_SONGS_OF_USER: process.env.REACT_APP_BASEURL + 'studio_info/getsongList/user/',
   REMOVE_SONG_FROM_ALBUM: process.env.REACT_APP_BASEURL + 'studio_info/releases/albummeta/removesong/',
   SEARCH_ALBUM: process.env.REACT_APP_BASEURL + 'studio_info/releases/album_search/',
   SONG_REORDER_INALBUM: process.env.REACT_APP_BASEURL + 'studio_info/releases/album-song-sequence/',
   GET_ALBUM_SONG:process.env.REACT_APP_BASEURL_DEMO + "releases/albumsongs/studio/",
   READY_FOR_RELEASE: process.env.REACT_APP_BASEURL+'user/albumApproval',
   GET_ALL_PLATFORM:process.env.REACT_APP_BASEURL+'studio_info/releases/streamingPlatforms/'
}
export const PlanEndpoint = {
  GET_PLAN_LIST :process.env.REACT_APP_BASEURL + 'studio_info/releases/plan/',
  GET_ORDER_DETAIL:process.env.REACT_APP_BASEURL + 'studio_info/releases/payment/details/'
}