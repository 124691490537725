import { 
  FETCH_ASSOCIATES_DATA_REQUEST,
  FETCH_ASSOCIATES_DATA_SUCCESS,
  FETCH_ASSOCIATES_DATA_FAILURE
 } from "./creatorAssociatesDataTypes"

const initialState = {
    loading: false,
    creatorAssociatesStore: "",
    // selectedQuestionList: null,
    error: '',
  }
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case   FETCH_ASSOCIATES_DATA_REQUEST:
        return {
          ...state,
          loading: true
        }
      case FETCH_ASSOCIATES_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          creatorAssociatesStore: action.payload,
          error: ''
        }
      case FETCH_ASSOCIATES_DATA_FAILURE:
        return {
          ...state,
          loading: false,
          creatorAssociatesStore: [],
          error: action.payload
        }
       
      default: return state
    }
  }
  
  export default reducer
  