import React, { useEffect, useState } from 'react';
import { Box, Paper, Tooltip, Typography } from '@mui/material';
import Search from './Search';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TableComponent from './Tablecomponent';
import { albumDynamic } from './TabConstData';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchRoyaltyDetail } from '../../redux';
import { useSelector, useDispatch } from 'react-redux';
import { formatIndianNumber, getMonthValue, flattenPartnerDetail } from '../../_services/formateRevenueData';

const MusicRelease = ({ hideTotal }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const studioData = useSelector((state) => state.userData.userData);
    const albumData = useSelector((state) => state.royaltyDataReducer.albumData);
    const singlealbumData = useSelector((state) => state.royaltyDataReducer.singlealbumData);
    const tabDynamic = location?.state
    const isDetailsPage = location.pathname === '/details';
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const total_count = albumData?.total_count
    const publisherId = studioData.studio_id;
    const partener_detail = { partner_type: 'studio', partner_id: publisherId, share_type: 'contract_partner' }

    const redirectDetails = (data) => {
        navigate('/details', { state: { data: albumDynamic, value: [data], pageCall: 'singleAlbumData', name: data?.album, id: data?.album_id } });
    }
    useEffect(function () {
        const flattenedPartnerDetail = flattenPartnerDetail(partener_detail);
        if (isDetailsPage) {
            // if (singlealbumData?.counts_per_album?.length == 0 || singlealbumData.length == 0) {
            const monthName = tabDynamic?.name;
            let songId = tabDynamic?.id ? tabDynamic?.id : getMonthValue(monthName)
            // dispatch(fetchRoyaltyDetail(tabDynamic.pageCall, { offset: page, limit: rowsPerPage}, songId + '/album', `SINGLE_ALBUM`))
            dispatch(fetchRoyaltyDetail(tabDynamic.pageCall, { offset: page, limit: rowsPerPage, publisher_type: 'studio', publisher_id: publisherId, ...flattenedPartnerDetail }, songId + '/album', `SINGLE_ALBUM`))

            // }
        } else {
            if (studioData.studio_id) {
                // if (albumData?.albums?.length == 0 || albumData.length == 0) {
                // dispatch(fetchRoyaltyDetail("albumData",{}, studioData.studio_id, `ALBUM`))

                dispatch(fetchRoyaltyDetail("albumData", { offset: page, limit: rowsPerPage, ...flattenedPartnerDetail }, studioData.studio_id, `ALBUM`))
                // }
            }
        }

    }, [studioData, dispatch, page, rowsPerPage]);


    const rows = (isDetailsPage ? singlealbumData?.counts_per_album : albumData?.albums?.length ? albumData?.albums : []) || [];


    const columns = [
        { id: 'album', numeric: false, field: "album", disablePadding: true, label: 'Release', },
        {
            id: 'primary_artist', label: 'Artist', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className='d-flex gap-2 flex-wrap align-items-center'>
                        {row?.primary_artist.length ? (
                            <>
                                {row?.primary_artist.slice(0, 2).map((d, i) => (
                                    <Typography key={i} className='rounded-pill px-3 py-1' sx={{ background: "#EBEBEB", fontSize: '13px' }}>{d}</Typography>
                                ))}
                                {row?.primary_artist.length > 2 && (
                                    <Tooltip title={row.primary_artist.slice(2).map(d => d).join(', ')} arrow>
                                        <Typography className='rounded-pill px-3 py-1' sx={{ background: "#EBEBEB", fontSize: '12px' }}> {"+" + row.primary_artist.length}</Typography>
                                    </Tooltip>
                                )}
                            </>
                        ) : null}
                    </Box>
                );
            }
        },
        { id: 'total_total_streams', numeric: true, field: "total_total_streams", disablePadding: false, label: 'Streams', },
        { id: 'total_release_downloads', numeric: true, field: "total_release_downloads", disablePadding: false, label: 'Release Downloads', },
        { id: 'total_video_downloads', numeric: true, field: "total_video_downloads", disablePadding: false, label: 'Video Downloads', },
        { id: 'total_track_downloads', numeric: true, field: "total_track_downloads", disablePadding: false, label: 'Track Downloads', },
        {
            id: 'total_streams_earning', label: 'Earnings', headerName: '', flex: 1, template: (row) => {
                const totalDownloads = row.total_streams_earning + row.total_release_downloads_earnings + row.total_track_downloads_earnings + row.total_video_downloads_earnings;
                return (
                    <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(totalDownloads)}</Box>
                );
            }
        },
        {
            id: 'action', label: 'Action', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className='text-decoration-none viewDetailgradient cursorPointer' onClick={() => redirectDetails(row)} >View Details</Box>
                )
            }

        },
    ];

    const headFooter = [
        { id: 'name', numeric: false, label: 'Totals' },
        { id: 'artist', numeric: false, label: '-' },
        { id: 'streams', numeric: true, label: albumData.length != 0 ? formatIndianNumber(albumData.overall_total_streams) : 0 },
        { id: 'releasedownloads', numeric: true, label: albumData.length != 0 ? formatIndianNumber(albumData.overall_release_downloads) : 0 },
        { id: 'videodownloads', numeric: true, label: albumData.length != 0 ? formatIndianNumber(albumData.overall_video_downloads) : 0 },
        { id: 'trackdownloads', numeric: true, label: albumData.length != 0 ? formatIndianNumber(albumData.overall_track_downloads) : 0 },
        { id: 'earnings', numeric: false, label: <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(albumData.overall_release_downloads_earnings + albumData.overall_track_downloads_earnings + albumData.overall_streams_earning + albumData.overall_video_downloads_earnings)}</Box> },
        { id: 'action', numeric: true, label: '' },
    ];

    return (
        <Paper sx={{ width: '100%', mb: 2, mt: 1 }}>
            <Search headValue={'Music Release'} />
            <TableComponent columns={columns} data={rows} headFooter={headFooter} hideTotal={hideTotal} total_count={total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
        </Paper>
    );
};

export default MusicRelease;
