import axios from "axios";
import { updateServicesApi } from '../../_constants/api.constant'
import {getSelfToken } from '../../_helpers/auth/secureToken'
export async function updateServices(postData) {
    const postDetail = {service_ids:postData.service} 
    const response = await axios.put(
        updateServicesApi.UPDATE+'/'+postData.user +'/',
        postDetail,
        {
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}   