import { Box, createTheme, Divider, Grid, Paper, ThemeProvider, useMediaQuery } from '@mui/material';
import React from 'react'
import LoginHeader from '../header/AdminHeader';
import RegisterForm from './registerForm';

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: (props) => ({
          backgroundColor: '#FFFFFF',
          border: '1px solid rgba(0, 0, 0, 0.2);',
          boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.11)',
          borderRadius: '6px',
          margin: '0.5rem',
        }),
      },
    },
  },
});

const Register = () => {
  const largeScreen = useMediaQuery('(min-width:1280px)');
  return (
    <Box sx={{ flexGrow: 1,height: '100lvh'}}  >
    <Grid container justifyContent="center" alignItems="start" sx={{ height: '100%'}} className="loginFormSection" >
      <Grid item xs={12} >
        <LoginHeader />
        {/* <Divider/> code to bring hozintal line */}
       </Grid>
      <Grid item container xs={12} justifyContent="center" alignItems="start" sx={{ height: '90%'}}>
        <Grid item container spacing={2} xs={12} lg={8} md={10} direction="column" margin='auto'>
                <div style={{textAlign:'center',paddingBottom:{ xs: '0.5rem', sm: '1rem' }}}>
                  <h2 className="fw-bold m-0" style={{fontSize:'2.25rem'}}>Sign Up</h2>
                </div>
                <ThemeProvider theme={theme}>
                  <Paper style={{textAlign:'center',alignSelf:'center',paddingBottom:{ xs: '0.5rem', sm: '0.5rem' },width: largeScreen?'60rem':'100%'}}>
                  <Grid container sx={{padding:{ xs: '1rem 0rem', sm: '2rem 0rem' }, }} >
                    <Grid item xs={12} sx={{textAlign:'left',marginLeft:'1.2rem'}}>
                    <h4 className="fw-bold" style={{fontSize:'1.25rem',color:'rgba(0, 0, 0, 0.7)'}}>Get Started With GNTunes</h4>
                    </Grid>
                    {/* <Grid item xs={12} sx={{textAlign:'center',lineHeight: '0.5rem'}}>
                      <span className='fw-normal' style={{fontSize:'24px'}}>Getting started is easy</span>
                    </Grid> */}
                    <Grid item xs={12}>
                    <RegisterForm />
                    </Grid>
                  </Grid>
                  </Paper>
                </ThemeProvider>
        </Grid>
      </Grid>
     </Grid>  
  </Box>
  )
}

export default Register