import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Alert, Box, Button, Checkbox, createTheme, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Paper, Radio, RadioGroup, Skeleton, Snackbar, TextField, ThemeProvider, Typography, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RegisterStudio } from '../../_services/Register/RegisterStudio';
import { formSnackbar } from '../../_constants/snackbar.constant';
import { SnackbarContext } from "../../App";
import CircularProgress from '@mui/material/CircularProgress';
import SnackbarContainer from '../../components/utils/Snackbar';
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { FormLabel } from 'react-bootstrap';
const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "rgba(0, 0, 0, 0.02);",
          padding: '1rem 0',
          border: '1px solid rgba(0, 0, 0, 0.2);',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
          borderRadius: '6px'
        },
      },
    },
  },
});

const RegisterForm = () => {
  const dispatch = useDispatch();
  const [orientation, setOrientation] = useState('row');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [registerType, setRegisterType] = useState([
    { id: 1, label: "Artist", value: "artist" },
    { id: 2, label: "Studio", value: "studio" },
    { id: 3, label: "Label", value: "label" },
    { id: 4, label: "Aggregator", value: "aggregator" },
  ])
  const [studioTypeList, setStudioTypeList] = useState([
    { id: 1, label: "Artist", value: "artist" },
    { id: 2, label: "Studio", value: "studio" },
    { id: 3, label: "Label", value: "label" },
    { id: 4, label: "Aggregator", value: "aggregator" },
  ]);
  const [selectedStudioType, setSelectedStudioType] = useState({ id: 2, label: "Studio", value: "studio" })
  const [snackBarMessage, setSnackBarMesage] = useState('')
  const { snack, setSnack } = useContext(SnackbarContext);
  const [error, setError] = useState('');
  const mdScreen = useMediaQuery('(max-width:1024px)');

  useEffect(() => {
    mdScreen ? setOrientation('column') : setOrientation('row');
  }, [mdScreen]);

  const navigate = useNavigate();
  const redirectTologin = () => {
    navigate('/');
  }
  const handleShowConfPassword = () => {
    setShowConfPassword(!showConfPassword);
  }
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [formState, setFormState] = useState({
    studio_name: '',
    email: '',
    phone_number: '',
    website: '',
    // *** Studio Type added by Ankit
    type_of_studio: '',
    first_name: '',
    last_name: '',
    password: '',
    confirmPassword: ''
  });

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [formErrors, setFormErrors] = useState({
    studio_name: false,
    studioNameErr: '',
    email: false,
    emailErr: '',
    phone_number: false,
    phoneNoErr: '',
    website: false,
    websiteErr: '',
    // *** Studio Type added by Ankit
    // studio_type: false,
    // studioError: '',
    first_name: false,
    firstNameErr: '',
    last_name: false,
    lastNameErr: '',
    password: false,
    passwordErr: '',
    confirmPassword: false,
    confirmPasswordErr: ''
  });
  const validateField = (name, value) => {
    let errorMessage = '';
    switch (name) {
      case 'studio_name':
        if (!value.trim()) {
          setFormErrors(prevState => ({ ...prevState, ['studio_name']: true, ['studioNameErr']: 'Studio name is required' }));
        }
        else {
          setFormErrors(prevState => ({ ...prevState, ['studio_name']: false, ['studioNameErr']: '' }));
        }
        break;
      case 'email':
        if (!value.trim()) {
          setFormErrors(prevState => ({ ...prevState, ['email']: true, ['emailErr']: 'Email is required' }));
        } else {
          setFormErrors(prevState => ({ ...prevState, ['email']: false, ['emailErr']: '' }));
        }
        break;
      case 'phone_number':
        if (!value.trim()) {
          setFormErrors(prevState => ({ ...prevState, ['phone_number']: true, ['phoneNoErr']: 'Phone number is required' }));
        } else if (!/^\d+$/.test(value)) {
          setFormErrors(prevState => ({ ...prevState, ['phone_number']: true, ['phoneNoErr']: 'Phone number must only contain numbers' }));
        } else {
          setFormErrors(prevState => ({ ...prevState, ['phone_number']: false, ['phoneNoErr']: '' }));
        }
        break;
      case 'website':

        if (!value) {
          setFormErrors(prevState => ({ ...prevState, ['website']: true, ['websiteErr']: 'Website is required' }));
        } else {
          setFormErrors(prevState => ({ ...prevState, ['website']: false, ['websiteErr']: '' }));
        }
        break;
      case 'first_name':

        if (!value.trim()) {
          setFormErrors(prevState => ({ ...prevState, ['first_name']: true, ['firstNameErr']: 'First name is required' }));
        } else {
          setFormErrors(prevState => ({ ...prevState, ['first_name']: false, ['firstNameErr']: '' }));
        }
        break;
      case 'last_name':
        if (!value.trim()) {
          setFormErrors(prevState => ({ ...prevState, ['last_name']: true, ['lastNameErr']: 'Last name is required' }));
        } else {
          setFormErrors(prevState => ({ ...prevState, ['last_name']: false, ['lastNameErr']: '' }));
        }
        break;
      case 'password':
        if (!value.trim()) {
          setFormErrors(prevState => ({ ...prevState, ['password']: true, ['passwordErr']: 'Password is required' }));
        } else {
          setFormErrors(prevState => ({ ...prevState, ['password']: false, ['passwordErr']: '' }));
        }
        break;
      case 'confirmPassword':
        if (!value.trim()) {
          setFormErrors(prevState => ({ ...prevState, ['confirmPassword']: true, ['confirmPasswordErr']: 'Confirm password is required' }));
        } else if (value !== formState.password) {
          setFormErrors(prevState => ({ ...prevState, ['confirmPassword']: true, ['confirmPasswordErr']: 'Confirm password must match password' }));
        } else {
          setFormErrors(prevState => ({ ...prevState, ['confirmPassword']: false, ['confirmPasswordErr']: '' }));
        }
        break;
      default:
        break;
    }
    return errorMessage;
  };
  const phoneChangeHandler = (phone) => {
    validateField('phone_number', phone);
    setFormState(prevState => ({ ...prevState, ['phone_number']: phone }));
  }
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // ***** Studio Type added by Ankit
    // if (name === 'studio_type') {
    //   setSelectedStudioType(value)
    // }
    validateField(name, value);
    setFormState(prevState => ({ ...prevState, [name]: value }));
  }

  function copyAndRemoveKey(obj, key) {
    const newObj = Object.assign({}, obj);
    delete newObj[key];
    return newObj;
  }

  const formInputChangeHandler = (e) => {
    if (e.target.value) {
      setSelectedStudioType(studioTypeList.find(s => s.id == e.target.value))
      setFormState(prevState => ({ ...prevState, ['type_of_studio']: e.target.value }))
    }
  }

  async function registerClickHandler(e) {
    e.preventDefault();
    const errors = {};
    // validate f_name
    if (formState.first_name != ''
      && formState.studio_name != '' && formState.email != ''
      && formState.confirmPassword != '' && formState.last_name != '' && formState.website != ''
      && formState.password != '' && formState.phone_number != '' && formState.type_of_studio != ''
    ) {
      if (!/^[A-Za-z][A-Za-z\s]*[A-Za-z]$/.test(formState.first_name)) {
        errors.first_name = true;
        errors.firstNameErr = 'First name is invalid.';
      }

      // validate l_name
      if (!/^[A-Za-z][A-Za-z\s]*[A-Za-z]$/.test(formState.last_name)) {
        errors.last_name = true;
        errors.lastNameErr = 'Last name is invalid.';
      }
      // validate email
      if (!/\S+@\S+\.\S+/.test(formState.email)) {
        errors.email = true;
        errors.emailErr = 'Email is invalid.';
      }
      // validate website
      // if (!/^(http|https):\/\/[^ "]+$/.test(formState.website)) {
      if (!/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/.test(formState.website)) {
        errors.website = true;
        errors.websiteErr = 'Invalid website format.';
      }

      // validate password
      const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (!regex.test(formState.password)) {
        errors.password = true;
        errors.passwordErr = "Password must be at least 8 characters long and contain at least one digit, one lowercase letter, one uppercase letter, one special character, and no whitespace characters.";
      }
      if (formState.password !== formState.confirmPassword) {
        errors.confirmPassword = true;
        errors.confirmPasswordErr = 'Confirm password must match password.';
      }
      formState['role'] = 1;
      formState['type_of_studio'] = formState?.type_of_studio;
      formState['associated_studio'] = '';
      formState['name'] = '';
      setFormErrors(errors);
      if (Object.keys(errors).length === 0) {
        dispatch(setLoader(true))
        // submit the form
        // call the API or function to create an account

        setSnack({ message: formSnackbar.PRE_REGISTER_MSG, open: true, vertical: 'bottom', horizontal: 'right', color: 'info', action: <CircularProgress size={20} color="inherit" /> });
        setTimeout(() => { setSnack({ open: false }) }, 3000)
        const postData = copyAndRemoveKey(formState, 'confirmPassword');
        const formData = new FormData()
        Object.entries(postData).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              formData.append(key, item);
            });
          } else {
            formData.append(key, value);
          }
        });
        RegisterStudio(formData).then(function (response) {
          if (response.status === 200) {
            if (response.data.email && response.data.email.length > 0) {
              setSnack({ message: formSnackbar.errors.alreadyRegistered, open: true, vertical: 'bottom', horizontal: 'right', color: 'error', action: '' });
            } else {
              navigate('/thank-you');
              setSnack({ message: formSnackbar.POST_REGISTER_MSG, open: true, vertical: 'bottom', horizontal: 'right', color: 'success', action: '' });
              setTimeout(() => { setSnack({ open: false }) }, 2000)
              dispatch(setLoader(false))
            }
          }
          else {
            let responseData = response.data;
            if (responseData.email && responseData.email.length > 0) {
              setSnack({ message: formSnackbar.errors.alreadyRegistered, open: true, vertical: 'bottom', horizontal: 'right', color: 'error', action: '' });
            }
            else {
              setSnack({ message: formSnackbar.errors.errorMsg, open: true, vertical: 'bottom', horizontal: 'right', color: 'error', action: '' });
            }
            setTimeout(() => { setSnack({ open: false }) }, 3000)
            dispatch(setLoader(false))
          }
        })
          .catch(function (error) {
            dispatch(setLoader(false))
            alert(error)
          })
      }
    } else {
      if (formState.studio_name == '' && formState.email != '' && formState.website != ''
        && formState.confirmPassword != '' && formState.last_name != ''
        && formState.password != '' && formState.phone_number != '' && formState.type_of_studio != '') {

        setSnackBarMesage("Fill the Studio Name")

      } else {
        if (formState.email == '' && formState.studio_name != '' && formState.website != ''
          && formState.confirmPassword != '' && formState.last_name != '' && formState.first_name != ''
          && formState.password != '' && formState.phone_number != '' && formState.type_of_studio != '') {
          setSnackBarMesage("Fill the Email Field")
        } else {
          if (formState.phone_number == '' && formState.studio_name != '' && formState.email != ''
            && formState.confirmPassword != '' && formState.last_name != '' && formState.website != ''
            && formState.password != '' && formState.type_of_studio != '' && formState.first_name != '') {
            setSnackBarMesage("Fill the Phone detail Field")
          } else {
            if (formState.website == '' && formState.studio_name != '' && formState.email != '' && formState.phone_number != ''
              && formState.confirmPassword != '' && formState.last_name != ''
              && formState.password != '' && formState.type_of_studio != '' && formState.first_name != '') {
              setSnackBarMesage("Fill the Website detail Field")
            } else {
              if (formState.first_name == '' && formState.website != '' && formState.studio_name != '' && formState.email != '' && formState.phone_number != ''
                && formState.confirmPassword != '' && formState.last_name != ''
                && formState.password != '' && formState.type_of_studio != '') {
                setSnackBarMesage("Fill the First Name detail Field")
              } else {
                if (formState.last_name == '' && formState.website != '' && formState.studio_name != '' && formState.email != '' && formState.phone_number != ''
                  && formState.confirmPassword != '' && formState.first_name != ''
                  && formState.password != '' && formState.type_of_studio != '') {
                  setSnackBarMesage("Fill the Last Name detail Field")
                } else {
                  if (formState.password == '' && formState.website != '' && formState.studio_name != '' && formState.email != '' && formState.phone_number != ''
                    && formState.confirmPassword != '' && formState.first_name != ''
                    && formState.last_name != '' && formState.type_of_studio != '') {
                    setSnackBarMesage("Fill the Password Field ")
                  } else {

                    if (formState.confirmPassword == '' && formState.website != '' && formState.studio_name != '' && formState.email != '' && formState.phone_number != ''
                      && formState.password != '' && formState.first_name != ''
                      && formState.last_name != '' && formState.type_of_studio != '') {
                      setSnackBarMesage("Fill the Confirm Password Field ")
                    } else {

                      if (formState.type_of_studio == '' && formState.website != '' && formState.studio_name != '' && formState.email != '' && formState.phone_number != ''
                        && formState.password != '' && formState.first_name != ''
                        && formState.last_name != '' && formState.confirmPassword != '') {
                        setSnackBarMesage("Select the Studio Type Field ")
                      } else {
                        setSnackBarMesage("Fill all the Mandatory Field ")
                      }

                    }

                  }
                }
              }
            }

          }

        }

      }
      // errors.last_name = true;
      // errors.lastNameErr = 'Fill the Studio Name.';
      handleClick()
      // dispatch(setSnackBar(true));
      // setTimeout(() => dispatch(setSnackBar(false), 2000));
      // dispatch(setSnackBarVariant('error'))
      // dispatch(setSnackBarMessage(formSnackbar.signup.fillMandatory_Field))
    }
  }
  // }
  return (
    <form onSubmit={registerClickHandler} >
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item container xs={12} direction={orientation} sx={{ margin: '0.5rem' }}>
          <Grid item xs={12} sx={{ padding: '0.7rem' }}>
            <div className="mb-2 text-start text-black-50"><label className='fs14 fw-500'>Your Studio Type *</label></div>
            {studioTypeList ? (
              studioTypeList.map((data) => (
                <div
                  className="btn-group col-md-3 col-sm-12 col-12 col-lg-3 text-white p-0 pb-2"
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  <input
                    type="radio"
                    className="btn-check"
                    name="userType"
                    id={data.id}
                    value={data.id}
                    onChange={formInputChangeHandler}
                  />
                  <label
                    className="btn btn-outline-danger overflow-hidden text-truncate"
                    htmlFor={data.id}
                  >
                    <b>{data.label}</b>
                  </label>
                </div>
              ))
            ) : (
              <Skeleton height={60} animation="wave" />
            )}
          </Grid>
          <Grid item xs={6} sx={{ padding: '0.7rem' }}>
            <TextField name={`studio_name`} label={selectedStudioType ? selectedStudioType.label + " Name" : "Studio Name"} value={formState.studio_name} onChange={handleInputChange} error={formErrors.studio_name} helperText={formErrors.studioNameErr} required fullWidth size='small' />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0.7rem' }}>
            <TextField name={`email`} label="Email" placeholder='example@email.com' value={formState.email} onChange={handleInputChange} error={formErrors.email} helperText={formErrors.emailErr} required fullWidth size='small' />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0.7rem' }}>
            {/* <TextField name={`phone_number`} label="Phone No." value={formState.phone_number} onChange={handleInputChange} error={formErrors.phone_number} helperText={formErrors.phoneNoErr} inputProps={{ maxLength: 15 }} required fullWidth size='small' />
               */}
            <PhoneInput
              country={'in'}
              className="addStrik"
              value={formState.phone_number}
              name="contactNumber"
              placeholder=""
              onChange={phoneChangeHandler}
              error={formErrors.phone_number}
              helperText={formErrors.phoneNoErr}
              // inputProps={{ maxLength: 15 }}
              inputProps={{
                required: true
              }}
            />
          </Grid>
          <Grid item xs={6} sx={{ padding: '0.7rem' }}>
            <TextField name={`website`} label="Website or Any Social URL " value={formState.website} onChange={handleInputChange} error={formErrors.website} helperText={formErrors.websiteErr} required fullWidth size='small' />
          </Grid>

          {/*    ******* Studio Type added by Ankit*********    
          <Grid item xs={6} sx={{ padding: '0.7rem' }}>
            <FormControl className='d-flex' component="fieldset">
              <FormLabel component="legend" className='text-start'>Your Studio Type *</FormLabel>
              <RadioGroup
                row
                aria-label="row-radio-buttons-group"
                name={`studio_type`}
                value={selectedStudioType}
                onChange={handleInputChange}
                required
              >
                {studioTypeList.map((item) => (
                  <FormControlLabel
                    key={item.id}
                    value={item.id}
                    control={<Radio color='primary' />}
                    label={item.studioName}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {error}
          </Grid> */}
          <Grid item container direction="column" xs={12} sx={{ padding: '0.7rem', marginTop: '0.6rem' }}>
            <ThemeProvider theme={theme}>
              <Paper>
                <Grid item container xs={12} direction={orientation}>
                  <Grid item xs={12} sx={{ padding: '0.7rem' }}>
                    <h5 className='fw-Normal' style={{ textAlign: 'left', color: 'rgba(0, 0, 0, 0.7)' }}>Personal Detail</h5>
                  </Grid>
                  <Grid item xs={6} lg={6} sm={10} sx={{ padding: '0.7rem' }}>
                    <TextField name={`first_name`} label="First Name" value={formState.first_name} onChange={handleInputChange} error={formErrors.first_name} helperText={formErrors.firstNameErr} required fullWidth size='small' />
                  </Grid>
                  <Grid item xs={6} lg={6} sm={10} sx={{ padding: '0.7rem' }}>
                    <TextField name={`last_name`} label="Last Name" value={formState.last_name} onChange={handleInputChange} error={formErrors.last_name} helperText={formErrors.lastNameErr} required fullWidth size='small' />
                  </Grid>
                  <Grid item xs={6} lg={6} sm={10} sx={{ padding: '0.7rem' }}>
                    <TextField name={`password`} label="Password" type={showPassword ? 'text' : 'password'} value={formState.password} onChange={handleInputChange}
                      error={formErrors.password} helperText={formErrors.passwordErr} required fullWidth size='small' InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowPassword}>
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }} />
                  </Grid>
                  <Grid item xs={6} lg={6} sm={10} sx={{ padding: '0.7rem' }}>
                    <TextField name={`confirmPassword`} label="Confirm Password" type={showConfPassword ? 'text' : 'password'} value={formState.confirmPassword} onChange={handleInputChange}
                      error={formErrors.confirmPassword} helperText={formErrors.confirmPasswordErr} required fullWidth size='small' InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowConfPassword}>
                              {showConfPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </ThemeProvider>
          </Grid>
          <Grid item xs={12} sx={{ padding: { xs: '1rem', sm: '2rem' }, textAlign: 'center' }}>
            <button type="submit" onClick={registerClickHandler} className="gn-actionbtn">Create Account</button>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <h6 className='fw-normal'>Have an account ?<Button className='linkBtn' disableRipple onClick={redirectTologin}><strong><Typography className='linkBtnTxt' sx={{ textTransform: 'capitalize', fontWeight: '600' }}>Sign in!</Typography></strong></Button></h6>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <h5 className='fw-normal' style={{ fontSize: '1.125rem' }}>By continuing you indicate that you read and agreed to the Terms of Use</h5>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </form>
  )
}

export default RegisterForm