import axios from "axios";
import { creatorAssociationURL } from "../../_constants/api.constant";
import { getSelfToken } from "../../_helpers/auth/secureToken";
export async function creatorAssociation(postData,actiontype,limit,offset){
    switch(actiontype)
    {
        case 'POST':
            {
                const response=await axios.post(
                creatorAssociationURL.POST,
                postData,
                {
                    headers:{
                        Authorization: 'Token '+getSelfToken(),
                    },
                }
                ).then(function(response) {
                return response;
                }).catch(function (error) {
                console.log(error);
                return error.response;
                });
                return response;
            }
            case 'VERIFY_UID':
                {
                    const response=await axios.post(
                        creatorAssociationURL.VERIFY_UID,
                    postData,
                    {
                        headers:{
                            Authorization: 'Token '+getSelfToken(),
                        },
                    }
                    ).then(function(response) {
                    return response;
                    }).catch(function (error) {
                    console.log(error);
                    return error.response;
                    });
                    return response;
                }
        case 'UPDATE':
            {   
                const response=await axios.put(
                    creatorAssociationURL.UPDATE,
                postData,
                {
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    },
                }
                ).then(function(response) {
                return response;
                }).catch(function (error) {
                console.log(error);
                return error.response;
                });
                return response;
            }    
        case 'GETLIST':
            {
                const response = await axios.get(
                    creatorAssociationURL.GETLIST,

                    {  params:{studio:postData.id,limit:postData.limit,offset:postData.offset},
                        headers: {
                            Authorization: 'Token ' + getSelfToken(),
                        },
                    }
                ).then(function (response) {
                    return response;
                }).catch(function (error) {
                    // handle error
                    console.log(error);
                    return error.response;
                });
                return response;
            }
       
        case 'DELETE':{
            const response = await axios.post(
                creatorAssociationURL.DELETE ,
                postData,
                {
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    },
                }
            ).then(function (response) {
                return response;
            }).catch(function (error) {
                // handle error
                console.log(error);
                return error.response;
            });
            return response;
        }
    default:{return}
    }
}