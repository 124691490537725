export const actionTypes = {
    FETCH_GENRE_DATA_REQUEST: 'FETCH_GENRE_DATA_REQUEST ',
    FETCH_GENRE_DATA_FAILURE: 'FETCH_GENRE_DATA_FAILURE ',
    FETCH_GENRE_DATA_SUCCESS: 'FETCH_GENRE_DATA_SUCCESS ',

    FETCH_SUBGENRE_DATA_REQUEST: 'FETCH_SUBGENRE_DATA_REQUEST ',
    FETCH_SUBGENRE_DATA_FAILURE: 'FETCH_SUBGENRE_DATA_FAILURE ',
    FETCH_SUBGENRE_DATA_SUCCESS: 'FETCH_SUBGENRE_DATA_SUCCESS ',

    FETCH_MOOD_DATA_REQUEST: 'FETCH_MOOD_DATA_REQUEST ',
    FETCH_MOOD_DATA_FAILURE: 'FETCH_MOOD_DATA_FAILURE ',
    FETCH_MOOD_DATA_SUCCESS: 'FETCH_MOOD_DATA_SUCCESS ',

    FETCH_RECORDLABEL_DATA_REQUEST: 'FETCH_RECORDLABEL_DATA_REQUEST ',
    FETCH_RECORDLABEL_DATA_FAILURE: 'FETCH_RECORDLABEL_DATA_FAILURE ',
    FETCH_RECORDLABEL_DATA_SUCCESS: 'FETCH_RECORDLABEL_DATA_SUCCESS ',

    FETCH_LANGUAGE_DATA_REQUEST: 'FETCH_LANGUAGE_DATA_REQUEST ',
    FETCH_LANGUAGE_DATA_FAILURE: 'FETCH_LANGUAGE_DATA_FAILURE ',
    FETCH_LANGUAGE_DATA_SUCCESS: 'FETCH_LANGUAGE_DATA_SUCCESS ',

}
