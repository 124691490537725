import axios from "axios";
import { userProfileApi } from "../../_constants/api.constant";
import {  getSelfToken } from '../../_helpers/auth/secureToken'
export async function updateProfile(postData,dataId,checkProfileUpdate) {
    const response = await axios.put(
        checkProfileUpdate==undefined?userProfileApi.UPDATE+'?id='+dataId:userProfileApi.UPDATEPROFILE+'?id='+dataId,
        postData,
        {
            // headers: {
            //     Authorization: 'Token ' + getSelfToken(),
            // },
        }
    ).then(function (response) {
   // window.location.reload();

        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}