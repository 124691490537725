import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { submitDemoURL } from '../../_constants/api.constant'
export async function submitDemo(postData,actiontype,limit,offset){
    switch(actiontype)
    {
        case 'POST':
            {
                const response=await axios.post(
                submitDemoURL.POST,
                postData,
                {
                    headers:{
                        Authorization: 'Token '+getSelfToken(),
                    },
                }
                ).then(function(response) {
                return response;
                }).catch(function (error) {
                console.log(error);
                console.log(error.response);
                return error.response;
                });
                return response;
            }
            case 'GET':
                {
                    const response = await axios.get(
                        submitDemoURL.GET+'/',
                        {   params:{search:postData.userId,limit:limit,offset:offset},
                            headers: {
                                Authorization: 'Token ' + getSelfToken(),
                            },
                        }
                    ).then(function (response) {
                        return response;
                    }).catch(function (error) {
                        console.log(error);
                        console.log(error.response);
                        return error.response;
                    });
                    return response;
                }
                case 'DELETE':{
                    const response = await axios.delete(
                        submitDemoURL.DELETE + "?id=" +postData.itemId,
                        {
                            headers: {
                                Authorization: 'Token ' + getSelfToken(),
                            },
                        }
                    ).then(function (response) {
                        return response;
                    }).catch(function (error) {
                        console.log(error);
                        console.log(error.response);
                        return error.response;
                    });
                    return response;
                }
    default:{return}
    }
}