import React,{useState} from 'react'
import { ToggleButton } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';

const SwitchFilter = ({SwitchlayoutComponent,handleSwitchLayoutComponent}) => {  
  const Icon = /*SwitchlayoutComponent ?*/<FilterListIcon fontSize="small" sx={{color:'#000000'}}/> 
  return (
    <div>
        <ToggleButton /*onClick={()=>{handleSwitchLayoutComponent(!SwitchlayoutComponent)}}*/ variant="outlined" className="switcherButton bg-white" size="small" aria-label='switchView' sx={{minWidth:'2.625rem'}}>
            {Icon}
        </ToggleButton>
    </div>
  )
}

export default SwitchFilter