import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { submitAsongURL } from "../../_constants/api.constant";
export async function CreateSongThroughTrack(formData,onUploadProgress){
    return axios.post(submitAsongURL.POST, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization':'Token '+ getSelfToken()
    },
    onUploadProgress,
  });
}