import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
// import {AlbumEndpoint} from '../../_constants/api.constant'
export async function getPaymentDetail(orderId) {
    const response = await axios.get(
       process.env.REACT_APP_BASEURL_DEMO+"releases/payment/details/"+orderId+"/",
    //    " https://gnapidev.gncreators.com/studio_info/releases/payment/details/"+orderId+"/",
        {   
            headers: {
                'Authorization': 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });
    return response
}