import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeUserToken } from '../../_helpers/auth/secureToken';
import { logoutHandler } from '../../_services/LOGOUT/logout.service';

export const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // const handleLogout = async () => {
    //   try {
    //     // Call the logout service
    //     await logoutHandler({});
        // Remove user token
        removeUserToken();
        // Navigate to the login page
        const callbackUrl = '/login';
        navigate(callbackUrl);
      // } catch (error) {
      //   console.error('Error during logout:', error);
      // }
    // };
    // Call the handleLogout function
    // handleLogout();
  }, [navigate]);

  return null;
};
