import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery, Button, FormHelperText, Tooltip } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { DatePicker, LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import AddAssociatedArtist from '../associatedArtist/AddAssociatedArtist';
import Autocomplete from '@mui/material/Autocomplete';
import { artistCode } from '../songList/associatedArtist/artistStaticCodes';
import AddAssociatedArtist from '../songList/associatedArtist/AddAssociatedArtist';
import ClearSelectionIcon from '../songList/helperComponent/ClearSelectionIcon';
// import { artistCode } from '../associatedArtist/artistStaticCodes';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const AlbumCredit = ({ data, handleInputChange, errors, releaseDate, releaseTime, releaseTimeChangeHandler,
    goLiveDate, goLiveDateChangeHandler, language, primaryArtList, remixerArtList, artistSelectChangeHandler, featureArtList, lyricistList,
    producerList, composerList, actorList, artistList, getAssociatedArtist, assoArtistRemoveHandler, recordLabelList, handleSubmit, releaseStatusReview, releaseStatusReleased, albumDisabledStatus, releaseDataBkp, handleBack , setActive, active}) => {
    /* Local state and media query */
    const [open, setOpen] = React.useState(false)
    const smallViewport = useMediaQuery('(max-width:768px)');

    //set min date from seven working days later from now
    const minDate = new Date()
    minDate.setDate(minDate.getDate() + 7)
    //check if date is still valid or not
    const isDateInvalid = releaseDate && new Date(releaseDate) < new Date();
    const handleOpenDrawer = () => {
        setOpen(true)
    }
    const handleDrawerClose = () => {
        setOpen(false)
    }

    const handleChangeMuliselect = (name, newValue, isNewArtist) => {
        switch (name) {
            case artistCode.primary_artist:
                {
                    artistSelectChangeHandler(artistCode.primary_artist, newValue, isNewArtist)
                    break;
                }
            case artistCode.feature_artist:
                {
                    artistSelectChangeHandler(artistCode.feature_artist, newValue)
                    break;
                }
            case artistCode.lyricist:
                {
                    artistSelectChangeHandler(artistCode.lyricist, newValue)
                    break;
                }
            case artistCode.composer:
                {
                    artistSelectChangeHandler(artistCode.composer, newValue)
                    break;

                }
            case artistCode.producer:
                {
                    artistSelectChangeHandler(artistCode.producer, newValue)
                    break;
                }
            case artistCode.actor:
                {
                    artistSelectChangeHandler(artistCode.actor, newValue)
                    break;
                }
            case artistCode.remixers:
                {
                    artistSelectChangeHandler(artistCode.remixers, newValue)
                    break;
                }
            default:
                break;
        }

    }
    return (
        <>
            <div>
                <div className="d-flex justify-content-between">
                    <p className='cardheading'>Release Credits</p>
                    <Button
                        onClick={handleOpenDrawer}
                        sx={{ mr: 1 }}
                        variant="text"
                        disabled={albumDisabledStatus}
                        color='error'
                        style={{ minWidth: '100px', height: '40px', padding: 0 }}
                    >
                        <strong>ADD ARTIST</strong>
                    </Button>
                </div>
                <div className='row'>
                    <div className="form-group col-md-6 mt-2 mb-3">
                        <Autocomplete
                            multiple
                            id="primary-artist-input"
                            size="small"
                            options={artistList}
                            onChange={(event, newValue) => handleChangeMuliselect(artistCode.primary_artist, newValue)}
                            limitTags={2}
                            getOptionLabel={(option) => option.artist_name}
                            value={primaryArtList}
                            disabled={albumDisabledStatus}
                            renderInput={(params) => (
                                <TextField {...params} name="primiary_artists" label="Primary Artist*" placeholder="Primary Artist*" />
                            )}
                        />
                    </div>
                    <div className="form-group col-md-6 mt-2 mb-3">
                        <Autocomplete
                            multiple
                            id="featuring-artist-input"
                            size="small"
                            options={artistList}
                            onChange={(event, newValue) => handleChangeMuliselect(artistCode.feature_artist, newValue)}
                            limitTags={2}
                            getOptionLabel={(option) => option.artist_name}
                            disabled={albumDisabledStatus}
                            value={featureArtList}
                            renderInput={(params) => (
                                <TextField {...params} name="feature_artists" label="Feature Artist" placeholder="Feature Artist" />
                            )}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group col-md-6 mt-2 mb-3">
                        <Autocomplete
                            multiple
                            id="composer-input"
                            size="small"
                            options={artistList}
                            onChange={(event, newValue) => handleChangeMuliselect(artistCode.composer, newValue)}
                            limitTags={2}
                            getOptionLabel={(option) => option.artist_name}
                            disabled={albumDisabledStatus}
                            value={composerList}
                            renderInput={(params) => (
                                <TextField {...params} name="composers" label="Composer" placeholder="Composer" />
                            )}
                        />
                    </div>
                    <div className="form-group col-md-6 mt-2 mb-3">
                        <Autocomplete
                            multiple
                            id="lyricist-input"
                            size="small"
                            options={artistList}
                            onChange={(event, newValue) => handleChangeMuliselect(artistCode.lyricist, newValue)}
                            limitTags={2}
                            getOptionLabel={(option) => option.artist_name}
                            disabled={albumDisabledStatus}
                            value={lyricistList}
                            renderInput={(params) => (
                                <TextField {...params} name="lyricists" label="Lyricist*" placeholder="Lyricist*" />
                            )}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group col-md-6 mt-2 mb-3">
                        <Autocomplete
                            multiple
                            id="producer-input"
                            size="small"
                            options={artistList}
                            onChange={(event, newValue) => handleChangeMuliselect(artistCode.producer, newValue)}
                            limitTags={2}
                            getOptionLabel={(option) => option.artist_name}
                            value={producerList}
                            disabled={albumDisabledStatus}
                            renderInput={(params) => (
                                <TextField {...params} name="producers" label="Producer(Optional)" placeholder="Producer" />
                            )}
                        />
                    </div>
                    <div className="form-group col-md-6 mt-2 mb-3">
                        <Autocomplete
                            multiple
                            id="size-small-outlined-multi"
                            size="small"
                            options={artistList}
                            onChange={(event, newValue) => handleChangeMuliselect(artistCode.remixers, newValue)}
                            limitTags={2}
                            getOptionLabel={(option) => option.artist_name}
                            disabled={albumDisabledStatus}
                            value={remixerArtList}
                            renderInput={(params) => (
                                <TextField {...params} name="remixer" label="Remixer(Optional)" placeholder="Remixer(Optional)" />
                            )}
                        />
                    </div>
                </div>
                <div className="form-group col-md-12 mt-5 d-flex justify-content-between " >
                <button
                //   disabled={prevBtnDisable}
                  onClick={handleBack}
                  style={{ mr: 1, color:'#E41116' }}
                  className='fs14 border-0 bg-body fw-500'
                 
                >
                  PREVIOUS
                </button>
                    <Tooltip
                        title={data.song_status === "In Review" ? releaseStatusReview : data.song_status === "Released" ? releaseStatusReleased : "Next"}
                        arrow
                    >
                        <span > 
          <button onClick={() => (JSON.stringify(data) === JSON.stringify(releaseDataBkp)) ? setActive(active+1) :handleSubmit() } className='nxt_actionbutton mx-3 py-1 px-4 fs14' >{JSON.stringify(data) === JSON.stringify(releaseDataBkp)? "NEXT":"SAVE"}</button>
                            
                        </span>
                    </Tooltip>
                </div>
            </div>
            {/* Add associated artist drawer:start */}
            <Drawer
                sx={{
                    width: '400px',
                    flexShrink: 0,
                    '& .MuiDrawer-paper':
                    {
                        width: smallViewport ? '300px' : '400px',
                        boxSizing: 'border-box',
                        backgroundColor: '#F6F6F6'
                    },
                }}
                anchor="right"
                open={open}
            >
                <AddAssociatedArtist artistList={artistList} handleDrawerClose={handleDrawerClose} getAssociatedArtist={getAssociatedArtist}
                    handleChangeMuliselect={handleChangeMuliselect} assoArtistRemoveHandler={assoArtistRemoveHandler} />
            </Drawer>
            {/* End */}
        </>
    )
}

export default AlbumCredit