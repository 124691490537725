import axios from "axios";
import { getSelfToken } from "../../_helpers/auth/secureToken";
import { logoutApi } from "../../_constants/api.constant";
export function logoutHandler(postData){
    const response=axios.post(
       logoutApi.LOGOUT,
       postData,
        {
            headers:{
                Authorization: 'Token '+getSelfToken(),
            }, 
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}