import { Checkbox, FormControlLabel, FormGroup, Radio, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';

const PlatformDistribution = ({ data, setData, handleInputChange, errors, selectedStore, platformList, handleSubmit, chooseSelectType, releaseStatusReview,
    albumDisabledStatus, releaseStatusReleased, releaseDataBkp, onChangeFlag, handleBack, setActive, active, setReleaseDataBkp, setSelectedStore }) => {
    // const [selectedPlatforms, setSelectedPlatforms] = useState(selectedStore);

    function handleCheckboxChange(e, d) {
        if (data.platform.find(c => e.target.id == c)) {
            let a = data.platform.filter(r => r != e.target.id);
            // setSelectedStore([...a]);

            setData({
                ...data,
                platform: [...a]
            })
        }
        else {
            let dataFilter = [...data.platform, e.target.id]
            if (dataFilter.length == platformList.length) {
            }
            // setSelectedStore([...selectedStore, e.target.id]);
            setData({
                ...data,
                platform: [...data.platform, e.target.id]
            })
        }
    }

    useEffect(function () {
        
        if (chooseSelectType == "True" || data.store_status == "True") {
            // setSelectedStore(platformList.length && platformList.map(p => p.platformId))
            setData({
                ...data,
                platform: platformList.length && platformList.map(p => p.platformId)
            })
            // setReleaseDataBkp({
            //     ...data,
            //     platform: platformList.length && platformList.map(p => p.platformId)
            // })
        } else {
            if (onChangeFlag == "False"&& data.store_status == false) { 
                setData({
                    ...data,
                    platform:data.platform
                })
            
            } else {
                setData({
                    ...data,
                    platform:[]
                })
              
            }
        }
    }, [data.store_status || chooseSelectType])



    return (
        <>
            <div>
                <p className='cardheading'>Distribution Platform</p>
                <div className='row mt-3'>
                    <div className="form-group col-md-12 ">
                        <p className='m-0'>Select Stores *</p>
                        <Radio
                            disabled={albumDisabledStatus}
                            checked={chooseSelectType == "True"}
                            onChange={handleInputChange}
                            value="True"
                            name='store_status'
                            inputProps={{ 'aria-label': '0' }}
                        />
                        All Platform
                        <Radio
                            disabled={albumDisabledStatus}
                            checked={chooseSelectType == "False"}
                            onChange={handleInputChange}
                            value="False"
                            name='store_status'
                            inputProps={{ 'aria-label': 'Yes' }}
                        />
                        Choose Manually
                    </div>
                </div>
                <div className='row mt-3' style={{ height: "200px", overflowY: "auto" }}>
                    <p className='m-0'>Choose Stores *</p>
                    {platformList.length > 0 && platformList.map((d) => (
                        <div key={d.platformId} className="col-md-4 mt-3">
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        onChange={(e) => handleCheckboxChange(e, d)}
                                        id={d.platformId.toString()}
                                        name={d.platformId.toString()}
                                        value={d.platformId.toString()}
                                        disabled={chooseSelectType == "True" || albumDisabledStatus ? true : false}

                                        checked={
                                            data.platform?.length
                                            &&
                                            (data.platform?.filter(v => v == d.platformId).length ? true : false)
                                        }
                                    />}
                                    label={d.platformName}
                                />
                            </FormGroup>
                        </div>
                    ))}

                </div>
            
                <div className="form-group col-md-12 mt-5 d-flex justify-content-between " >
                <button
                //   disabled={prevBtnDisable}
                  onClick={handleBack}
                  style={{ mr: 1, color:'#E41116' }}
                  className='fs14 border-0 bg-body fw-500'
                 
                >
                  PREVIOUS
                </button>
                    <Tooltip title={data.song_status == "In Review" ? releaseStatusReview : data.song_status == "Released" ? releaseStatusReleased : "Next"} arrow>
                        {/* <span>
                        <button disabled={albumDisabledStatus || (JSON.stringify(data) === JSON.stringify(releaseDataBkp)) ? true : false} onClick={() => handleSubmit()} className='nxt_actionbutton mx-3 py-1 px-4 fs14' >NEXT</button>
                        </span> */}
                      

                                                <button onClick={() => (JSON.stringify(data) === JSON.stringify(releaseDataBkp)) ? setActive(active+1) :handleSubmit() } className='nxt_actionbutton mx-3 py-1 px-4 fs14' >{JSON.stringify(data) === JSON.stringify(releaseDataBkp)? "NEXT":"SAVE"}</button>
                        {/* <button onClick={() => (JSON.stringify(data) === JSON.stringify(releaseDataBkp)) ? setActive(active+1) :handleSubmit() } className='nxt_actionbutton mx-3 py-1 px-4 fs14' >NEXT</button> */}
                    </Tooltip>
                </div>
            </div>
        </>
    )
}

export default PlatformDistribution;
