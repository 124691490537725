import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGenreData } from '../../_services/Genre/genre';
import { updateGenre } from '../../_services/Genre/genre.update.service';
import {
  fetchLoginUser,
  setLoader,
  setSnackBar,
  setSnackBarMessage,
  setSnackBarVariant,
} from '../../redux';
import { formSnackbar } from '../../_constants/snackbar.constant';
import SnackbarContainer from '../Snackbar';

function Genre(props) {
  const [genre, setGenre] = useState([]);
  const [userGenre, setUserGenre] = useState("");
  const userData = useSelector((state) => state.userData.userData);
  const userDetail = useSelector((state) => state.userDetail.userDetail);
  const dispatch = useDispatch();

  useEffect(function () {
    setUserGenre(userDetail ? userDetail.genres :"" );
  }, [userDetail]);

  useEffect(function () {
    if (userData.studio_id) {
      dispatch(fetchLoginUser(userData.studio_id));
    }
  }, [userData.studio_id]);

  useEffect(function () {
    getGenreData()
      .then(function (response) {
        dispatch(setLoader(false));
        setGenre(response.data.data);
      })
      .catch(function (error) {
        dispatch(setLoader(false));
        console.log(error);
        console.log(error.response);
        return error.response;
      });
  }, []);

  function UpdateGenreCategory(postData) {
    dispatch(setLoader(true));
    dispatch(setSnackBarVariant('info'));
    dispatch(setSnackBarMessage(formSnackbar.PRE_GENRE_UPDATE));
    dispatch(setSnackBar(true));
    updateGenre(postData)
      .then(function (response) {
        if (response.status === 200) {
          dispatch(setSnackBarVariant('success'));
          dispatch(setSnackBarMessage(formSnackbar.POST_GENRE_UPDATE));
          setTimeout(() => dispatch(setSnackBar(false)), 2000);
          dispatch(setLoader(false));
        } else {
          dispatch(setLoader(false));
          dispatch(setSnackBarVariant('error'));
          dispatch(
            setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure)
          );
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        }
      })
 
  }

  function onChangeGenreHandler(e, data) {
    if (userGenre && userGenre.find((d) => e.target.id == d.id)) {
      let a = userGenre.filter((r) => r.id != e.target.id);
      setUserGenre([...a]);
      let selectedIds = a ? (a.length > 0 ? a.map((obj) => obj.id) : []) : [];
      let postData = {};
      postData['user'] = userData.studio_id;
      postData['genre'] = selectedIds.join(',');
      UpdateGenreCategory(postData);
    } else {
      setUserGenre([...userGenre, data]);
      let selectedIds = [];
      selectedIds = userGenre
        ? userGenre.length > 0
          ? userGenre.map((obj) => obj.id)
          : []
        : [];
      selectedIds = [...selectedIds, data.id];
      let postData = {};
      postData['user'] = userData.studio_id;
      postData['genre'] = selectedIds.join(',');
      UpdateGenreCategory(postData);
    }
  }
  return (
    <div>
      <h4 className="mb-4">Genre</h4>
          <p className="text-secondary">
            Here are some genres that are typically played in studios.
          </p>
          <div className="pt-3 row px-2">
            {genre
              ? genre.map((d) => (
                  <div className="col-md-4 col-sm-12 col-lg-3 p-0 " key={d.id}>
                    <div className="form-check-inline d-inline-flex">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => onChangeGenreHandler(e, d)}
                              id={d.id}
                              name={d.id}
                              checked={
                                userGenre
                                  ? userGenre.filter((v) => v.id === d.id).length
                                    ? true
                                    : false
                                  : false
                              }
                            />
                          }
                          label={d.genre}
                        />
                      </FormGroup>
                    </div>
                  </div>
                ))
              : []}
          </div>
      {/*  to show and hide the snackbar messages */}
      <SnackbarContainer />
    </div>
  );
}

export default Genre;
