import { Grid, Menu, MenuItem, styled, Typography, useMediaQuery } from '@mui/material'
import React, { useCallback } from 'react'
import { useEffect } from 'react';
 import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { getSocialPlatformType } from '../../_services/SocialPlatform/socialPlatform.services';
import { updateSocialPlatform } from '../../_services/SocialPlatform/updateSocialPlatform.services';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare,faTwitter,faLinkedin,faYoutubeSquare,faInstagramSquare,faSpotify } from '@fortawesome/free-brands-svg-icons';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_constants/snackbar.constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SocialLinks from './SocialLink';
import { getSocialPlatform } from '../../_services/SocialPlatform';
import SnackbarContainer from '../Snackbar';
import { deleteSocial } from '../../_services/SocialLink/SocialLink.delete';

    // Define the menu items and their corresponding icons
    const menuItems = [
        { icon: faFacebookSquare, label: 'Facebook',value:'facebook' },
        { icon: faTwitter, label: 'Twitter',value:'twitter' },
        { icon: faLinkedin, label: 'LinkednIN',value:'linkedin' },
        { icon: faYoutubeSquare, label: 'Youtube',value:'youtube' },
        { icon: faInstagramSquare, label: 'Instagram',value:'instagram' },
        { icon: faSpotify, label: 'Spotify',value:'spotify' },
        { icon: faGlobe, label: 'Other',value:'other' }
    ];
          
    // Define the menu item style
    const menuItemStyle = {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px',
        cursor: 'pointer',
    };
    
    // Define the menu item icon style
    const menuItemIconStyle = {
        marginRight: '10px',
    };
    
    // Define the menu item label style
    const menuItemLabelStyle = {
        flexGrow: 1,
    };

function SocialPlatform() {
  const userData = useSelector(state=>state.userData.userData)
  const dispatch = useDispatch();
  const [socialLinks, setSocialLinks] = useState([])
  const [socialTypes, setSocialTypes] = useState([{
    "id": '',
    "socialType": {
        "id": 1,
        "socialType": "face",
        "socialTypeCode": "twitter_3",
        "created_at": "2023-05-05T14:11:45.251898Z",
        "updated_at": "2023-05-05T14:11:45.251945Z"
    },
    "image": null,
    "name": "My Link",
    "url": "http://www.teitter.com/test",
    "status": null,
    "user": 193
}])
  const [disable, setDisable] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedType, setselectedType] = useState();
  const smallViewport = useMediaQuery('(min-width:768px)');
  const addNewLinkHandler = (socialTypeId) =>{
    /* setDisable(true) */
    setSocialLinks([{},...socialLinks])
    console.log(socialTypeId)
    let selectecType = socialTypes && socialTypes.find(social=>social.id === socialTypeId)
    setselectedType(selectecType)
    handleMenuClose();
  }
  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
   };
  const linkDeleteHandler = (linkId, index) =>{
      
      const items = [...socialLinks]
      if(items.length>0)
      {
        
        setSocialLinks(items.filter((item, index) => item.id !== linkId));
        setDisable(false)
        /* delete service to parmanently delete the social link */
        if(linkId!==undefined){
          dispatch(setSnackBar(true))
          dispatch(setSnackBarMessage(formSnackbar.socialLink.socialLinkDelete))
          dispatch(setSnackBarVariant('info'))
          if(JSON.stringify(items[0]) === '{}'){
            setDisable(true)
          }
          let dataObj = {}
          dataObj.userId = userData.studio_id;
          dataObj.linkId  = linkId
          deleteSocial(dataObj.userId,dataObj.linkId).then(function (response) {
              
              if(response.status ===200 || response.status ===204) {
                setTimeout(()=>dispatch(setSnackBar(false)),2000)
                dispatch(setSnackBarMessage(formSnackbar.socialLink.socialLinkDeleteSuccess))
                dispatch(setSnackBarVariant('success'))
               }
              else{
                 dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
                  dispatch(setSnackBarVariant('error'))
              }  
          })
        }
      }

  }
  const disableHandler = (arg,type) =>{
    if(type==='SAVE')
      setDisable(false)
    else
    {
      if(JSON.stringify(socialLinks[0]) === '{}')
        setDisable(true)
      else
        setDisable(false)
    }
  }

  const renderCard = useCallback((link, index) => {
    return (
      <SocialLinks 
      key = {link.id} 
      linkId = {link.id} 
      linkData = {link} index={index}
      linkDeleteHandler  = {linkDeleteHandler}
      disableHandler = {disableHandler}
      socialTypes={socialTypes}
      currentUserId = {userData.studio_id}
      reRenderSocialLinks = {reRenderSocialLinks}
      selectedSocialType={link.id === undefined?selectedType:link}
      /* selectedIcon = {getSocialTypeIcon(selectedType.socialTypeCode)} */
      />
    );
  }, [socialLinks]);

   const renderSocialTypes = () =>{
    getSocialPlatformType(userData.studio_id).then((response)=>{
            if(response.status ===200)
            {   dispatch(setLoader(false))
                setSocialTypes(response.data.data?response.data.data:[])
                reRenderSocialLinks();
            }
            else{
                alert(`The service has been responded with the error code ${response.status}`)
            }
        })
   }

  const reRenderSocialLinks = () =>{
    getSocialPlatform(userData.studio_id)
    .then((response)=>{
      if(response.status ===200)
      {
          setSocialLinks(response && response.data.data?response.data.data.reverse():{})
      }
      else{
          alert(`The service has been responded with the error code ${response.status}`)
      }
    })
  } 
 
  useEffect(()=>{
    
    userData.studio_id && renderSocialTypes()
  },[userData.studio_id])
  const getSocialTypeIcon = (socialTypeC)=>{
    switch(socialTypeC)
    {
        case 'facebook_1':{
            return faFacebookSquare
        }
        case 'instagram_2':{
            return faInstagramSquare
        }
        case 'twitter_3':{
            return faTwitter
        }
        case 'linkedin_4':{
            return faLinkedin
        }
        case 'youtube_5':{
            return faYoutubeSquare
        }
        case 'spotify_6':{
            return faSpotify
        }
        case 'website':{
            return faGlobe
        }
        default:
            return;    
    }
  }

  return (
    <Grid container spacing={4}>
    <Grid item xs={12} sm={6}>
    
      <h4 className="mb-2">Social Platform</h4>
      <Typography variant="subtitle1" gutterBottom>
            In which social media do you have your profile?
      </Typography>
    </Grid>
    <Grid item xs={12} sm={6} sx={{textAlign:'end', paddingTop:smallViewport?'0':'0.5rem !important'}}>
      <button className='gn-actionbtn' onClick={handleButtonClick} >
        Add Platform
      </button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {socialTypes && socialTypes.map((menuItem) => (
        <MenuItem
            key={menuItem.id}
            style={menuItemStyle}
            onClick={() => addNewLinkHandler(menuItem.id)}
            value={menuItem.id}
            >
            <FontAwesomeIcon
            icon={getSocialTypeIcon(menuItem.socialTypeCode)}
            style={menuItemIconStyle}
            />
            <div style={menuItemLabelStyle}>{menuItem.socialType}</div>
        </MenuItem>
        ))}
      </Menu>
    </Grid>
    <Grid item xs={12} sm={12} sx={{marginTop:!smallViewport?'0rem':'1rem'}}>
      {
          socialLinks && socialLinks.length>0 && socialLinks.map((currentItem, index)=>renderCard(currentItem, index))
      }
    </Grid>
    {/*  to show and hide the snackbar messages */}
    <SnackbarContainer />
  </Grid>
  )
}

export default SocialPlatform