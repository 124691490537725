import { Button, Grid, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const LoginForm = ({ isLoggedIn, handleLogin,error }) => {
    const navigate = useNavigate();
    const [credential, setCredential] = useState('');
    const [credentialError, setCredentialError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const smScreen = useMediaQuery('(max-width:600px)');
    const handleShowPassword = () => {
      setShowPassword(!showPassword);
    };
    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setCredential(inputValue);
        
      };
      const handlePasswordChange = (event) => {
        const inputValue = event.target.value;
        setPassword(inputValue);
        
      };
      // const handleEmailBlur = (event) => {
      //   const inputValue = event.target.value;
      //   // Validate input
      //   if (!validateCredential(inputValue)) {
      //           setCredentialError(true);
      //       } else {
      //           setCredentialError(false);
      //       }    
      // };
      // const handlePassBlur = (event) => {
      //   const inputValue = event.target.value;
      //   //Validate input
      //   if (!validatePassword(inputValue)) {
      //     setPasswordError(true);
      //   } else {
      //     setPasswordError(false);
      //   }
      // };
      const validateCredential = (credential) => {
        // Regular expression for validating email, mobile number, or username
        //TO DO: check for username with symbol and limit for mobile number input
        //const regex = /^(?:[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}|\d{10,15}|[a-zA-Z0-9._-]+)$/;(regex forusername, mob number and email)
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(credential);
      };
    
      const validatePassword = (password) => {
        // Regular expression for validating password
        const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return regex.test(password);
      };
    const redirectToRegister = () => {
        navigate('/register');
      }
    const redirectToForgot = () =>{
      navigate('/forgot-password');
    }  
    async function handleFormSubmit(e) {
        e.preventDefault();
        const credentialType = determineCredentialType(credential);
        const credentails = {}
       
        if (validateCredential(credential) && validatePassword(password)) {
            // Handle sign-in logic
            if(credentialType === 'email'){
              credentails.email= credential;
            }else if(credentialType === 'mobile'){
              credentails.mobile= credential;
            }else{
              credentails.username= credential;
            }
            credentails.password= password;
          
            handleLogin(credential,password);
            console.log(`Sign in with ${credentialType} credentials:`, credential,);
          } else {
            if (!validateCredential(credential)) {
              setCredentialError(true);
            }
            if (!validatePassword(password)) {
              setPasswordError(true);
            }
        }
       
    }
    const determineCredentialType = (value) => {
        if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/.test(value)) {
          return 'email';
        } else if (/^\d{10,15}$/.test(value)) {
          return 'mobile';
        } else {
          return 'username';
        }
      };

      // if (isLoggedIn) {
      //   // Redirect to desired page
      //   return navigate('/');
      // }
      useEffect(() =>{
        if (isLoggedIn) {
         navigate('/dashboard',{ replace: true });
        }
    },[isLoggedIn]);
      
  return (
    <>
    <form onSubmit={handleFormSubmit}>
      <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12} md={8} >
          <Grid item xs={12} sx={{ padding: { xs: '0.5rem',  } }}>
            <TextField
              name={`credential`}
              value={credential}
              //label="Studio Name / Email / Mobile No." @needs to be implemented later 
              label=" Email" 
              //onBlur={handleEmailBlur}
              onChange={handleInputChange}
              //placeholder="Studio Name / Email / Mobile No." @needs to be implemented later 
              placeholder = "Email"
              size='small'
              margin="normal"
              autoComplete="new-credential"
              error={credentialError}
              helperText={credentialError ? 'Please enter a valid email' : ''}
              autoFocus
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sx={{ padding: { xs: '0.5rem',  } }}>
            <TextField
              name={`password`}
              label="Password" 
              value={password}
              //onBlur={handlePassBlur}
              onChange={handlePasswordChange}
              placeholder="Password"
              size='small'
              type={showPassword ? 'text' : 'password'}
              margin="normal"
              autoComplete="new-password"
              error={passwordError}
              helperText={
                passwordError
                  ? 'Password must be at least 8 characters long and contain at least one digit, one lowercase letter, one uppercase letter, one special character, and no whitespace characters.'
                  : ''
              }
              required
              fullWidth
              InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
            />
          </Grid>
          <Grid item xs={12} sx={{ padding: { xs: '1rem'  } }}>
            <Button className='linkBtn p-0' startIcon={<LockIcon style={{color:'rgba(116, 120, 141, 1)'}}/>} disableRipple  onClick={redirectToForgot}><Typography className='linkBtnTxt' sx={{ textTransform: 'capitalize' }}>Forgot Password ?</Typography></Button>
          </Grid>
          <Grid container item xs={12} sx={{ padding: { xs: '1rem',},justifyContent:'space-between' }}>
          <Grid item xs={6} sx={{ padding: { xs: '0rem'  } }}>
            Create account ?{' '}
            <Button className='linkBtn' sx={{padding:'0.3rem 0rem'}} disableRipple onClick={redirectToRegister} ><Typography className='linkBtnTxt' sx={{ textTransform: 'capitalize',fontWeight:'600' }}>Sign up!</Typography></Button>
            </Grid>
            <Grid item xs={6} sx={{ padding: { xs: '0'  } ,textAlign:'end',whiteSpace:'nowrap'}}>
            <button type="submit" className="gn-actionbtn" style={{scale:smScreen?'0.9':'1'}}>Sign in</button>
            </Grid>
          </Grid>
          <div style={{color: 'red',fontSize: '0.75rem',marginTop: '4px',marginLeft: '14px',marginRight: '14px',}}>{error}</div>
        </Grid>
      </Grid>
    </form>
    </>
  )
}

export default LoginForm