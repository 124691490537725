import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import userAdminImtype from '../../img/userAdmin.png';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter' 
import { useNavigate, useParams } from 'react-router-dom';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import {createTheme,ThemeProvider,Grid,IconButton,Slider,Typography,Tooltip, useMediaQuery,} from "@mui/material";
import { tooltipClasses }  from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import styled from '@emotion/styled';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    maxWidth: '13.9rem',  
  },
}));
export default function ResponsiveCardList({cardData,moduleName}) {
  const smallScreen = useMediaQuery('(max-width: 600px)');
    const history = useNavigate()
    const showInListView = (module)=>{
        switch(module)
        {
          case 'artistList':
            {
              return (
                <div className='my-3 responsive-list' onClick={()=>history(`/artistDetail/${cardData.userHandle}`)} style={{cursor:'pointer'}}>
                <Card sx={{ maxWidth: '500px', width:'100%'}}>        
                    <div className='d-flex w-100 '>
                      <div style={{padding:'0.5rem'}}>  
                      <CardMedia
                        component="img"
                        sx={{ width:'60px', height:'60px',borderRadius:'15px'}}
                        image={cardData.avatar == null || cardData.avatar === "" || cardData.avatar === process.env.REACT_APP_CREATOR_MEDIA_URL+'/media/'+cardData.avatar ? userAdminImtype : process.env.REACT_APP_CREATOR_MEDIA_URL+'media/'+cardData.avatar}
                        />        
                      </div>
                      <div style={{padding:'0.5rem',flexGrow:1}}>
                        <div className='d-flex justify-content-between'>
                          {cardData.name.length > 13 && smallScreen ? <BootstrapTooltip title={cardData.name} sx={{marginTop:'-8px',width:'auto'}} TransitionComponent={Zoom}>
                          <strong className='cardheadingFont overflow-text'>{cardData.name.slice(0, 10)}...</strong></BootstrapTooltip>:
                          <strong className='cardheadingFont overflow-text'>{cardData.name}</strong>}
                            {/* <div style={{ minWidth:'72px', padding:0}} className='status text-light'>         
                                {cardData.status.length > 0 && cardData.status.toLowerCase() ==='pending'?<Typography variant="body2" className='VerifyStatus pendingStatus status text-light cardheadingFont' sx={{backgroundColor:getSongStatusBgColor(cardData.status)}} gutterBottom>
                                {cardData.status}
                                </Typography>:cardData.status.length > 0 && cardData.status.toLowerCase() === "rejected"?<Typography variant="body2" className='VerifyStatus notVerifyStatus status text-light cardheadingFont' sx={{backgroundColor:getSongStatusBgColor(cardData.status)}} gutterBottom>
                                {cardData.status}
                                </Typography>:cardData.status.length > 0 && cardData.status.toLowerCase() === "verified" ?<Typography variant="body2" className='VerifyStatus verifyStatus status text-light cardheadingFont' sx={{backgroundColor:getSongStatusBgColor(cardData.status)}} gutterBottom>
                                {cardData.status}
                                </Typography>:<Typography variant="body2" className='VerifyStatus notVerifyStatus status text-light cardheadingFont' sx={{backgroundColor:getSongStatusBgColor(cardData.status)}} gutterBottom>
                                Not Verified 
                                </Typography>}
                            </div>   */}
                        </div>
                        <div className='cardheadingFont text-secondary cardLine py-2'>{cardData.email}</div>
                        <div className='cardfont'>{cardData.genres}</div>
                        <div className={'d-flex justify-content-between'} >
                            <div className='cardfont'>{cardData.location}</div>
                            {/* <div className='cardfont '>{cardData.regDate}</div> */}
                        </div>
                      </div>
                   </div>
                  </Card>
                 </div>
              )
            }
          default:{
            return;
          }
        }
    }
    return (
      showInListView(moduleName)
    )
}