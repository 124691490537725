import React from 'react';
import Box from '@mui/material/Box';
import Search from '../royalty/Search';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import TableComponent from '../royalty/Tablecomponent';

const rows = [
    {id:1,name: 'Amazon', streams: 437, prevday: <Box className="d-flex gap-2" sx={{ color: '#FF0002' }}><ArrowDownwardIcon sx={{ fontSize: '18px' }} />- 17.65% </Box>},
    {id:2,name: 'Apple Music', streams: 437, prevday: <Box className="d-flex gap-2" sx={{ color: '#51B800' }}><ArrowUpwardIcon sx={{ fontSize: '18px' }} />+ 3600%</Box>},
    {id:3,name: 'Facebook', streams: 437, prevday: <Box className="d-flex gap-2" sx={{ color: '#51B800' }}><ArrowUpwardIcon sx={{ fontSize: '18px' }} />+ 3600%</Box>},
    {id:4,name: 'Spotify', streams: 437, prevday: <Box className="d-flex gap-2" sx={{ color: '#FF0002' }}><ArrowDownwardIcon sx={{ fontSize: '18px' }} />- 66.67%</Box>},
    {id:5,name: 'YouTube', streams: 437, prevday: <Box className="d-flex gap-2" sx={{ color: '#51B800' }}><ArrowUpwardIcon sx={{ fontSize: '18px' }} />+ 3600%</Box>},
];

const columns = [{ id: 'name', field:"name", numeric: false, disablePadding: true, label: 'Track', },
{ id: 'streams', field:"streams", numeric: true, disablePadding: false, label: 'Streams', },
{ id: 'prevday', field:"prevday", numeric: true, disablePadding: false, label: 'VS. PREVIOUS7 DAYS', }
];

export default function Streams() {
 
    return (
        <Box sx={{ width: '100%' }}>
            <Search headValue={'Streams'} />
            <TableComponent columns={columns} data={rows} hideTotal={true} />

        </Box>
    );
}