import * as React from 'react';
import Box from '@mui/material/Box';
import { FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { handleInputPhoneChange } from './helperComponent';
import { useSelector } from 'react-redux';

export default function PayeeDetail({ payoutData, setPayoutData, payoutDataErrors, handleInputChange }) {
  const countryData = useSelector((state) => state.royaltyDataReducer.countryData);
  return (
    <Box>
      <Typography variant='h6'>Enter Your Information</Typography>
      <Typography variant='span'>To ensure that you receive your payments on time, please enter your details as you shared them with your bank.</Typography>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item container xs={12} direction={'row'} sx={{ margin: '0.5rem' }}>
          <Grid item xs={12} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='first_name'
              noValidate
              fullWidth={true}>
              <TextField
                onBlur={handleInputChange}
                onChange={handleInputChange}
                value={payoutData.first_name}
                error={payoutDataErrors.first_name}
                helperText={payoutDataErrors.first_name ? 'Please enter valid full name':''}
                // helperText={payoutDataErrors.first_nameErr}
                name={`first_name`} label="Full Name" fullWidth size='small' required />
            </FormControl>
          </Grid>
          <Grid item xs={12} >
            <FormControl margin='dense' sx={{ paddingLeft: '5px' }}>
              <FormLabel id="demo-radio-buttons-group-label">Type *</FormLabel>
              <RadioGroup
                row
                onChange={handleInputChange}
                value={payoutData.type}
                aria-labelledby="demo-radio-buttons-group-label"
                name="type"
              >
                <FormControlLabel value="Individual" control={<Radio />} label="Individual" />
                <FormControlLabel value="Company" control={<Radio />} label="Company" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='contact_email'
              noValidate
              fullWidth={true}>
              <TextField
                onBlur={handleInputChange}
                onChange={handleInputChange}
                error={payoutDataErrors.email}
                helperText={payoutDataErrors.emailErr}
                value={payoutData.contact_email}
                name={`contact_email`} label="Email" placeholder='example@email.com' fullWidth size='small' required />
            </FormControl>
          </Grid>
          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='phone_number'
              noValidate
              fullWidth={true}>
              <PhoneInput
                onChange={(event) => handleInputPhoneChange(event, payoutData, setPayoutData)}
                value={payoutData.phone_number}
                country={'in'}
                name="phone_number"
                inputProps={{ maxLength: 15 }}
              />
            </FormControl>
          </Grid>
        

          {payoutData.type == 'Company' && <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='company'
              noValidate
              fullWidth={true}>
              <TextField
                onChange={handleInputChange}
                value={payoutData.company}
                // error={payoutDataErrors.company}
                // helperText={payoutDataErrors.companyNameErr}
                name={`company`} label="Company" fullWidth size='small' required />
            </FormControl>
          </Grid>}
          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='address'
              noValidate
              fullWidth={true}>
              <TextField
                onChange={handleInputChange}
                value={payoutData.address}
                error={payoutDataErrors.address}
                helperText={payoutDataErrors.addressErr}
                name={`address`} label="Address" fullWidth size='small' required />
            </FormControl>
          </Grid>
          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='city'
              noValidate
              fullWidth={true}>
              <TextField
                onChange={handleInputChange}
                value={payoutData.city}
                error={payoutDataErrors.city}
                helperText={payoutDataErrors.city ? 'Please enter valid city name':''}
                // helperText={payoutDataErrors.cityErr}
                name={`city`} label="City" fullWidth size='small' required />
            </FormControl>
          </Grid>
          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='zipcode'
              noValidate
              fullWidth={true}>
              <TextField
                onBlur={handleInputChange}
                onChange={handleInputChange}
                value={payoutData.zipcode}
                error={payoutDataErrors.zip_code}
                helperText={payoutDataErrors.zipcodeErr}
                name={`zipcode`} label="ZIP" fullWidth size='small' required />
            </FormControl>
          </Grid>
          <Grid item xs={6} >
            <FormControl margin='dense' sx={{ padding: '5px' }}
              name='country'
              noValidate
              fullWidth={true}>
              <InputLabel id="demo-simple-select-label">Country *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Country"
                size='small'
                required
                onChange={handleInputChange}
                value={payoutData.country}
                name='country'
              >
                {countryData?.data?.length && countryData?.data.map((d, i) => (
                  <MenuItem value={d.id} id={d.id} key={d.id}>
                    {d.country_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}