import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getServiceData } from '../../_services/Services/service.get';
import { updateServices } from '../../_services/Services/service.update';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchLoginUser,
  setLoader,
  setSnackBar,
  setSnackBarMessage,
  setSnackBarVariant
} from '../../redux';
import { formSnackbar } from '../../_constants/snackbar.constant';
import SnackbarContainer from '../Snackbar';

function Services(props) {
  const [service, setService] = useState([]);
  const [userService, setUserService] = useState([]);
  const userData = useSelector((state) => state.userData.userData);
  const userDetail = useSelector((state) => state.userDetail.userDetail);
  const dispatch = useDispatch();

  useEffect(function () {
    setUserService(userDetail.services ? userDetail.services : []);
  }, [userDetail]);

  useEffect(function () {
    if (userData.studio_id) {
      dispatch(fetchLoginUser(userData.studio_id));
    }
  }, [userData.studio_id]);

  useEffect(function () {
    dispatch(setLoader(true));
    getServiceData()
      .then(function (response) {
        dispatch(setLoader(false));
        setService(response.data.data);
      })
      .catch(function (error) {
        dispatch(setLoader(false));
        console.log(error);
        console.log(error.response);
        return error.response;
      });
  }, []);

  function updateService(postData) {
    dispatch(setLoader(true));
    dispatch(setSnackBarVariant('info'));
    dispatch(setSnackBarMessage(formSnackbar.PRE_SERVICE_UPDATE));
    dispatch(setSnackBar(true));
    updateServices(postData)
      .then(function (response) {
        if (response.status === 200) {
          dispatch(setSnackBarVariant('success'));
          dispatch(setSnackBarMessage(formSnackbar.POST_SERVICE_UPDATE));
          setTimeout(() => dispatch(setSnackBar(false)), 2000);
          dispatch(setLoader(false));
          // alert('success')
        } else {
          dispatch(setLoader(false));
          dispatch(setSnackBarVariant('error'));
          dispatch(
            setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure)
          );
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
          // alert('fail')
        }
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        return error.response;
      });
  }

  function onChangeServiceHandler(e, data) {
    if (userService && userService.find((d) => e.target.id == d.id)) {
      let a = userService.filter((r) => r.id != e.target.id);
      setUserService([...a]);
      let selectedIds = a ? (a.length > 0 ? a.map((obj) => obj.id) : []) : [];
      var postData = {};
      postData['user'] = userData.studio_id;
      postData['service'] = selectedIds.join(',');
      updateService(postData);
    } else {
      setUserService([...userService, data]);
      let selectedIds = [];
      selectedIds = userService
        ? userService.length > 0
          ? userService.map((obj) => obj.id)
          : []
        : [];
      selectedIds = [...selectedIds, data.id];
      var postData = {};
      postData['user'] = userData.studio_id;
      postData['service'] = selectedIds.join(',');
      updateService(postData);
    }
  }

  return (
    <div>
      <h4 className="mb-4">Services</h4>
          <p className="text-secondary">
            We offer a range of services for musicians, singers and other audio professionals.
          </p>
          <div className="pt-3 row px-2">
            {service
              ? service.map((d) => (
                  <div className="col-md-4 col-sm-12 col-lg-3 p-0 " key={d.id}>
                    <div className="form-check-inline d-inline-flex">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => onChangeServiceHandler(e, d)}
                              id={d.id}
                              name={d.id}
                              checked={
                                userService
                                  ? userService.filter((v) => v.id == d.id).length
                                    ? true
                                    : false
                                  : false
                              }
                            />
                          }
                          label={d.service}
                        />
                      </FormGroup>
                    </div>
                  </div>
                ))
              : []}
          </div>
      {/*  to show and hide the snackbar messages */}
      <SnackbarContainer />
    </div>
  );
}

export default Services;
