import React, { useEffect, useState } from 'react';
import { Modal, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import link from '../../img/link (2).png'

const ConnectionAction = ({data, handleUnlink}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: '8px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1) !important',
    p: 4,
    maxWidth: 600,
    zIndex: 2,
  };
  const [buttonText, setButtonText] = useState();
  const [showUnlink,setShowUnlink] = useState(false);
  const [textAsPerStatus, setTextAsPerStatus] = useState('') //just to set the text as per status if not any action
  const userData = useSelector(state=>state.userData.userData)
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
 
  useEffect(()=>{
    if(data.connection_info.status==='pending' && data.connection_info.sender_id===userData.studio_id){
       setButtonText('Revoke Request')
       setShowUnlink(true)
    }
    else if(data.connection_info.status==='pending' && data.connection_info.sender_id!==userData.studio_id){
      setTextAsPerStatus('Request Pending')  
      setShowUnlink(false)
    }
    else if (data.connection_info.status==='accepted'){
      setButtonText('Remove Connection');  
      setShowUnlink(true)
    }
    else if (data.connection_info.status==='rejected'){
      setTextAsPerStatus('Request rejected')  
      setShowUnlink(false)
    }
    else{
      setButtonText(''); 
      setShowUnlink(false) 
    }
  },[data]) //code to handle the action button to revoke, unlink the connection

  const unlinkAssociate = () => {
    handleUnlink(data.connection_info.sender_id,data.connection_info.receiver_id);
    handleClose()
  };

  return (
      <div>
        {showUnlink?(
            <div>
              <img src={link} alt='' width='20px' height='20px' onClick={handleOpen} title={buttonText} />
            </div>
          ):(
            <div style={{opacity:'0.5'}}>
                <img src={link} alt='' width='20px' height='20px' title={textAsPerStatus} />
            </div>
          )
        }
        <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <section className="mb-4">
                  <h4 style={{ fontWeight: '900' }}>{buttonText}</h4>
                </section>
                <section className="body">
                  <p style={{ fontWeight: '400', marginBottom: '0.5rem' }}>
                    Are you sure you want to {buttonText}?
                  </p>
                  <p style={{ color: '#3333337a', fontWeight: '500' }}>
                    You cannot undo this action.
                  </p>
                </section>
                <section className="footer d-flex justify-content-end mt-4">
                  <button
                    className="gn-actionbtn outlined dark me-3"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  {showUnlink && ( 
                    <button
                      aria-label="Unlink"
                      className="gn-actionbtn"
                      onClick={unlinkAssociate}
                      title={buttonText} 
                    >
                      {buttonText}
                    </button>
                  )}
                  
                </section>
              </Box>
        </Modal>
      </div>
  );
};

export default ConnectionAction;
