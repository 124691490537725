import axios from "axios";
import { getSelfToken } from '../../_helpers/auth/secureToken';
import { userRoleApi } from '../../_constants/api.constant';

export async function userRoleService(postData, actiontype,limit,offset) {
  switch (actiontype) {
    case 'POST': {
      try {
        const response = await axios.post(
          userRoleApi.POST,
          postData,
          {
            // params: prarmTempObj,
            headers: {
                Authorization: 'Token '+getSelfToken(),
            },
          }
        );
      return response;
      } catch (error) {
        console.log(error);
        console.log(error.response);
        return error.response.data;
      }
    }
    case 'GET': {
      try {
        const response = await axios.get(
          userRoleApi.GET,
          {
            params:{search:postData.userId,limit:limit,offset:offset},
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
          }
        );
        return response;
      } catch (error) {
        console.log(error);
        console.log(error.response);
        return error.response.data;
      }
    }
    case 'USRROLES': {
      try {
        const response = await axios.get(
          userRoleApi.USRROLES,
          {
            // params: { search: postData.userId },
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
          }
        );
        return response;
      } catch (error) {
        console.log(error);
        console.log(error.response);
        return error.response.data;
      }
    }case 'SEARCH': {
      try {
        const response = await axios.get(
          userRoleApi.SEARCH,
          {
            params: { associated_studio: postData.associated_studio,search:postData.search,limit:limit,offset:offset},
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
          }
        );
        return response;
      } catch (error) {
        console.log(error);
        console.log(error.response);
        return error.response.data;
      }
    }
    default: {
      throw new Error('Invalid action type!');
    }
  }
}
