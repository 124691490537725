import React from 'react'
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';


export default function Footer(props) {
  return (
    

    <footer className="main-footer text-center fixed-bottom border footer" style={{zIndex:'1'}}>
      <div className="pt-3 footerBg" dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_COPYRIGHT }} />
    </footer>
          
        );
      }
