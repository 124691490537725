import { Box, Card, CardContent, CardMedia, Divider, Link, List, ListItem, ListItemText, Stack, Tooltip, Typography, Zoom, styled } from '@mui/material';
import React from 'react'
import { tooltipClasses }  from '@mui/material/Tooltip';
import profileImge from '../../img/header/profileImage.png';
import userAdminImtype from '../../img/userAdmin.png';
import { Navigate } from 'react-router-dom';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      maxWidth: '13.9rem',  
    },
  }));
const listStyle ={
    width: 'max-content',
    minWidth: '55%',
    margin:'1rem',
    lineHeight:'1rem', 
  }
  const cardStyle={
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '25rem',
    maxHeight: '20rem',
    minWidth: '18rem',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1) !important',
    borderRadius:'6px',
    }
const usersCardView = ({data}) => {
const userRoles = (id)=>{
    switch (id) {
        case 1:
            return 'Admin';
            break;
        case 2:
        return 'Editor';
        break;
        case 3:
        return 'Viewer';
        break;
        default:
            return 'User';
            break;
    }    
    
    }
  
  return (
     <Card variant="outlined" sx={cardStyle} key={data.studio_id}>
    <Box sx={{ display: 'flex', flexDirection: 'column', width:'100%'}} >
      <CardContent className='p-3' sx={{ flex: '1 0 100%', paddingRight:0}} >
      <Stack direction='row' spacing={1}>
            <div class="item" style={{ width: '100px', paddingLeft: '2px' }}>
                {/* {data && data.isVerified && <span className="notify-badge"><VerifiedIcon fontSize='small' sx={{color:'#458EFF',borderRadius:'50%',backgroundColor:'#fff'}}/></span>}  */}
                <CardMedia
                component="img"
                sx={{ borderRadius: '50%', width: '5rem', height: '5rem' }}
                image={data.avatar == null || data.avatar === ""  ? userAdminImtype : process.env.REACT_APP_BASEURL_MEDIA+'/media/'+data.avatar} 
                onError={(e) => { e.target.src = profileImge }} alt="img"
                />
            </div>
            {/* <Stack className='detail text-secondary' sx={{ width: '100%'}}>{data.name}</Stack>
            <Stack className='detail text-secondary' sx={{ width: '100%' }}>{userRoles(data.user_role_id)}</Stack> */}
            <Stack>
            {data && data.name && data.name.length > 20 ? <BootstrapTooltip title={data.name} sx={{marginTop:'-8px',width:'auto'}} TransitionComponent={Zoom} ><Typography variant="subtitle1" component="div" className='detail text-secondary'>{data.role === 1 ? data.studio_name.slice(0,10):data.name.slice(0, 10)}...</Typography></BootstrapTooltip>:
            <Typography variant="subtitle1" component="div" className='detail text-secondary'>{data.role === 1 ? data.studio_name :data.name}</Typography>}
                
                <Typography variant="body2" component="div" className='detail text-secondary'>
                   {userRoles(data.role)}
                </Typography>
            </Stack>
        </Stack>
      <List sx={listStyle} component="span" aria-label="Creators Details">
        <ListItem key={data.id}>
            <ListItemText className='cardDataKey' primary="Email"/>
            {data && data.email && data.email.length > 18 ? <BootstrapTooltip title={data.email} sx={{marginTop:'-8px',width:'auto'}} TransitionComponent={Zoom} ><ListItemText className='cardDatauser'>{data.email.slice(0, 18)}...</ListItemText></BootstrapTooltip>:
            <ListItemText className='cardDatauser' >{data.email}</ListItemText>}
            </ListItem>
            <Divider className='listborder' variant='middle'/>
            <ListItem key={'mob'+data.id}>
            <ListItemText className='cardDataKey' primary="Mobile " />
            <ListItemText className='cardDatauser userHandle'>{data.phone_number}</ListItemText>
            </ListItem> 
        </List>
      </CardContent>
  </Box>
    
  </Card>
  
  )
  
}

export default usersCardView