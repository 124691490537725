import React, { useEffect } from 'react';
import { useState } from 'react';
import {  Card, CardContent, IconButton, Typography, Dialog, DialogTitle, DialogContent, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SnackbarContainer from '../Snackbar';
import { postAgreement } from '../../_services/Agreement/agreement.service';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_constants/snackbar.constant';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';
import Policy from '../../documents/GrooveNexus.pdf';
import FileUploadCard from './FileUpload';
function Agreement() {
  const [loading, setLoading] = useState(true); 
  const [agreementSubmit, setAgreementSubmit] = useState(null);
  const [agreementLayout, setAgreementLayout] = useState(true);
  const userData = useSelector((state) => state.userData.userData);
    const [uploadedMou, setUploadedMou] = useState('');
  const [data, setData] = useState({
    file: '',
  });
  const dispatch = useDispatch();
  const [pdfURL] = useState(Policy);
  const [agreementId,setAgreementid] = useState('')
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [errorState, setErrorState] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [previewPdfUrl, setPreviewPdfUrl] = useState('');
  const [openPdfPreview, setOpenPdfPreview] = useState(false);
  const [isAgreementSubmitted, setIsAgreementSubmitted] = useState(false);
  const [fileUploadDisabled, setFileUploadDisabled] = useState(false);
  function handleDownload() {
    setAgreementSubmit(null);
    setUploadedMou(null);
    setAgreementLayout(false);
    setDisableBtn(true);
    setErrorState(false);
  }

  function saveSubmit(e) {
    e.preventDefault();
    data.studio_id = userData.studio_id;
    dispatch(setLoader(true));
    dispatch(setSnackBarVariant('info'));
    dispatch(setSnackBarMessage(formSnackbar.agreement.submittingAgreement));
    dispatch(setSnackBar(true));
    let postData = data;
    const formData = new FormData();
    for (const key in postData) {
      formData.append(key, postData[key]);
    }
    postAgreement(formData, 'POST').then(function (response) {
      if (response.status === 200) {
       dispatch(setLoader(false));
        dispatch(setSnackBarVariant('success'));
        dispatch(setSnackBarMessage(formSnackbar.agreement.submittingAgreementSuccess));
        setTimeout(() => dispatch(setSnackBar(false)), 2000);
        setDisableBtn(true);
        setAgreementLayout(false);
        setIsAgreementSubmitted(true);
        setFileUploadDisabled(true);
       } else {
        dispatch(setLoader(false));
        dispatch(setSnackBarVariant('error'));
        dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
        setTimeout(() => dispatch(setSnackBar(false)), 3000);
        setAgreementLayout(true);
        setIsAgreementSubmitted(false);
        setFileUploadDisabled(false);
      }
    });
  }
 const handleUploadProgress = (progressEvent) => {
    setFileUploadProgress(progressEvent);
  };

  function validatePDF(file) {
    if (!file) {
      return false;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const fileType = file.type;

    if (fileType !== 'application/pdf') {
      try {
        throw new Error('Invalid file format. Please upload a PDF file.');
      } catch (error) {
        setErrorState(true);
        console.error(error.message);
      }
      return false;
    } else {
      reader.onloadend = () => {
        if (file.name.match(/\.(pdf|PDF)$/)) {
          setAgreementSubmit(reader.result);
          setData({
            ...data,
            ['file']: file,
          });
          return true;
        } else {
          return false;
        }
      };
    }
  }
  
  const getAgreementData = (postData) => {
    dispatch(setLoader(true));
    postAgreement(postData, 'GET').then(function (response) {
      if (response.status === 200) {
        if (response.data.data[0] && response.data.data[0].file !== null) {
          setAgreementid(response.data.data[0].id);
          setDisableBtn(true);
          setAgreementLayout(false);
          setIsAgreementSubmitted(true);
          getAgreementLayout('submit');
          setFileUploadDisabled(true);
          let pdfUrl = process.env.REACT_APP_BASEURL_MEDIA + '/media/' + response.data.data[0].file;
          fetch(pdfUrl)
            .then((response) => response.arrayBuffer())
            .then((arrayBuffer) => {
              const base64String = arrayBufferToBase64(arrayBuffer);
              const fileDetails = {
                name: response.data.data[0].file,
                type: 'application/pdf', // Set the MIME type to application/pdf
                size: base64String.length,
                base64: base64String,
              };
              const file = base64ToFile(fileDetails.base64, fileDetails.name, fileDetails.type);
              setUploadedMou(file);
              setAgreementSubmit(base64String);
            })
            .catch((error) => {
              console.error('Error retrieving file:', error);
            });
          setPreviewPdfUrl(pdfUrl);
        } else {
          getAgreementLayout('preview');
        }
      } else {
        dispatch(setSnackBarVariant('error'));
        dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
      }
      setLoading(false); // Set loading to false after fetching and processing
      dispatch(setLoader(false));
    });
  };
  
  function arrayBufferToBase64(arrayBuffer) {
    const uint8Array = new Uint8Array(arrayBuffer);
    let binaryString = '';
    for (let i = 0; i < uint8Array.length; i++) {
      binaryString += String.fromCharCode(uint8Array[i]);
    }
    return btoa(binaryString);
  }
  
  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
  
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
  
    return bytes.buffer;
  }
  
  function base64ToFile(base64String, fileName, fileType) {
    const arrayBuffer = base64ToArrayBuffer(base64String);
    const blob = new Blob([arrayBuffer], { type: fileType });
    return new File([blob], fileName, { lastModified: Date.now() });
  }
  
  
  useEffect(() => {
    fileUploadProgress === 100 && !errorState && !isAgreementSubmitted && setDisableBtn(false);
  }, [fileUploadProgress]);
  
  useEffect(() => {
    let postData = {}
      postData.id = userData.studio_id;
      userData.studio_id && getAgreementData(postData)
      uploadedMou === '' ? setAgreementLayout(true) : setAgreementLayout(false)
  }, [userData.studio_id])


  useEffect(() => {
    if(isAgreementSubmitted){
      setDisableBtn(true);
      let postData = {}
      postData.id = userData.studio_id;
      userData.studio_id && getAgreementData(postData)
      uploadedMou === '' ? setAgreementLayout(true) : setAgreementLayout(false)
    }
  }, [isAgreementSubmitted])
  

  const handleChangePDF = (event) => {
    let file = event.target.files[0];
    if (file) {
      validatePDF(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        setAgreementSubmit(event.target.result);
      };
      reader.onerror = (e) => {
        console.error('Error occurred while reading the file');
        setErrorState(true);
      };
      setUploadedMou(file);
      reader.readAsDataURL(file);
    }
  };
 
  function handlePreview(file) {
    if (file instanceof File || file instanceof Blob) {
      const reader = new FileReader();
  
      reader.onload = () => {
        const arrayBuffer = reader.result;
        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
  
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const fileDataUrl = fileReader.result;
          setPreviewPdfUrl(fileDataUrl);
          setOpenPdfPreview(true);
        };
  
        fileReader.readAsDataURL(blob);
      };
  
      reader.readAsArrayBuffer(file);
    } else {
      console.error('Invalid file object');
    }
  }
  
  const deleteAgreement =(id)=>{
    let postData ={}
    postData.id = id;
    postAgreement(postData, 'DELETE').then(function (response) {
      if (response.status === 200) {
        dispatch(setLoader(false));
        dispatch(setSnackBarVariant('success'));
        dispatch(setSnackBarMessage(formSnackbar.agreement.submittingAgreementSuccess));
        setTimeout(() => dispatch(setSnackBar(false)), 2000);
        setDisableBtn(true);
        setAgreementLayout(true);
        } else {
        dispatch(setSnackBarVariant('error'));
        dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
        setTimeout(() => dispatch(setSnackBar(false)), 3000);
        
      }
    });
  }
  const handlePDFRemove = () => {
    isAgreementSubmitted && agreementId && deleteAgreement(agreementId);
    setAgreementLayout(false);
    setDisableBtn(true);
    setErrorState(false);
    setUploadedMou(null);
    setFileUploadDisabled(false);
    setIsAgreementSubmitted(false);
    setAgreementSubmit(null);
    setUploadedMou(null);
    setData({
      ...data,
      ['file']: '',
    });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    validatePDF(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      setAgreementSubmit(event.target.result);
    };
    reader.onerror = (e) => {
      console.error('Error occurred while reading the file');
      setErrorState(true);
    };
    setUploadedMou(file);
    reader.readAsDataURL(file);
  };

  const getAgreementLayout = (arg) => {
    switch (arg) {
      case 'preview': {
        return (
          <>
            <div>
              <p className="text-secondary">Download the agreement & fill all the field then upload it.</p>
              <div className="container text-center">
                <iframe src={pdfURL} width="100%" height="400" allow="autoplay"></iframe>
                <div className="pt-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <a href={pdfURL} style={{ textDecoration: 'none' }} className="gn-actionbtn text-light" onClick={handleDownload} download="GrooveNexus_MOU">
                    download
                  </a>
                </div>
              </div>
            </div>
          </>
        );
      }
      case 'submit': {
        return (
          <>
            <div>
              <p className="text-secondary">Upload the agreement</p>
              <div className="container">
                <div>
                  <div>
                    {agreementSubmit === null ? (
                      <label className="agreement-uploader" onDrop={handleDrop} onDragOver={(event) => event.preventDefault()} onChange={handleChangePDF}  style={fileUploadDisabled ? {backgroundColor: '#f2f2f2',opacity: '0.6'}: {backgroundColor: 'transparent'}}>
                        <div className="dropzone-content">
                          <div className="text-center">
                            <IconButton color="primary" component="span" >
                              <UploadFileIcon fontSize="large" color="primary" />
                            </IconButton>
                          </div>
                          <Typography variant="body1">
                            <span style={{ color: '#1976D2', textDecoration: 'underline', cursor: 'pointer' }}>
                              Click to upload
                            </span>{' '}
                            or drag and drop
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            PDF or .Doc (max 5MB)
                          </Typography>
                        </div>
                        <input type="file"  accept="application/pdf" style={{ display: 'none' }} disabled={fileUploadDisabled}/>
                      </label>
                    ) : (
                      <div className="agreement-uploader" style={{backgroundColor: '#f2f2f2',opacity: '0.6'}}>
                        <div className="text-center">
                          <IconButton color="primary" component="span" disabled>
                            <UploadFileIcon fontSize="large" color="primary" />
                          </IconButton>
                        </div>
                        <Typography variant="body1">
                          <span style={{ color: '#1976D2', textDecoration: 'underline', cursor: 'pointer' }}>Click to upload</span>{' '}
                          or drag and drop
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          PDF or .Doc (max 5MB)
                        </Typography>
                      </div>
                    )}
                  </div>
                 </div>
                 <Box className="my-4" sx={{ display: 'flex', justifyContent: 'center' }}>
                    {uploadedMou !== null && agreementSubmit !== null && (
                      <Card sx={{ width: '100%', maxWidth: 600,overflowWrap:'anywhere' }}>
                        <CardContent>
                          <FileUploadCard
                            file={agreementSubmit}
                            fileDetails={uploadedMou}
                            removePdf={handlePDFRemove}
                            progress={fileUploadProgress}
                            progressHandler={handleUploadProgress}
                            error={errorState}
                            isAgreementSubmitted={isAgreementSubmitted}
                            handlePreview={handlePreview} // Pass the handlePreview function as a prop
                            agreementId={isAgreementSubmitted && agreementId ? agreementId : ''}
                          />
                        </CardContent>
                      </Card>
                    )}
                  </Box>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="pt-4">
                  <button onClick={saveSubmit} className="gn-actionbtn" disabled={disableBtn}>
                      Submit
                    </button>
                   </div>
              </div>
              <Dialog
                open={openPdfPreview}
                onClose={() => setOpenPdfPreview(false)}
                fullWidth
                maxWidth="xl"
                PaperProps={{
                  style: {
                    backgroundColor: 'rgb(50 54 57)',
                  },
                }}
              >
                <DialogTitle>
                  <IconButton aria-label="close" component="span" onClick={() => setOpenPdfPreview(false)} style={{ position: 'absolute', right: '8px', top: '8px', color: 'white' }}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent style={{ height: '80vh' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <iframe src={previewPdfUrl} className="pdf-preview-iframe" title="PDF Preview" />
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          </>
        );
      }
      default:
        return;
    }
  };

  return (
    <div>
      <h4 className="mb-4">Agreement For Collaboration Between Music Studios</h4>
      <div className="card account-bg">
        <div className="card-body">{agreementLayout ? getAgreementLayout('preview') : getAgreementLayout('submit')}
       </div>
       <SnackbarContainer /> {/*  to show and hide the snackbar messages */}
      </div>
    </div>
  );
}

export default Agreement;
