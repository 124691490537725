import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardContent, CardMedia, Divider, Grid, Link, List, ListItem, ListItemText, Modal, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';
import { tooltipClasses } from '@mui/material/Tooltip';
import profileImge from '../../img/header/profileImage.png';
import Zoom from '@mui/material/Zoom';
import userAdminImtype from '../../img/userAdmin.png';
// import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import { useState, useEffect } from 'react';
import link from '../../img/link (2).png'
import { useDispatch, useSelector } from 'react-redux';
import { creatorAssociation, studioLinking } from '../../_services/creatorAssociation/creatorAssociation.service';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_constants/snackbar.constant';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';


const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    maxWidth: '13.9rem',
  },
}));
const listStyle = {
  width: 'max-content',
  minWidth: '100%',
  // margin:'1rem',
  // lineHeight:'1rem', 
}
const cardStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  minWidth: '16rem',
  maxHeight: '16rem',
  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1) !important',
  borderRadius: '6px',
}


const ArtistCardLayout = ({ data, fetchAssociatedCreatorsList, handleUnlink }) => {
  const navigate = useNavigate();
  const userData = useSelector(state => state.userData.userData)
  const mediumViewport = useMediaQuery('(max-width:720px)');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [buttonText, setButtonText] = useState();
  const [showUnlink, setShowUnlink] = useState(false);
  const [textAsPerStatus, setTextAsPerStatus] = useState('') //just to set the text as per status if not any action
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: mediumViewport ? '95%' : '50%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    maxWidth: 600,
    zIndex: 2
  };
  useEffect(() => {
    if (data.connection_info.status === 'pending' && data.connection_info.sender_id === userData.studio_id) {
      setButtonText('Revoke Request')
      setShowUnlink(true)
    }
    else if (data.connection_info.status === 'pending' && data.connection_info.sender_id !== userData.studio_id) {
      setTextAsPerStatus('Request Pending')
      setShowUnlink(false)
    }
    else if (data.connection_info.status === 'accepted') {
      setButtonText('Remove Connection');
      setShowUnlink(true)
    }
    else if (data.connection_info.status === 'rejected') {
      setTextAsPerStatus('Request rejected')
      setShowUnlink(false)
    }
    else {
      setButtonText('');
      setShowUnlink(false)
    }
  }, [data]) //code to handle the action button to revoke, unlink the connection

  const handleConfirm = () => {
    handleUnlink(data.connection_info.sender_id, data.connection_info.receiver_id)
    handleClose(false)

  }
  return (
    // <Card variant="outlined" sx={cardStyle} key={data.id}>
    <Card className="box-album-card shadow-table"  role="button" tabIndex="0">
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CardContent className='p-2' sx={{ flex: '1 0 100%' }}>
          <Stack direction='row' spacing={1}>
            <div className="item" style={{ width: '100px', paddingLeft: '2px' }}>

              <CardMedia
                component="img"
                sx={{ borderRadius: '10px', width: '5rem', height: '5rem' }}
                image={data.avatar ? process.env.REACT_APP_CREATOR_MEDIA_URL + data.avatar : userAdminImtype}
                onError={(e) => { e.target.src = profileImge }}
                alt="img"
              />
            </div>
            <Stack className='detail text-secondary ' sx={{ width: '50%', paddingTop: "1rem" }} title={data.name}>
              {data.name && data.name.length > 10 ?
                <BootstrapTooltip className='text-truncate' sx={{ marginTop: '-8px', width: 'auto' }} TransitionComponent={Zoom}>
                  <Link href='#' className="userHandle" variant="button" color="text.secondary" sx={{ paddingBottom: '.1rem', textDecoration: 'none', cursor: 'pointer' }} component="span" onClick={() => { navigate(`/artistDetail/${data.uid}`); }}>
                    {data.name.slice(0, 10)}...
                  </Link>
                </BootstrapTooltip> :
                <Link href='#' className="userHandle" variant="button" color="text.secondary" sx={{ paddingBottom: '.1rem', textDecoration: 'none', cursor: 'pointer' }} component="span" onClick={() => { navigate(`/artistDetail/${data.uid}`); }} >
                  {data.name}
                </Link>
              }

              {data.connection_info && data.connection_info.status && data.connection_info.status.toLowerCase() ?
                <Typography variant="body2" className={`status ${data.connection_info.status.toLowerCase()}Status text-light`} style={{ background: getSongStatusBgColor(data.connection_info.status) }} gutterBottom>
                  {data.connection_info.status}
                </Typography>
                :
                <Typography variant="body2" className={`status notVerifyStatus text-light`} style={{ background: getSongStatusBgColor('not verified') }} gutterBottom>
                  Not Verified
                </Typography>
              }
            </Stack>
            <div className='d-flex justify-content-end'>
              {userData.role !== 3 ? <div>
                {showUnlink ? (
                  <div>
                    <img src={link} alt='' width='20px' height='20px' onClick={handleOpen} title={buttonText} />
                  </div>
                ) : (
                  <div style={{ opacity: '0.5' }}>
                    <img src={link} alt='' width='20px' height='20px' title={textAsPerStatus} />
                  </div>
                )}

              </div> : ''}
            </div>
          </Stack>
          <Divider className='listborder mt-3' />
          <List sx={listStyle} component="span" aria-label="Creators Details" key={data.id}>
            <ListItem key={'user' + data.id}>
              <ListItemText className='cardDataKey' primary="Genre" />
              {data.genres && data.genres.length > 0 ? (
                <BootstrapTooltip title={data.genres.join(', ')} sx={{ width: 'auto' }} TransitionComponent={Zoom}>
                  <ListItemText className='cardData userHandle' sx={{ maxWidth: '12rem', }}>
                    {Array.isArray(data.genres) ? data.genres.join(', ') : data.genres}
                  </ListItemText>
                </BootstrapTooltip>
              ) : (
                <ListItemText className='cardData userHandle' sx={{ maxWidth: '12rem' }}>
                  No genres available
                </ListItemText>
              )}
            </ListItem>

            <ListItem key={'loc' + data.id} className='pb-2'>
              <ListItemText className='cardDataKey ' primary="Location" />
              {data.location && data.location != null &&
                <ListItemText className='cardData userHandle'>{data.location}</ListItemText>
              }
            </ListItem>
          </List>
        </CardContent>
      </Box>


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <section className="mb-4">
            <h4 style={{ fontWeight: '900' }}>{buttonText}</h4>
          </section>
          <section className="body">
            <p style={{ fontWeight: '400', marginBottom: '0.5rem' }}>Are you sure you want to {buttonText} with {data.name}?</p>
            <p style={{ color: '#3333337a', fontWeight: '500' }}>You can not undo this action.</p>
          </section>
          <section className="footer d-flex justify-content-end mt-4">
            <button className='gn-actionbtn outlined dark me-3' onClick={handleClose}>Cancel</button>
            <button rea-label="Done" title="Done" className='gn-actionbtn' onClick={handleConfirm} >{buttonText}</button>
          </section>
        </Box>
      </Modal>
    </Card>

  )
}

export default ArtistCardLayout