import { useState, useEffect, useContext, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { SnackbarContext } from "../../App";
import SnackbarContainer from '../../components/utils/Snackbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import General from '../account/General';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UploadAlbumFile from './UploadFile';
import { getSingleAlbum } from '../../_services/album/getSingleAlbum';
import { fetchMusicCategoryDetail, fetchReleaseAlbum, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { formSnackbar } from '../../_constants/snackbar.constant';
import moment from 'moment-timezone';
import { updateAlbum } from '../../_services/album/updateAlbum';
import { UploadFile } from '@mui/icons-material';
// import { actionTypes } from '../../redux/royalty/royaltyDataTypes';
import AlbumCredit from './ReleaseCredits';
import { artistCode } from '../songList/associatedArtist/artistStaticCodes';
import { handleArtistData, handleInputChange } from '../songList/helperComponent/helperComponent';
import { associatedArtistsRequestHandler } from '../../_services/associatedArtists/associatedArtist.service';
import AlbumReleaseInformation from './AlbumReleaseInformation';
import { regexValidator } from '../../_helpers/reusablefunctions/regexValidator';
import ApiService from '../../_services/ApiService';
import PlatformDistribution from './PlatformDistribution';
import { getPlatformList } from '../../_services/album/getAllPlatformList.';
import ReleaseFinalReview from './FinalReview';
import { getGenre } from '../../_services/profile';
import { getLanguage } from '../../_services/Language';
import { recordLabelRequest } from '../../_services/recordLabel/recordLabel.service';
import { subgenreRequest } from '../../_services/subgenre.service';
import GenralInformation from './General';
import ConfirmModal from '../../components/reusableComponents/ConfirmModal';
import WarningModal from '../../components/reusableComponents/WarningModal';
import PayForDistribution from './PayForDistribution';
import PaymentSuccessDialog from '../../components/reusableComponents/PaymentSuccessModal';
import { getPaymentDetail } from '../../_services/album/paymentDistribution.service';
import { viewOrderDetail } from '../../_services/Plan/viewOrderdetail.service';

function CreateAlbum(props) {
  const [type, setType] = useState("services")
  const [active, setActive] = useState(1)
  const [albumName, setAlbumName] = useState('')
  const [albumData, setAlbumData] = useState()
  const [releasehistory, setReleasehistory] = useState("False");
  const [copyrighttype, setCopyrighttype] = useState();
  const [primaryArtList, setPrimaryArtList] = useState([])
  const [remixerArtList, setRemixerArtList] = useState([])
  const [featureArtList, setFeatureArtList] = useState([])
  const [lyricistList, setLyricistList] = useState([])
  const [composerList, setComposerList] = useState([])
  const [producerList, setProducerList] = useState([])
  const [platformList, setPlatformList] = useState([])
  const [selectedStore, setSelectedStore] = useState()
  const [typeAction, setTypeAction] = useState('add')
  const [releaseTime, setReleaseTime] = useState(null);
  const [actorList, setActorList] = useState([])
  const [assoArtistList, setAssoArtistList] = useState([])
  const [selectedFile, setSelectedFile] = useState(null);
  const [copyrightData, setCopyrightData] = useState({ 'copyrightC': '', 'copyrightP': '' });
  const [copyrightp_name, setCopyrightp_name] = useState('');
  const [copyrightc_name, setCopyrightc_name] = useState('');
  const [copyrights_c, setCopyrights_c] = useState('');
  const [copyrights_p, setCopyrights_p] = useState('');
  const [releaseDate, setRelease_Date] = useState(null)
  const [prereleaseDate, setPreRelease_Date] = useState(null)
  const [openSuccess, setOpenSuccess] = useState(true)
  const [copyrightList, setCopyrightList] = useState([])
  // const [planId, setPlanId] = useState('')
  const genre = useSelector((state) => state.musicCategorgyReducer?.genreData ? state.musicCategorgyReducer?.genreData.data : []);
  const subGenreList = useSelector((state) => state.musicCategorgyReducer?.subGenreData ? state.musicCategorgyReducer?.subGenreData.data : []);
  const warningRef = useRef()
  const paymentSucessRef = useRef()

  const [songList, setSongList] = useState([])
  const [publisher_type, setPublisher_type] = useState('')
  const [titleVersionList, setTitleVersionList] = useState([])
  const language = useSelector((state) => state.musicCategorgyReducer?.languageData ? state.musicCategorgyReducer?.languageData.data : []);
  const recordLabelList = useSelector((state) => state.musicCategorgyReducer?.recordLabelData ? state.musicCategorgyReducer?.recordLabelData.data : []);
  // const [subGenreList, setsubGenreList] = useState([])
  const [chooseSelectType, setChooseSelectType] = useState('False')
  const [albumDisabledStatus, setAlbumDisabledStatus] = useState(true)
  const [onChangeFlag, setOnChangeFlag] = useState("False")
  const [releaseDataBkp, setReleaseDataBkp] = useState();
  const confirmref = useRef();
  const releaseStatusReview = "You don't have permission to perform this task as it has been sent to the distributor for approval."
  const releaseStatusReleased = "You don't have permission to perform this task as it has been already distributed"
  const [nextBtnDisable, setNextBtnDisable] = useState(true)
  const [orderIDFlag, setOrderIdFlag] = useState(false)
  const [releaseData, setReleaseData] = useState()

  const history = useNavigate()
  const userData = useSelector((state) => state.userData.userData);
  const { albumId } = useParams(); // to get the song id from the url if user reapply
  const dispatch = useDispatch();
  const [paymentFail, setPaymentFail] = useState(false)


  const [accountTabs, setAccountTabs] = useState([
    {
      id: 1,
      tabActiveId: 1,
      tabTitle: 'UPLOAD FILE',
      tabType: 'uploadFile'
    },
    {
      id: 2,
      tabActiveId: 2,
      tabTitle: 'GENERAL',
      tabType: 'general'
    },
    {
      id: 3,
      tabActiveId: 3,
      tabTitle: 'RELEASE CREDITS',
      tabType: 'releaseCredit'
    },
    {
      id: 4,
      tabActiveId: 4,
      tabTitle: 'RELEASE INFORMATION',
      tabType: 'ReleaseInformation'
    },
    {
      id: 5,
      tabActiveId: 5,
      tabTitle: 'PLATFORM',
      tabType: 'platform'
    },
    {
      id: 6,
      tabActiveId: 6,
      tabTitle: 'FINAL REVIEW',
      tabType: 'finalReview'
    },
  ])

  const [data, setData] = useState(
    {
      album_name: "",
      primary_artists: '',
      featuring_artists: '',
      composers: '',
      lyricists: '',
      producers: '',
      record_labels: '',
      actors: '',
      remixers: '',
      release_date: null,
      release_time: '',
      catalog: '',
      title_version: '',
      copyright_p: null,
      copyright_c: null,
      release_history: 'False',
      copyright_type: '',
      previous_release_date: '',
      upc_code: '',
      album_description: '',
      publisher_type: "",
      genre: '',
      sub_genre: '',
      language: '',
      song_status: '',
      store_status: "False",
      platform: '',
      cover_image: "",
      planId: ''
    }
  );

  const [errors, setErrors] = useState({
    album_name: false,
    album_name_txt: false,
    primary_artist: false,
    primary_artist_helperTxt: false,
    remixers: false,
    remixer_artist_helperTxt: false,
    featuring_artist: false,
    featuring_artist_helperTxt: false,
    composer: false,
    composer_helperTxt: false,
    lyricist: false,
    lyricist_helperTxt: false,
    producer: false,
    producer_helperTxt: false,
    record_label: false,
    record_label_helperTxt: false,
    actor: false,
    actor_helperTxt: false,
    upc_code: false,
    upc_code_helperTxt: false,
    catalog: false,
    catalog_helperTxt: false,
    copyrightp_name: false,
    copyrightp_name_helperTxt: false,
    copyrightc_name: false,
    copyrightc_name_helperTxt: false,
    album_description: false,
    album_description_helperTxt: false,
    sub_genre: false,
    sub_genre_helperTxt: false,


  });

  const location = useLocation();

  const payment_success_url = new URLSearchParams(location.search).get("orderId");
  const payment_fail_url = new URLSearchParams(location.search).get("payment_fail");

  useEffect(() => {
    userData.studio_id && getAssociatedArtist()
    ApiService.get('copyrightList').then(res => {
      let response = res.data.data
      if (response) {
        setCopyrightList(response)
      }
    }).catch(function (error) {
      dispatch(setLoader(false))
      console.log(error);
      console.log(error.response);
      return error.response;
    })

    ApiService.get('titleVersion').then(res => {
      let response = res.data.data
      if (response) {
        setTitleVersionList(response)
      }
    }).catch(function (error) {
      console.log(error);
      console.log(error.response);
      return error.response;
    })
    getPlatformList().then(res => {
      let response = res.data.data
      if (response) {
        setPlatformList(response)
      }
    }).catch(function (error) {
      dispatch(setLoader(false))
      console.log(error);
      console.log(error.response);
      return error.response;
    })
    if (genre == undefined || genre.length == 0) {
      dispatch(fetchMusicCategoryDetail("genreData", `GENRE`))
    }

    if (subGenreList == undefined || subGenreList.length == 0) {
      dispatch(fetchMusicCategoryDetail("subGenreData", `SUBGENRE`))
    }
    if (language == undefined || language.length == 0) {
      dispatch(fetchMusicCategoryDetail('languageData', `LANGUAGE`))
    }
    if (recordLabelList == undefined || recordLabelList.length == 0) {
      dispatch(fetchMusicCategoryDetail('recordLabelData', `RECORDLABEL`))
    }

  }, [userData.studio_id])

  useEffect(function () {
    if (userData.studio_id || assoArtistList.length) {
      getSingleAlbum({ 'userId': userData && userData.studio_id }, { "album_id": albumId })
        .then((response) => {
          if (response.status == 200) {
            setAlbumName(response.data[0].album_name)
            setAlbumData(response.data[0])
            setReleaseData(response.data)
            // setArray(response.data)
            setOrderIdFlag(false)
          }
          // setMoodList(response.data.data)
        })
        .catch((error) => {
          dispatch(setLoader(false))
          dispatch(setSnackBar(true))
          dispatch(setSnackBarVariant('error'))
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
          setTimeout(() => dispatch(setSnackBar(false)), 2000)
          return error.response;
        })
    }

  }, [userData.studio_id || assoArtistList.length || orderIDFlag == true]);

  const artistSelectChangeHandler = (name, artistData, isNewArtist) => {
    switch (name) {
      case artistCode.primary_artist:
        handleArtistData(name, primaryArtList, setPrimaryArtList, 'primary_artists', isNewArtist, artistData, artistCode, data, setData);
        break;
      case artistCode.feature_artist:
        handleArtistData(name, featureArtList, setFeatureArtList, 'featuring_artists', isNewArtist, artistData, artistCode, data, setData);
        break;
      case artistCode.lyricist:
        handleArtistData(name, lyricistList, setLyricistList, 'lyricists', isNewArtist, artistData, artistCode, data, setData);
        break;
      case artistCode.composer:
        handleArtistData(name, composerList, setComposerList, 'composers', isNewArtist, artistData, artistCode, data, setData);
        break;
      case artistCode.producer:
        handleArtistData(name, producerList, setProducerList, 'producers', isNewArtist, artistData, artistCode, data, setData);
        break;
      case artistCode.actor:
        handleArtistData(name, actorList, setActorList, 'actors', isNewArtist, artistData, artistCode, data, setData);
        break;
      case artistCode.remixers:
        handleArtistData(name, remixerArtList, setRemixerArtList, 'remixers', isNewArtist, artistData, artistCode, data, setData);
        break;
      default:
        break;
    }
  };

  const getAssociatedArtist = (name, artistData, isNewArtist) => {
    let postData = {}
    if (userData.role === 1) {
      postData['userId'] = userData.studio_id
    } else {
      if (userData.hasOwnProperty('associated_studio')) {
        postData['userId'] = userData.associated_studio
      }
    }
    associatedArtistsRequestHandler(postData, 'GET')
      .then(response => {
        //set the associated artist list
        setAssoArtistList(response.data.results)
        isNewArtist && artistSelectChangeHandler(name, artistData, isNewArtist) //call the handler when new artist has beed added
      })
      .catch(err => {
        dispatch(setSnackBar(true));
        setTimeout(() => dispatch(setSnackBar(false), 2000));
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      })
  }

  const [value, setValue] = useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const mediumViewport = useMediaQuery('(min-width:1100px)');
  const tabStyle = {
    bgcolor: 'background.paper',
    borderRadius: '10px',
    margin: mediumViewport ? '4px' : '10px 0px 10px 0px',
    // padding:'20px'
  }

  const fileValidation = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const fileType = file.type;
    if (file && fileType.startsWith('image/')) {
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          if (img.height === 3000 && img.width === 3000) {
            if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
              dispatch(setSnackBar(true));
              dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadType));
              dispatch(setSnackBarVariant('error'));
              setTimeout(() => dispatch(setSnackBar(false)), 3000)
              return false
            }
            else {
              if (file.size > 10000000) {
                dispatch(setSnackBar(true));
                dispatch(setSnackBarMessage(formSnackbar.errors.fileUploadSizeImage));
                dispatch(setSnackBarVariant('error'));
                setTimeout(() => dispatch(setSnackBar(false)), 3000)
              } else {
                setTypeAction('add')
                setSelectedFile(file);
                setData({
                  ...data,
                  cover_image: file
                })
                setTimeout(function () {
                  handleSubmit(file)
                }, 500)


                return true
              }
            }
          } else {
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => dispatch(setSnackBar(false)), 3000)
            return false
          }
        }
      }

    } else {
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadType));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return false
    }
  }

  const releaseTimeChangeHandler = (newValue, timeType) => {
    switch (timeType) {
      case 'releaseTime':
        setReleaseTime(newValue)
        break;
      default:
        break;
    }

    const isReleaseTimeDateInvalid = newValue === null || isNaN(Date.parse(newValue));
    if (!isReleaseTimeDateInvalid) {
      const datePayload = dateZoneHandler(newValue).toISOString();
      setData({ ...data, [timeType === 'releaseTime' ? 'release_time' : timeType === 'callerTune1' ? 'crbt_cuts_duration_for_clip1' : 'crbt_cuts_duration_for_clip2']: datePayload })
    }
  }
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      fileValidation(file)
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      fileValidation(file)
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const assoArtistRemoveHandler = (artistTypecd, index, artistId) => {
    const listsMap = {
      [artistCode.primary_artist]: setPrimaryArtList,
      [artistCode.feature_artist]: setFeatureArtList,
      [artistCode.lyricist]: setLyricistList,
      [artistCode.composer]: setComposerList,
      [artistCode.producer]: setProducerList,
      [artistCode.actor]: setActorList,
      [artistCode.remixers]: setRemixerArtList,
    };

    const listToUpdate = listsMap[artistTypecd];
    if (listToUpdate) {
      const updatedList = [...listToUpdate];
      const itemIndex = updatedList.findIndex((artist) => artist.artist_id === artistId);
      if (itemIndex !== -1) {
        updatedList.splice(itemIndex, 1);
        listToUpdate(updatedList);
      }
    }
  }

  let songInfo;

  useEffect(() => {
    if (assoArtistList && releaseData) {
      setArray(releaseData)
    }

  }, [assoArtistList && releaseData])

  function getArtistName(selectedArtist) {
    return {
      artistName: assoArtistList.length > 0 ? assoArtistList.filter(artist => selectedArtist.find(item => item.artist_code === artist.artist_code)) : [],
      artistCode: selectedArtist && selectedArtist.length > 0 && selectedArtist.map(value => value.artist_code).join(',')
    }
  }

  const setArray = (data) => {
    //match and set the data accordingly
    songInfo = data && data.length > 0 ? (data.find(song => song.album_id.toString() === albumId.toString())) : null

    const selectedPrimaryArtist = getArtistName(songInfo.primary_artists)
    setPrimaryArtList(selectedPrimaryArtist.artistName)
    songInfo.primary_artists = selectedPrimaryArtist.artistCode
    const selectedFeatureArtist = getArtistName(songInfo.featuring_artists)
    setFeatureArtList(selectedFeatureArtist.artistName)
    songInfo.featuring_artists = selectedFeatureArtist.artistCode
    const selectedProducers = getArtistName(songInfo.producers)
    setProducerList(selectedProducers.artistName)
    songInfo.producers = selectedProducers.artistCode
    const selectedComposers = getArtistName(songInfo.composers)
    setComposerList(selectedComposers.artistName)
    songInfo.composers = selectedComposers.artistCode
    const selectedLyrcists = getArtistName(songInfo.lyricists)
    setLyricistList(selectedLyrcists.artistName)
    songInfo.lyricists = selectedLyrcists.artistCode
    const selectedRemixer = getArtistName(songInfo.remixers)
    setRemixerArtList(selectedRemixer.artistName)
    songInfo.remixers = selectedRemixer.artistCode
    const selectedActors = getArtistName(songInfo.actors)
    assoArtistList && assoArtistList.filter(artist => songInfo?.actors.find(item => item.artist_code === artist.artist_code))
    setActorList(selectedActors.artistName)
    songInfo.actors = selectedActors.artistCode

    // const selectedPrimaryArtist = assoArtistList.length > 0 && assoArtistList.filter(artist => songInfo.primary_artists.find(item => item.artist_code === artist.artist_code))
    // console.debug("Release:selectedPrimaryArtist:assoArtistList", assoArtistList)

    // console.debug("Release:selectedPrimaryArtist:assoArtistList", assoArtistList)
    // console.debug("Release:selectedPrimaryArtist", selectedPrimaryArtist)
    // setPrimaryArtList(selectedPrimaryArtist)
    // songInfo.primary_artists = songInfo?.primary_artists && songInfo.primary_artists.length > 0 && songInfo.primary_artists.map(value => value.artist_code).join(',')
    // const selectedFeatureArtist = assoArtistList && assoArtistList.filter(artist => songInfo.featuring_artists.find(item => item.artist_code === artist.artist_code))
    // setFeatureArtList(selectedFeatureArtist)
    // songInfo.featuring_artists = songInfo?.featuring_artists && songInfo.featuring_artists.length > 0 && songInfo.featuring_artists.map(value => value.artist_code).join(',')
    // const selectedProducers = assoArtistList && assoArtistList.filter(artist => songInfo?.producers.find(item => item.artist_code === artist.artist_code))
    // setProducerList(selectedProducers)
    // songInfo.producers = songInfo?.producers && songInfo.producers.length > 0 && songInfo?.producers.map(value => value.artist_code).join(',')
    // const selectedComposers = assoArtistList && assoArtistList.filter(artist => songInfo?.composers.find(item => item.artist_code === artist.artist_code))
    // setComposerList(selectedComposers)
    // songInfo.composers = songInfo?.composers && songInfo.composers.length > 0 && songInfo?.composers.map(value => value.artist_code).join(',')
    // const selectedLyrcists = assoArtistList && assoArtistList.filter(artist => songInfo.lyricists.find(item => item.artist_code === artist.artist_code))
    // setLyricistList(selectedLyrcists)
    // songInfo.lyricists = songInfo?.lyricists && songInfo.lyricists.length > 0 && songInfo?.lyricists.map(value => value.artist_code).join(',')
    // const selectedRemixer = assoArtistList && assoArtistList.filter(artist => songInfo?.remixers.find(item => item.artist_code === artist.artist_code))
    // setRemixerArtList(selectedRemixer)
    // songInfo.remixers = songInfo?.remixers && songInfo.remixers.length > 0 && songInfo?.remixers.map(value => value.artist_code).join(',')
    // const selectedActors = assoArtistList && assoArtistList.filter(artist => songInfo?.actors.find(item => item.artist_code === artist.artist_code))
    // setActorList(selectedActors)
    // songInfo.actors = songInfo?.actors && songInfo.actors.length > 0 && songInfo.actors.map(value => value.artist_code).join(',')

    setPublisher_type(songInfo.publisher_type)
    setAlbumDisabledStatus(songInfo.song_status == "" || songInfo.song_status == "In Review" || songInfo.song_status == "Released" || songInfo.song_status == "Ready for Release" ? true : false)
    setSelectedFile(songInfo.cover_image != null ? songInfo.cover_image : null)
    setTypeAction(songInfo.cover_image != undefined || songInfo.cover_image != null ? 'edit' : "add")
    if (songInfo.copyright_p) {
      setCopyrights_p(songInfo.copyright_p.copyright_year.id);
      setCopyrightp_name(songInfo.copyright_p.copyright_name);
    }
    if (songInfo.copyright_c) {
      setCopyrights_c(songInfo.copyright_c.copyright_year.id);
      setCopyrightc_name(songInfo.copyright_c.copyright_name);
    }
    if (songInfo.release_date != "") {
      setRelease_Date(songInfo.release_date)
    } else {
      setRelease_Date(null)
    }
    songInfo.previous_release_date = songInfo.previous_release_date
    if (songInfo.previous_release_date != "") {
      setPreRelease_Date(songInfo.previous_release_date)
    } else {
      setPreRelease_Date(null)
    }

    if (songInfo.release_time != "") {
      setReleaseTime(songInfo.release_time)
    } else {

      setReleaseTime(null)
    }
    let releaseHistory = songInfo.release_history.toString()
    releaseHistory = releaseHistory.charAt(0).toUpperCase() + releaseHistory.slice(1)
    setReleasehistory(releaseHistory)
    // setSelectedStore(songInfo.platform)
    setChooseSelectType(songInfo.store_status == false ? "False" : songInfo.store_status == true ? "True" : "False")

    setData(songInfo && songInfo)
    setReleaseDataBkp(songInfo && songInfo)


  }

  const dateZoneHandler = (date) => {
    const newDate = moment.utc(date.toISOString()).tz("Asia/Kolkata");
    return newDate
  }
  const releaseDateChangeHandler = (newValue, pickerType) => {
    const formattedDate = newValue.format('YYYY-MM-DD');
    if (pickerType == 'releaseDate') {
      setData({
        ...data,
        release_date: formattedDate,
      })
      // setRelease_Date(newValue)
    } else {
      setData({
        ...data,
        previous_release_date: formattedDate,
      })
      // setPreRelease_Date(newValue)
    }
    // const isReleaseDateInvalid = formattedDate === null || isNaN(Date.parse(formattedDate));
    // if (!isReleaseDateInvalid) {
    // const datePayload = dateZoneHandler(formattedDate).toISOString();
    setData({ ...data, [pickerType === 'releaseDate' ? 'release_date' : 'previous_release_date']: formattedDate })
    // }
  }

  const handleInputChangeCopyright = (event) => {
    const { name, value } = event.target;
    // Use the spread operator to create a copy of the data object
    const newData = { ...data };

    switch (name) {
      case 'copyrights_p': {
        setCopyrights_p(value)
        newData.copyright_p = {
          ...newData.copyright_p,
          copyright_year: copyrightList.find(c => c.id == value),
          publisher_id: userData.studio_id,
          publisher_type: newData.publisher_type,
        };
        break;
      }
      case 'copyrights_c': {
        setCopyrights_c(value)
        newData.copyright_c = {
          ...newData.copyright_c,
          copyright_year: copyrightList.find(c => c.id == value),
          publisher_id: userData.studio_id,
          publisher_type: newData.publisher_type,
        };
        break;
      }
      case 'copyrightc_name': {
        const isValid = regexValidator(value, 'text')
        if (!isValid) {
          setErrors({ ...errors, copyrightc_name: true, copyrightc_name_helperTxt: formSnackbar.errors.validCopyrightInput });
        } else {
          setErrors({ ...errors, copyrightc_name: false, copyrightc_name_helperTxt: false });
        }
        setCopyrightc_name(value)
        newData.copyright_c = {
          ...newData.copyright_c,
          copyright_name: value,
          publisher_id: userData.studio_id,
          publisher_type: newData.publisher_type,
        };
        break;
      }
      case 'copyrightp_name': {
        const isValid = regexValidator(value, 'text')
        if (!isValid) {
          setErrors({ ...errors, copyrightp_name: true, copyrightp_name_helperTxt: formSnackbar.errors.validCopyrightInput });
        } else {
          setErrors({ ...errors, copyrightp_name: false, copyrightp_name_helperTxt: false });
        }
        setCopyrightp_name(value)
        newData.copyright_p = {
          ...newData.copyright_p,
          copyright_name: value,
          publisher_id: userData.studio_id,
          publisher_type: newData.publisher_type,

        };
        break;
      }
      default: {
        break;
      }
    }

    // Update the data state with the modified newData
    setData(newData);
  };


  const isFieldsValid = (errObj) => {
    let array = [];
    for (let key in errObj) {
      array.push(errObj[key])
    }
    if (array.every((item) => item === false)) {
      return true;
    }
    else {
      return false;
    }
  }

  useEffect(() => {
    validationHandler()

  }, [data])

  const validationHandler = () => {

    if (data?.release_history === false || data?.release_history === 'False' || releasehistory == "False" || releasehistory == false) {

      if ((data.release_date === '')
        || (copyrights_c === '' || copyrights_c.length < 1)
        || (copyrights_p === '' || copyrights_p.length < 1) || (copyrightp_name === '' || copyrightc_name.length < 1)
      ) {
        setNextBtnDisable(true)
      } else {
        if (isFieldsValid(errors) === true)
          setNextBtnDisable(false)
        else {
          if (releasehistory == "False" || releasehistory == false) {
            setNextBtnDisable(false)
          } else {
            setNextBtnDisable(true)
          }

        }
      }

    } else {
      if ((data?.release_history === 'True' || data?.release_history === true || data?.release_history != null) && data?.upc_code != null && data.previous_release_date != null) {
        setNextBtnDisable(false)
      } else {
        setNextBtnDisable(true)
      }

    }
  }

  const redirectDetails = () => {
    history('/payment', { state: { release: data, userData: userData, songList: songList } });
  }

  const confirmHandler = () => {
    redirectDetails()
    // handleSubmit()
  }


  const confirmHandlerFunction = () => {
    warningRef.current.handleOpen()
    // handleSubmit()
  }

  useEffect(() => {
    // Check if the showPopup parameter is present in the query string
    // console.log("showPopup:", showPopup);
    // console.log("id:", id);

    if (payment_success_url) {
      setTimeout(() => {
        // Redirect to the same page without the query parameter
        // console.log("Redirecting...");
        window.history.replaceState(null, "", `/addrelease/${albumId}`);
        // setOpenCongratsPopup(false)
      }, 5000);
    } else {
      setTimeout(() => {
        // Redirect to the same page without the query parameter
        // console.log("Redirecting...");
        window.history.replaceState(null, "", `/addrelease/${albumId}`);
        // setOpenCongratsPopup(false)
      }, 5000);
    }
  }, [payment_success_url || payment_fail_url]);

  useEffect(() => {
    if (payment_success_url && data) {
      getPaymentDetail(payment_success_url).then(res => {
        let response = res.data
        if (response.status == "Success") {
          setData({
            ...data,
            planId: response.PlanID.id
          })
          paymentSucessRef.current.handleOpen()
          // setTimeout(()=>{

          // },2000)

        } else {
          // console.debug("Your Payment Status is Failed")
          setPaymentFail(true)
        }
        // setOrderDetail(res.data)
      })
      // handleSubmit()
    } else {
      if (payment_fail_url == "True") {
        paymentSucessRef.current.handleOpen()
        setPaymentFail(true)
      }
      // alert("Your Payment Status is Failed")
    }
  }, [data && albumId && payment_success_url])

  useEffect(() => {
    if (data.planId != '' && data.planId != undefined && payment_success_url) {
      handleSubmit()
    }

  }, [data.planId && payment_success_url])

  function handleCopyRight(id, copyRightName, copyRightYear, publisherType, publisherId) {

    let data = {
      "id": id,
      "copyright_year": copyrightList.find(c => c.id == copyRightYear),
      "copyright_name": copyRightName,
      "copyright_type": null,
      "publisher_id": publisherType,
      "publisher_type": publisherId
    }
    return data;

  }


  const warningHandler = () => {
    warningRef.current.handleClose();
    // handleSubmit() //call the submit
  }

  const handleReset = () => {
    history('/addrelease/' + albumId);
  };

  const handleBack = () => {
    setActive(active - 1)
  }

  const handleSubmit = (file) => {
    // Validate album details, and create the card if valid

    const formData = new FormData();

    const albumImage = {    // Upload File tab
      cover_image: (file != undefined || file != null || file != '') && file
    }
    const albumGeneral = {
      album_name: data.album_name, // General Detail tab
      genre: data.genre,
      sub_genre: data.sub_genre,
      language: data.language,
      album_description: data.album_description,
      title_version: data.title_version,
    };
    const albumCredit = {      // Album Credit tab
      primary_artists: data.primary_artists == false ? "" : data.primary_artists,
      featuring_artists: data.featuring_artists == false ? "" : data.featuring_artists,
      composers: data.composers == false ? "" : data.composers,
      lyricists: data.lyricists == false ? "" : data.composers,
      producers: data.producers == false ? "" : data.composers,
      remixers: data.remixers == false ? "" : data.composers,
      actors: data.actors == false ? "" : data.composers,
      record_labels: data.record_labels

    };
    let copyrightCData = {}
    let copyrightPData = {}
    copyrightPData = {
      'copyright_name': data?.copyright_p?.copyright_name,
      'publisher_id': userData.studio_id,
      'publisher_type': publisher_type,
      'copyright_year': data?.copyright_p?.copyright_year?.id,
    }
    copyrightCData = {
      'copyright_name': data?.copyright_c?.copyright_name,
      'publisher_id': userData.studio_id,
      'publisher_type': publisher_type,
      'copyright_year': data?.copyright_c?.copyright_year?.id,
    }
    data.copyright_p = copyrightPData;
    data.copyright_c = copyrightCData;

    const albumInformation = {    // Album Information tab
      release_date: data.release_date,
      release_time: data.release_time,
      copyright_p: data.copyright_p,
      copyright_c: data.copyright_c,
      upc_code: data.upc_code,
      release_history: releasehistory,
      previous_release_date: data.previous_release_date

    };
    const distributionPlatform = {    // Upload File tab
      platform: data.platform ? data.platform.join(',') : null,
      store_status: chooseSelectType
    }
    const finishData = {    // Upload File tab
      song_status: 'In Review',
      plan: data.planId
    }
    const premiumAccount = {
      song_status: 'In Review',
    }
    if (active == 1 && (payment_success_url == undefined || payment_success_url == "" || payment_success_url == null)) {
      for (const key in albumImage) {
        if (albumImage.hasOwnProperty(key) && albumImage[key] !== null) {
          formData.append(key, albumImage[key]);
        }
      }
    }
    if (active == 2) {
      for (const key in albumGeneral) {
        if (albumGeneral.hasOwnProperty(key) && albumGeneral[key] !== null) {
          formData.append(key, albumGeneral[key]);
        }
      }
    }
    if (active == 3) {
      for (const key in albumCredit) {
        if (albumCredit.hasOwnProperty(key) && albumCredit[key] !== null) {
          formData.append(key, albumCredit[key]);
        }
      }
    }
    if (active == 4) {
      for (const key in albumInformation) {
        if (albumInformation.hasOwnProperty(key) && albumInformation[key] !== null) {
          if (typeof albumInformation[key] === 'object' && active == 4) {
            for (const subKey in albumInformation[key]) {
              formData.append(`${key}[${subKey}]`, albumInformation[key][subKey]);
            }
          } else {
            formData.append(key, albumInformation[key]);
          }

        }
      }
    }
    if (active == 5) {
      for (const key in distributionPlatform) {
        if (distributionPlatform.hasOwnProperty(key) && distributionPlatform[key] !== null) {
          formData.append(key, distributionPlatform[key]);
        }
      }
    }
    if (active == 6)
      for (const key in premiumAccount) {
        if (premiumAccount.hasOwnProperty(key) && premiumAccount[key] !== null) {
          formData.append(key, premiumAccount[key]);
        }
      }
    if (payment_success_url)
      for (const key in finishData) {
        if (finishData.hasOwnProperty(key) && finishData[key] !== null) {
          formData.append(key, finishData[key]);
        }
      }

    // Call the onCreateAlbum function to add the new album
    //call api to create the album
    dispatch(setLoader(true))
    updateAlbum(albumId, formData)
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          let d = []

          let res = response?.data
          if (res.copyright_c != null && res.copyright_p != null) {
            setData({
              ...res,
              copyright_p: handleCopyRight(res.copyright_p, data.copyright_p.copyright_name, data.copyright_p.copyright_year, data.copyright_p.publisher_id, data.copyright_p.publisher_type),

              copyright_c: handleCopyRight(res.copyright_c, data.copyright_c.copyright_name, data.copyright_c.copyright_year, data.copyright_c.publisher_id, data.copyright_c.publisher_type),
            })
            setReleaseDataBkp({
              ...res,
              copyright_p: handleCopyRight(res.copyright_p, data.copyright_p.copyright_name, data.copyright_p.copyright_year, data.copyright_p.publisher_id, data.copyright_p.publisher_type),

              copyright_c: handleCopyRight(res.copyright_c, data.copyright_c.copyright_name, data.copyright_c.copyright_year, data.copyright_c.publisher_id, data.copyright_c.publisher_type),
            })
          } else {
            setData(response?.data)
            setReleaseDataBkp(response?.data)
          }

          if (payment_success_url) {
            setOrderIdFlag(true)
          }


          //   setCopyrights_p(songInfo.copyright_p.copyright_year.id);
          //   setCopyrightp_name(songInfo.copyright_p.copyright_name);
          // setData(response?.data)
          // console.debug("setData:active", active)
          if (active == 1 || active == 6) {

          } else {
            setActive(active + 1)
          }

          if (active == 6) {

            dispatch(fetchReleaseAlbum(userData.studio_id, { limit: 25, offset: 0 }, d, null));
            handleRouteBack()
          }
          dispatch(setSnackBar(true))
          dispatch(setSnackBarVariant('success'))
          dispatch(setSnackBarMessage(formSnackbar.album.albumUpdate))
          dispatch(setLoader(false))
          setTimeout(function () {
            dispatch(setSnackBar(false))
          }, 2000)
        }
      })
      .catch(function (error) {
        dispatch(setSnackBar(true))
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
        setTimeout(function () {
          dispatch(setSnackBar(false))
        }, 2000)
      })
    // }
  };

  const handleRouteBack = () => {
    history('/releasealbum')
  }

  return (
    <div>
      <div >
        <div>
          <button className="icononly-btn mb-2 w-100 d-flex align-items-center justify-content-start" onClick={handleRouteBack}><ArrowBackIcon className='mb-2' /><h5 className='ms-2'>{data.album_name}</h5></button>
        </div>
        <div className="d-flex main-account-container mb-5">
          <div className="album-tabs">
            <Box sx={tabStyle}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                orientation={mediumViewport ? "vertical" : "horizontal"}
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable auto tabs example"
              >
                {accountTabs ? accountTabs.map(item => {
                  return (
                    <div key={item.id} className={active === item.tabActiveId ? "text-danger text-secondary text-decoration-none" : "text-secondary text-decoration-none"}
                      onClick={() => { setType(item.tabType); setActive(item.tabActiveId) }}
                    >
                      <Tab className='fontSize14' label={item.tabTitle}
                        sx={{ textAlign: 'left' }} />
                    </div>
                  )
                }) : null}
              </Tabs>
            </Box>
          </div>
          <div className="album-tab-content">
            <Box  sx={{ /* maxWidth: { xs: 320, sm: 200 }, */ bgcolor: 'background.paper', borderRadius: '10px', margin: '5px 0px', padding: '20px' }}>
              {(() => {
                switch (active) {
                  case 1:
                    return <UploadAlbumFile
                      data={data}
                      albumData={albumData}
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      handleFileChange={handleFileChange}
                      handleDragOver={handleDragOver}
                      handleDrop={handleDrop}
                      handleSubmit={handleSubmit}
                      type={typeAction}
                      setSongList={setSongList}
                      releaseStatusReview={releaseStatusReview}
                      releaseStatusReleased={releaseStatusReleased}
                      releaseDataBkp={releaseDataBkp}
                      albumDisabledStatus={albumDisabledStatus}
                      active={active}
                      setActive={setActive}
                    />;
                  case 2:
                    return <GenralInformation
                      data={data} errors={errors}
                      handleInputChange={(event) => handleInputChange(event, data, setData, errors, setErrors, formSnackbar, setReleasehistory, setCopyrighttype,)}
                      genre={genre}
                      titleVersionList={titleVersionList}
                      subGenreList={subGenreList}
                      // contentTypelist={contentTypelist}
                      language={language}
                      handleSubmit={handleSubmit}
                      releaseStatusReview={releaseStatusReview}
                      releaseStatusReleased={releaseStatusReleased}
                      albumDisabledStatus={albumDisabledStatus}
                      releaseDataBkp={releaseDataBkp}
                      handleBack={handleBack}
                      setActive={setActive}
                      active={active}
                    />
                  case 3:
                    return <AlbumCredit data={data} errors={errors} handleInputChange={(event) => handleInputChange(event, data, setData, errors, setErrors, formSnackbar)}
                      primaryArtList={primaryArtList}
                      remixerArtList={remixerArtList}
                      artistSelectChangeHandler={artistSelectChangeHandler}
                      featureArtList={featureArtList}
                      lyricistList={lyricistList}
                      producerList={producerList}
                      composerList={composerList}
                      actorList={actorList}
                      artistList={assoArtistList}
                      getAssociatedArtist={getAssociatedArtist}
                      assoArtistRemoveHandler={assoArtistRemoveHandler}
                      recordLabelList={recordLabelList}
                      handleSubmit={handleSubmit}
                      releaseStatusReview={releaseStatusReview}
                      releaseStatusReleased={releaseStatusReleased}
                      albumDisabledStatus={albumDisabledStatus}
                      releaseDataBkp={releaseDataBkp}
                      handleBack={handleBack}
                      setActive={setActive}
                      active={active}
                    />;
                  case 4:
                    return <AlbumReleaseInformation
                      data={data} errors={errors}
                      handleInputChangeCopyright={(event) => handleInputChangeCopyright(event, errors, setErrors, formSnackbar, setData, '', '', '', '', "", setReleasehistory, setCopyrighttype)}
                      // handleInputChange={(event) => handleInputChange(event, data, setData, errors, setErrors, formSnackbar, setReleasehistory, setCopyrighttype)}
                      handleInputChange={(event) => handleInputChange(event, data, setData, errors, setErrors, formSnackbar, '', '', '', '', "", setReleasehistory, setCopyrighttype, setPreRelease_Date)}
                      releaseDateChangeHandler={releaseDateChangeHandler}
                      releaseTimeChangeHandler={releaseTimeChangeHandler}
                      releaseDate={releaseDate}
                      prereleaseDate={prereleaseDate}
                      releaseTime={releaseTime}
                      copyrightc_name={copyrightc_name}
                      copyrightp_name={copyrightp_name}
                      copyrights_p={copyrights_p}
                      copyrights_c={copyrights_c}
                      setCopyrights_p={setCopyrights_p}
                      setCopyrights_c={setCopyrights_c}
                      releasehistory={releasehistory}
                      copyrighttype={copyrighttype}
                      copyrightList={copyrightList}
                      artistList={assoArtistList}
                      handleSubmit={handleSubmit}
                      releaseStatusReview={releaseStatusReview}
                      releaseStatusReleased={releaseStatusReleased}
                      albumDisabledStatus={albumDisabledStatus}
                      releaseDataBkp={releaseDataBkp}
                      nextBtnDisable={nextBtnDisable}
                      setActive={setActive}
                      active={active}

                      handleBack={handleBack}
                    />;

                  case 5:
                    return <PlatformDistribution
                      data={data}
                      handleInputChange={((event) => handleInputChange(event, data, setData, errors, setErrors, formSnackbar,
                        '', '', '', '', '', setReleasehistory, setCopyrighttype, setCopyrights_c, setCopyrights_p, setCopyrightc_name, setCopyrightp_name, setChooseSelectType, setOnChangeFlag))}
                      setData={setData}
                      errors={errors}
                      selectedStore={selectedStore}
                      setSelectedStore={setSelectedStore}
                      platformList={platformList}
                      chooseSelectType={chooseSelectType}
                      handleSubmit={handleSubmit}
                      releaseStatusReview={releaseStatusReview}
                      releaseStatusReleased={releaseStatusReleased}
                      albumDisabledStatus={albumDisabledStatus}
                      releaseDataBkp={releaseDataBkp}
                      onChangeFlag={onChangeFlag}
                      handleBack={handleBack}
                      setActive={setActive}
                      active={active}
                      setReleaseDataBkp={setReleaseDataBkp}
                    />

                  case 6:
                    return <ReleaseFinalReview data={data} language={language}
                      primaryArtList={primaryArtList}
                      lyricistList={lyricistList}
                      featureArtList={featureArtList}
                      producerList={producerList}
                      composerList={composerList}
                      remixerArtList={remixerArtList}
                      actorList={actorList}
                      recordLabelList={recordLabelList}
                      platformList={platformList}
                      copyrightList={copyrightList}
                      copyrightp_name={copyrightp_name}
                      copyrightc_name={copyrightc_name}
                      copyrights_p={copyrights_p}
                      copyrights_c={copyrights_c}
                      handleSubmit={handleSubmit}
                      errors={errors} genre={genre} songList={songList}
                      confirmHandlerFunction={confirmHandlerFunction}
                      releaseStatusReview={releaseStatusReview}
                      releaseStatusReleased={releaseStatusReleased}
                      albumDisabledStatus={albumDisabledStatus}
                      releaseDataBkp={releaseDataBkp}
                      releasehistory={releasehistory}
                      handleBack={handleBack}
                      nextBtnDisable={nextBtnDisable}
                      setActive={setActive}
                      active={active}
                      subGenreList={subGenreList}
                      userData={userData}
                    />
                  case 7:
                    return <PayForDistribution data={data} userData={userData} />

                  default:
                    return <UploadAlbumFile
                      albumData={albumData}
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      handleFileChange={handleFileChange}
                      handleDragOver={handleDragOver}
                      handleDrop={handleDrop}
                      handleSubmit={handleSubmit}
                    />
                }
              })()}
            </Box>
          </div>
        </div>
      </div>

      <SnackbarContainer />
      <ConfirmModal confirmHandler={confirmHandler} ref={confirmref} />
      <WarningModal confirmHandler={confirmHandler} type={'release'} ref={warningRef} />
      <PaymentSuccessDialog open={openSuccess} paymentFail={paymentFail} onClose={''} data={data} ref={paymentSucessRef} />

    </div>
  );
}

export default CreateAlbum;