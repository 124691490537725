import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { submitAsongURL } from "../../_constants/api.constant";
export async function uploadSongTrack(formData,songid,onUploadProgress){
    return axios.put(`${submitAsongURL.UPDATE}${songid}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization':'Token '+ getSelfToken()
    },
    onUploadProgress,
  });
}