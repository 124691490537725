import React, { useState, useEffect } from 'react'
import { Grid, TablePagination, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DemoCardList from './DemoCardList.js';
import NoResults from '../../components/reusableComponents/NoResults.js';
import { default_limit, record_limits } from '../../_constants/recordLimitSet.constant.js';
import { setLoader } from '../../redux/index.js';
import { demoStudioList } from '../../_services/DemoStudio/demoStudio.service.jsx';

function ReceiverDemoList(props) {
    const dispatch = useDispatch();
    const [demoList, setDemoList] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(default_limit);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState()
    const isNextButtonDisabled = demoList.length < rowsPerPage || (page + 1) >= Math.ceil(totalCount / rowsPerPage);
    const isPreviousButtonDisabled = page === 0;
    let smScreen = useMediaQuery('(max-width:768px)')

    const demoData = (page, rowsPerPage, offset) => {
        const limit = rowsPerPage;
        dispatch(setLoader(true));
        demoStudioList('studio', limit, offset).then(function (response) {
            dispatch(setLoader(false))
            // console.debug('receiver: res', response)
            let demoResponse = response.results.reverse();
            setDemoList(demoResponse)
            setTotalCount(response.total_count)

        }).catch(function (error) {
            dispatch(setLoader(false))
            console.log(error);
            console.log(error.response);
            return error.response;
        })
    }
    useEffect(() => {
        setPage(0);
        demoData(0, rowsPerPage);
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        const newOffset = newPage;
        demoData(newPage, rowsPerPage, newOffset);
    };
    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        const newPage = Math.min(Math.floor((page * rowsPerPage) / newRowsPerPage), Math.ceil(1000 / newRowsPerPage) - 1);
        setRowsPerPage(newRowsPerPage);
        setPage(newPage);
        demoData( newPage, newRowsPerPage);
    };

    return (
        <div className={smScreen ? 'p-2' : 'p-4'}>
            <h2>Demo</h2>
            <div className='my-4'>
                {demoList && demoList.length > 0 ? (
                    !smScreen ? (
                        <Grid alignItems="center" justify="center" >
                            {demoList.map((item, index) => (
                                <div key={item.id}>
                                    <DemoCardList
                                        cardData={item}
                                        moduleName={'demoList'}
                                        dataList={demoList}
                                    />
                                </div>
                            ))}
                            <TablePagination
                                rowsPerPageOptions={record_limits}
                                colSpan={3}
                                sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px' }}
                                className="cardPagination"
                                component="div"
                                count={totalCount}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                nextIconButtonProps={{ disabled: isNextButtonDisabled }}
                            />
                        </Grid>
                    ) : (
                        <React.Fragment>
                            {demoList.map((item, index) => (
                                <div key={item.id} style={{ width: '100%' }}>
                                    <DemoCardList
                                        cardData={item}
                                        moduleName={'demoList'}
                                        dataList={demoList}

                                    />
                                </div>
                            ))}
                            <div
                                className="d-flex justify-content-between mb-5"
                                style={{ width: '100%', marginTop: '20px' }}
                            >
                                <div style={{ width: '50%' }}>
                                    <button
                                        onClick={() => handleChangePage(null, page - 1)}
                                        disabled={isPreviousButtonDisabled}
                                        className={`pagination-button ${isPreviousButtonDisabled ? 'disabled' : 'enabled'}`}
                                    >
                                        Previous
                                    </button>
                                </div>
                                <div style={{ width: '20px' }}></div>
                                <div style={{ width: '50%' }}>
                                    <button
                                        onClick={() => handleChangePage(null, page + 1)}
                                        disabled={isNextButtonDisabled}
                                        className={`pagination-button ${isNextButtonDisabled ? 'disabled' : 'enabled'}`}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                ) : (
                    <NoResults />
                )}

            </div>
        </div>
    )
}

export default ReceiverDemoList