import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { SnackbarContext } from "../../App";
import SnackbarContainer from '../../components/utils/Snackbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import General from './General';
import Services from './Services';
import SocialPlatform from './SocialPlatform';
import LanguageAndRegion from './LanguageAndRegion';
import Agreement from './Agreement';
import Genre from './Genre';

function Account(props) {
    const { snack, setSnack } = useContext(SnackbarContext);
    const [type, setType] = useState("general")
    const [active, setActive] = useState("6")
    const [accountTabs, setAccountTabs] = useState([
        {
            id: 6,
            tabActiveId: "6",
            tabTitle: 'General',
            tabType: 'general'
        },
        {
            id: 1,
            tabActiveId: "1",
            tabTitle: 'Services',
            tabType: 'services'
        },
        {
            id: 2,
            tabActiveId: "2",
            tabTitle: 'Genre',
            tabType: 'genre'
        },
        {
            id: 3,
            tabActiveId: "3",
            tabTitle: 'Social Platform',
            tabType: 'socialplatform'
        },
        {
            id: 4,
            tabActiveId: "4",
            tabTitle: 'Region',
            tabType: 'language&region'
        },
        {
            id: 5,
            tabActiveId: "5",
            tabTitle: 'Agreement',
            tabType: 'agreement'
        },


    ])
    useEffect(function () {
        let currentURL = window.location.href.split('/account?user=')[1]
        if (currentURL === undefined)
            currentURL = window.location.href.split('/?user=')[1]
        const currentTab = accountTabs.find(item => item.tabType === currentURL)
        currentTab && setActive(currentTab.tabActiveId)

    }, []);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const mediumViewport = useMediaQuery('(min-width:1100px)');
    const tabStyle = {
        bgcolor: 'background.paper',
        borderRadius: '10px',
        margin: mediumViewport ? '4px' : '10px 0px 10px 0px',
        // padding:'20px'
    }
    return (
        <div>
            <div >
                <div className="d-flex main-account-container mb-5">
                    <div className="album-tabs">
                        <Box sx={tabStyle}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                orientation={mediumViewport ? "vertical" : "horizontal"}
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable auto tabs example"
                                sx={{
                                    '& .MuiTabs-indicator': {
                                      backgroundColor: '#dc3545', // Custom color
                                    },
                                  }}
                            >
                                {accountTabs ? accountTabs.map(item => {
                                    return (
                                        <Tab
                                            key={item.id}
                                            component={Link}
                                            to={`?user=${item.tabType}`}
                                            label={item.tabTitle}
                                            onClick={() => { setType(item.tabType); setActive(item.tabActiveId) }}
                                            className={`align-items-start ${active == item.tabActiveId ? "text-danger fw-bold" : "text-secondary"}`}
                                        />

                                    )
                                }) : null}

                            </Tabs>
                        </Box>
                    </div>
                    <div className="album-tab-content">
                        <Box sx={{ minHeight: '350px', bgcolor: 'background.paper', borderRadius: '10px', margin: '5px 0px', padding: '20px' }}>
                            {(() => {
                                switch (window.location.search) {
                                    case '?user=general':
                                        return <General />;
                                    case '?user=services':
                                        return <Services />;
                                    case '?user=genre':
                                        return <Genre />;
                                    case '?user=socialplatform':
                                        return <SocialPlatform />;
                                    case '?user=language&region':
                                        return <LanguageAndRegion />;
                                    case '?user=agreement':
                                        return <Agreement />;
                                    default:
                                        return <General />
                                }
                            })()}
                        </Box>
                    </div>
                </div>
            </div>

            <SnackbarContainer />
        </div>
    );
}

export default Account;