import { Grid, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ResponsiveCardList from '../../components/reusableComponents/songResponsiveList';
import useMediaQuery from '@mui/material/useMediaQuery'
import NoResults from '../../components/reusableComponents/NoResults';
import AlbumCard from './AlbumCard';
import { useSelector } from 'react-redux';

const ReleaseCard = ({ albumListData, handleChangePage, handleChangeRowsPerPage, ...props }) => {
  const smScreen = useMediaQuery('(max-width:768px)');
  const [albumList, setAlbumList] = useState(albumListData)
  const subGenreList = useSelector((state) => state.musicCategorgyReducer?.subGenreData ? state.musicCategorgyReducer?.subGenreData.data : []);
  const genre = useSelector((state) => state.musicCategorgyReducer?.genreData ? state.musicCategorgyReducer?.genreData.data : []);
  const record_limits = [10, 20, 25, 30] //used to set the limits from one signle place
  useEffect(() => {
    setAlbumList(albumListData.results)
  }, [albumListData])
  return (
    <div >
      {albumList && albumList.length > 0 ? (
        <div>
          <Grid container spacing={2}>
            {albumList
              .map((album, index) => (
                <Grid item xs={12} md={6} sm={12} lg={3} >
                  <AlbumCard
                    key={index}
                    subGenreList={subGenreList}
                    genre={genre}
                    albumInfo={album}
                    albumList={albumList}
                    assoArtistList={props.assoArtistList}
                    getArtistName={props.getArtistName}
                    setAlbumList={setAlbumList}
                    deleteAlbum={props.deleteAlbum}
                    handleClick={props.handleClick}
                  />
                </Grid>
              ))}
          </Grid>
          <TablePagination
            rowsPerPageOptions={record_limits}
            colSpan={3}
            className="cardPagination"
            component="div"
            count={albumListData.totalCount}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
          />
        </div>
        // </Grid>
      )
        : (
          <NoResults />
        )}
    </div>

  )
}//end

export default ReleaseCard