import React from 'react';
import { FormControl, InputLabel, MenuItem, Radio, Select, TextField, FormHelperText, Tooltip } from '@mui/material';
import { DatePicker, LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const AlbumReleaseInformation = ({ data, handleInputChange, errors, prereleaseDate, releasehistory, releaseDateChangeHandler, copyrights_c, handleInputChangeCopyright,
    releaseDate, releaseTime, callerTune1, callerTune2, releaseTimeChangeHandler, copyrighttype, copyrightList, copyrightp_name, copyrightc_name, copyrights_p, setCopyrights_p, setCopyrights_c, handleSubmit
    , releaseStatusReview, releaseStatusReleased, albumDisabledStatus, releaseDataBkp, handleBack, nextBtnDisable, setActive, active, setPreRelease_Date }) => {
    const minDate = new Date()
    const maxDate = new Date()

    minDate.setDate(minDate.getDate() + 7)
    maxDate.setDate(maxDate.getDate())
    const isDateInvalid = releaseDate && new Date(releaseDate) < new Date();
    const releaseHistoryValue = releasehistory === false || releasehistory === "False"

    return (
        <>
            <div>
                <p className='cardheading'>Add Release Information</p>
                <div className='row'>
                    <div className="form-group col-md-12 mt-2 mb-3">
                        <FormControl
                            name='catalog'
                            noValidate
                            autoComplete="off"
                            fullWidth>
                            <TextField
                                disabled={true}
                                size="small"
                                value={data.catalog}
                                // error={errors.catalog}
                                // helperText={errors.catalog_helperTxt}
                                name="catalog"
                                label="Catalog Id" variant="outlined" className="w-100 rounded "
                            />
                        </FormControl>
                    </div>
                    <div className='col-md-12'>
                        <p className='m-0'>Has it been previously released? *</p>
                        <Radio
                            disabled={albumDisabledStatus}
                            checked={releasehistory === "False"}
                            onChange={handleInputChange}
                            value="False"
                            name='release_history'
                            inputProps={{ 'aria-label': '0' }}
                        />
                        No, it's a new release
                        <Radio
                            disabled={albumDisabledStatus}
                            checked={releasehistory === "True"}
                            onChange={handleInputChange}
                            value="True"
                            name='release_history'
                            inputProps={{ 'aria-label': 'Yes' }}
                        />
                        Yes (UPC/EAN/JAN required)
                    </div>
                    <div className="form-group mt-2 mb-3">
                        <FormControl fullWidth name="previous_release_date">
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label={`Previous Release Date${releaseHistoryValue ? '' : '*'}`}
                                    inputFormat="DD/MM/YYYY"
                                    value={(releasehistory == "False" || releasehistory == false) ? null : data.previous_release_date != '' ? data.previous_release_date : null}
                                    name="previous_release_date"
                                    maxDate={maxDate}
                                    onChange={(date) => releaseDateChangeHandler(date, 'preReleaseDate')}
                                    renderInput={(params) => <TextField size="small" sx={{ minWidth: "100%", mt: 1 }} {...params} />}
                                    disabled={releaseHistoryValue || albumDisabledStatus}
                                />
                            </LocalizationProvider>
                            {isDateInvalid && (<FormHelperText error> Please select a past date.</FormHelperText>)}
                        </FormControl>
                    </div>
                    <div className="form-group col-md-12 mb-3">

                        <FormControl
                            name='upc_code'
                            noValidate
                            autoComplete="off"
                            fullWidth
                        >
                            <TextField
                                variant="outlined"
                                // label="UPC/EAN/JAN"
                                label={`UPC/EAN/JAN${releaseHistoryValue ? '' : '*'}`}
                                size="small"
                                type="text"
                                name='upc_code'
                                inputProps={{ maxLength: 12 }}
                                value={data.upc_code}
                                onChange={handleInputChange}
                                error={errors.upc_code}
                                helperText={errors.upc_code ? errors.upc_code_helperTxt : ' Please provide the 12-digit numeric code'}
                                disabled={releaseHistoryValue || albumDisabledStatus}

                            />
                        </FormControl>
                    </div>
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className='col-md-6 mt-3'>
                                <FormControl fullWidth size='small' disabled={albumDisabledStatus} name="copyrights_p" >
                                    <InputLabel>Ⓟ Copyright Year*</InputLabel>
                                    <Select
                                        name="copyrights_p"
                                        label="Ⓟ Copyright Year*"
                                        value={data?.copyright_p?.copyright_year?.id}
                                        disabled={albumDisabledStatus}

                                        onChange={handleInputChangeCopyright}

                                    >
                                        {copyrightList && copyrightList.map((item, index) => (
                                            <MenuItem
                                                value={item.id}
                                                key={item.id}
                                            >
                                                {item.copyright_year}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-md-6 mt-3'>
                                <FormControl
                                    name='copyrightp_name'
                                    noValidate
                                    autoComplete="off"
                                    fullWidth>
                                    <TextField
                                        size="small"
                                        disabled={albumDisabledStatus}
                                        value={data?.copyright_p?.copyright_name}
                                        onChange={handleInputChangeCopyright}
                                        name="copyrightp_name"
                                        label="Ⓟ Copyright*" variant="outlined" className="w-100 rounded "
                                        error={errors.copyrightp_name}
                                        helperText={errors.copyrightp_name ? errors.copyrightp_name_helperTxt : ''}
                                    />
                                </FormControl>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-6 mt-3'>
                                <FormControl fullWidth size='small' disabled={albumDisabledStatus} name="copyrights_c">
                                    <InputLabel >© Copyright Year*</InputLabel>
                                    <Select
                                        name="copyrights_c"
                                        label="© Copyright Year*"
                                        value={data?.copyright_c?.copyright_year?.id}
                                        disabled={albumDisabledStatus}

                                        onChange={handleInputChangeCopyright}
                                    // onMouseEnter={() => handleMouseEnter('copyrights_c')}
                                    // onMouseLeave={handleMouseLeave}
                                    // endAdornment={hoveredSelect == 'copyrights_c' && (
                                    //     <ClearSelectionIcon name={'copyrights_c'} onClear={handleClearSelection} />
                                    // )}
                                    >
                                        {copyrightList && copyrightList.map((item, index) => (
                                            <MenuItem
                                                value={item.id}
                                                key={item.id}
                                            >
                                                {item.copyright_year}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-md-6 mt-3'>
                                <FormControl
                                    name='copyrightc_name'
                                    noValidate
                                    autoComplete="off"
                                    fullWidth>
                                    <TextField
                                        size="small"
                                        disabled={albumDisabledStatus}
                                        value={data?.copyright_c?.copyright_name}
                                        // value={data.copyrightc_name}
                                        onChange={handleInputChangeCopyright}
                                        name="copyrightc_name"
                                        label="© Copyright*" variant="outlined" className="w-100 rounded "
                                        error={errors.copyrightc_name}
                                        helperText={errors.copyrightc_name ? errors.copyrightc_name_helperTxt : ''}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-6 mt-2 ">
                                <FormControl fullWidth>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Release Date*"
                                            disabled={albumDisabledStatus}
                                            inputFormat="YYYY-MM-DD"
                                            // value={data.release_date ? data.release_date : null}
                                            value={data.release_date ? dayjs(data.release_date).format('YYYY-MM-DD') : null}
                                            name="release_date"
                                            minDate={minDate}
                                            onChange={(date) => releaseDateChangeHandler(date, 'releaseDate')}
                                            renderInput={(params) => <TextField size="small" sx={{ minWidth: "100%", mt: 1 }} {...params} />}
                                        />
                                    </LocalizationProvider>
                                    {isDateInvalid && (<FormHelperText error> Please select a future date.</FormHelperText>)}
                                </FormControl>
                            </div>
                            <div className='col-md-6 mt-3'>


                                <FormControl
                                    name='release_time'
                                    noValidate
                                    autoComplete="off"
                                    fullWidth
                                >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <MobileTimePicker
                                            label="Release Time(Optional)"
                                            value={releaseTime}
                                            disabled={albumDisabledStatus}

                                            // value={data.release_time}
                                            onChange={(time) => releaseTimeChangeHandler(time, 'releaseTime')}
                                            renderInput={(params) => <TextField size="small" {...params} />}
                                        />

                                    </LocalizationProvider>
                                </FormControl>


                            </div>
                        </div>
                    </div>

                </div>

                <div className="form-group col-md-12 mt-5 d-flex justify-content-between " >
                    <button
                        //   disabled={prevBtnDisable}
                        onClick={handleBack}
                        style={{ mr: 1, color: '#E41116' }}
                        className='fs14 border-0 bg-body fw-500'

                    >
                        PREVIOUS
                    </button>
                    <Tooltip title={data.song_status == "In Review" ? releaseStatusReview : data.song_status == "Released" ? releaseStatusReleased : "Next"} arrow>
                        {/* <span >
                        <button
                            disabled={
                                albumDisabledStatus ? true : (nextBtnDisable || data.release_date === "" || data.release_date === null) ? true :
                                    JSON.stringify(data) === JSON.stringify(releaseDataBkp) ? true :
                                        false
                            }
                   
                            onClick={() => handleSubmit()} className='nxt_actionbutton mx-3 py-1 px-4 fs14' >Next</button>
                            </span> */}
                        <button onClick={() => (JSON.stringify(data) === JSON.stringify(releaseDataBkp)) ? setActive(active + 1) : handleSubmit()} className='nxt_actionbutton mx-3 py-1 px-4 fs14' >{JSON.stringify(data) === JSON.stringify(releaseDataBkp) ? "NEXT" : "SAVE"}</button>

                    </Tooltip>
                </div>
            </div>
        </>
    )
}

export default AlbumReleaseInformation