import axios from "axios";
import {loginUserApi} from '../../_constants/api.constant'
import {getSelfToken,removeUserToken} from '../../_helpers/auth/secureToken'
export async function getStudioByID(dataId){
    console.log('From-getUser',loginUserApi.VIEW+''+JSON.stringify(dataId)+'/',)
    const response=await axios.get(
        loginUserApi.VIEW+''+dataId+'/',
        {
            headers:{
                Authorization: 'Token '+getSelfToken(),
            },
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        // handle error
        if(error.response.status === 401)
        {   window.location.href='/'
            removeUserToken();
        }

        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}