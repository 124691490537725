import axios from "axios";
import { getSelfToken } from '../../_helpers/auth/secureToken'
import { receiverDemoURL } from '../../_constants/api.constant'

// Define the function
export async function demoStudioList(searchValue, limit, offset) {
    try {
        const response = await axios.get(
            receiverDemoURL.GET,
            {
                params: { search: searchValue, limit: limit, offset: offset },
                headers: {
                    Authorization: 'Token ' + getSelfToken(),
                },
            }
        );
        return response.data; // Return the actual data
    } catch (error) {
        console.error(error);
        return error.response;
    }
}
