import {
  FETCH_ASSOCIATES_DATA_REQUEST,
  FETCH_ASSOCIATES_DATA_SUCCESS,
  FETCH_ASSOCIATES_DATA_FAILURE,
  } from './creatorAssociatesDataTypes'
import { setLoader } from '../loader/loaderActions'
import { creatorAssociation } from '../../_services/creatorAssociation/creatorAssociation.service'

  export const fetchCreatorAssociates = (userId, rowsPerPage, page) => {

    return (dispatch) => {
      dispatch(fetchCreatorAssociatesRequest())
      creatorAssociation({ id: userId, limit: rowsPerPage, offset: page }, 'GETLIST')
        .then(response => {
          const associateData = response.data
          dispatch(fetchCreatorAssociatesSuccess(associateData))
          dispatch(setLoader(false))
        })
        .catch(error => {
          dispatch(fetchCreatorAssociatesFailure(error.message))
          dispatch(setLoader(false))
        })
    }
  }
  
  export const fetchCreatorAssociatesRequest = () => {
    return {
      type: FETCH_ASSOCIATES_DATA_REQUEST
    }
  }
  
  export const fetchCreatorAssociatesSuccess = userData => {
    return {
      type: FETCH_ASSOCIATES_DATA_SUCCESS,
      payload: userData
    }
  }
  
  export const fetchCreatorAssociatesFailure = error => {
    return {
      type: FETCH_ASSOCIATES_DATA_FAILURE,
      payload: error
    }
  }
  