import React from 'react'
import { Box, FormControl, IconButton, Stack, TextField, ToggleButton } from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'

const SearchBox = ({ handleSearchInput, value }) => {
  return (
    <div>
      <div>
        <TextField
          className='searchicon bg-white'
          id="standard-bare"
          variant="outlined"
          fullWidth
          size='small'
          input="true" type="text" placeholder="Search.."
          defaultValue=""
          // value={value&&value.length>0?value:''}
          onChange={(e) => handleSearchInput(e.target.value)}
          InputProps={{
            startAdornment: (
              <IconButton edge="start">
                <SearchOutlined />
              </IconButton>
            ),
          }}
        />
      </div>
    </div>
  )
}

export default SearchBox