import './App.css';
import './style/customStyle.css';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import Login from './admin/Login/login';
import Register from './admin/Register/register';
import Forgot from './admin/forgot';
import PrivateRoute from './PrivateRoute';
import SingupVerification from './admin/SingupVerification';
import { createContext, useEffect, useState } from 'react';
import SnackbarContainer from './components/utils/Snackbar';
import { Logout } from './components/utils/Logout';
import NotFound from './admin/PageNotFound';
import { fetchUserDetail } from './redux';
import { useDispatch, useSelector } from 'react-redux';
import ResetPassword from './admin/resetPassword';
import { Backdrop, } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { user } from './config/auth/AppConstants';
import Details from './module/royalty/Details';

const SnackbarContext = createContext();
const theme = createTheme({
  palette: {
    secondary: {
      main: '#333'
    }
  },
});

function App() {
  const dispatch = useDispatch();
  const openBackdrop = useSelector((state) => state.loader.loader);
  const [snack, setSnack] = useState({
    message: '',
    color: '',
    open: false,
    vertical: 'bottom',
    horizontal: 'right',
    action: '',
  });

    /*convert ISO date to dd/mm/yy*/
    const convertDateTime = (dateTime) =>{
      let joinedDate =  new Date(dateTime);
      let dateString = new Intl.DateTimeFormat('en-GB', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(joinedDate);
     return dateString; 
      }
      
      useEffect(() => {
        const id = localStorage.getItem(user.studioUserId);
        loginStatus() && dispatch(fetchUserDetail(id))
      },[]);
  

  function loginStatus() {
    const token = localStorage.getItem('token');
    if (token) {

      return true;
    }
    else {
      return false;
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <SnackbarContext.Provider value={{ snack, setSnack }}>
          <SnackbarContainer />
          <Backdrop open={openBackdrop} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 500 }}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='login' element={<Login />} />
            <Route path='studio/activate/:tokenKey/:tokenValue' element={<Login />} />
            <Route path='register' element={<Register />} />
            <Route path='/forgot-password' element={<Forgot />} />
            <Route path='/studio/passwordreset/:rowId/:token' element={<ResetPassword />} />
            <Route path='thank-you' element={<SingupVerification />} />
            <Route path="dashboard" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="royalty" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="payout" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="payoutdetails" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="viewdetails" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path='/details' element={<Details />} />
            <Route path="analytics" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="list" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="artistDetail/:userhandle" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="associatedartist" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="associatedartist/:assoId" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="associateArtistDetails/:assoId" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="addassociatedartist" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="users" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="addUser" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="releases" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="releases/:songId" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="releases/:songId/:albumId" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="songpitchCreate" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="songpitchView/:songId" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="songpitch" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="addreleasesong" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            {/* <Route path="addreleasesong/:songId" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} /> */}
            <Route path="account" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="submitdemo" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="demoReceiver" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="demoReceiver/:demoId" element={<PrivateRoute isAuthenticated={() => loginStatus()}></PrivateRoute>} />
            <Route path="addreleasesong" element={<PrivateRoute  isAuthenticated={()=>loginStatus()}></PrivateRoute>} />
            <Route path="addreleasesong/:albumId" element={<PrivateRoute  isAuthenticated={()=>loginStatus()}></PrivateRoute>} />
            <Route path="addreleasesong/:songId/:albumId" element={<PrivateRoute  isAuthenticated={()=>loginStatus()}></PrivateRoute>} />   
            <Route path="/addrelease/:albumId" element={<PrivateRoute  isAuthenticated={()=>loginStatus()}></PrivateRoute>} />        
            <Route path="/payment" element={<PrivateRoute  isAuthenticated={()=>loginStatus()}></PrivateRoute>} />
            <Route path="/payment-success/:orderId" element={<PrivateRoute  isAuthenticated={()=>loginStatus()}></PrivateRoute>} />
            {/* <Route path="/addrelease/:albumId?" element={<PrivateRoute  isAuthenticated={()=>loginStatus()}></PrivateRoute>} />  */}
            <Route path="/addrelease/:albumId/payment_fail" element={<PrivateRoute  isAuthenticated={()=>loginStatus()}></PrivateRoute>} />        
            <Route path="FAQs" element={<PrivateRoute  isAuthenticated={()=>loginStatus()}></PrivateRoute>} />
            <Route path="releasealbum" element={<PrivateRoute isAuthenticated={()=>loginStatus()}></PrivateRoute>} />
            <Route path="albumdetail/:albumId" element={<PrivateRoute isAuthenticated={()=>loginStatus()}></PrivateRoute>} />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<NotFound />} />
          </Routes>

        </SnackbarContext.Provider>
      </ThemeProvider>
    </>
  );
}

export default App;
export { SnackbarContext };