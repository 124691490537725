import axios from "axios";
import { userDeleteSocialLinkApi } from '../../_constants/api.constant'
import {  getSelfToken } from '../../_helpers/auth/secureToken'
export async function deleteSocial(dataId,id) {
    let prarmTempObj = {};
    prarmTempObj.id = id;
    const response = await axios.delete(
        userDeleteSocialLinkApi.DELETE, //+'?id='+id, //add the social link id to the 
        {
            params: prarmTempObj,
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
    //window.location.reload();
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}