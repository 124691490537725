import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const Loader = ({ size }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#E41116',
      },
      secondary: {
        main: '#ff8f00',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CircularProgress color="primary" size={size} sx={{position:'absolute', top:'50%', left:'50%'}}/>
    </ThemeProvider>
  );
};

Loader.propTypes = {
  size: PropTypes.number,
};

Loader.defaultProps = {
  size: 40,
};

export default Loader;
