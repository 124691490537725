import axios from "axios";
import {activateUserAccount} from '../../_constants/api.constant'
export async function activateUserEmail(tokenData){
    const response=await axios.get(
        activateUserAccount.GET+tokenData+'/'
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        console.log(error.response);
        return error.response;
        });
    return response;
}