import axios from "axios";
import { loginApi } from "../../_constants/api.constant"
import {setUserId} from '../../_helpers/auth/secureToken'
export async function login(data) {
 const response = await axios.post(
    loginApi.LOGIN,
    data,
  ).then(function (response) {
    setUserId(response.data.data[0].studio_id)
    return response;
  }).catch(function (error) {

    if (error.response) {
   
      return (error.response)

    }
    else return (error);

  });


  return response;
}