import React,{useRef, useEffect, useState} from 'react';
import styles from '../../style/donuteStepper.module.css'
import useMediaQuery from '@mui/material/useMediaQuery'

const DonutStepper = ({ strokeWidth, percentage,activeStep, steps,strokeColor,trackColor,textColor,fontSize,subHedingSteps }) => {
    const xmScreen = useMediaQuery('(max-width:320px)');
    //to calcuate and convert one step to percent value
    const percentageOfOneStep = 100/steps.length;
    const covertStepToPercentage = percentageOfOneStep* (activeStep+1)
    const radius = (40 - strokeWidth / 2);
    const pathDescription = `
      M 50,50 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

    const diameter = Math.PI * 2 * radius;
    const progressStyle = {
			stroke: strokeColor,
  		strokeLinecap: 'round',
      strokeDasharray: `${diameter}px ${diameter}px`,
      //strokeDashoffset: `${((100 - percentage) / 100 * diameter)}px`,
      strokeDashoffset: `${((100 - covertStepToPercentage) / 100 * diameter)}px`,
    };

    return (
      <div style={{display:'flex',justifyContent:xmScreen?'space-between':'start',padding:xmScreen?'0rem':'0.5rem', flexWrap:'wrap'}}> 
        <div>
            <svg
                className={'CircularProgressbar'}
                viewBox="0 0 100 100"
                        width={100}
                        height={100}
            >
                <path
                className="CircularProgressbar-trail"
                d={pathDescription}
                strokeWidth={strokeWidth}
                fillOpacity={0}
                            style={{
                                stroke: trackColor,
                            }}
                />

                <path
                className="CircularProgressbar-path"
                d={pathDescription}
                strokeWidth={strokeWidth}
                fillOpacity={0}
                style={progressStyle}
                />

                <text
                className="CircularProgressbar-text"
                x={50}
                y={50}
                    style={{
                    fill: textColor,
                    fontSize: xmScreen?'14px':fontSize,
                    fontWeight:'bold',
                    dominantBaseline: 'central',
                    textAnchor: 'middle',
                    }}
                >
                {/* {`${percentage}%`} */}
                {`${activeStep+1} of ${steps.length}`}
                </text>
            </svg>
        </div>
         <div style={{padding:!xmScreen?'1rem 0.5rem':'0.5rem 0rem'}}>
                <h5 style={{color:'rgba(0, 0, 0, 0.58)', fontWeight:'bold'}}>{steps[activeStep]}</h5>
                {subHedingSteps[activeStep] === "New Release" ||subHedingSteps[activeStep] === "Song Credits" ?'':
                <h6 style={{color:'rgba(0, 0, 0, 0.58)', fontSize:'14px'}}>{subHedingSteps[activeStep]}</h6>}
         </div>
      </div> 
    );
};
export default DonutStepper;