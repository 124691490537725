import axios from 'axios';
import { getAppToken, getSelfToken } from '../_helpers/auth/secureToken';
const BASE_URL = process.env.REACT_APP_BASEURL;

class APIService {
  constructor(baseURL) {
    this.baseURL = baseURL || BASE_URL;
    this.api = axios.create({
      baseURL: this.baseURL
    });

    // Define your endpoints here
    this.endpoints = {
      titleVersion: "/studio_info/releases/title_version/",
      copyrightList: "/studio_info/releases/copyright/",
      copyrightCreate: "/studio_info/releases/copyright_add",
      copyrightUpdate: "/studio_info/releases/copyright_add",
      overviewData: "/releases/overview/studio",
      trackData: "/releases/allsongs/studio",
      albumData: "/releases/allalbums/studio",
      singleDetails: "/releases/songs",
      singleMonthDetails: "/releases/month",
      storeData: "/releases/allplatforms/studio",
      countryData: "/releases/allcountry/studio",
      monthData: "/releases/allmonth/studio",
      videoData: "/releases/allvideo/studio",
      singleAlbumData: "/releases/albums",
      singleCountryData: "/releases/country",
      singlestoreData: "/releases/platform",
      countryList: "/releases/country/",
      payeeData: "/studio_info/releases/payee",
      updatePayeeData:"/studio_info/releases/payee/",
      viewPayeeData:"/studio_info/releases/payee_info/studio",
      deletBankData:"/studio_info/releases/payee_info_delete",
      genreData:'studio_info/genre/',
      subGenreData:'studio_info/releases/sub_genre/',
      moodData:'studio_info/releases/mood/',
      recordLabelData:'studio_info/releases/record_label/',
      languageData:'studio_info/language/',
      generateOrderId:'/releases/payment/',
      specificSongInfo:'/studio_info/releases/songmetaId/studio',
      // delete album
      deleteAlbum:'studio_info/releases/albummetadelete',
      // ---------song pitch api endpoints -------
      pitchSong:'studio_info/releases/song_pitching',
      songMeta:'studio_info/releases/songmeta/studio',
      songpitchList:'studio_info/releases/song_pitching/studio',
      songpitchView:'studio_info/releases/song_pitchingId/studio',
      songpitchSearch:'studio_info/releases/pitching_search',
    };
  }
  async get(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      // params.app = getAppToken();
      let response
      if (endpointKey == "overviewData" || endpointKey == 'trackData'
        || endpointKey == 'albumData' || endpointKey == 'singleDetails' ||
        endpointKey == 'storeData' || endpointKey == 'countryData' || endpointKey == 'monthData' ||
        endpointKey == 'singleMonthDetails' || endpointKey == 'videoData' ||
         endpointKey == 'singleAlbumData' || endpointKey == 'singleCountryData' || endpointKey == 'singlestoreData' || endpointKey == 'countryList') {
        response = await this.api.get(endpoint, {
          params,
          // headers: { Authorization: 'Token ' + getSelfToken() },
        })
      } else {
        response = await this.api.get(endpoint, {
          params,
          headers: { Authorization: 'Token ' + getSelfToken() },
        });
      }
      return response;
    } catch (error) {
      // Use a template string to include the error message in the throw statement
      throw new Error(`API request failed: ${error.message}`);
    }
  }
  async post(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      // data.app = getAppToken();
      let response
      if(endpointKey == "generateOrderId"){ 
       response = await this.api.post(endpoint, data, {
        // headers: { Authorization: 'Token ' + getSelfToken() }
      });
    }else{
      response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'Token ' + getSelfToken() }
      })
    }
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }
  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      // params.app = getAppToken();
      const response = await this.api.delete(endpoint, {
        params,
        headers: { Authorization: 'Token ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async put(endpointKey, id, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '?' + searchParam;
      }
      // data['app'] = getAppToken();
      const response = await this.api.put(endpoint+id, data, {
        headers: { Authorization: 'Token ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }
}



export default new APIService(BASE_URL);
