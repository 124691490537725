import React, { useState } from 'react';
import { Email } from '@mui/icons-material'
import { Lock } from '@mui/icons-material'
import { useHistory, useLocation, Redirect,useParams, useNavigate} from 'react-router-dom';
// import logo from "../img/logo1.png"
// import Footer from './footer';
// import { Link } from 'react-router-dom';
// import InputBase from '@mui/material/InputBase';
// import google from '../img/google.svg'
// import { login } from '../_services/auth/auth.service';
import { resetPassword } from "../_services/forgotPassword/index"
import {
    setSsoToken,
    setSelfToken,
} from '../_helpers/auth/secureToken';
import { setLoader } from '../redux';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux'
import {
    setAppToken,
    setUserAuthType,
    getSelfToken,
    setTempSsoToken,
    getAppToken,
    getUserAuthType
} from '../_helpers/auth/secureToken';
import { isLogin } from '../_helpers/auth/secureToken';

import { authentication } from '../config/auth/AppConstants'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Divider, Grid, TextField } from '@mui/material';
//import backgroundImage from '../img/bg_image_texture3.png'
import ForgotPassHeader from './header/AdminHeader';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius:3,
    boxShadow: 24,
    p: 3
  };
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ResetPassword(props) {
    const dispatch = useDispatch();
    let history = useNavigate();
    let location = useLocation();
    let {rowId, token} = useParams();

    let { from } = location.state || { from: { pathname: "/" } };
    const [open, setOpen] = React.useState(false); //to show and hide the modal
    const handleOpen = () => setOpen(true);
    const handleClose=()=>setOpen(false)
    const [modalText, setModalText] = useState('')
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState();
    const [password, setPassword] = useState("");
    const [passError, setPassError] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassError, setNewPassError] = useState("");
    const [conPassword, setConPassword] = useState("");
    const [conPassError, setConPassError] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showConPassword, setShowConPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);



    async function onClickHandler(e) {
        e.preventDefault();
        let data = new FormData();
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        var regexp =  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
     
        if (email.match(mailformat)) {
            data.append('email', email);
            setEmailError("")
        } else {
            setEmailError(
                <label className="text-danger mt-1 ">Please enter valid Email</label>
            )
        }

        if (password.match(regexp) || format.test(password)) {
            data.append('password', password);
            setPassError("")
        } else {
            setPassError(
                <span className="text-danger mt-1 mx-2">Please enter valid password </span>
            )
        }
        if (conPassword.match(password)) {
            data.append('c_password', conPassword);

            setConPassError("")
        } else {
            setConPassError(
                <span className="text-danger mt-1 mx-2">Please enter valid confirm password </span>
            )
        }
        data.append("token",rowId+'/'+token)
        if (email.match(mailformat) && password.match(regexp) || format.test(password) && conPassword.match(password)) {
            dispatch(setLoader(true))
            resetPassword(data,rowId+'/'+token).then(function (response) {
                if(response.status === 200){
                    dispatch(setLoader(false))
                    setModalText('You have successfully reset your password.')
                    handleOpen();
                    setTimeout(()=>{history('/login')},3000)
                }
                else
                {
                    setModalText(`There is an error. ${response.data.error[0]}`)
                    dispatch(setLoader(false))
                    handleOpen();
                }
            })/* .catch(function (error) {
                dispatch(setLoader(false))
                console.log(error);
                console.log(error.response);
                return error.response;
            }) */
        }
    }

    return (    
    <Box sx={{ flexGrow: 1, height: '100vh' }}>
    <Grid container justifyContent="center" alignItems="start" sx={{ height: '100%' }} className="loginFormSection">
        <Grid item xs={12} sx={{ height: '10%' }}>
        <ForgotPassHeader />
        <Divider /> 
        </Grid>
        <Grid item container xs={12} lg={6} justifyContent="center" sx={{ height: '90%',padding: '0 1rem'  }}>
        <Grid item container spacing={4} xs={12} sm={10} md={8} direction="column" margin='1rem'>
            <div style={{ textAlign: 'center', paddingBottom: '1rem', marginTop: { xs: '1rem', sm: '2rem' } }}>
            <h2 className="fw-bold" style={{fontSize:'2.25rem'}}>Reset Password</h2>
            </div>
            <div className="" style={{ alignSelf: 'center', width: 'auto' }}>
                <div className="login-form  mt-4 mb-4 border rounded shadow bg-white">
                    <form action="" method="" className="row g-4 mx-4 py-4" >
                        {/* <h2 className="text-center">Get Your Free Account</h2> */}
                        {/* <div className='text-center'>
                            <h5>Nothing to worry about. We have you covered</h5>
                            <h6>All you need to do is input your registered email address and leave the rest to us.</h6>
                        </div> */}
                            <h6 className="text-secondary fw-normal" style={{fontSize:'1.125rem'}}>
                            Enter the new password
                        </h6>
                        {/* <div className="border d-flex p-2 rounded">
                            <Email className="  text-secondary" />
                            <InputBase
                                className="ms-2 w-100"
                                placeholder="Email Address"
                                onChange={e => { setEmail(e.target.value); setEmailError("") }}
                            />
                        </div> */}
                            <div >
                            <TextField 
                                id="outlined-basic"
                                label="Password*"
                                size='small'
                                variant="outlined" 
                                type={showPassword ? "text" : "password"}
                                className="w-100"
                                placeholder=" Password *"
                                name="password"
                                onChange={e => { setPassword(e.target.value); setPassError("") }}
                                InputProps={{
                                endAdornment: (
                                <IconButton  onClick={(e) => setShowPassword(!showPassword)} edge="end">
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                                ),
                                }}
                            />  
                            </div>
                        {passError}
                        <div>
                            <TextField 
                            id="outlined-basic"
                            label="Confirm Password*"
                            variant="outlined" 
                            type={showConPassword ? "text" : "password"}
                            className="w-100"
                            placeholder=" Confirm  Password *"
                            name="password"
                            size='small'
                            onChange={e => { setConPassword(e.target.value); setConPassError("") }}
                            InputProps={{
                                endAdornment: (
                                <IconButton  onClick={(e) => setShowConPassword(!showConPassword)} edge="end">
                                    {showConPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                                ),
                            }}
                            />
                            {conPassError}
                            </div>
                            <div className='col-md-12 px-2 m-2'>
                                <span className='mt-4 text-secondary'>Password must contain:</span>
                                <ul className='ps-3 mt-2'>
                                    <li className='small'> At least 8 characters,one special characters, one number. </li>
                                    <li className='small'> At least one uppercase letter ,one lowercase letter .</li>
                                </ul>
                            </div>
                       
                        {/* <div className="col-md-12">
                            <span>Go to the:</span>  <Link to="/login" className="text-decoration-none">Login</Link>
                            <button type="submit" className="gn-actionbtn float-end w-25 py-2" onClick={onClickHandler}>Submit</button>
                        </div> */}
                        <div className="col-md-12 m-0">
                            <button type="submit" className="gn-actionbtn float-end px-4 py-2 mt-4" onClick={onClickHandler}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
           <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style} className='text-secondary' >
                    {/* <h6>Mail has been sent to your registered email</h6> */}
                    <h6>{modalText}</h6>

                </Box>
            </Modal>
          
        </Grid>
        </Grid>
    </Grid>
    </Box>
);
}



export default ResetPassword;