import React, { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery'
import AudioPlayer from '../../components/reusableComponents/customAudioPlayer';
import { getDateAsPerFormat, getTime } from '../../_helpers/reusablefunctions/dateSetter';
import { MenuItem, Tooltip } from '@mui/material';
import imgPlaceHolder from '../../img/default image.png'
import { useCallback } from "react";
import useRazorpay from "react-razorpay";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getPlanList } from '../../_services/Plan/getPlanList.service';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';


const ReleaseFinalReview = ({ data, genre, language, errors,
  primaryArtList, featureArtList, composerList, producerList, remixerArtList, actorList, lyricistList, recordLabelList, copyrights_p,
  copyrights_c, copyrightc_name, copyrightp_name, copyrightList, songList, platformList, confirmHandlerFunction, releaseStatusReview, releaseStatusReleased,
  albumDisabledStatus, handleBack, releasehistory, nextBtnDisable, setActive, active, userData, subGenreList, releaseDataBkp, handleSubmit }) => {
  const smScreen = useMediaQuery('(max-width:768px)');

  const [showFullLyrics, setShowFullLyrics] = useState(false);
  const navigate = useNavigate();
  const { albumId } = useParams();
  const [planList, setPlanList] = useState([])
  const [nextBtnDisableButton, setNextBtnDisableButton] = useState(false)
  // const albumValidation = songList.length > 0 && songList.every(item => item.song.song_status === 'In Review') && (data.song_status !== "In Review" || data.song_status != "Released" || data.song_status != "Ready for Release")
  const albumValidation =  songList.length > 0 && songList.every(item => item.song.song_status === 'In Review') && // Check if every song_status in songList is 'In Review'
  data.song_status !== 'In Review' && // Check if data.song_status is not 'In Review'
  data.song_status !== 'Released' && // Check if data.song_status is not 'Released'
  data.song_status !== 'Ready for Release' &&
  data.song_status !== 'Rejected'; // Check if data.song_status is not 'Ready for Release'

  // This function is just handle the scenario for record label;
  const handleRecordLabelDisplay = (recordLabel) => {
    if (Array.isArray(recordLabel))
      return recordLabelList && recordLabelList.length > 0 ? recordLabelList.find(item => item.label_id === data.record_labels[0]).label_name : ''
    else
      if (recordLabel != null && recordLabel != undefined && recordLabel != "")
        return recordLabelList && recordLabelList.length > 0 ? recordLabelList.find(item => item.label_id === data.record_labels).label_name : ''
      else
        return ""
  }

const isFieldsValid = (errObj) => {
  let array = [];
  for (let key in errObj) {
    array.push(errObj[key])
  }
  if (array.every((item) => item === false)) {
    return true;
  }
  else {
    return false;
  }
}

useEffect(()=>{
  if((releaseDataBkp.song_status == "draft" || releaseDataBkp.song_status == "Rejected") && (data.song_status == "draft" || data.song_status == "Rejected")){ // Check release status 
    if(songList.length > 0 && songList.every(item => item.song.song_status === 'In Review')){   // check that user fill all the mandatory field  of song and song status must be in review  
    if((releaseDataBkp.cover_image !== '' || releaseDataBkp.cover_image !== null || releaseDataBkp.cover_image !== undefined) && (data.cover_image !== '' || data.cover_image !== null || data.cover_image !== undefined)){ // check in upload file release has cover image or not
      if((releaseDataBkp.genre !== '' || releaseDataBkp.genre !== null)&& (data.genre !== '' || data.genre !== null)){ //  check in general genre should filled
        if((releaseDataBkp.sub_genre !== '' || releaseDataBkp.sub_genre !== null) && ((data).sub_genre !== '' || data.sub_genre !== null)){ //  check in general subgenre should filled
          if((releaseDataBkp.language !== '' || releaseDataBkp.language !== null) && (data.language !== '' || data.language !== null)){ //  check in general language should filled        
            if((releaseDataBkp.primary_artists !== "" && releaseDataBkp.primary_artists !== undefined 
          && releaseDataBkp.primary_artists !== null && releaseDataBkp.primary_artists.length !=0  
          && releaseDataBkp.primary_artists !== false) &&
          (data.primary_artists !== "" && data.primary_artists !== undefined && data.primary_artists !== null 
          && data.primary_artists.length !=0 && data.primary_artists !== false)){  //  check in release credit primary artist should filled
            if ((releaseDataBkp?.release_history === false || releaseDataBkp?.release_history === 'False' || releasehistory == "False" || releasehistory == false)&&(data?.release_history === false || data?.release_history === 'False' || releasehistory == "False" || releasehistory == false)) { //  check in album release information release history radio is filled as no 
              if (((releaseDataBkp.release_date !== '')
                || (releaseDataBkp.copyright_c !== '' || releaseDataBkp.copyright_c !== null )  //  check in album release information copyrightC or copyrightP year and name with along release date is filled 
                || (releaseDataBkp.copyright_p !== '' || releaseDataBkp.copyright_p !== null )  
                || (releaseDataBkp?.copyright_p?.copyrightp_name === '' || releaseDataBkp?.copyright_c?.copyrightp_name === '')) 
                &&(((data.release_date !== '')
                || (data.copyright_c !== '' || data.copyright_c != null )
                || (data.copyright_p !== '' || data.copyright_p != null) || (data?.copyright_p?.copyrightp_name === '' || data?.copyright_c?.copyrightp_name === ''))
                )
              ) {
                if((releaseDataBkp.platform !== ''|| releaseDataBkp.platform.length !== 0|| releaseDataBkp.platform != false)   
                   && (data.platform != ''|| data.platform.length != 0|| data.platform != false)){  //  check in platform distribution store is selected or not
                  setNextBtnDisableButton(false)
                 }else{
                   setNextBtnDisableButton(true)
                 }
                // setNextBtnDisableButton(true)
              } else {
                setNextBtnDisableButton(true)
        
              }
        
            } else {
                if (((releaseDataBkp.release_date !== '') ||(releaseDataBkp?.upc_code != null || releaseDataBkp?.upc_code != '' || releaseDataBkp?.upc_code != undefined)
                || (releaseDataBkp.previous_release_date != null||  releaseDataBkp.previous_release_date != '')
                  || (releaseDataBkp.copyright_c !== '' || releaseDataBkp.copyright_c !== null )
                  || (releaseDataBkp.copyright_p !== '' || releaseDataBkp.copyright_p !== null ) || (releaseDataBkp?.copyright_p?.copyrightp_name === '' || releaseDataBkp?.copyright_c?.copyrightp_name === '')) 
                  &&(((data.release_date !== ''|| data.release_date !== null || data.release_date !== undefined) || (data?.upc_code != null || data?.upc_code != '' || (data?.upc_code != undefined))
                  ||(data.previous_release_date != null||  data.previous_release_date != '')
                  || (data.copyright_c !== '' || data.copyright_c != null )
                  || (data.copyright_p !== '' || data.copyright_p != null) || (data?.copyright_p?.copyrightp_name === '' || data?.copyright_c?.copyrightp_name === ''))
                  )
                ){
                  if((releaseDataBkp.platform !== ''|| releaseDataBkp.platform.length !== 0|| releaseDataBkp.platform != false) && (data.platform != ''|| data.platform.length != 0|| data.platform != false)){
                    setNextBtnDisableButton(false)
                   }else{
                     setNextBtnDisableButton(true)
                   }

                
              }else{
                setNextBtnDisableButton(true)
              }

            }
 
          }else{
            setNextBtnDisableButton(true)
          }
        }else{
          setNextBtnDisableButton(true)
        }
        }else{
          setNextBtnDisableButton(true)
        }
      }else{
        setNextBtnDisableButton(true)
      }
    }else{
      setNextBtnDisableButton(true)
    }
    }else{
      setNextBtnDisableButton(true)
    }

  }else{
    setNextBtnDisableButton(true)
  }
},[releaseDataBkp])


  const validationOnDistribute = releaseDataBkp.song_status == "In Review" ? releaseStatusReview : releaseDataBkp.song_status == "Released" ? releaseStatusReleased :  (
    (releaseDataBkp.cover_image === '' || releaseDataBkp.cover_image === null || releaseDataBkp.cover_image === undefined)||
          (releaseDataBkp.genre === '' || releaseDataBkp.genre === null) ||
          (releaseDataBkp.sub_genre === '' || releaseDataBkp.sub_genre === null) ||
          (releaseDataBkp.language === '' || releaseDataBkp.language === null) ||
          (releaseDataBkp.primary_artists === "" || releaseDataBkp.primary_artists === undefined || releaseDataBkp.primary_artists === null || releaseDataBkp.primary_artists === false) ||
          ((nextBtnDisable == true || nextBtnDisable == 'True') && (releaseDataBkp.release_date === "" || releaseDataBkp.release_date === null)) ||
          (releaseDataBkp.platform === ''|| releaseDataBkp.platform.length== 0|| releaseDataBkp.platform === false)
        )? "Please Fill All Mandatory Field": songList.length > 0 && songList.every(item => item.song.song_status === 'In Review')? "Distribute": "Add Song Meta for all Song"

 
  const ViewSongMeta = (e, song) => {
    navigate('/releases/' + song.song_id + "/" + albumId)
  }

  const toggleReadMore = (e) => {
    e.preventDefault();
    setShowFullLyrics(!showFullLyrics);
  };


const gstPercentage = 18;

useEffect(() => {
  if(data.plan!= undefined && data.plan != ''){ 
  getPlanList().then(res => {
    let response = res.data.data
    if (response) {
      setPlanList(response)
    }
  }).catch(function (error) {
    console.log(error);
    console.log(error.response);
    return error.response;
  })
}
}, [data.plan!= undefined && data.plan != ''])


function getGstAddedGSTAmount(amount, songCount){
  const gstAmount = (amount * songCount * gstPercentage) / 100;
  const totalprice = amount * songCount + gstAmount;
  return Math.ceil(totalprice)
}

  const truncatedLyrics = data && data.song_lyrics ? data.song_lyrics.substring(0, 500) : '';
  const displayedLyrics = showFullLyrics ? data?.song_lyrics : truncatedLyrics;

  return (
    <>
      <h4 className="cardheading">Final Review</h4>
      <div className=''>
        <div className='mt-2' id="song-poster-cnt">
          <img src={data.cover_image != null ? process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' + data.cover_image : imgPlaceHolder} alt="Upload poster" className="albumPoster" />
        </div>
        <div style={{ background: " #ECECEC" }} className='mt-3 px-3 rounded pt-2'>
          {songList.length > 0 && songList.map((a, index) => {
            return (<div className='mt-3' key={index}>
               <h5>{a.song.song_name == null ? "Audio " + (index + 1) : a.song.song_name}</h5>
              <div className='d-flex align-items-baseline'>
              {smScreen ?
                <AudioPlayer className="w-100" page='page' type='small-releasealbumplayer' audioSrc={process.env.REACT_APP_BASEURL + 'song' + a.song.song_file} /> : <AudioPlayer className="w-100" page='page' type='releasealbumplayer' audioSrc={process.env.REACT_APP_BASEURL + 'song' + a.song.song_file} />}
                     <Tooltip title="Preview Song" arrow>
                    <VisibilityIcon onClick={(e) => ViewSongMeta(e, a.song, )} className='mx-1 cursorPointer mt-2' sx={{ color: "#ED3237" }} />
                  </Tooltip>
                  </div>
            </div>)
          })}
          <div className={`d-flex ${smScreen ? 'flex-wrap align-items-center flex-column' : 'justify-content-between'}`}>

            <div className={`flex-grow-1 ${smScreen ? 'py-3 w-100 pt-3' : 'px-2 pt-3'}`}>
              {/* <div className='d-flex align-items-center'>
              <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
            </div> */}
              <span className="fs-6 fw-bold">Basic Information</span>

              <div className='row mt-3'>
                <div className='col-md-8' style={{ paddingRight: '0.7rem' }}>
                  <div className="value-pair fs-09"><strong>Release Name: </strong><span className='truncate text-secondary'>{data.album_name != ""? data.album_name:"--"}</span></div>
                  <div className="value-pair fs-09"><strong>Genre: </strong><span className='text-secondary'>{(data.genre!= null || data.genre != "" || data.genre != undefined) ?genre.find(item => item.id === data.genre)?.genre:"--"}</span></div>

                </div>
                <div className='col-md-4' style={{ paddingRight: '0.7rem' }}>
                  <div className="value-pair fs-09"><strong>Language: </strong><span className='text-secondary'>{(data.language !=null || data.language !="" || data.language !=undefined) ? language.find(item => item.id === data.language)?.language:"--"}</span></div>
                  <div className="value-pair fs-09"><strong>Sub Genre: </strong><span className='text-secondary'>{(data.sub_genre !=null || data.sub_genre !="" || data.sub_genre !=undefined) ? subGenreList.find(item => item.id === data.sub_genre)?.sub_genre:"--"}</span></div>

                </div>

                {(data.album_description != "" || data.album_description != null || data.album_description !=undefined) ?<div className='col-md-12'>
                  <div className="value-pair fs-09"><strong>Release Description: </strong><span className='text-secondary'>{data.album_description}</span></div>
                </div>:""}

              </div>
            </div>

          </div>
          {/* row 2 */}

          {/* row 3*/}
          <div className={`d-flex ${smScreen ? 'flex-wrap align-items-center flex-column' : 'justify-content-between'}`}>
            <div className={`flex-grow-1 ${smScreen ? 'py-3 w-100' : 'px-2'}`}>
              <div className='d-flex align-items-center'>
                <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
              </div>
              <span className="fs-6 fw-bold">Release Credit</span>
              {/* row 3*/}
              <div className='row mt-3'>
                <div className='col-md-4'>
                  <div className="value-pair fs-09"><strong>Primary Artist/Singer: </strong><span>{primaryArtList.length>0 ? primaryArtList.map(artist => artist.artist_name).join(', '):"--"}</span></div>
                  <div className="value-pair fs-09"><strong>Lyricist: </strong><span>{lyricistList.length>0 ? lyricistList.map(artist => artist.artist_name).join(', '):"--"}</span></div>
                  <div className="value-pair fs-09"><strong>Actor: </strong><span>{actorList.length>0 ?actorList.map(artist => artist.artist_name).join(', '):"--"}</span></div>

                </div>
                <div className='col-md-4'>
                  <div className="value-pair fs-09"><strong>Featuring Artist: </strong><span>{featureArtList.length>0 ? featureArtList.map(artist => artist.artist_name).join(', '):"--"}</span></div>
                  <div className="value-pair fs-09"><strong>Producer: </strong><span>{producerList.length>0 ? producerList.map(artist => artist.artist_name).join(', '):"--"}</span></div>
                  <div className="value-pair fs-09"><strong>Remixer: </strong><span>{remixerArtList.length>0 ? remixerArtList.map(artist => artist.artist_name).join(', '):"--"}</span></div>
                </div>
                <div className='col-md-4'>
                  <div className="value-pair fs-09"><strong>Composer: </strong><span>{composerList.length>0 ? composerList.map(artist => artist.artist_name).join(', '):"--"}</span></div>
                </div>

              </div>
            </div>
          </div>

          {/* row 4*/}
          <div className={`d-flex ${smScreen ? 'flex-wrap align-items-center flex-column' : 'justify-content-between'}`}>

            <div className={`flex-grow-1 ${smScreen ? 'py-3 w-100' : 'px-2'}`}>
              <div className='d-flex align-items-center'>
                <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
              </div>
              <span className="fs-6 fw-bold">Release Information</span>
              {/* row 4*/}
              <div className='row mt-3'>
                <div className='col-md-6'>
                  <div className="value-pair fs-09"><strong>Has it been previously released?: </strong><span>{data.release_history === 'False' || data.release_history === false ? 'No' : 'Yes'}</span></div>
                  <div className="value-pair fs-09"><strong>© Copyright Year:</strong><span>  {copyrightList &&
                    copyrightList.map(copyright => {
                      if (copyright.id === copyrights_c) {
                        return copyright.copyright_year+ ' Year';
                      }
                      return null; // or an alternative value if there is no match
                    })}</span></div>
                  <div className="value-pair fs-09"><strong>© Copyright: </strong><span>{copyrightc_name}</span></div>
                  <div className="value-pair fs-09"><strong>Release Time: </strong><span>{data.release_time ? getTime(data.release_time) : '--'}</span></div>
                 {(data.upc_code != "" || data.upc_code !=undefined || data.upc_code != null) ?<div className="value-pair fs-09"><strong>UPC Code: </strong><span className='text-secondary'>{data.upc_code}</span></div>:"--"}
                </div>
                <div className='col-md-6'>
                  <div className="value-pair fs-09"><strong>Previous Release Date: </strong><span>{(data.release_history === 'False' || data.release_history === false)? "--": data.previous_release_date == null || data.previous_release_date == '' ? '--' : getDateAsPerFormat(data.previous_release_date, "DD Mon, YYYY")}</span></div>
                  {/* <div className="value-pair fs-09"><strong>CallerTune Preview-2/Clip Start Time: </strong><span>{data.crbt_cuts_duration_for_clip2 ? getTime(data.crbt_cuts_duration_for_clip2) : ''}</span></div> */}
                  <div className="value-pair fs-09"><strong>Ⓟ Copyright Year: </strong><span>{copyrightList &&
                    copyrightList.map(copyright => {
                      if (copyright.id === copyrights_p) {
                        return copyright.copyright_year;
                      }
                      return null; // or an alternative value if there is no match
                    })}</span></div>
                  <div className="value-pair fs-09"><strong>Ⓟ Copyright: </strong><span>{copyrightp_name}</span></div>
                  <div className="value-pair fs-09"><strong>Release Date: </strong><span>{data.release_date == ""? "--":getDateAsPerFormat(data.release_date, "DD Mon, YYYY")}</span></div>
                </div>

              </div>
            </div>
          </div>

          <div className={`d-flex ${smScreen ? 'flex-wrap align-items-center flex-column' : 'justify-content-between'}`}>
            <div className={`flex-grow-1 ${smScreen ? 'py-3 w-100' : 'px-2'}`}>
              <div className='d-flex align-items-center'>
                <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
              </div>
              <span className="fs-6 fw-bold">Platforms</span>
              {/* row 3*/}
              <div className='row mt-3'>
                {platformList.length && platformList.filter(d => data.platform.includes(d.platformId)).map(d => (
                  <div key={d.platformId} className='col-md-4 p-2'>
                    <span>{d.platformName}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {data.plan != '' && data.plan !=undefined ? 
          <div className={`d-flex ${smScreen ? 'flex-wrap align-items-center flex-column' : 'justify-content-between'}`}>
            <div className={`flex-grow-1 ${smScreen ? 'py-3 w-100' : 'px-2 mb-3'}`}>
              <div className='d-flex align-items-center'>
                <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
              </div>
              <span className="fs-6 fw-bold">Payment Detail</span>
              <div className='row mt-3'>
              <div className='col-md-6'>
             <div className="value-pair fs-09"><strong>Purchased Plan: </strong><span className='text-secondary'>{planList.length>0? planList.find(d=> d.id == data.plan)?.name:"--"}</span></div> 
             <div className="value-pair fs-09"><strong>Track Count: </strong><span className='text-secondary'>{songList.length? songList.length:0}</span></div> 
             <div className="value-pair fs-09"><strong>Payment Status </strong><span className='text-secondary'>Paid</span></div> 
                </div>
                <div className='col-md-6'>
             <div className="value-pair fs-09"><strong>Plan Price: </strong><span className='text-secondary'>{<CurrencyRupeeIcon className='mb-1' sx={{ fontSize: '15px' }} />}{planList.length>0? planList.find(d=> d.id == data.plan)?.price:"--"}</span></div> 
             <div className="value-pair fs-09"><strong>Total Paid Amount + 18% Gst: </strong><span className='text-secondary'>{<CurrencyRupeeIcon  className='mb-1' sx={{ fontSize: '15px' }} />}{getGstAddedGSTAmount(planList.length>0? planList.find(d=> d.id == data.plan)?.price:null, songList.length)}</span></div> 
                </div>
                </div>
           
             
            </div>
          </div>:''}
        </div>


        <div className="form-group col-md-12 mt-5 d-flex justify-content-between " >
          <button
            //   disabled={prevBtnDisable}
              onClick={handleBack}
            style={{ mr: 1, color: '#E41116' }}
            className='fs14 border-0 bg-body fw-500'

          >
            PREVIOUS
          </button>
 
          <Tooltip title={releaseDataBkp.song_status == "In Review" ? releaseStatusReview : releaseDataBkp.song_status == "Released" ? releaseStatusReleased :  (
            (releaseDataBkp.cover_image === '' || releaseDataBkp.cover_image === null || releaseDataBkp.cover_image === undefined)||
                  (releaseDataBkp.genre === '' || releaseDataBkp.genre === null) ||
                  (releaseDataBkp.sub_genre === '' || releaseDataBkp.sub_genre === null) ||
                  (releaseDataBkp.language === '' || releaseDataBkp.language === null) ||
                  (releaseDataBkp.primary_artists === "" || releaseDataBkp.primary_artists === undefined || releaseDataBkp.primary_artists === null || releaseDataBkp.primary_artists === false) ||
                  (nextBtnDisable == true && (releaseDataBkp.release_date === "" || releaseDataBkp.release_date === null)) ||
                  (releaseDataBkp.platform === ''|| releaseDataBkp.platform.length== 0|| releaseDataBkp.platform === false)
                )? "Please Fill All Mandatory Field": songList.length > 0 && songList.every(item => item.song.song_status === 'In Review')? "Distribute": "Add Song Meta for all Song"} arrow>
                 
        <span> <button disabled={nextBtnDisableButton == false && validationOnDistribute == 'Distribute' ? false :true}
          
              onClick={() => userData.premium_features == true ? handleSubmit() :confirmHandlerFunction()}
               className='nxt_actionbutton mx-3 py-1 px-4 fs14' >DISTRIBUTE</button></span>
          </Tooltip>
        </div>
      </div>
    </>
  )
}

export default ReleaseFinalReview