import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import StreamIcon from '@mui/icons-material/Stream';
import DownloadIcon from '@mui/icons-material/Download';
import Streams from './Streams';
import Downloads from './Downloads';
function Analytics(props) {

    const [accountTabs, setAccountTabs] = useState([
        { id: 1, tabActiveId: "1", tabTitle: 'STREAMS', tabType: 'Streams', icons: <StreamIcon sx={{ fontSize: "14px" }} /> },
        { id: 2, tabActiveId: "2", tabTitle: 'DOWNLOADS', icons: <DownloadIcon sx={{ fontSize: "14px" }} />, tabType: 'downloads' }])
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box>
            <Box className='container mt-3'>
                <Typography variant='h5'> Analytics </Typography>
                <Typography variant='span' sx={{ opacity: '70%', fontSize: '15px' }}>Some stores don’t provide live sales & streaming data, so these reports may not reflect your exact final sales figures.</Typography>
                <Box className="bg-white shadow-sm rounded mt-3">
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList sx={{
                                '& .Mui-selected': {
                                    color: '#E41116 !important', // Change this to the desired color
                                },
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#E41116', // Change this to the desired color for the indicator
                                },
                            }}
                            indicator= 'true'

                                onChange={handleChange} aria-label="lab API tabs example">
                                {accountTabs ? accountTabs.map(item => {
                                    return (
                                        <Tab sx={{ fontSize: '13px' }} icon={item.icons} iconPosition="start" label={item.tabTitle} key={item.id} value={item.tabActiveId} />)
                                }) : null}
                            </TabList>
                        </Box>

                        {(() => {
                            switch (value) {
                                case '1':
                                    return <TabPanel value='1'><Streams /></TabPanel>;
                                case '2':
                                    return <TabPanel value='2'><Downloads /></TabPanel>;
                            }
                        })()}
                    </TabContext>
                </Box>
            </Box>
        </Box>
    );
}
export default Analytics;