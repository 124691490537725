import { Autocomplete, Box, Fade, FormControl, IconButton,  Modal, Slider, TextField, Typography } from '@mui/material'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import React from 'react'
import defaultpic from '../../img/default.png'
import { updateGeneral } from '../../_services/General/general.update';
 import { formSnackbar } from '../../_constants/snackbar.constant';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from 'react';
import { getLanguage } from '../../_services/Language/getLanguage.service';
 import { useDispatch, useSelector } from 'react-redux';
import { fetchUserDetail, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import SnackbarContainer from '../Snackbar';
import PhoneInput from 'react-phone-input-2';
import '../../style/style.css'
import CropIcon from '@mui/icons-material/Crop';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AvatarEditor from 'react-avatar-editor';
import Backdrop from '@mui/material/Backdrop';

function General(props) {
    const dispatch = useDispatch();
     const userData = useSelector((state) => state.userData.userData);
     const userDetail = useSelector((state) => state.userDetail.userDetail);
     const [contactNumber,setContactNumber] = useState("")
    const [contactNumberError,setContactNumberError]=useState(false)
    const [avatarFile, setAvatarFile] = useState()
    const [open, setOpen] = React.useState(false);
    const editorRef = React.useRef(null);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedFile, setSelectedFile] = useState(null);
    
    const [previewImage, setPreviewImage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [scale, setScale] = useState(1);
    const smScreen = useMediaQuery('(max-width:600px)');
    const xsSmScreen = useMediaQuery('(max-width:375px)');
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: smScreen?'100%':500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 3,
      };
    // const [selectedCountry, setSelectedCountry] = useState('IN');
    const [userThumbnai, setUserThumbnai] = useState("");
    const [languageList, setLanguageList] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const[languageError,setLanguageError]=useState(false)
    const [data, setData] = useState(
        {   
            studio_name:'',
            phone_number:'',
            website:'',
            language:'',
            country_code:'',
        }
      )
    const [errors, setErrors] = useState(
        {
        studio_name:false,
        phone_number:false,
        website:false,
        language:false,
        country_code:false
        }
    );
   
    useEffect(function () 
    {   
        userData.languages?setSelectedLanguages(userData.languages.map(item=>item)):setSelectedLanguages([])
        setContactNumber(userData.phone_number,)
        userData.studio_id && Object.keys(userData).length > 0 && setData({studio_name:userData.studio_name,
            phone_number:userData.phone_number,
            website:userData.website,
            country_code:'',})
        getLanguage().then(function (response) 
        {
           setLanguageList(response.data ? response.data.data : "")
           dispatch(setLoader(false))
        }).catch(function (error)
        {
            dispatch(setLoader(false))
            console.log(error);
            console.log(error.response);
            return error.response;
        })
    }, [userData.studio_id]
    )

      const handleInputChange = (event) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const URLregex = /^(ftp|http|https):\/\/[^ "]+$/;
        const studioRegex=/^[a-zA-Z]*$/;
        const pattern = /^(?:\+?\d{1,3})?[ -]?\(?(?:\d{3})\)?[ -]?\d{3}[ -]?\d{4}$/;
        const {name, value } = event.target;  
         setData({
         ...data,
         [name]: value
         });
         switch(event.target.name)
         {
           case 'studio_name':{
          
             if(!studioRegex.test(value))
             {
             setErrors({...errors,studio_name:'please enter characters'})
             }
             else
             {
                setErrors("")
             }
               setErrors({ ...errors, studio_name: value.length > 100 });
             break;
           }
           case 'website':{
            if (!URLregex.test(value)) {
                setErrors({...errors,website:"Please enter a valid URL"});
              }
              else{
                setErrors("")
              }
              setErrors({ ...errors, website: value.length > 50 });
             break;
           }
           case 'language': {
            if (value.length === 0) {
                setLanguageError(true);
            } else {
                setLanguageError(false);
            }
            break;
        }
        // case 'email': {
        //     if (!regex.test(value)) {
        //         setErrors({ ...errors, email: true });
        //     } else {
        //         setErrors({ ...errors, email: false });
        //     }
            
        //     break;
        // }
        //    case 'country_code':{
        //     setErrors({ ...errors, country_code: selectedCountry.id });
        //     break;
        //   }
           default:
             {
              break;
             }
         }
       }
       const phoneChangeHandler=(phone,event)=>{
        setContactNumber(phone)
        phone.length>11 && setContactNumberError(false)
        }
        const handleLanguageChange = (event, value) => 
        {   
            setSelectedLanguages(value)
            setLanguageError(false)
        }
      function saveSubmit(e) {
     
        data.userId = userData.studio_id;
        data.phone_number = contactNumber;
        if (selectedLanguages) 
        {   
            let languageIds = selectedLanguages.map(value=>value.id)
            data.languages = languageIds.join(',');
        }
        if (selectedLanguages.length === 0) {
            setLanguageError(true);
            return;
        }
        // if (data.email.trim() === '') {
        //     setErrors({ ...errors, email: true });
        //     return;
        // }
        dispatch(setSnackBar(true))
        dispatch(setSnackBarVariant('info'))
        dispatch(setSnackBarMessage(formSnackbar.PRE_PROFILE_UPDATE))
        dispatch(setLoader(true))
        let postData=data
        const formData = new FormData();
        for (const key in postData) {
            formData.append(key, postData[key]);
        }
        
        try{
            UpdateGeneral(userData.studio_id,postData)
            dispatch(setLoader(false))
            dispatch(setSnackBarVariant('success'))
            dispatch(setSnackBarMessage(formSnackbar.POST_PROFILE_UPDATE))
            setTimeout(() =>  dispatch(setSnackBar(false)), 2000)
            if(avatarFile !== undefined)
                saveAvatar();
        }
        catch(err)
        {
            dispatch(setLoader(false))
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
            setTimeout(() =>  dispatch(setSnackBar(false)), 3000)
            }   
        
      }
    function UpdateGeneral(id, postData) {
        dispatch(setSnackBar(true))
        dispatch(setSnackBarMessage(formSnackbar.PRE_PROFILE_UPDATE))
        dispatch(setSnackBarVariant("info"))
        updateGeneral(id, postData).then(function (response) {
            dispatch(setLoader(false))
            if(response.status === 200){
                setTimeout(()=>{dispatch(setSnackBar(false))}, 2000)
                dispatch(setSnackBarMessage(formSnackbar.POST_PROFILE_UPDATE))
                dispatch(setSnackBarVariant("success"))
                dispatch(fetchUserDetail(userData.studio_id))
            }
            else{
                dispatch(setSnackBarVariant('error'))
                dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
                setTimeout(()=>{dispatch(setSnackBar(false))}, 3000)
            }
           
        })
        .catch((error)=>{
            alert(error)
        })
    }
    

    // Function to convert data URL to File object


    const handleFileSelect = (e) => {

        const file = e.target.files[0];
      
        // Validation: Allowed file formats
        const allowedFormats = ['image/png', 'image/jpeg'];
        if (!allowedFormats.includes(file.type)) {
          // Show error message or handle invalid format
          dispatch(setSnackBar(true))
          dispatch(setSnackBarVariant('error'))
          dispatch(setSnackBarMessage('Invalid file format. Only PNG and JPEG files are allowed.'))
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
          return;
        }
      
        // Validation: Maximum file size
        const maxSize = 10 * 1024 * 1024; // 2MB
        if (file.size > maxSize) {
          // Show error message or handle oversized file
          dispatch(setSnackBar(true))
          dispatch(setSnackBarVariant('error'))
          dispatch(setSnackBarMessage('File size exceeds the maximum limit (10MB).'))
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
          return;
        }
      
        // Validation: Allowed resolution
        const allowedResolution = { width: 500, height: 500 };
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          // All validations passed, proceed with handling the file
          handleOpen(); // Open the modal
          setSelectedFile(file);
          setPreviewImage(URL.createObjectURL(file));

            // Reset the input element's value to allow selecting the same file again
            e.target.value = null;
        };
      };

    const handleCrop = () => {
    if (editorRef.current) {
        const canvas = editorRef.current.getImageScaledToCanvas();
        const croppedImageUrl = canvas.toDataURL();
        setCroppedImage(croppedImageUrl);
        setUserThumbnai(croppedImageUrl)
        canvas.toBlob((blob) => {
                setAvatarFile(blob)
          }, 'image/jpeg');
    }
    handleClose() //close the modal
    };
    const handleScaleChange = (e) => {
        const scaleValue = parseFloat(e.target.value);
        setScale(scaleValue);
      };
    
    function getOriginalImageName(blobFile) {
        const file = new File([blobFile], 'temp.jpg', { type: blobFile.type });
        return file.name;
      }

      const saveAvatar  = async function(){
        let postData = new FormData()
        postData.append("avatar", avatarFile,getOriginalImageName(avatarFile))
        if (avatarFile) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('info'))
            dispatch(setSnackBarMessage(formSnackbar.PRE_PROFILE_UPDATE))
            //To handle the image change with the update profile//Two services called dependent of each other
            try{
                UpdateGeneral(userData.studio_id,postData)
                dispatch(setSnackBarMessage(formSnackbar.POST_PROFILE_UPDATE))
                dispatch(setSnackBarVariant('success'))
                setTimeout(() => dispatch(setSnackBar(false)), 3000);
                dispatch(setLoader(false))
                setAvatarFile(undefined)
            }
            catch(err)
            {
                dispatch(setLoader(false))
                dispatch(setSnackBarMessage(formSnackbar.errorMsg))
                dispatch(setSnackBarVariant('error'))
                setTimeout(() => dispatch(setSnackBar(false)), 2000);
                setAvatarFile(undefined)
                }   
        }
    }
    const filteredLanguage = languageList.filter(
        (option)=> !selectedLanguages.includes(option.language)
    )
     // Function to determine if an option is disabled (already selected)
  const getOptionDisabled = (option) => {
    return selectedLanguages.some((selectedOption) => selectedOption.id === option.id);
  };


  return (
    <div> 
        <h4 className='mb-4'>General</h4>
                <p className='text-secondary'> Please some basic information about yourself.</p>
                <div className='pt-3 row px-2'>
                {/* Image:start */}
                <div className='d-flex'>
                <div className="avatar-upload" style={{maxWidth:'150px'}} title="Upload your image (Resolution: 190px*190px)">
                    <div className="avatar-edit">
                            <input type='file' onChange={handleFileSelect} id="imageUpload" accept=".png, .jpg, .jpeg" />
                                <label htmlFor="imageUpload">
                                    <PhotoCameraIcon />
                                </label>
                        </div>
                        <div className="avatar-preview">
                            <div id="imagePreview" /* style="background-image: url('http://i.pravatar.cc/500?img=7');" */>
                               <img src={userThumbnai ? userThumbnai : userData === "" ? defaultpic : process.env.REACT_APP_BASEURL_MEDIA+ '/media/' + userData.avatar}  className="dropImage" onError={(e) => { e.target.src = defaultpic }} />

                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-secondary'>
                    <span style={{fontSize:'0.8rem'}}>Allowed file formats are PNG and JPEG, and the maximum file size is 10MB.</span>
                </div>

                <div>
                            <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={open}
                            onClose={handleClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                onClick: null, // Disable close on background click
                            }}
                            >
                            <Fade in={open}>
                            <Box sx={style}>
                                <div className='d-flex'>
                                    <div>
                                        <Typography id="transition-modal-title" variant="h6" component="h2">Crop and Save Image</Typography>
                                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                            Adjust the crop area to select the desired portion of the image. Click the 'Save' button to save the cropped image.
                                        </Typography>
                                    </div>
                                    <div>
                                    <IconButton onClick={handleClose} aria-label="Close" color="secondary">
                                        <CloseIcon />
                                    </IconButton>
                                    </div>
                                </div>
                                   
                                {previewImage && (
                                        <div>
                                        <div className="m-2" style={{width:'100%',maxWidth: '100%',backgroundColor:'transparent',border:'solid 2px', borderRadius:'8px', borderStyle:'dashed', textAlign:'center'}}>  
                                            <AvatarEditor
                                                ref={editorRef}
                                                image={previewImage}
                                                width={xsSmScreen?190:230}
                                                height={xsSmScreen?190:230}
                                                border={50}
                                                color={[255, 255, 255, 0.6]}
                                                scale={scale}
                                                borderRadius={115}
                                                rotate={0}
                                                style={{touchAction: "manipulation"}}
                                            />
                                        </div>  
                                        <div>
                                        <div className='d-flex align-items-center'>
                                            <div  style={{width:'100px'}}>
                                                <Typography variant="body1"><ZoomInIcon /> Zoom In</Typography>
                                            </div>
                                            <div style={{width:'10px'}}></div>
                                            <div style={{flexGrow:'1',maxWidth:'12rem',marginTop: '5px'}}>
                                                <Slider
                                                min={1}
                                                max={2}
                                                step={0.1}
                                                value={scale}
                                                onChange={handleScaleChange}
                                                /> 
                                            </div>
                                        </div>
                                            <button className='gn-actionbtn' onClick={handleCrop} ><CropIcon /> Crop</button>
                                        </div>
                                        </div>
                                    )}
                            </Box>
                            </Fade>
                        </Modal>
                    </div>

                {/* Image:end */}
                    <div className='mt-3'>
                        <FormControl
                                name='studio_name'
                                noValidate
                                autoComplete="off"
                                fullWidth                      
                        >
                            <TextField
                                value={data.studio_name}
                                onChange={handleInputChange}
                                variant="outlined"  
                                label="Studio Name"
                                error={errors.studio_name}
                                helperText={errors.studio_name ? "please enter character"/*formSnackbar.errors.characterCountErrorlow*/ : ""}
                                name='studio_name'
                                size="small" 
                                type="text"   
                                required               
                            />
                        </FormControl>
                    </div>
                    <div className='mt-4'>
                        <PhoneInput
                            country={'in'}
                            value={contactNumber}
                            name="phone_number"
                            placeholder=""
                            onChange={phoneChangeHandler}
                            specialLabel="Phone No"
                        />
                        {contactNumberError && (
                        <Typography color="error" variant="caption" display="block" gutterBottom>Please add a contact number</Typography>
                        )}
                    </div>
                    <div className='mt-3'>
                       
                        
                <FormControl fullWidth size='small'>
                           <Autocomplete
                            multiple
                            id="language-field"
                            size="small"
                            options={filteredLanguage}
                            getOptionLabel={option=>option.language}
                            onChange={handleLanguageChange}
                            value={selectedLanguages}
                            getOptionDisabled={getOptionDisabled} 
                            renderInput={(params) => (
                            <TextField {...params} error={languageError && (
                                <Typography color="error" variant="caption" display="block" gutterBottom>
                                    Please select a language.
                                </Typography>
                            )} label="Language*"  helperText={languageError?'Please select a langauge.':''} placeholder="Language" />
                            )}
                        />
                </FormControl>
                </div>
                    <div className='mt-3'>
                        <FormControl
                                name='email'
                                noValidate
                                autoComplete="off"
                                fullWidth                    
                        >
                            <TextField
                                value={userData.email}
                                variant="outlined"  
                                defaultValue={' '}
                                label="Email"
                                name='email'
                                size="small" 
                                type="text"
                                disabled                     
                            />
                        </FormControl>
                    </div>
                    <div className='mt-3'>
                        <FormControl
                                name='website'
                                noValidate
                                autoComplete="off"
                                fullWidth                    
                        >
                            <TextField
                                value={data.website}
                                onChange={handleInputChange}
                                helperText={errors.website? 'Please enter a valid URL' : ""}
                                error={errors.website}
                                variant="outlined"  
                                label="Website"
                                name='website'
                                size="small" 
                                type="text"                   
                            />
                        </FormControl>
                    </div>
                    <div className="form-group col-md-12  mt-3 mb-3 " style={{display:'flex',justifyContent:'flex-end'}}>
                          <button  className='gn-actionbtn' onClick={saveSubmit}>Save</button>
                    </div> 
                </div>
         {/*  to show and hide the snackbar messages */}
    <SnackbarContainer />
    </div>  
  )
}

export default General