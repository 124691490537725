import { TablePagination, useMediaQuery } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useEffect, useMemo, useState } from 'react';
import Search from '../filters/SearchFilter';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import CardView from './usersCardView';
import { userRoleService } from '../../_services/UserRoles';
import { fetchLoginUser, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { useDispatch, useSelector } from 'react-redux';
import { formSnackbar } from '../../_constants/snackbar.constant';
import Loader from '../../components/utils/Loader';
import NoResults from '../../components/reusableComponents/NoResults'
import SnackbarContainer from '../Snackbar'
import { Add } from '@mui/icons-material';
import ResponsiveCardList from '../../components/reusableComponents/songResponsiveList';
import { default_limit } from '../../_constants/recordLimitSet.constant';
import { record_limits } from '../../_constants/recordLimitSet.constant';
import SearchBox from '../../components/reusableComponents/SearchBox';

function UserRole() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const userDetail = useSelector((state) => state.userDetail.userDetail);
  const [searchValue, setSearchValue] = useState('');
  // const [loading, setLoading] = useState(true);
  const [cardData, setCardData] = useState('');
  let [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const mediumScreen = useMediaQuery('(max-width:1280px)');
  const smallScreen = useMediaQuery('(max-width: 600px)');
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState()
  const isNextButtonDisabled = cardData.length < rowsPerPage || (page + 1) >= Math.ceil(totalCount / rowsPerPage);
  const isPreviousButtonDisabled = page === 0;
  useEffect(() => {
    if (userData.studio_id) {
      getAllUsersList(userData.studio_id, page);
    }
  }, [userData.studio_id, rowsPerPage, page])

  useEffect(() => {
    if (userData.studio_id) {
      if (searchValue && searchValue.length >= 1) {
        quickSearchApiHandler(searchValue);
      } else {
        getAllUsersList(userData.studio_id, page);
      }
    }
  }, [userData.studio_id, searchValue])


  const quickSearchApiHandler = async (quickSearch) => {
    if (quickSearch && quickSearch.length !== '') {
      try {
        dispatch(setLoader(true))

        const postData = {
          associated_studio: userData.studio_id,
          search: quickSearch,
        };

        const fixedLimit = default_limit;
        const fixedOffset = 0;
        setRowsPerPage(default_limit);
        setPage(0);

        const response = await userRoleService(postData, 'SEARCH', fixedLimit, fixedOffset);

        if (response.status === 200) {
          setCardData(response.data.results);
          setTotalCount(response.data.total_count);
        } else {
          dispatch(setSnackBar(true));
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
          dispatch(setSnackBarVariant('error'));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        }
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setLoader(false))
      }
    }
  };
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
    getAllUsersList(userData.studio_id, newPage, newRowsPerPage);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getAllUsersList = async (userId, page) => {
    dispatch(setLoader(true))
    const offset = page;
    const limit = rowsPerPage;
    let data = {}
    data['userId'] = userData.studio_id;
    await userRoleService(data, 'GET', limit, offset).then((response) => {
      if (response.status === 200) {
        setCardData(response.data.results);
        setTotalCount(response.data.total_count)
        setPage(page);
      } else {
        dispatch(setSnackBarVariant('error'));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        setTimeout(() => dispatch(setSnackBar(false)), 2000);
      }
      dispatch(setLoader(false));
    })
      .catch((error) => {
        console.log('Error', error);
        dispatch(setLoader(false));
        dispatch(setSnackBarVariant('error'));
        dispatch(setSnackBarMessage('Error Message'));
        dispatch(setSnackBar(true));
        setTimeout(() => dispatch(setSnackBar(false)), 2000);
      });
  }
  // const handleSearchChange = (value) => {

  //      setSearchValue(value);
  // };
  //to handle the search data to perform search
  const handleSearch = (userDataObj) => {
    let filteredData = [];
    if (userDataObj && userDataObj.length > 0) {
      filteredData = userDataObj;
    }
    else {
      filteredData = '';
    }
    copyHandleLayoutRerender(filteredData);
  };
  function handleClick() {
    navigate("/addUser");

  }
  //to render the component on search
  const copyHandleLayoutRerender = (userData) => {
    setCardData(userData);
  }
  const handleSearchInput = (value) => {
    setSearchValue(value)
  };
  return (
    <div >
      <h2 >User Management</h2>
      <div className={`d-flex justify-content-between align-items-center`}>
        <div className="flex-grow-1" style={{ paddingRight: '1.5rem', maxWidth: '18rem' }}>
          <SearchBox handleSearchInput={handleSearchInput} />
          {/* <Search  onChange={(value) => setSearchValue(value)} /> */}

        </div>
        <div className='d-flex align-items-center gap-3'>
          <button className='gn-actionbtn' onClick={handleClick} style={{ margin: '0.5rem 0' }}><Add />Create Users</button>
        </div>
      </div>
      <Grid2 container spacing={0} direction={!smallScreen ? 'row' : 'column'} alignItems="center" justify="center" className={`d-flex align-items-center  ${smallScreen ? 'w-100 mx-0' : 'mx-1'} `} sx={{ gap: 4, }}>
        {cardData && cardData.length > 0 ? (!smallScreen ? (
          cardData.map((card) => (
            <Grid2 container spacing={2} item xl={6} md={6} sm={12} xs={12} style={{ maxWidth: '16rem', marginRight: '2.1rem', paddingTop: '20px' }}>
              <CardView data={card} key={card.studio_id} />
            </Grid2>
          ))
        ) : (
          <Grid2 item xs={12}>
            {cardData.map((card) => (
              <div key={card.studio_id}>
                <ResponsiveCardList cardData={card} moduleName="userList" key={card.studio_id} />
              </div>
            ))}
          </Grid2>
        )) : (
          <NoResults />
        )}
      </Grid2>
      <TablePagination
        rowsPerPageOptions={record_limits}
        colSpan={3}
        sx={{
          width: '100%',
          padding: '32px 0px 32px 32px',
          fontSize: 'inherit',
          overflowX: 'hidden',
          marginLeft: 'inherit',
        }}
        className="cardPagination"
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={"Cards per page"}
        SelectProps={{
          inputProps: {
            'aria-label': 'cards per page',
          },
          native: true,
        }}
        nextIconButtonProps={{ disabled: isNextButtonDisabled }}
      />
      {/*  to show and hide the snackbar messages */}
      <SnackbarContainer />
    </div>
  )
}

export default UserRole;
