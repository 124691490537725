import * as React from 'react';
import Box from '@mui/material/Box';
import { Avatar, Button, Card, Divider, FormControlLabel, Grid, Link, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import PayoutDetails from '../modals/PayoutDetails';
import TableComponent from '../Tablecomponent';
import payoutmethod from '../../../img/payoutmethod.svg'
import currentbalance from '../../../img/currentbalance.svg'
import BankIcon from "../../../img/bank-building.svg"
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useNavigate } from 'react-router-dom';
import rupee from '../../../img/rupee.svg'
import rupee1 from '../../../img/rupee1.svg'

export default function Payout() {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const rows = [];

    const columns = [
        { id: 'payout', numeric: false, field: "payout", disablePadding: true, label: 'Payout', },
        { id: 'requestedon', numeric: true, field: "requestedon", disablePadding: false, label: 'Requested On', },
        { id: 'payouttype', numeric: true, field: "payouttype", disablePadding: false, label: 'Payout Type', },
        { id: 'status', numeric: true, field: "status", disablePadding: false, label: 'Status', },
        { id: 'action', numeric: true, field: "action", disablePadding: false, label: 'Action', }
    ];

    const color = {
        color: 'rgba(76, 78, 100, 0.87)'
    }

    const openViewHandlerDetail = () => {
        navigate('/viewdetails')
    }

    return (
        <Box>
            {/* <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: '500' }}> Payout </Typography> */}
            <h2>Payout</h2>
            <Grid container spacing={2} mt={1}>

                <Grid item xs={12} md={6}>
                    <Box className="bg-white shadow-sm rounded" minHeight="230px">
                        <Box className='px-3 py-3'><Typography style={color} variant='h6' >{'Payouts'}</Typography ></Box>
                        <Divider />
                        <Box className="d-flex">
                            <Box className='align-items-center justify-content-evenly w-50 d-flex flex-column'>
                                <Box className=" d-flex align-items-center justify-content-center gap-2">
                                    <img src={currentbalance} alt='currentbalance' />
                                    <Box>
                                        <Typography style={color} variant='h4' ><CurrencyRupeeIcon fontSize={'large'} />{'00'}</Typography >
                                        <Typography style={{ ...color, fontSize: 14 }} variant='span' >{'Your Current Balance'}</Typography >
                                    </Box>
                                </Box>
                                <Button disabled className='text-white rounded-pill px-4' sx={{ backgroundColor: 'rgba(76, 78, 100, 0.68)' }} >Withdraw</Button>
                            </Box>
                            <Divider sx={{ height: '165px !important' }} orientation="vertical" flexItem />
                            <Box className="w-50 d-flex flex-column justify-content-center">
                                <Box className="d-flex align-items-center gap-2 pb-3 ps-3">
                                    <img src={rupee} alt='rupee' />
                                    <Box>
                                        <Typography style={color} variant='h6' ><CurrencyRupeeIcon fontSize={'small'} />{'00'}</Typography >
                                        <Typography style={{ ...color, fontSize: 14 }} variant='span' >{'Available Balance'}</Typography >
                                    </Box>
                                </Box>
                                <Divider />
                                <Box className="d-flex align-items-center gap-2 pt-2 ps-3 ">
                                    <img src={rupee1} alt='rupee' />
                                    <Box>
                                        <Typography style={color} variant='h6' ><CurrencyRupeeIcon fontSize={'small'} />{'00'}</Typography >
                                        <Typography style={{ ...color, fontSize: 14 }} variant='span' >{'Pending'}</Typography >
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>

                    <Box className="bg-white shadow-sm rounded " minHeight="230px">
                        <Box className='d-flex justify-content-between align-items-center px-3 py-3'>
                            <Typography style={color} variant='h6' >{'Payout Methods'}</Typography >
                            <Box className='d-flex gap-2'>
                                <Button sx={{ backgroundColor: '#E41116 !important' }} onClick={openViewHandlerDetail} className='gn-actionbtn px-2 py-1 shadow-none text-white fw-normal'>View Banks</Button>
                                {/* <Button sx={{ backgroundColor: '#E41116' }} onClick={openHandlerDetail} className='gn-actionbtn text-white px-2 py-1 shadow-sm'><CurrencyRupeeIcon fontSize={'small'} />Payout</Button> */}
                            </Box>
                        </Box>
                        <Divider />
                        <Box className="d-flex">
                            <Box className='w-75'>
                                <List >
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ height: 55, width: 55 }} className='bg-light border'>
                                                <img className='w-50' src={BankIcon} alt='bankIcon' />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItem className='p-0  ps-2 ' alignItems="flex-start">
                                            <ListItemText
                                                className='fs-6 fw-bold'
                                                primary={<Typography variant='span'>Bank Transfer
                                                </Typography>}
                                            />

                                        </ListItem>
                                    </ListItem>
                                </List>
                            </Box>
                            <Box className='w-25'>
                                <img src={payoutmethod} className='w-100' alt='payoutmethod' />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Box className="bg-white shadow-lg rounded mt-3 py-4 mb-5">
                <Typography style={{ ...color, paddingBottom: 10, paddingLeft: 10 }} variant='h6' >{'Payout History'}</Typography >
                <PayoutDetails open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} />
                <TableComponent columns={columns} data={rows} hideTotal={true} />
            </Box>
        </Box >
    );
}