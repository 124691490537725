import { FormControl, TextField, useMediaQuery, Select, MenuItem, Button, Box, Grid, useTheme, Typography, InputLabel, OutlinedInput, FormHelperText, Modal, Fade, IconButton, Slider } from '@mui/material'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import addPoster from '../../img/addPoster.svg'
import defaultpic from '../../img/default.png'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_constants/snackbar.constant';
import { userRoleService } from '../../_services/UserRoles/index';
import Loader from '../../components/utils/Loader';
import { RegisterStudio } from '../../_services/Register/RegisterStudio';
import SnackbarContainer from '../Snackbar'
import AvatarEditor from 'react-avatar-editor';
import CropIcon from '@mui/icons-material/Crop';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Backdrop from '@mui/material/Backdrop';

const AddUserForm = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const userDetail = useSelector((state) => state.userDetail.userDetail);
  const mediumViewport = useMediaQuery('(max-width:720px)');
  const [userThumbnai, setUserThumbnai] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setMobileNumber] = useState("");
  const [userRoleData, setUserRoleData] = useState([]);
  const [userRole, setUserRole] = useState("");
  const history = useNavigate()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [nameError, setNameError] = useState(""); // Added state for name error
  const [emailError, setEmailError] = useState(""); // Added state for email error
  const [phoneError, setPhoneError] = useState(""); // Added state for phone number error
  const [userRoleError, setUserRoleError] = useState(""); // Added state for user role error
  const smScreen = useMediaQuery('(max-width:600px)');
  const xsSmScreen = useMediaQuery('(max-width:375px)');
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: smScreen ? '100%' : 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const editorRef = React.useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [scale, setScale] = useState(1);

  useEffect(() => {
    userRoleService('', 'USRROLES').then((response) => {
      if (response.status === 200) {
        setUserRoleData(response.data.data);
      }
      else {
        dispatch(setLoader(true));
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
        setTimeout(() => dispatch(setSnackBar(false)), 2000)
      }
    })
      .catch((error) => {
        console.log('Error', error);
        dispatch(setLoader(true));
        dispatch(setSnackBarVariant('error'));
        dispatch(setSnackBarMessage('Error Message'));
        dispatch(setSnackBar(true));
        setTimeout(() => dispatch(setSnackBar(false)), 2000);
      });
    console.log(userRoleData)
  }, [])

  const handleRouteBack = () => {

    history('/users')
  }

  const handleNameChange = (event) => {
    setName(event.target.value);
    setNameError(""); // Resetting name error on change
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(""); // Resetting email error on change
  };

  const handleMobileNumberChange = (event) => {
    setMobileNumber(event.target.value);
    setPhoneError(""); // Resetting phone number error on change
  };

  const handleUserRoleChange = (event) => {
    setUserRole(event.target.value);
    setUserRoleError(""); // Resetting user role error on change
  };
  const [profileAvatar, setProfileAvatar] = useState('')
  // function onChangeImageHandler(e) {
  //   e.preventDefault();
  //   var reader = new FileReader();
  //   reader.readAsDataURL(e.target.files[0]);
  //   setProfileAvatar(e.target.files[0])
  //   var avatar = e.target.files[0]
  //   var postData = new FormData()
  //   reader.onload = function (e) {
  //     setUserThumbnai(e.target.result);
  //   }
  // }
  const handleFileSelect = (e) => {
    const file = e.target.files[0];

    // Validation: Allowed file formats
    const allowedFormats = ['image/png', 'image/jpeg'];
    if (!allowedFormats.includes(file.type)) {
      // Show error message or handle invalid format
      dispatch(setSnackBar(true))
      dispatch(setSnackBarVariant('error'))
      dispatch(setSnackBarMessage('Invalid file format. Only PNG and JPEG files are allowed.'))
      setTimeout(() => dispatch(setSnackBar(false)), 3000);
      return;
    }

    // Validation: Maximum file size
    const maxSize = 10 * 1024 * 1024; // 2MB
    if (file.size > maxSize) {
      // Show error message or handle oversized file
      dispatch(setSnackBar(true))
      dispatch(setSnackBarVariant('error'))
      dispatch(setSnackBarMessage('File size exceeds the maximum limit (10MB).'))
      setTimeout(() => dispatch(setSnackBar(false)), 3000);
      return;
    }

    // Validation: Allowed resolution
    const allowedResolution = { width: 500, height: 500 };
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      /* const { width, height } = img;
      if (width !== allowedResolution.width || height !== allowedResolution.height) {
        // Show error message or handle invalid resolution
        dispatch(setSnackBar(true))
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage('Invalid image resolution. The image should be within 500*500 pixels.'))
        setTimeout(() => dispatch(setSnackBar(false)), 3000);
        return;
      } */

      // All validations passed, proceed with handling the file
      handleOpen(); // Open the modal
      setSelectedFile(file);
      setPreviewImage(URL.createObjectURL(file));

      // Reset the input element's value to allow selecting the same file again
      e.target.value = null;
    };
  };
  function getOriginalImageName(blobFile) {
    const file = new File([blobFile], 'temp.jpg', { type: blobFile.type });
    return file.name;
  }
  const handleNextClick = () => {

    let isValid = true;
    // Validating name field
    if (!name) {
      setNameError("Please enter a name");
      isValid = false;
    } else if ((!/^[A-Za-z\s]+$/.test(name))) {
      setNameError("Please enter a valid name");
      isValid = false;
    }
    // Validating email field
    if (!email) {
      setEmailError("Please enter an email");
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError("Please enter a valid email");
      isValid = false;
    }

    // Validating phone number field
    if (!phone_number) {
      setPhoneError("Please enter a phone number");
      isValid = false;
    } else if (!/^\d{10}$/.test(phone_number)) {
      setPhoneError("Please enter a valid phone number");
      isValid = false;
    }

    // Validating user role field
    if (!userRole) {
      setUserRoleError("Please select a user role");
      isValid = false;
    }

    if (isValid) {
      dispatch(setLoader(true))
      dispatch(setSnackBarVariant('info'))
      dispatch(setSnackBarMessage('Creating user with specified role...'))
      dispatch(setSnackBar(true))

      const formData = new FormData();
      let nameParts = name.split(" ");
      let firstName = nameParts[0];
      let lastName = nameParts[1];
      formData.append('first_name', firstName);
      formData.append('last_name', lastName);
      formData.append('name', name);
      formData.append('studio_name', '');
      formData.append('password', '123');
      formData.append('website', '');
      formData.append('email', email);
      formData.append('phone_number', phone_number);
      formData.append('role', userRole);
      formData.append('associated_studio', userData.studio_id);
      // formData.append('avatar', profileAvatar );
      if (profileAvatar !== null && profileAvatar !== undefined && profileAvatar !== '')
        formData.append("avatar", profileAvatar, getOriginalImageName(profileAvatar))
      if (firstName) {
        // userRoleService(formData, 'POST').then(function (response) {
        //   if(response.status === 200){
        //       dispatch(setSnackBarMessage('User added Successfully'))
        //       dispatch(setSnackBarVariant('success'))
        //       setTimeout(() => dispatch(setSnackBar(false)), 3000);
        //       dispatch(fetchUserDetail(userData.studio_id));
        //       history('/users');
        //   }
        //   else
        //   {
        //       dispatch(setSnackBarMessage('Unable to add user',response))
        //       dispatch(setSnackBarVariant('error'))
        //       setTimeout(() => dispatch(setSnackBar(false)), 3000);
        //   }
        // })
        RegisterStudio(formData).then(function (response) {
          if (response.status === 200) {
            if (response.data.email && response.data.email.length > 0) {
              dispatch(setSnackBar(true))
              dispatch(setSnackBarVariant('error'))
              dispatch(setSnackBarMessage(formSnackbar.errors.alreadyRegistered))
              setTimeout(() => dispatch(setSnackBar(false)), 2000);
            } else {
              dispatch(setSnackBar(true))
              dispatch(setSnackBarVariant('success'))
              dispatch(setSnackBarMessage('Creating user with specified role...'))
              setTimeout(() => dispatch(setSnackBar(false)), 2000);
              history('/users');
              setLoader(true);
            }
            setTimeout(() => dispatch(setLoader(false)), 2000);

          }
          else {
            let responseData = response.data;
            if (responseData.email && responseData.email.length > 0) {
              dispatch(setSnackBar(true))
              dispatch(setSnackBarVariant('error'))
              dispatch(setSnackBarMessage(formSnackbar.errors.alreadyRegistered))
              setTimeout(() => dispatch(setSnackBar(false)), 2000);

            }
            else {
              dispatch(setSnackBar(true))
              dispatch(setSnackBarVariant('error'))
              dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg,))
              setTimeout(() => dispatch(setSnackBar(false)), 2000);
            }
            setTimeout(() => dispatch(setLoader(false)), 2000);
          }
        })
          .catch(function (error) {
            alert(error)
          })
        setTimeout(() => setLoader(false), 2000);
      }
    }
  }
  const handleCrop = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      const croppedImageUrl = canvas.toDataURL();
      setCroppedImage(croppedImageUrl);
      setUserThumbnai(croppedImageUrl)
      canvas.toBlob((blob) => {
        setProfileAvatar(blob)
      }, 'image/jpeg');
    }
    handleClose() //close the modal
  };


  const handleScaleChange = (e) => {
    const scaleValue = parseFloat(e.target.value);
    setScale(scaleValue);
  };

  return (
    <div id="addsong-stepper-container">
      <div>
        <div >
          <button className="icononly-btn " onClick={handleRouteBack}><h5><ArrowBackIcon /> Back</h5></button>
        </div>
        <div className="card mt-2 border-0 mb-5">

          <div className="card-body d-flex flex-column border-0" style={{ padding: '0.5rem' }}>
            <div className="m-2" style={{ paddingLeft: isMobile ? '0rem' : '2rem' }}>
              <h4>Let's set up a user for the role</h4>
            </div>

            <div className='d-flex justify-content-center' style={{ paddingBottom: '2rem' }}>
              <Grid container item spacing={2} xs={11} className='card m-0' sx={{ justifyContent: 'center' }} >
                <Grid item xs={12} className='p-0'>
                  <Typography variant="h5" sx={{ fontSize: isMobile ? 18 : 24, padding: isMobile ? '1rem' : "1rem 3rem" }}>Add Profile Pic*</Typography>
                  <div className='d-flex' style={{ padding: isMobile ? '1rem' : "1rem 3rem" }}>
                    <div className="avatar-upload" title="Upload your image (Resolution: 190px*190px)">
                      <div className="avatar-edit">
                        <input type='file' onChange={handleFileSelect} id="imageUpload" accept=".png, .jpg, .jpeg" />
                        <label htmlFor="imageUpload">
                          <PhotoCameraIcon />
                        </label>
                      </div>
                      <div className="avatar-preview">
                        <div>
                          <img src={userThumbnai ? userThumbnai : userData.studio_id === "" ? defaultpic : process.env.REACT_APP_BASEURL + '/media/' + userDetail.avatar} alt="" className="dropImage" onError={(e) => { e.target.src = defaultpic }} />
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className='text-secondary' style={{ padding: mediumViewport ? '1rem' : '1rem 2rem' }}>
                    <span style={{ fontSize: '0.7rem', maxWidth: '100px' }}>Allowed file formats are PNG and JPEG, and the maximum file size is 10MB.</span>
                  </div>

                  <div>
                    {/*                     <input type="file" accept="image/*" onChange={handleFileSelect} />
                            <Button onClick={handleOpen}>Open modal</Button> */}
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        onClick: null, // Disable close on background click
                      }}
                    >
                      <Fade in={open}>
                        <Box sx={style}>
                          <div className='d-flex'>
                            <div>
                              <Typography id="transition-modal-title" variant="h6" component="h2">Crop and Save Image</Typography>
                              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                Adjust the crop area to select the desired portion of the image. Click the 'Save' button to save the cropped image.
                              </Typography>
                            </div>
                            <div>
                              <IconButton onClick={handleClose} aria-label="Close" color="secondary">
                                <CloseIcon />
                              </IconButton>
                            </div>
                          </div>
                          {/*  {previewImage && (
                                        <div>
                                        <h3>Image Preview</h3>
                                        <img src={previewImage} alt="Preview" />
                                        </div>
                                    )} */}
                          {previewImage && (
                            <div>
                              <div className="m-2" style={{ width: '100%', maxWidth: '100%', backgroundColor: 'transparent', border: 'solid 2px', borderRadius: '8px', borderStyle: 'dashed', textAlign: 'center' }}>
                                <AvatarEditor
                                  ref={editorRef}
                                  image={previewImage}
                                  width={xsSmScreen ? 190 : 230}
                                  height={xsSmScreen ? 190 : 230}
                                  border={50}
                                  color={[255, 255, 255, 0.6]}
                                  scale={scale}
                                  borderRadius={115}
                                  rotate={0}
                                  style={{ touchAction: "manipulation" }}
                                />
                              </div>
                              <div>
                                <div className='d-flex align-items-center'>
                                  <div style={{ width: '100px' }}>
                                    <Typography variant="body1"><ZoomInIcon /> Zoom In</Typography>
                                  </div>
                                  <div style={{ width: '10px' }}></div>
                                  <div style={{ flexGrow: '1', maxWidth: '12rem', marginTop: '5px' }}>
                                    <Slider
                                      min={1}
                                      max={2}
                                      step={0.1}
                                      value={scale}
                                      onChange={handleScaleChange}
                                    />
                                  </div>
                                </div>
                                {/*                                             <IconButton onClick={handleCrop} aria-label="Crop Image">
                                            <CropIcon />
                                            </IconButton> */}
                                <button className='gn-actionbtn' onClick={handleCrop} ><CropIcon /> Crop</button>
                              </div>
                            </div>
                          )}

                          {/* {croppedImage && (
                                    <div>
                                    <h3>Cropped Image</h3>
                                    <img src={croppedImage} alt="Cropped" />
                                    </div>
                                )} */}
                        </Box>
                      </Fade>
                    </Modal>
                  </div>
                </Grid>
                <Grid container item spacing={2} xs={11} className='m-0 p-0' sx={{ justifyContent: 'center', padding: '0.5rem !important' }} >
                  <Grid item xs={12} className='p-0' >
                    <Box mb={3}>
                      <TextField
                        id="name"
                        label="Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        value={name}
                        error={Boolean(nameError)}
                        helperText={nameError} // Displaying name error message
                        onChange={handleNameChange}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} className='p-0'>
                    <Box mb={3}>
                      <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        type="email"
                        value={email}
                        error={Boolean(emailError)}
                        helperText={emailError} // Displaying email error message
                        onChange={handleEmailChange}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} className='p-0'>
                    <Box mb={3}>
                      <TextField
                        id="mobileNumber"
                        label="Mobile Number"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        value={phone_number}
                        error={Boolean(phoneError)}
                        helperText={phoneError} // Displaying phone number error message
                        onChange={handleMobileNumberChange}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12} className='p-0'>
                    <Box mb={2}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel id="user-role-label" style={{ textAlign: 'center' }}>User Roles</InputLabel>
                        <Select
                          labelId="user-role-label"
                          id="user-role"
                          value={userRole}
                          size="small"
                          input={<OutlinedInput label="User Roles" />}
                          error={Boolean(userRoleError)}
                          onChange={handleUserRoleChange}
                        >
                          {userRoleData && userRoleData.map((role) => (
                            role.id === 1 ?
                              <MenuItem disabled value={role.id} key={role.id}>
                                {role.role_label}
                              </MenuItem> :
                              <MenuItem value={role.id} key={role.id}>
                                {role.role_label}
                              </MenuItem>
                          ))}
                        </Select>
                        {userRoleError && (
                          <FormHelperText error={true}>{userRoleError}</FormHelperText> // Displaying user role error message
                        )}
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} className='p-0' sx={{ textAlign: 'right' }}>
                    <Box mb={2}>
                      <button
                        onClick={handleNextClick}
                        className='gn-actionbtn'
                        style={{ minWidth: '100px' }}
                      >
                        Save
                      </button>
                    </Box>
                  </Grid>
                </Grid>

              </Grid>

            </div>
          </div>
        </div>
        {/*  to show and hide the snackbar messages */}
        <SnackbarContainer />
      </div>
    </div>
  )
}
export default AddUserForm