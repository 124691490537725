import axios from "axios";
import { subgenreURL } from '../_constants/api.constant'
import {  getSelfToken } from '../_helpers/auth/secureToken'
export async function subgenreRequest() {
    const response = await axios.get(
        subgenreURL.GET,
        {
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });
    return response;
}