import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Box, styled, useMediaQuery } from '@mui/material';
import AudioPlayer from '../../components/reusableComponents/customAudioPlayer';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter';
const arrowStyle = {
    fontWeight: 700,
};

const DemoDetail = ({ cardData }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const mdScreen = useMediaQuery('(max-width:768px)');
    const smScreen = useMediaQuery('(max-width:600px)');
    const widthValue = !mdScreen ? '50%' : '100%';
    const [demoInfo, setDemoInfo] = React.useState([])
    const [demoProgress, setDemoProgress] = React.useState([])
    const userData = useSelector(state => state.userData.userData)
    console.debug("demodetails: userdata: ",userData)
    useEffect(() => {
        if (location.state) {
            setDemoInfo(location.state)
        }
    }, [location])
    //to handle the back navigation
    const handleRouteBack = () => {
        navigate('/demoReceiver')
    }
    //to create the song entry from the released inside the song hub with the available credits

    return (
        <>
            {demoInfo && Object.keys(demoInfo).length > 0 &&
                <div className="container" style={{ padding: '1.2rem 1.2rem 3.5rem 1.2rem' }}>
                    <div>
                        <button className="icononly-btn" onClick={handleRouteBack}><ArrowBackIcon /> Back</button>
                    </div>
                    <div className='d-flex pb-3' style={{ flexWrap: mdScreen ? 'wrap' : 'nowrap' }}>
                        <Box
                            className='shadow-sm bg-white  border rounded-2 mt-4'
                            sx={{
                                width: widthValue,
                                height: 'calc(50% - 10px)',
                            }}
                        >
                            <div className='p-3'>
                                <div>
                                    <div className='d-flex align-items-center'>
                                        <span className="fs-6 text-secondary">General</span>
                                        <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                    </div>
                                    <div>
                                        <div className="value-pair color-secondory "><strong>Song Name: </strong>
                                            <span className='text-capitalize'>{demoInfo.demo_name}</span>
                                        </div>
                                        <div className="value-pair color-secondory "><strong>Genre: </strong>
                                            {demoInfo.genre.map((genreItem) => (
                                                <span className='text-capitalize' key={genreItem.id}>{genreItem.genre}, </span>
                                            ))}
                                        </div>
                                        <div className="value-pair color-secondory "><strong>Language: </strong>
                                            {demoInfo.language.map((languageItem) => (
                                                <span className='text-capitalize' key={languageItem.id}>{languageItem.language}, </span>
                                            ))}
                                        </div>
                                        {demoInfo.demo_file ?
                                            <div className="value-pair color-secondory ">
                                                {/* <strong>Audio: </strong> */}
                                                <AudioPlayer type='small'
                                                    audioSrc={demoInfo.demo_file ?
                                                        `${process.env.REACT_APP_BASEURL}gndemo/media/${demoInfo.demo_file}`
                                                        : ''}
                                                />
                                            </div> :
                                            <div>
                                                {demoInfo.video_link ?
                                                    <div className="value-pair color-secondory ">
                                                        <strong>Video Link: </strong>
                                                        <a href={demoInfo.video_link} target="_blank" rel="noopener noreferrer">
                                                            {demoInfo.video_link}
                                                        </a>
                                                    </div> :
                                                    <div className="value-pair color-secondory ">
                                                        <strong>External Link: </strong>
                                                        <a className='wordBreak' href={demoInfo.external_link} target="_blank" rel="noopener noreferrer">
                                                            {demoInfo.external_link}
                                                        </a>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Box>
                        <div style={{ paddingLeft: mdScreen ? '' : '2rem', width: '100%' }}>
                            <Box
                                className='shadow-sm bg-white  border rounded-2 w-100 mt-4 overflow-x-hidden'
                                sx={{
                                    maxHeight: 'calc(100vh - 200px)',
                                }}
                            >
                                <div className='p-3'>
                                    <div className='d-flex align-items-center'>
                                        <span className="fs-6 text-secondary">Addition Information</span>
                                        <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                    </div>
                                    <div className='d-flex justify-content-between flex-wrap'>
                                        <div className="value-pair color-secondory "><strong>Description: </strong>
                                            <p className='detailText'>
                                                {demoInfo.demo_description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </div>
                    </div>
                </div>
            }
        </>

    )
}

export default DemoDetail