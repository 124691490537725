import { Box, Container, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { getCreaterData } from '../../_services/Artist/artist.service'
import SongList from '../songList/SongList'
import Dashboard from './Dashboard'
import UserRole from '../userRole/userRole'
import AddSubmitSong from '../songList/AddSongForRelease'
import SongDetails from '../songList/SongDetails'
import AddUsers from '../userRole/AddUserForm'
import Account from '../account/Account'
import ArtistDetail from '../artistList/artistDetail'
import AssociateArtistIndex from '../AssociateArtist/AssociateArtistIndex'
import AddAssociateArtist from '../AssociateArtist/AddAssociateArtist';
import AssociateArtistDetails from '../AssociateArtist/AssociateArtistDetails'
import ArtistList from '../artistList/ArtistList'
import TestRoute from '../submitDemo/TestRoute'
import DemoIndex from '../submitDemo/DemoIndex'
import SnackbarContainer from '../Snackbar'
import ReceiverDemoList from '../recieverDemo/ReceiverDemoList'
import DemoDetail from '../recieverDemo/DemoDetails'
import Royalty from '../royalty/Royalty'
import Payout from '../royalty/payout/Payout'
import Analytics from '../Analytics/Analytics'
import AllAlbumSong from '../album/AllAlbumSong'
import AlbumDetail from '../album/AlbumDetail'
import Payoutdetails from '../royalty/payout/Payoutdetails'
import BankViewDetails from '../royalty/payout/BankViewDetails'
import CreateAlbum from '../album/CreateAlbum'
import PayForDistribution from '../album/PayForDistribution'
import GetPaymentDetail from '../album/getPaymentDetail'
import FAQs from '../Faqs/FAQs'
import SongPitchInformation from '../pitchSong/SongPitchInformation'
import SongpitchTable from '../pitchSong/SongpitchTable'
import SongpitchView from '../pitchSong/SongpitchView'


const DashboardPage = () => {
  
  const artistList = useMatch('/list');
  const artistDetails = useMatch('/artistDetail/:userhandle');
  const songList = useMatch('/releases');
  const associatedArtist = useMatch('/associatedartist'); 
  const editAssociatedArtist = useMatch('/associatedartist/:assoId');
  const associateArtistDetails =useMatch('/associateArtistDetails/:assoId');
  const dashbaord = useMatch('dashboard');
  const royalty = useMatch('royalty');
  const payout = useMatch('payout');
  const payoutdetails = useMatch('payoutdetails');
  const viewdetails = useMatch('viewdetails');
  const analytics = useMatch('analytics');
  const userRoles = useMatch('/users');
  const addUser = useMatch('addUser');
  const addReleaseSong = useMatch('/addreleasesong/:albumId');
  const viewReleaseSong = useMatch('/releases/:songId/:albumId');
  const editReleaseSong = useMatch('/addreleasesong/:songId/:albumId');
  const submitAsong = useMatch('/releases/:songId');
  const account =useMatch('/account');
  const submitDemo =useMatch('/submitdemo');
  const demoReceiver =useMatch('/demoReceiver');
  const demoDetails =useMatch('/demoReceiver/:demoId');
  const allAlbumSong =useMatch('/releasealbum');
  const albumDetail =useMatch('/albumdetail/:albumId');
  const createAlbum = useMatch('/addrelease/:albumId')
  const redirectPayment = useMatch('/payment')
  const paymentSuccess = useMatch("/addrelease/:albumId/:orderId")
  const failPayment = useMatch("/addrelease/:albumId/payment_fail")
  const faq = useMatch("FAQs")
  const songpitchCreate = useMatch("/songpitchCreate")
  const songpitchView = useMatch("/songpitchView/:songId")
  const songpitch = useMatch("/songpitch")

 
  return(
    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
    {dashbaord && <Dashboard />}
    {songList &&  <SongList type='song'/>}
    {artistList && <ArtistList type='artist' />}
    {artistDetails && <ArtistDetail />}
    {associatedArtist && <AssociateArtistIndex />}
    {editAssociatedArtist && <AddAssociateArtist />}
    {associateArtistDetails && <AssociateArtistDetails />}
    {userRoles && <UserRole />}
    {addUser && <AddUsers />}
    {account && <Account />}
    {addReleaseSong && <AddSubmitSong />}
    {editReleaseSong && <AddSubmitSong />}
    {submitAsong && <SongDetails />}
    {viewReleaseSong && <SongDetails/>}
    {demoReceiver && <ReceiverDemoList />}
    {demoDetails && <DemoDetail />}
    {royalty && <Royalty />}
    {payout && <Payout />}
    {payoutdetails && <Payoutdetails />}
    {analytics && <Analytics />}
    {viewdetails&& <BankViewDetails />}
    {allAlbumSong && <SongList />}
    {/* {allAlbumSong && <AllAlbumSong />} */}
    {albumDetail && <AlbumDetail />}
    {createAlbum && <CreateAlbum />}
    {redirectPayment && <PayForDistribution/>}
    {paymentSuccess && <CreateAlbum />}
    {failPayment && <CreateAlbum />}
    {faq && <FAQs />}
    {submitDemo && <DemoIndex />}
    {/* ------------- pitch navigation component ----------- */}
    {songpitchCreate && <SongPitchInformation />}
    {songpitchView && <SongpitchView /> }
    {songpitch && <SongpitchTable />}
    <SnackbarContainer />
   </Box>
  )
}

export default DashboardPage