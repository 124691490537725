import axios from "axios";
import { getCityApi } from '../../_constants/api.constant'
import { getSelfToken } from '../../_helpers/auth/secureToken'

export async function getCity() {

    const response = await axios.get(
        getCityApi.GET,
        {
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}