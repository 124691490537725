import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@mui/material';
import Search from './Search';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TableComponent from './Tablecomponent';
import { countryDynamic } from './TabConstData';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchRoyaltyDetail } from '../../redux';
import { useSelector } from 'react-redux';
import { formatIndianNumber, getMonthValue, flattenPartnerDetail } from '../../_services/formateRevenueData';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';

const Countries = ({ hideTotal }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const studioData = useSelector((state) => state.userData.userData);
    const singlecountryData = useSelector((state) => state.royaltyDataReducer.singlecountryData);
    const countryData = useSelector((state) => state.royaltyDataReducer.countryData);
    const location = useLocation();
    const isDetailsPage = location.pathname === '/details';
    const tabDynamic = location?.state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const total_count = countryData?.total_count
    const publisherId = studioData.studio_id;
    const partener_detail = { partner_type: 'studio', partner_id: publisherId, share_type: 'contract_partner' }

    const redirectDetails = (data) => {
        navigate('/details', { state: { data: countryDynamic, value: [data], pageCall: 'singleCountryData', name: data?.country, id: data?.country_id } });
    }

    useEffect(function () {
        const flattenedPartnerDetail = flattenPartnerDetail(partener_detail);
        if (isDetailsPage) {
            const monthName = tabDynamic?.name;
            let songId = tabDynamic?.id ? tabDynamic?.id : getMonthValue(monthName)
            dispatch(fetchRoyaltyDetail(tabDynamic.pageCall, { offset: page, limit: rowsPerPage, publisher_type: 'studio', publisher_id: publisherId, ...partener_detail  }, songId + '/country', `SINGLE_COUNTRY`))
        } else {
            if (studioData.studio_id) {
                dispatch(fetchRoyaltyDetail("countryData", { offset: page, limit: rowsPerPage,...partener_detail }, studioData.studio_id, `COUNTRY`))
            }
        }
    }, [studioData, dispatch, page, rowsPerPage]);

    const rows = (isDetailsPage ? singlecountryData?.counts_per_country : countryData?.total_data?.length ? countryData.total_data : []) || [];

    const columns = [
        {
            id: 'country', label: 'Country', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className="d-flex align-items-center gap-2">
                        {row.country_logo || row.country_logo == 'None' ? <Box sx={{ height: 35, width: 35 }}>
                            <img className='w-100 h-100 rounded-pill'
                                style={{ objectFit: 'cover' }}
                                src={process.env.REACT_APP_BASEURL + 'song' + row.country_logo} alt='country-image' />
                        </Box> : <PublicRoundedIcon sx={{ opacity: '0.5' }} />}
                        {row.country}
                    </Box>
                );
            }
        },
        { id: 'total_stream_count', field: "total_stream_count", numeric: true, label: 'Streams' },
        { id: 'release_download_count', field: "release_download_count", numeric: true, label: 'Release Downloads' },
        { id: 'track_download_count', field: "track_download_count", numeric: true, label: 'Track Downloads' },
        { id: 'video_download_count', field: "video_download_count", numeric: true, label: 'Video Downloads' },
        {
            id: 'total_stream_earning', label: 'Earnings', headerName: '', flex: 1, template: (row) => {
                const totalDownloads = row.total_stream_earning + row.total_release_download_earning + row.total_track_download_earning + row.total_video_download_earning;
                return (
                    <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(totalDownloads)}</Box>
                );
            }
        },
        {
            id: 'action', label: 'Action', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className='text-decoration-none viewDetailgradient cursorPointer' onClick={() => redirectDetails(row)} >View Details</Box>
                )
            }
        },
    ];
    const headFooter = [
        { id: 'name', numeric: false, label: 'Totals' },
        { id: 'streams', numeric: true, label: countryData.length != 0 ? formatIndianNumber(countryData.overall_total_streams) : 0 },
        { id: 'releasedownloads', numeric: true, label: countryData.length != 0 ? formatIndianNumber(countryData.overall_release_downloads) : 0 },
        { id: 'trackdownloads', numeric: true, label: countryData.length != 0 ? formatIndianNumber(countryData.overall_track_downloads) : 0 },
        { id: 'videodownloads', numeric: true, label: countryData.length != 0 ? formatIndianNumber(countryData.overall_video_downloads) : 0 },
        { id: 'earnings', numeric: true, label: <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(countryData.overall_release_downloads_earnings + countryData.overall_track_downloads_earnings + countryData.overall_streams_earning + countryData.overall_video_downloads_earnings)}</Box> },
        { id: 'action', numeric: false, label: '' }
    ];

    return (
        <Paper sx={{ width: '100%', mb: 2, mt: 1 }}>
            <Search headValue={'Countries'} />
            <TableComponent columns={columns} data={rows} headFooter={headFooter} hideTotal={hideTotal} total_count={total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
        </Paper>
    );
};

export default Countries;
