import {
  FETCH_PAYEEE_BANK_DATA_REQUEST,
  FETCH_PAYEEE_BANK_DATA_FAILURE,
  FETCH_PAYEEE_BANK_DATA_SUCCESS
} from "./payoutBankDataTypes";
const initialState = {
    loading: false,
    payeeBankData: [],
    // selectedQuestionList: null,
    error: '',
  }
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case   FETCH_PAYEEE_BANK_DATA_REQUEST:
        return {
          ...state,
          loading: true
        }
      case FETCH_PAYEEE_BANK_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          payeeBankData: action.payload,
          error: ''
        }
      case FETCH_PAYEEE_BANK_DATA_FAILURE:
        return {
          ...state,
          loading: false,
          payeeBankData: [],
          error: action.payload
        }
       
      default: return state
    }
  }
  
  export default reducer
  