import { Divider } from '@mui/material';
import React from 'react';
// import StudioHeader from '../../img/header/gnStudioLogo.png';
import StudioHeader from "../../img/header/gntunes-logo.svg";
import '../../style/customStyle.css';
import { useNavigate } from 'react-router-dom';

const LoginHeader = () => {
  const navigate = useNavigate();
  const redirectToHome = () =>{
    navigate('/');
  }
  return (
    <nav className="navbar navbar-light p-0">
        <div className="container-fluid header" style={{padding: '0.5rem 1rem'  }}>
            <a className="navbar-brand" href="#" onClick={redirectToHome}>
            <img src={StudioHeader} alt="logo" className="logo" />
            </a>
        </div>
    </nav>

  )
}

export default LoginHeader