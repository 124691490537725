import React from 'react';
import Box from '@mui/material/Box';
import Search from '../royalty/Search';
import TableComponent from '../royalty/Tablecomponent';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
const rows = [
    { id: 1, name: '', downloads: '', prevday:""},
];

const columns = [
    { id: 'name', field:'name', numeric: false, disablePadding: true, label: 'Track', },
    { id: 'downloads', field:'downloads', numeric: true, disablePadding: false, label: 'Downloads', },
    { id: 'prevday', field:'prevday', numeric: true, disablePadding: false, label: 'VS. PREVIOUS7 DAYS', }
];


export default function Downloads() {

    return (
        <Box sx={{ width: '100%' }}>
            <Search headValue={'Downloads'} />
            <TableComponent hideTotal={true}  columns={columns} data={rows} />
        </Box>
    );
}