import { useEffect, useMemo, useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import { SearchOutlined } from '@mui/icons-material';

const Search = ({ onChange }) => {
  const handleOnChangeDebounce = useMemo(() => {
    return debounce((searchValue) => {
      onChange(searchValue);
    }, 500);
  }, []);

 
  const handleInputChange = (value) => {
    const newValue = value;
    handleOnChangeDebounce(newValue);
  };
  return (
    <>
      <TextField 
          className='searchicon '
          id="standard-bare"
          variant="outlined"
          fullWidth
          size='small'
          input="true" type="text" placeholder="Search.."
          defaultValue=''
          onChange={(e)=>handleInputChange(e.target.value)}
          margin="normal"
          InputProps={{
          startAdornment: (
              <IconButton  edge="start"><SearchOutlined /></IconButton>),
          }}
          sx={{maxWidth:'20rem'}}
      />
    </>
  );
};

export default Search;
