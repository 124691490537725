import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import ApiService from '../../../_services/ApiService';
import BankIcon from "../../../img/bank-building.svg"
import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Divider, FormControlLabel, FormGroup, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Switch, Typography } from '@mui/material';
import NoResults from '../../../components/reusableComponents/NoResults';
import CardSekeleton from '../../../components/reusableComponents/SkeletonCard';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../../redux';
import { useDispatch } from 'react-redux';
import { formSnackbar } from '../../../_constants/snackbar.constant';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationModal from './ConfirmationModal';
const BankViewDetails = ({ }) => {
    const userData = useSelector((state) => state.userData.userData);
    const [bankDetail, setBankDetail] = useState([])
    const [bankId, setBankId] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const warningRef = useRef()
    const skeletonCards = Array.from({ length: 12 }, (_, index) => (
        <CardSekeleton key={index} />
    ));
    const loader = useSelector((state) => state.loader.loader);

    const messageConfirmation = {
        head: 'Are you sure you want to delete?',
        message: 'You can not undo this action.'
    }
    const getDetail = () => {
        dispatch(setLoader(true))
        ApiService.get('viewPayeeData', {}, userData.studio_id).then(res => {
            if (res.status == 200 || res.status == '201') {
                dispatch(setLoader(false))
                setBankDetail(res.data.results)
            }
        }).catch(function (error) {
            console.log(error);
            console.log(error.response);
            dispatch(setSnackBarVariant('error'));
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
        });
    }

    useEffect(() => {
        if (userData.studio_id) {
            getDetail()
        }

    }, [userData.studio_id])

    const backroute = () => {
        navigate('/payout')
    }

    const openHandlerDetail = () => {
        navigate('/payoutdetails')
    }

    const editBankDetail = (data) => {
        navigate('/payoutdetails', { state: { page: 'edit', data: data } })
    }

    const handleStatusChange = (event, data) => {
        const formData = new FormData();
        let isChecked = event.target.checked;
        formData.append("status", isChecked ? "True" : 'False')

        ApiService.put('updatePayeeData', data.id + '/', formData, null).then(res => {
            if (res.status === 200 || res.status === 201) {
                setBankDetail(bankDetail.map((statusIndex) => {
                    if (statusIndex.id === data.id) {
                        statusIndex.status = isChecked; // Set status based on the isChecked value
                    } else {
                        statusIndex.status = false; // Set status based on the isChecked value
                    }
                    return statusIndex;
                }));
            }

        }).catch(function (error) {
            console.log(error);
            console.log(error.response);
            dispatch(setSnackBarVariant('error'));
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));

        });
    }

    const warningHandler = () => {
        warningRef.current.handleClose();
        deleteDetail()
    }
    const warningHandlerOpen = (id) => {
        warningRef.current.handleOpen()
        setBankId(id)

    }
    const deleteDetail = () => {
        dispatch(setLoader(true))
        ApiService.delete('deletBankData', {}, bankId).then(res => {
            if (res.status == 200 || res.status == '201') {
                let results = bankDetail.filter(d => d.id !== bankId)
                setBankDetail(results)
                dispatch(setLoader(false))
            }
        }).catch(function (error) {
            console.log(error);
            console.log(error.response);

        });
    }
    return (
        <Box>
            <Box className='d-flex align-items-center pe-3 justify-content-between'>
                <Button className="w-75 justify-content-start gap-3 text-body" onClick={backroute} ><ArrowBackIcon />{'Bank Details'}</Button>
                <Button
                    sx={{ backgroundColor: '#E41116 !Important' }}
                    onClick={openHandlerDetail}
                    className='gn-actionbtn text-white px-2 py-1 shadow-sm'
                    disabled={bankDetail.length == 2 ? true : false}>Add Bank</Button>
            </Box>
            <Box>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item container xs={12} direction={'row'} className='gap-3' sx={{ margin: '0.5rem' }}>
                        {
                            loader ? (skeletonCards) :
                                bankDetail.length > 0 && !loader ? (bankDetail.map((bank, index) => (
                                    <Grid key={index} item xs={12} md={3}  >
                                        <Card>
                                            <List >
                                                <ListItem
                                                    secondaryAction={
                                                        <Box className='flex-column gap-4 d-flex'>
                                                            <IconButton className='' edge="end" aria-label="delete">
                                                                <DeleteIcon className='cursorPointer fs-5' onClick={() => warningHandlerOpen(bank.id)} />
                                                                {/* <EditIcon className='cursorPointer fs-5' onClick={() => editBankDetail(bank)} /> */}
                                                            </IconButton>
                                                            <IconButton className='' edge="end" aria-label="delete">
                                                                {/* <DeleteIcon className='cursorPointer fs-5' onClick={() => warningHandlerOpen(bank.id)} /> */}
                                                                <EditIcon className='cursorPointer fs-5' onClick={() => editBankDetail(bank)} />
                                                            </IconButton>

                                                        </Box>
                                                    }
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar sx={{ height: 55, width: 55 }} className='bg-light border'>
                                                            <img className='w-50' src={BankIcon} alt='bankIcon' />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        className='ms-2'
                                                        primary={<Typography variant='span'>Bank Transfer   <FormGroup sx={{ top: '45px', left: '25px' }} >
                                                            <FormControlLabel control={<Switch checked={(bank.status == "True" || bank.status) ? true : false} defaultChecked={(bank.status == "True" || bank.status) ? true : false} onChange={(event) => handleStatusChange(event, bank)} />} label={bank.status ? "Active" : ''} />
                                                        </FormGroup></Typography>}
                                                    />

                                                </ListItem>
                                            </List>
                                        </Card>
                                    </Grid>
                                ))) : (<NoResults />)}
                        <Grid md={12} xs={12}>
                            {bankDetail.length > 0 ? bankDetail.map((bank, index) => (
                                bank.status === 'True' || bank.status === true &&
                                < Card className='border rounded mb-5' >
                                    <Typography px={2} variant='h5' py={2}>Payee Information</Typography>
                                    <Divider />
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={4}>
                                            <List>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'Type'}
                                                        secondary={bank.type ? bank.type : '--'}
                                                    />
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'Phone Number'}
                                                        secondary={bank.phone_number ? bank.phone_number : '--'}
                                                    />
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'Email'}
                                                        secondary={bank.contact_email ? bank.contact_email : '--'}
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <List>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'Full Name'}
                                                        secondary={bank.first_name ? bank.first_name : '--'}
                                                    />
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'Company'}
                                                        secondary={bank.company ? bank.company : '--'}
                                                    />
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'ZIP'}
                                                        secondary={bank.zipcode ? bank.zipcode : '--'}
                                                    />
                                                </ListItem>

                                            </List>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <List>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'Address'}
                                                        secondary={bank.address ? bank.address : '--'}
                                                    />
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'City'}
                                                        secondary={bank.city ? bank.city : '--'}
                                                    />
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'Country'}
                                                        secondary={bank.country_name ? bank.country_name : '--'}
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    </Grid>
                                    <Typography px={2} variant='h5' py={2}>Bank Information</Typography>
                                    <Divider />
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={4}>
                                            <List>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'Name on Account'}
                                                        secondary={bank.account_holder_name ? bank.account_holder_name : '--'}
                                                    />
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'Account Number'}
                                                        secondary={bank.account_number ? bank.account_number : '--'}
                                                    />
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'IFSC Code'}
                                                        secondary={bank.ifsc_code ? bank.ifsc_code : '--'}
                                                    />
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'Bank Phone Number'}
                                                        secondary={bank.bank_phone_number ? bank.bank_phone_number : '--'}
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <List>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'Swift'}
                                                        secondary={bank.swift ? bank.swift : '--'}
                                                    />
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'Bank Name'}
                                                        secondary={bank.bank_name ? bank.bank_name : '--'}
                                                    />
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'Bank Address'}
                                                        secondary={bank.bank_address ? bank.bank_address : '--'}
                                                    />
                                                </ListItem>


                                            </List>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <List>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'Bank City'}
                                                        secondary={bank.bank_city ? bank.bank_city : '--'}
                                                    />
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'Bank Type'}
                                                        secondary={bank.account_type ? bank.account_type : '--'}
                                                    />
                                                </ListItem>
                                                <ListItem alignItems="flex-start">
                                                    <ListItemText
                                                        primary={'Branch Name'}
                                                        secondary={bank.branch_name ? bank.branch_name : '--'}
                                                    />
                                                </ListItem>

                                            </List>
                                        </Grid>
                                    </Grid>
                                </Card>
                            )) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <ConfirmationModal confirmHandler={warningHandler} ref={warningRef} messageConfirmation={messageConfirmation} />
        </Box >
    )
}

export default BankViewDetails