import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { assoicatedArtistURL } from '../../_constants/api.constant'
export async function associatedArtistsRequestHandler(postData,actiontype,limit,offset){
    switch(actiontype)
    {
        case 'POST':
            {
                const response=await axios.post(
                assoicatedArtistURL.POST,
                postData,
                {
                    // params:prarmTempObj,
                    headers:{
                        Authorization: 'Token '+getSelfToken(),
                    },
                }
                ).then(function(response) {
                return response;
                }).catch(function (error) {
                console.log(error);
                return error.response;
                });
                return response;
            }
        case 'UPDATE':
                {   
                    const artist_code = postData.get('artist_code')
                    const response=await axios. put(
                    assoicatedArtistURL.UPDATE+'/'+artist_code +'/',
                    postData,
                    {
                        // params:{id:postData.get('id')},
                        headers: {
                            Authorization: 'Token ' + getSelfToken(),
                        },
                    }
                    ).then(function(response) {
                    return response;
                    }).catch(function (error) {
                    console.log(error);
                    return error.response;
                    });
                    return response;
                }    
            case 'GET':
                {
                    const response = await axios.get(
                        assoicatedArtistURL.GET,
                        // assoicatedArtistURL.GET+'/'+postData!==null && postData.assoArtistId,

                        {    
                            params:{search:postData.userId,limit:limit,offset:offset},
                            headers: {
                                Authorization: 'Token ' + getSelfToken(),
                            },
                        }
                    ).then(function (response) {
                        return response;
                    }).catch(function (error) {
                        // handle error
                        console.log(error);
                        return error.response;
                    });
                    return response;
                }
                case 'SEARCH':
                {
                    const response = await axios.get(
                        assoicatedArtistURL.SEARCH,
                        {   params:{search:postData.searchData},
                            headers: {
                                Authorization: 'Token ' + getSelfToken(),
                            },
                        }
                    ).then(function (response) {
                        return response;
                    }).catch(function (error) {
                        // handle error
                        console.log(error);
                        return error.response;
                    });
                    return response;
                }    
                case 'SPECIFICGET':
                {
                    const response = await axios.get(
                        assoicatedArtistURL.SPECIFICGET+'/'+postData.artist_id+'/',
                        { 
                            headers: {
                                Authorization: 'Token ' + getSelfToken(),
                            },
                        }
                    ).then(function (response) {
                        return response;
                    }).catch(function (error) {
                        // handle error
                        console.log(error);
                        return error.response;
                    });
                    return response;
                }
                case 'DELETE':{
                    const response = await axios.delete(
                        assoicatedArtistURL.DELETE + "/" +postData.itemId + '/',
                        {
                            headers: {
                                Authorization: 'Token ' + getSelfToken(),
                            },
                        }
                    ).then(function (response) {
                        return response;
                    }).catch(function (error) {
                        // handle error
                        console.log(error);
                        return error.response;
                    });
                    return response;
                }
    default:{return}
    }
}