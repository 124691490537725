import { Grid, TablePagination, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import NoResults from '../../components/reusableComponents/NoResults';
import { record_limits } from '../../_constants/recordLimitSet.constant';
import ArtistCardLayout from '../../components/reusableComponents/ArtistCardLayout';

const ArtistCard = ({ data, fetchAssociatedCreatorsList, handleUnlink, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage, totalCount }) => {
  // const [totalCount, setTotalCount] = useState(totalCount?totalCount:0)
  const isNextButtonDisabled = data.length < rowsPerPage || (page + 1) >= Math.ceil(totalCount / rowsPerPage);
  const [associateList, setAssociateList] = useState(data)

  useEffect(() => {
    if (data) {
      setAssociateList(data);
    }
  }, [data]);

  return (
    <div>
      {associateList && associateList.length > 0 ?
        <div>
          <div className='wrapper-card'>
            {associateList.map((data, index) => (
              <div key={data.uid}>
                <ArtistCardLayout data={data} handleUnlink={handleUnlink} />
              </div>
            ))}
          </div>
          <TablePagination
            rowsPerPageOptions={record_limits}
            colSpan={3}
            sx={{ width: '100%', marginTop: '20px', marginBottom: '20px', padding: '32px 0px 32px 32px' }}
            className="cardPagination"
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            }}
            nextIconButtonProps={{ disabled: isNextButtonDisabled }}

          />
        </div>
        : (
          <NoResults />
        )}
    </div>
  );
};

export default ArtistCard;
