import React, { useState } from 'react';
import { CheckCircleOutline, Email, ErrorOutline } from '@mui/icons-material'
import { useHistory, useLocation, useNavigate } from 'react-router-dom';
import logo from "../img/header/gnStudioLogo.png"
import Footer from './footer';
import { Link } from 'react-router-dom';
import InputBase from '@mui/material/InputBase';
import { forgotPassword } from '../_services/forgotPassword';
import Box from "@mui/material/Box";
import { useDispatch } from 'react-redux'
import { setLoader } from '../redux';
import Modal from "@mui/material/Modal";
import { borderRadius } from '@mui/system';
import { Divider, Grid, Paper, TextField, ThemeProvider, createTheme } from '@mui/material';
import backgroundImage from '../img/background/bg_image_texture3.png'
import ForgotPassHeader from './header/AdminHeader';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
  p: 3
};

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          padding: '1rem',
          border: '1px solid rgba(0, 0, 0, 0.2);',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)',
          borderRadius: '6px',
        },
      },
    },
  },
});

function Forgot(props) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState();
  const [open, setOpen] = React.useState(false);
  const [errorResponse,setErrorResponse]= useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    navigate('/')
  }
  const navigate = useNavigate()

  async function onClickHandler(e) {
    e.preventDefault();
    dispatch(setLoader(true))
    let data = new FormData();
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      data.append('email', email);
      setEmailError("")
    } else {
      setEmailError(
        <label className="text-danger mt-1" style={{ marginLeft: '10px' }}>Please enter a valid Email</label>
      )
    }
    if (email.match(mailformat)) {
      dispatch(setLoader(true))
      forgotPassword(data).then(function (response) {
        if(response.status == 400){ 
        setErrorResponse(<h6 className='text-center'>This Email of {response.data.message}  <ErrorOutline color="error" sx={{width:'25px !important'}}  /></h6>)
        // console.debug("forgotPasswordforgotPassword", response)
        handleOpen()
        dispatch(setLoader(false))
        }else{
          setErrorResponse(<h6 className='text-center'>Password reset link has been sent to your registered email address. <CheckCircleOutline color="success" sx={{width:'25px !important'}} /></h6>)
        // console.debug("forgotPasswordforgotPassword", response)
        handleOpen()
        dispatch(setLoader(false))
        }
      }).catch(function (error) {
        dispatch(setLoader(false))
        console.log(error);
        console.log(error.response);
        return error.response;
      })
    }
  }

  return (
    <Box sx={{ flexGrow: 1, height: '100vh' }}>
      <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }} className="loginFormSection">
        <Grid item xs={12} sx={{ height: '10%' }}>
          <ForgotPassHeader />
          <Divider />
        </Grid>
        <Grid item container xs={12} justifyContent="center" sx={{ height: '90%', padding: '0 1rem' }}>
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <div style={{ textAlign: 'center', paddingBottom: '1rem', marginTop: { xs: '1rem', sm: '2rem' } }}>
              <h2 className="fw-bold mt-4" style={{ fontSize: '2.25rem' }}>Forgot password</h2>
            </div>
            <div className="" style={{ alignSelf: 'center', width: 'auto' }}>
              <div className="login-form mt-4 mb-4 border rounded shadow bg-white">
                <form action="" method="" className="row g-4 mx-4 py-4">
                  <h6 className="text-secondary fw-normal" style={{ fontSize: '1.125rem' }}>
                    Enter your registered email address, mobile number, or username to change your GNTunes account password.
                  </h6>
                  <div>
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      size='small'
                      className="w-100"
                      placeholder="Email"
                      onChange={e => { setEmail(e.target.value); setEmailError("") }}
                    />
                  </div>
                  {emailError}

                  <div>
                  </div>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className='text-secondary' >
                      {/* <h6>{Password reset link has been sent to your registered email address.}</h6> */}
                      {errorResponse}

                    </Box>
                  </Modal>

                  <div className="col-md-12">
                    <button type="submit" className="gn-actionbtn float-end w-40 py-2" onClick={onClickHandler}>SEND PASSWORD RESET LINK</button>
                  </div>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Forgot;
