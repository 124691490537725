import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import {updateAddressApi} from '../../_constants/api.constant'
export async function createAddress(postData){
    const response=await axios.post(
        updateAddressApi.INSERT,
       postData,
        {
            headers:{
                Authorization: 'Token '+getSelfToken(),
            },
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        // handle error
     

        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}