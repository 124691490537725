import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useMediaQuery from '@mui/material/useMediaQuery'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMusicCategoryDetail, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_constants/snackbar.constant';
import { submitSong } from '../../_services/submitAsong/submitAsong.service';
import { getGenre } from '../../_services/profile';
import { getLanguage } from '../../_services/Language';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import addAudio from '../../img/submitSong/addAudio.svg'
import addPoster from '../../img/submitSong/addPoster.svg'
import SnackbarContainer from '../Snackbar';
import imgPlaceHolder from '../../img/general/PlaceholderImg.webp'
import { contentTypeRequest } from '../../_services/submitAsong/contentType.service';
import DonutStepper from '../../components/reusableComponents/DonuteStepper';
import ConfirmModal from '../../components/reusableComponents/ConfirmModal';
import moment from 'moment-timezone';
import { moodRequest } from '../../_services/mood.service';
import { subgenreRequest } from '../../_services/subgenre.service';
import NewRelease from './release-song-steps/NewRelease';
import SongCredits from './release-song-steps/SongCredits';
import AddMedia from './release-song-steps/AddMedia';
import FinalReview from './release-song-steps/FinalReview';
import { associatedArtistsRequestHandler } from '../../_services/associatedArtists/associatedArtist.service';
import { artistCode } from './associatedArtist/artistStaticCodes';
import WarningModal from '../../components/reusableComponents/WarningModal';
import { uploadSongTrack } from '../../_services/submitAsong/uploadSongTrack.service';
import { removeSongTrack } from '../../_services/submitAsong/removeSongTrack.service';
import axios from 'axios';
import { getSingleSongDetails } from '../../_services/submitAsong/getSingleSong.service';
import { recordLabelRequest } from '../../_services/recordLabel/recordLabel.service';
import ReleaseInformation from './release-song-steps/ReleaseInformation';
import { handleInputChange, handleArtistData } from './helperComponent/helperComponent'
import ApiService from '../../_services/ApiService';
import { getSingleAlbum } from '../../_services/album/getSingleAlbum';
import { regexValidator } from '../../_helpers/reusablefunctions/regexValidator';
const stepperNo = ['1', '2', '3', '4']

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));
function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const numbers = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5
  };
  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? (
        <Check />
      ) : (
        <div>{numbers[String(props.icon)]}</div>
      )}
    </ColorlibStepIconRoot>

  );
}
const steps = ['Basic Information', 'Song Credits', 'Release Information', 'Final Review'];
const subHedingSteps = ['New Release', 'Song Credits', 'Audio file & Cover Image', 'Review your responses'];

export default function AddSubmitSong() {
  const userData = useSelector((state) => state.userData.userData);
  const { songId, albumId } = useParams(); // to get the song id from the url if user reapply
  const dispatch = useDispatch();
  const history = useNavigate()
  const ref = useRef();
  const warningRef = useRef()
  const smScreen = useMediaQuery('(max-width:768px)');
  const [nextBtnDisable, setNextBtnDisable] = useState(true)
  const [prevBtnDisable, setPrevBtnDisable] = useState(true)
  const [data, setData] = useState(
    {
      song_name: '',
      explicit_content: '',
      release_history: '',
      copyright_type: '',
      previous_release_date: '',
      content_types: '',
      genre: '',
      sub_genre: '',
      mood: '',
      instrumental: '',
      isrc_code: '',
      upc_code: '',
      primary_artists: '',
      featuring_artists: '',
      composers: '',
      lyricists: '',
      producers: '',
      record_labels: '',
      actors: '',
      language: '',
      song_description: '',
      release_date: '',
      release_time: '',
      crbt_cuts_duration_for_clip1: '',
      crbt_cuts_duration_for_clip2: '',
      song_lyrics: '',
      // cover_image: '',
      song_file: '',
      song_status: '',
      remixers: '',
      catalog: '',
      title_version: '',
      copyright_p: null,
      copyright_c: null,
    }
  );

  const [errors, setErrors] = useState({
    song_name: false,
    song_name_helpertxt: false,
    sub_genre: false,
    sub_genre_helpertxt: false,
    isrc_code: false,
    isrc_code_helperTxt: false,
    upc_code: false,
    upc_code_helperTxt: false,
    catalog: false,
    catalog_helperTxt: false,
    primary_artist: false,
    primary_artist_helperTxt: false,
    remixers: false,
    remixer_artist_helperTxt: false,
    featuring_artist: false,
    featuring_artist_helperTxt: false,
    composer: false,
    composer_helperTxt: false,
    lyricist: false,
    lyricist_helperTxt: false,
    producer: false,
    producer_helpertxt: false,
    record_label: false,
    record_label_helpertxt: false,
    actor: false,
    actor_helpertxt: false,
    song_lyrics: false,
    song_lyrics_helpertxt: false,
    song_description: false,
    song_description_helpertxt: false,
    copyrightp_name: false,
    copyrightp_name_helpertxt: false,
    copyrightc_name: false,
    copyrightc_name_helpertxt: false,
  });

  const [song_file, setSong_File] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedValue, setSelectedValue] = useState();
  const [releasehistory, setReleasehistory] = useState("False");
  const [copyrighttype, setCopyrighttype] = useState("False");
  const [contentTypeValue, setContentTypeValue] = useState();
  const [instruValue, setInstruValue] = useState('');
  const [song_status, setSong_Status] = useState('pending')
  // const [genre, setGenre] = useState([])
  const genre = useSelector((state) => state.musicCategorgyReducer?.genreData ? state.musicCategorgyReducer?.genreData.data : []);
  const subGenreList = useSelector((state) => state.musicCategorgyReducer?.subGenreData ? state.musicCategorgyReducer?.subGenreData.data : []);
  const language = useSelector((state) => state.musicCategorgyReducer?.languageData ? state.musicCategorgyReducer?.languageData.data : []);
  const recordLabelList = useSelector((state) => state.musicCategorgyReducer?.recordLabelData ? state.musicCategorgyReducer?.recordLabelData.data : []);
  const moodList = useSelector((state) => state.musicCategorgyReducer?.moodData ? state.musicCategorgyReducer?.moodData.data : []);

  const [releaseDate, setRelease_Date] = useState(null)
  const [prereleaseDate, setPreRelease_Date] = useState(null)
  const [releaseTime, setReleaseTime] = useState(null);
  const [callerTune1, setCallerTune1] = useState(null);
  const [callerTune2, setCallerTune2] = useState(null);
  const [stepName, setStepName] = useState(activeStep === 0 ? 'Basic Information' : '')
  const [contentTypelist, setContenTypeList] = useState()
  const [songDataBkp, setSongDataBkp] = useState();
  const [titleVersionList, setTitleVersionList] = useState([])
  const [copyrightList, setCopyrightList] = useState([])
  const [audioFile, setAudioFile] = useState(null);
  const fileInputRef = useRef(null)
  const [songPoster, setSongPoster] = useState(null);
  const [songImage, setSongImage] = useState("")
  const fileInputPosterRef = useRef(null)
  const [albumDetail, setAlbumDetail] = useState()
  //to handle associated artists selection and payload:start
  const [primaryArtList, setPrimaryArtList] = React.useState([])
  const [remixerArtList, setRemixerArtList] = React.useState([])
  const [featureArtList, setFeatureArtList] = React.useState([])
  const [lyricistList, setLyricistList] = React.useState([])
  const [composerList, setComposerList] = React.useState([])
  const [producerList, setProducerList] = React.useState([])
  const [actorList, setActorList] = React.useState([])
  const [assoArtistList, setAssoArtistList] = React.useState([])
  const [songid, setSongid] = React.useState(songId ? songId : null)
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [file, setFile] = useState(null);
  const [uploadedSize, setUploadedSize] = useState(null)
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const [songDetails, setSongDetails] = React.useState([]);
  const [copyrightData, setCopyrightData] = React.useState({ 'copyrightC': '', 'copyrightP': '' });
  const [copyrightp_name, setCopyrightp_name] = React.useState('');
  const [copyrightc_name, setCopyrightc_name] = React.useState('');
  const [copyrights_c, setCopyrights_c] = React.useState('');
  const [copyrights_p, setCopyrights_p] = React.useState('');
  const [albumName, setAlbumName] = useState('')

  const artistSelectChangeHandler = (name, artistData, isNewArtist) => {
    switch (name) {
      case artistCode.primary_artist:
        handleArtistData(name, primaryArtList, setPrimaryArtList, 'primary_artists', isNewArtist, artistData, artistCode, data, setData);
        break;
      case artistCode.feature_artist:
        handleArtistData(name, featureArtList, setFeatureArtList, 'featuring_artists', isNewArtist, artistData, artistCode, data, setData);
        break;
      case artistCode.lyricist:
        handleArtistData(name, lyricistList, setLyricistList, 'lyricists', isNewArtist, artistData, artistCode, data, setData);
        break;
      case artistCode.composer:
        handleArtistData(name, composerList, setComposerList, 'composers', isNewArtist, artistData, artistCode, data, setData);
        break;
      case artistCode.producer:
        handleArtistData(name, producerList, setProducerList, 'producers', isNewArtist, artistData, artistCode, data, setData);
        break;
      case artistCode.actor:
        handleArtistData(name, actorList, setActorList, 'actors', isNewArtist, artistData, artistCode, data, setData);
        break;
      case artistCode.remixers:
        handleArtistData(name, remixerArtList, setRemixerArtList, 'remixers', isNewArtist, artistData, artistCode, data, setData);
        break;
      default:
        break;
    }
  };

  const getAssociatedArtist = (name, artistData, isNewArtist) => {
    let postData = {}
    if (userData.role === 1) {
      postData['userId'] = userData.studio_id
    } else {
      if (userData.hasOwnProperty('associated_studio')) {
        postData['userId'] = userData.associated_studio
      }
    }
    associatedArtistsRequestHandler(postData, 'GET')
      .then(response => {
        //set the associated artist list
        setAssoArtistList(response.data.results)
        isNewArtist && artistSelectChangeHandler(name, artistData, isNewArtist) //call the handler when new artist has beed added
      })
      .catch(err => {
        dispatch(setSnackBar(true));
        setTimeout(() => dispatch(setSnackBar(false), 2000));
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      })
  }
  useEffect(() => {
    if (userData.studio_id) {
      getAssociatedArtist()
    }
  }, [userData.studio_id]) // to get the associated artist list  
  //to handle artists selection and payload:end

  //remove the added artist automatically when removed from the add artist wizard drawer:start4
  const assoArtistRemoveHandler = (artistTypecd, index, artistId) => {
    const listsMap = {
      [artistCode.primary_artist]: setPrimaryArtList,
      [artistCode.feature_artist]: setFeatureArtList,
      [artistCode.lyricist]: setLyricistList,
      [artistCode.composer]: setComposerList,
      [artistCode.producer]: setProducerList,
      [artistCode.actor]: setActorList,
      [artistCode.remixers]: setRemixerArtList,
    };

    const listToUpdate = listsMap[artistTypecd];
    if (listToUpdate) {
      const updatedList = [...listToUpdate];
      const itemIndex = updatedList.findIndex((artist) => artist.artist_id === artistId);
      if (itemIndex !== -1) {
        updatedList.splice(itemIndex, 1);
        listToUpdate(updatedList);
      }
    }
  };

  function getSongDetails(userId, song_id) {
    dispatch(setLoader(true))

    ApiService.get('specificSongInfo', { 'song_id': song_id }, userId).then(response => {
      if (response.status === 200) {
        setSongDetails(response.data)
        setArray(response.data)
      }
      else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
      }
      dispatch(setLoader(false))
    }).catch(function (error) {
      dispatch(setLoader(false))
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return error.response;
    })

  }


  //get the dynamic data
  useEffect(function () {
    // ---------------- title version api calling ---------
    ApiService.get('titleVersion').then(res => {
      let response = res.data.data
      if (response) {
        setTitleVersionList(response)
      }
    }).catch(function (error) {
      console.log(error);
      console.log(error.response);
      return error.response;
    })
    ApiService.get('copyrightList').then(res => {
      let response = res.data.data
      if (response) {
        setCopyrightList(response)
      }
    }).catch(function (error) {
      dispatch(setLoader(false))
      console.log(error);
      console.log(error.response);
      return error.response;
    })
    if (genre == undefined || genre.length == 0) {
      dispatch(fetchMusicCategoryDetail("genreData", `GENRE`))
    }

    if (subGenreList == undefined || subGenreList.length == 0) {
      dispatch(fetchMusicCategoryDetail("subGenreData", `SUBGENRE`))
    }
    if (language == undefined || language.length == 0) {
      dispatch(fetchMusicCategoryDetail('languageData', `LANGUAGE`))
    }
    if (recordLabelList == undefined || recordLabelList.length == 0) {
      dispatch(fetchMusicCategoryDetail('recordLabelData', `RECORDLABEL`))
    }
    if (moodList == undefined || moodList.length == 0) {
      dispatch(fetchMusicCategoryDetail('moodData', `MOOD`))
    }

    if (userData.studio_id) {
      getSingleAlbum({ 'userId': userData && userData.studio_id }, { "album_id": albumId })
        .then((response) => {
          if (response.data[0]) {
            setAlbumName(response.data[0].album_name)
            setSongImage(response.data[0].cover_image)
            setAlbumDetail(response.data[0])

          }
          // setMoodList(response.data.data)
        })
        .catch((error) => {
          dispatch(setLoader(false))
          dispatch(setSnackBar(true))
          dispatch(setSnackBarVariant('error'))
          dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
          setTimeout(() => dispatch(setSnackBar(false)), 2000)
          return error.response;
        })
    }
  }, [userData.studio_id]);


  /* Code handle the reapply scenario:start */
  useEffect(() => {
    window.sessionStorage.clear(); //clearing sesssion storage for associated artist retaining
  }, [])
  let songInfo;
  const setArray = (data) => {
    songInfo = data && data.length > 0 ? (data.find(song => song.song_id.toString() === songId.toString())) : null
    //match and set the data accordingly
    const selectedPrimaryArtist = assoArtistList && assoArtistList.filter(artist => songInfo.primary_artist_codes.find(item => item === artist.artist_code))
    setPrimaryArtList(selectedPrimaryArtist)
    songInfo.primary_artists = songInfo?.primary_artists?.length > 0 ? songInfo.primary_artists.map(value => value.artist_code).join(',') : songInfo.primary_artists;
    const selectedFeatureArtist = assoArtistList && assoArtistList.filter(artist => songInfo.featuring_artist_codes.find(item => item === artist.artist_code))
    setFeatureArtList(selectedFeatureArtist)
    songInfo.featuring_artists = songInfo.featuring_artists && songInfo.featuring_artists.length > 0 && songInfo.featuring_artists.map(value => value.artist_code).join(',')
    const selectedComposers = assoArtistList && assoArtistList.filter(artist => songInfo.composers_codes.find(item => item === artist.artist_code))
    setComposerList(selectedComposers)
    songInfo.composers = songInfo?.composers?.length > 0 ? songInfo.composers.map(value => value.artist_code).join(',') : songInfo.composers;
    const selectedLyrcists = assoArtistList && assoArtistList.filter(artist => songInfo.lyricists_codes.find(item => item === artist.artist_code))
    setLyricistList(selectedLyrcists)
    // songInfo.lyricists = songInfo.lyricists && songInfo.lyricists.length > 0 && songInfo.lyricists.map(value => value.artist_code).join(',')
    songInfo.lyricists = songInfo?.lyricists?.length > 0 ? songInfo.lyricists.map(value => value.artist_code).join(',') : songInfo.lyricists;
    const selectedProducers = assoArtistList && assoArtistList.filter(artist => songInfo.producers_codes.find(item => item === artist.artist_code))
    setProducerList(selectedProducers)
    songInfo.producers = songInfo.producers && songInfo.producers.length > 0 && songInfo.producers.map(value => value.artist_code).join(',')
    const selectedRemixer = assoArtistList && assoArtistList.filter(artist => songInfo.remixers.find(item => item.artist_code === artist.artist_code))
    setRemixerArtList(selectedRemixer)
    songInfo.remixers = songInfo.remixers && songInfo.remixers.length > 0 && songInfo.remixers.map(value => value.artist_code).join(',')
    const selectedActors = assoArtistList && assoArtistList.filter(artist => songInfo.actors_codes.find(item => item === artist.artist_code))
    setActorList(selectedActors)
    songInfo.actors = songInfo.actors && songInfo.actors.length > 0 && songInfo.actors.map(value => value.artist_code).join(',')
    // setContentTypeValue(songInfo.content_types)
    let explictValue = songInfo.explicit_content.toString()
    explictValue = explictValue.charAt(0).toUpperCase() + explictValue.slice(1)
    setSelectedValue(explictValue)
    let releaseHistory = songInfo.release_history.toString()
    releaseHistory = releaseHistory.charAt(0).toUpperCase() + releaseHistory.slice(1)
    setReleasehistory(releaseHistory)
    let copyrighttype = songInfo.copyright_type.toString()
    copyrighttype = copyrighttype.charAt(0).toUpperCase() + copyrighttype.slice(1)
    setCopyrighttype(copyrighttype)
    let instrumentValue = songInfo.instrumental.toString()
    instrumentValue = instrumentValue.charAt(0).toUpperCase() + instrumentValue.slice(1)
    setInstruValue(instrumentValue)
    if (songInfo.release_date != "") {
      setRelease_Date(songInfo.release_date)
    } else {
      setRelease_Date(null)
    }
    songInfo.previous_release_date = songInfo.previous_release_date
    if (songInfo.previous_release_date != "") {
      setPreRelease_Date(songInfo.previous_release_date)
    } else {
      setPreRelease_Date(null)
    }

    if (songInfo.release_time != "") {
      setReleaseTime(songInfo.release_time)
    } else {

      setReleaseTime(null)
    }

    // setReleaseTime(songInfo.release_time)
    // songInfo.catalog = songInfo.catalog
    songInfo.crbt_cuts_duration_for_clip1 != "" && setCallerTune1(songInfo.crbt_cuts_duration_for_clip1)
    songInfo.crbt_cuts_duration_for_clip2 != "" && setCallerTune2(songInfo.crbt_cuts_duration_for_clip2)
    setAudioFile(process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' + songInfo.song_file)
    setSongPoster(process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' + songInfo.cover_image)
    if (songInfo.copyright_p) {
      setCopyrights_p(songInfo.copyright_p.copyright_year.id);
      setCopyrightp_name(songInfo.copyright_p.copyright_name);
    }
    if (songInfo.copyright_c) {
      setCopyrights_c(songInfo.copyright_c.copyright_year.id);
      setCopyrightc_name(songInfo.copyright_c.copyright_name);
    }
    //songInfo.song_status="In Review" //to set the song in review again
    songInfo.isrc_code = songInfo.isrc_code === null ? '' : songInfo.isrc_code
    songInfo.upc_code = songInfo.upc_code === null ? '' : songInfo.upc_code
    setData(songInfo)
    setSongDataBkp(songInfo) // setting the pre filled data to the bkp
  }

  useEffect(() => {
    const fetchSongDetails = async () => {
      if (userData.studio_id && songId !== undefined) {
        if (activeStep === 0 && songId && userData.studio_id) {
          await getSongDetails(userData.studio_id, songId);
        }
      }
    };

    // Only fetch song details if assoArtistList is empty
    if (assoArtistList) {
      fetchSongDetails();
    }
  }, [userData.studio_id, songId, activeStep, assoArtistList]);

  /* Detect back or refresh */
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
      const confirmationMessage = 'Are you sure you want to leave?';
      event.returnValue = confirmationMessage;
      if (!window.confirm()) {
        event.preventDefault();
      }
      else {
        history('/releases')
      }
    };

    const handlePopState = (event) => {
      const confirmationMessage = 'Are you sure you want to leave?';
      if (window.confirm()) {
        history('/releases')
      } else {
        // User clicked "Cancel", go back to the current page
        history(null, null, window.location.pathname);
        // window.history.pushState(null, null, window.location.pathname);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
  /* End */

  /* To upload the audio file */

  function validateSongFile(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const fileType = file.type;
      const fileName = file.name;
      const fileSize = file.size;

      if (file && fileType.startsWith('audio/')) {
        if (!fileName.match(/\.(wav|WAV)$/)) {
          dispatch(setSnackBar(true));
          dispatch(setSnackBarMessage(formSnackbar.errors.fileUploadType));
          dispatch(setSnackBarVariant('error'));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
          return false;
        } else {
          if (fileSize > 50000000) {
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.fileUploadSize));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
          } else {
            const audioElement = document.createElement('audio');
            audioElement.src = reader.result;
            audioElement.addEventListener('loadedmetadata', () => {
              const duration = audioElement.duration;
              if (duration < 70) { // Duration should be at least 1.10 minutes (70 seconds)
                dispatch(setSnackBar(true));
                dispatch(setSnackBarMessage('Audio duration should be more than or equal to 1.10 minutes.'));
                dispatch(setSnackBarVariant('error'));
                setTimeout(() => dispatch(setSnackBar(false)), 3000);
                return false;
              } else {
                setAudioFile(reader.result);
                setData({
                  ...data,
                  song_file: file
                });
                return true;
              }
            });
          }
        }
      } else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.fileUploadType));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000);
        return false;
      }
    };
  }
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    validateSongFile(file)
    event.target.value = ''; //to set file null
  };

  const handleFileInputRemove = (songid) => {
    //to remove the file from the server
    let fomrPayload = new FormData()
    fomrPayload.append('song_id', data.song_id)
    removeSongTrack(fomrPayload)
      .then((response) => {
        // Handle successful audio file removal
        setAudioFile(null)
        setData({
          ...data,
          song_file: ''
        })
        setFile(null)
        setUploadPercentage(0)
        setUploadedSize(0)
        setIsFileUploaded(false)
        dispatch(setLoader(false))
      })
      .catch((error) => {
        // Handle audio file removal error
        alert('Something went wrong. Please try again later.')
      });
  }
  /* To upload the song poster */
  function validateImagePoster(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const fileType = file.type;
    if (file && fileType.startsWith('image/')) {
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          if (img.height === 3000 && img.width === 3000) {
            if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
              dispatch(setSnackBar(true));
              dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadType));
              dispatch(setSnackBarVariant('error'));
              setTimeout(() => dispatch(setSnackBar(false)), 3000)
              return false
            }
            else {
              if (file.size > 10000000) {
                dispatch(setSnackBar(true));
                dispatch(setSnackBarMessage(formSnackbar.errors.fileUploadSizeImage));
                dispatch(setSnackBarVariant('error'));
                setTimeout(() => dispatch(setSnackBar(false)), 3000)
              }
              else {
                setSongPoster(reader.result);
                setData({
                  ...data,
                  cover_image: file
                });
                return true
              }

            }
          }
          else {
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => dispatch(setSnackBar(false)), 3000)
            return false
          }
        };
      };
    }
    else {
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadType));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return false
    }
  }

  const handleDropSongPoster = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    validateImagePoster(file)
  };


  const handleChangeSongPoster = (event) => {
    let file = event.target.files[0];
    validateImagePoster(file)
  };

  const handlePosterRemove = () => {
    setSongPoster(null)
    setData({
      ...data,
      cover_image: ''
    });
  }

  const isFieldsValid = (errObj) => {
    let array = [];
    for (let key in errObj) {
      array.push(errObj[key])
    }
    if (array.every((item) => item === false)) {
      return true;
    }
    else {
      return false;
    }
  }
  //handle cover image and track upload to server
  function updateKeysExcept(obj, keysToExclude, newKeyPrefix, retain_value) {
    return Object.keys(obj).reduce((acc, key) => {
      if (keysToExclude.includes(key)) {
        acc[key] = retain_value;  // Retain the excluded key as-is
      } else {
        acc[newKeyPrefix + key] = obj[key];  // Update other keys with a prefix
      }
      return acc;
    }, {});
  }
  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];

    // Check file format
    const allowedFormats = ['wav'];
    const fileFormat = selectedFile.name.split('.').pop().toLowerCase();
    if (!allowedFormats.includes(fileFormat)) {
      dispatch(setSnackBar(true))
      dispatch(setSnackBarVariant('error'))
      dispatch(setSnackBarMessage(formSnackbar.errors.fileTypeUploadError))
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return;
    }

    // Check file size
    const maxSizeInBytes = 250 * 1024 * 1024; // 250MB
    if (selectedFile.size > maxSizeInBytes) {
      dispatch(setSnackBar(true))
      dispatch(setSnackBarVariant('error'))
      dispatch(setSnackBarMessage(formSnackbar.errors.largeFileLimitError))
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return;
    }
    setFile(selectedFile);
    const formData = new FormData();
    formData.append('song_file', selectedFile);
    formData.append('song_id', songid);

    uploadSongTrack(formData, songid, (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      const uploadedSizeInBytes = progressEvent.loaded;
      //const uploadedSizeInMB = (uploadedSizeInBytes / (1024 * 1024)).toFixed(2);
      setUploadedSize(uploadedSizeInBytes)
      setUploadPercentage(percentCompleted);
    })
      .then((response) => {
        // Handle successful upload
        try {
          const songData = response.data;
          setSongDataBkp(songData)
          // Update the state while retaining the previous cover_image key
          const updatedState = updateKeysExcept(songData, ['cover_image'], '', data.cover_image);
          setData(updatedState)
          setAudioFile(process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' + response.data.song_file);
          setIsFileUploaded(true)
        }
        catch (err) {
          alert('Something went wrong. Please try again later')
        }
      })
      .catch((error) => {
        // Handle upload error
        console.error(error);
      });
    event.target.value = null;
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      setStepName(steps[prevActiveStep + 1])
      validationHandler(prevActiveStep + 1)
      return (prevActiveStep + 1)
    });

  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      setStepName(steps[prevActiveStep - 1])
      prevActiveStep === 1 && setPrevBtnDisable(true)
      validationHandler(prevActiveStep - 1)
      return (prevActiveStep - 1)
    });
  };
  const handleReset = () => {
    history('/addrelease/' + albumId);
  };

  const validationHandler = (step) => {
    if (step === 0) {
      if ((data?.song_name === '' || data.song_name == null || data?.song_name.length < 1) || (data?.genre === '' || data?.genre === null || data?.genre.length < 1) || (data?.sub_genre === '' || data?.sub_genre === null || data?.sub_genre.length < 1) || (data?.mood === '' || data?.mood === null || data?.mood.length < 1)
        || (data.instrumental === '' || data.instrumental === null || data.instrumental.length < 1) ||
        (data.explicit_content === '' || data.explicit_content === null || data.explicit_content.length < 1) || (data.language === '' || data.language === null || data.language === null || (data.language != null && data.language.length < 1)))
        setNextBtnDisable(true)
      else {
        if (isFieldsValid(errors) === true)
          setNextBtnDisable(false)
        else
          setNextBtnDisable(true)
      }
    }
    else if (step === 1) {
      if ((data.primary_artists === '' || data.primary_artists.length < 1) ||
        (data.composers === '' || data.composers.length < 1) || (data.lyricists === '' || data.lyricists.length < 1)
      ) {
        setNextBtnDisable(true)
        setPrevBtnDisable(false)
      }
      else {
        if (isFieldsValid(errors) === true) {
          setNextBtnDisable(false)
          setPrevBtnDisable(false)
        }
        else {
          setNextBtnDisable(true)
          setPrevBtnDisable(false)
        }
      }
    }
    else if (step === 2) {
      if (data?.release_history === false || data?.release_history === 'False' || releasehistory == "False" || releasehistory == false) {
        if (
          // (data.release_date === '' || data.release_date?.length < 1) ||
          (data.copyright_type === '' || data.copyright_type.length < 1) || (copyrights_c === '' || copyrights_c.length < 1)
          || (copyrights_p === '' || copyrights_p.length < 1) || (copyrightp_name === '' || copyrightc_name.length < 1)
        ) {
          setNextBtnDisable(true)
        } else {
          if (isFieldsValid(errors) === true)
            setNextBtnDisable(false)
          else {
            if (releasehistory == "False" || releasehistory == false) {
              setNextBtnDisable(false)
            } else {
              setNextBtnDisable(true)
            }

          }
        }

      } else {

        if ((data?.release_history === 'True' || data?.release_history === true || data?.release_history != null) && data.upc_code.length === 12 && data.previous_release_date != null) {
          if (
            // (data.release_date === '' || data.release_date?.length < 1)||
            (data.copyright_type === '' || data.copyright_type.length < 1) || (copyrights_c === '' || copyrights_c.length < 1)
            || (copyrights_p === '' || copyrights_p.length < 1) || (copyrightp_name === '' || copyrightc_name.length < 1)
          ) {
            setNextBtnDisable(true)
          } else {
            setNextBtnDisable(false)
          }
        } else {
          setNextBtnDisable(true)
        }

      }
    }
    else if (step === 3) {
      if ((data.song_file === '' || data.song_file === null)) {
        setNextBtnDisable(true)
        setPrevBtnDisable(false)
      }
      else {
        setNextBtnDisable(false)
        setPrevBtnDisable(false)
      }
    }
    else {

    }
  }
  //To handle the validatio on input fields
  useEffect(() => {
    validationHandler(activeStep);
    //   if(songDetails.length> 0 && (songDetails[0].genre == ''||songDetails[0].genre == null)){ 
    //   setData({
    //     ...data,
    //     genre:(songDetails[0].genre == ''||songDetails[0].genre == null)?albumDetail.genre:songDetails[0].genre,
    //     sub_genre:(songDetails[0].sub_genre == ''||songDetails[0].sub_genre == null)?albumDetail.sub_genre:songDetails[0].sub_genre,
    //     title_version:(songDetails[0].title_version == ''||songDetails[0].title_version == null)?albumDetail.title_version:songDetails[0].title_version,
    //     language:(songDetails[0].language == ''||songDetails[0].language == null)?albumDetail.language:songDetails[0].language,

    //   })
    // }
  }, [data, releasehistory])

  const warningHandler = () => {
    warningRef.current.handleClose();
    saveSubmitaSong() //call the submit
  }

  const handleFinalSave = () => {
    warningRef.current.handleOpen()
    setData({ ...data, song_status: 'In Review' })
  }
  function saveSubmitaSong(e) {
    /* data.userId = userData.id; */
    if (JSON.stringify(data) === JSON.stringify(songDataBkp)) {
      if (activeStep === 3) {
        handleReset()
      }
      return;
    }
    dispatch(setLoader(true))
    let postData = data;
    let copyrightCData = {}
    let copyrightPData = {}
    let publisherId = userData.role === 1 ? userData.studio_id : userData.hasOwnProperty('associated_studio') ? userData.associated_studio : null
    postData.song_name = postData.song_name.trim()
    //to set the boolean value as per server 
    let explictValue = postData.explicit_content.toString()
    explictValue = explictValue.charAt(0).toUpperCase() + explictValue.slice(1)
    postData.explicit_content = explictValue;
    if (activeStep == 2) {
      if (releasehistory === false || releasehistory === "False") {
        postData.upc_code = '';
        postData.previous_release_date = '';
      }
      copyrightPData = {
        'copyright_name': copyrightp_name,
        'publisher_id': publisherId,
        'publisher_type': postData.publisher_type,
        'copyright_year': copyrights_p,
      }
      copyrightCData = {
        'copyright_name': copyrightc_name,
        'publisher_id': publisherId,
        'publisher_type': postData.publisher_type,
        'copyright_year': copyrights_c,
      }
      postData.copyright_p = copyrightPData;
      postData.copyright_c = copyrightCData;
    }
    let instrumentValue = postData.instrumental.toString()
    instrumentValue = instrumentValue.charAt(0).toUpperCase() + instrumentValue.slice(1)
    postData.instrumental = instrumentValue;
    if (songid === null || songid === undefined || songid === '') {
      if (postData.demo_id === undefined && postData.demo_id !== null && postData.song_id !== '') {
        const formData = new FormData();
        for (const key in postData) {
          postData[key] && formData.append(key, postData[key]);
        }
        formData.append('publisher_id', publisherId);
        formData.append('publisher_type', 'studio');
        formData.append('album', albumId)
        formData.append('cover_image', songImage)
        submitSong(formData, 'POST').then(function (response) {
          if (response.status === 200 || response.status === 201) {
            dispatch(setLoader(false))
            // response && Object.values(data).length > 0 &&
            setSongid(response?.data?.data[0].fields.song_id)
            setData(response?.data?.data[0].fields)
            setSongDataBkp(response?.data?.data[0].fields)
            if (activeStep === 3) {
              dispatch(setSnackBar(true))
              dispatch(setSnackBarVariant('success'))
              dispatch(setSnackBarMessage(formSnackbar.submitAsong.ReleaseInfoCreateSuccess))
              setTimeout(() => dispatch(setSnackBar(false)), 3000)
              setTimeout(() => handleReset(), 1000)
              window.sessionStorage.clear()
            }
          }
          else {
            dispatch(setLoader(false))
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
            setTimeout(() => dispatch(setSnackBar(false)), 3000)
          }
        })
      } //post service
    }
    else {
      const formData = new FormData();
      for (const key in postData) {
        if (key !== 'publisher_id' && key !== 'song_id' && key !== 'song_status') {
          if (postData[key] !== songDataBkp[key]) {
            if (typeof postData[key] === 'object' && activeStep == 2) {
              for (const subKey in postData[key]) {
                formData.append(`${key}[${subKey}]`, postData[key][subKey]);
              }
            } else {
              formData.append(key, postData[key]);
            }
          }
        }
        else {
          formData.append(key, postData[key]);
          // to send the song id and user id always, 
        }
      }
      formData.append('cover_image', songImage)
      formData.append('album', albumId)
      //songInfo is used to get the single song data from all song data, line 242 setting songInfo with the data
      submitSong(formData, 'UPDATE').then(function (response) {
        if (response.status === 200) {
          dispatch(setLoader(false))
          response && Object.values(data).length > 0 && setSongid(response.data.song_id)
          setData(response.data)
          setSongDataBkp(response.data)
          if (activeStep === 3) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('success'))
            dispatch(setSnackBarMessage(formSnackbar.submitAsong.ReleaseInfoCreateSuccess))
            setTimeout(() => dispatch(setSnackBar(false)), 3000)
            setTimeout(() => handleReset(), 1000)
            window.sessionStorage.clear()
          }
        }
        else {
          dispatch(setLoader(false))
          dispatch(setSnackBarVariant('error'))
          dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
          setTimeout(() => dispatch(setSnackBar(false)), 3000)
        }
      })
    }
  }

  /*   function doNothing(){} */
  const handleNextClick = (step) => {
    //to request when button is enabled and clicked on next
    if (nextBtnDisable === false && activeStep !== 3) {
      saveSubmitaSong()
    }
    step === steps.length - 1 ? handleFinalSave() : handleNext();
  }

  const handleRouteBack = () => {
    if (data.song_name != "" || data.explicit_content != "" || data.content_types != "" ||
      data.genre != "" || data.sub_genre != "" || data.mood != "" ||
      data.instrumental != "" || data.isrc_code != "" || data.upc_code != "") {
      ref.current.handleOpen()
    }
    else {
      history('/addrelease/' + albumId)
    }
  }

  const confirmHandler = () => {
    history('/addrelease/' + albumId)
  }

  const dateZoneHandler = (date) => {
    const newDate = moment.utc(date.toISOString()).tz("Asia/Kolkata");
    return newDate
  }
  const releaseDateChangeHandler = (newValue, pickerType) => {
    const formattedDate = newValue.format('YYYY-MM-DD');
    if (pickerType == 'releaseDate') {
      setRelease_Date(formattedDate)
    } else {
      setPreRelease_Date(formattedDate)
    }
    // const isReleaseDateInvalid = newValue === null || isNaN(Date.parse(newValue));
    // if (!isReleaseDateInvalid) {
    //   const datePayload = dateZoneHandler(newValue).toISOString();
    setData({ ...data, [pickerType === 'releaseDate' ? 'release_date' : 'previous_release_date']: formattedDate })
    // }
  }

  const releaseTimeChangeHandler = (newValue, timeType) => {
    switch (timeType) {
      case 'releaseTime':
        setReleaseTime(newValue)
        break;
      case 'callerTune1':
        setCallerTune1(newValue)
        break;
      case 'callerTune2':
        setCallerTune2(newValue)
        break;
      default:
        break;
    }

    const isReleaseTimeDateInvalid = newValue === null || isNaN(Date.parse(newValue));
    if (!isReleaseTimeDateInvalid) {
      const datePayload = dateZoneHandler(newValue).toISOString();
      setData({ ...data, [timeType === 'releaseTime' ? 'release_time' : timeType === 'callerTune1' ? 'crbt_cuts_duration_for_clip1' : 'crbt_cuts_duration_for_clip2']: datePayload })
    }
  }



  const handleInputChangeCopyright = (event) => {
    const { name, value } = event.target;
    // Use the spread operator to create a copy of the data object
    const newData = { ...data };

    switch (name) {
      case 'copyrights_p': {
        setCopyrights_p(value)
        newData.copyright_p = {
          ...newData.copyright_p,
          copyright_year: value,
          publisher_id: userData.studio_id,
          publisher_type: newData.publisher_type,
        };
        break;
      }
      case 'copyrights_c': {
        setCopyrights_c(value)
        newData.copyright_c = {
          ...newData.copyright_c,
          copyright_year: value,
          publisher_id: userData.studio_id,
          publisher_type: newData.publisher_type,
        };
        break;
      }
      case 'copyrightc_name': {
        const isValid = regexValidator(value, 'text')
        if (!isValid) {
          setErrors({ ...errors, copyrightc_name: true, copyrightc_name_helperTxt: formSnackbar.errors.validCopyrightInput });
        } else {
          setErrors({ ...errors, copyrightc_name: false, copyrightc_name_helperTxt: false });
        }
        setCopyrightc_name(value)
        newData.copyright_c = {
          ...newData.copyright_c,
          copyright_name: value,
          publisher_id: userData.studio_id,
          publisher_type: newData.publisher_type,
        };
        break;
      }
      case 'copyrightp_name': {
        const isValid = regexValidator(value, 'text')
        if (!isValid) {
          setErrors({ ...errors, copyrightp_name: true, copyrightp_name_helperTxt: formSnackbar.errors.validCopyrightInput });
        } else {
          setErrors({ ...errors, copyrightp_name: false, copyrightp_name_helperTxt: false });
        }
        setCopyrightp_name(value)
        newData.copyright_p = {
          ...newData.copyright_p,
          copyright_name: value,
          publisher_id: userData.studio_id,
          publisher_type: newData.publisher_type,

        };
        break;
      }
      default: {
        break;
      }
    }

    // Update the data state with the modified newData
    setData(newData);
  };

  const getSteps = (activeStep) => {
    switch (activeStep) {
      case 0:
        {
          return <>
            <NewRelease data={data} errors={errors} handleInputChange={(event) => handleInputChange(event, data, setData, errors, setErrors, formSnackbar, setContentTypeValue, setInstruValue, setSong_File, setSong_Status, setSelectedValue, setReleasehistory, setCopyrighttype,)} genre={genre}
              titleVersionList={titleVersionList}
              moodList={moodList}
              subGenreList={subGenreList}
              selectedValue={selectedValue}
              // contentTypelist={contentTypelist}
              contentTypeValue={contentTypeValue}
              language={language}
              instruValue={instruValue} />
          </>
        }
      case 1:
        {
          return <>
            <SongCredits data={data} errors={errors} handleInputChange={(event) => handleInputChange(event, data, setData, errors, setErrors, formSnackbar, setContentTypeValue, setInstruValue, setSong_File, setSong_Status, setSelectedValue, setReleasehistory, setCopyrighttype)}
              primaryArtList={primaryArtList}
              remixerArtList={remixerArtList}
              artistSelectChangeHandler={artistSelectChangeHandler}
              featureArtList={featureArtList}
              lyricistList={lyricistList}
              producerList={producerList}
              composerList={composerList}
              actorList={actorList}
              artistList={assoArtistList}
              albumDetail={albumDetail}
              getAssociatedArtist={getAssociatedArtist}
              assoArtistRemoveHandler={assoArtistRemoveHandler}
              recordLabelList={recordLabelList}
              setPrimaryArtList={setPrimaryArtList}
              assoArtistList={assoArtistList}
              setData={setData}
              setFeatureArtList={setFeatureArtList}
              songId={songId}
              songDataBkp={songDataBkp}
              setRemixerArtList={setRemixerArtList}
              setComposerList={setComposerList}
              setProducerList={setProducerList}
              setLyricistList={setLyricistList} />
          </>
        }
      case 2:
        {
          return <>
            <ReleaseInformation data={data} errors={errors}
              handleInputChangeCopyright={(event) => handleInputChangeCopyright(event)}
              handleInputChange={(event) => handleInputChange(event, data, setData, errors, setErrors, formSnackbar, setContentTypeValue, setInstruValue, setSong_File, setSong_Status, setSelectedValue, setReleasehistory, setCopyrighttype)}
              releaseDateChangeHandler={releaseDateChangeHandler}
              releaseTimeChangeHandler={releaseTimeChangeHandler}
              releaseDate={releaseDate}
              prereleaseDate={prereleaseDate}
              releaseTime={releaseTime}
              copyrightc_name={copyrightc_name}
              copyrightp_name={copyrightp_name}
              copyrights_p={copyrights_p}
              copyrights_c={copyrights_c}
              setCopyrights_p={setCopyrights_p}
              setCopyrights_c={setCopyrights_c}
              callerTune1={callerTune1}
              callerTune2={callerTune2}
              releasehistory={releasehistory}
              copyrighttype={copyrighttype}
              contentTypelist={contentTypelist}
              contentTypeValue={contentTypeValue}
              language={language} copyrightList={copyrightList}
              artistList={assoArtistList}
              instruValue={instruValue} />
          </>
        }
      case 3: {
        return <>
          <FinalReview data={data} songPoster={songPoster} imgPlaceHolder={imgPlaceHolder} genre={genre}
            // contentTypelist={contentTypelist} 
            audioFile={audioFile} language={language} subGenreList={subGenreList} moodList={moodList} titleVersionList={titleVersionList}
            remixerArtList={remixerArtList} copyrightList={copyrightList} copyrights_p={copyrights_p}
            copyrights_c={copyrights_c} copyrightc_name={copyrightc_name} copyrightp_name={copyrightp_name}
            primaryArtList={primaryArtList} featureArtList={featureArtList} producerList={producerList} composerList={composerList} lyricistList={lyricistList}
            actorList={actorList} recordLabelList={recordLabelList} />
        </>
      }
      default: return;
    }
  } // end

  return (
    <div className='mb-4' id="addsong-stepper-container">
      <div className='' >
        <button className="icononly-btn" onClick={handleRouteBack}>
          <h5> <ArrowBackIcon /> {albumName}</h5>
        </button>
      </div>

      <Box className=" mt-3">
        {!smScreen ? (
          <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={index} {...stepProps}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}  {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        ) : (
          <div>
            <DonutStepper strokeWidth={10} percentage={90} activeStep={activeStep}
              steps={steps} strokeColor='#E41116' trackColor="#d6d6d6" textColor="#161513" fontSize="18px" subHedingSteps={subHedingSteps} />
          </div>
        )}
        <div>
          <div className="card account-bg my-4 border-0 rounded" style={{ backgroundColor: '#fff' }}>
            <div className="card-body">
              <div className="content-area">
                {activeStep === steps.length ? (
                  <React.Fragment>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {getSteps(activeStep)}
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className="card-footer py-3" style={{ backgroundColor: '#fff' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <button
                  disabled={prevBtnDisable}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                  className={prevBtnDisable ? 'disablebtn' : 'gn-actionbtn outlined'}
                  style={{ minWidth: '100px' }}
                >
                  PREVIOUS
                </button>
                <Box sx={{ flex: '1 1 auto' }} />
                <button
                  disabled={nextBtnDisable}
                  onClick={() => handleNextClick(activeStep)}
                  className={`gn-actionbtn  ${nextBtnDisable ? 'cursor-not-allowed' : ""}`}
                  style={{ minWidth: '100px' }}
                >
                  {activeStep === steps.length - 1 ? 'FINISH' : 'NEXT'}
                </button>
              </Box>
            </div>
          </div>
        </div>
      </Box>
      <div>

        <SnackbarContainer />
        <ConfirmModal confirmHandler={confirmHandler} ref={ref} />
        <WarningModal confirmHandler={warningHandler} ref={warningRef} />
      </div>
    </div>
  );
}