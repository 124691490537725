import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, createTheme, CssBaseline, Divider, Grid, Stack, ThemeProvider, useMediaQuery } from '@mui/material';
import bgImg from '../../img/background/bg_image_texture1.png'
import Sidebar from './sidebar';
import Header from './header';
import DashboardPage from '../../module/dashboard/DashboardPage';
import Footer from '../footer';
import { useTheme } from '@emotion/react';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...(open && {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		}),
	}),
);



const Layout = () => {
	const mdTheme = createTheme();
	const [open, setOpen] = React.useState(true);
	const mediumViewport = useMediaQuery('(max-width:1279px)');
	const theme = useTheme();
	const smallScreenWidth = theme.breakpoints.down('sm') ? '280px' : '100%';
	React.useEffect(() => {
		mediumViewport ? setOpen(false) : setOpen(true);
	}, [mediumViewport])

	const handleDrawerOpen = () => {
		setOpen(!open);
	};
	const DrawerHeader = styled('div')(({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	}));


	return (

		<ThemeProvider theme={mdTheme}>
			<div >
				<Box sx={{ display: 'flex' }} className="h-100">
					<CssBaseline />
					<Sidebar open={open} handleDrawerOpen={handleDrawerOpen} />
					<Main open={open} >
						<DrawerHeader />
						<DashboardPage />
						<Footer sx={{ pt: 4 }} />
					</Main>
				</Box>
			</div>

		</ThemeProvider>

	);
};

export default Layout;
