import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { recordLabelURL } from "../../_constants/api.constant";
export async function recordLabelRequest(){
    const response = await axios.get(
        recordLabelURL.GET,
        {   
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        return error.response;
    });
    return response;
}