import { actionTypes, SET_TAB_VALUE } from './musicCategoryTypes';

const initialState = {
  loading: false,
  genreData: [],
  subGenreData: [],
  moodData:[],
  recordLabelData: [],
  languageData: [],
  // singlealbumData: [],
  // singleTrackData: [],
  // singlevideoData: [],
  // storeData: [],
  // countryData: [],
  // monthData: [],
  // videoData: [],
  error: '',
};

const reducer = (state = initialState, action) => {
  const actionHandlers = {
    [actionTypes.FETCH_GENRE_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_GENRE_DATA_SUCCESS]: { loading: false, genreData: action.payload, error: '' },
    [actionTypes.FETCH_GENRE_DATA_FAILURE]: { loading: false, genreData: [], error: action.payload },

    [actionTypes.FETCH_SUBGENRE_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_SUBGENRE_DATA_SUCCESS]: { loading: false, subGenreData: action.payload, error: '' },
    [actionTypes.FETCH_SUBGENRE_DATA_FAILURE]: { loading: false, subGenreData: [], error: action.payload },

    [actionTypes.FETCH_MOOD_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_MOOD_DATA_SUCCESS]: { loading: false, moodData: action.payload, error: '' },
    [actionTypes.FETCH_MOOD_DATA_FAILURE]: { loading: false, moodData: [], error: action.payload },

    [actionTypes.FETCH_RECORDLABEL_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_RECORDLABEL_DATA_SUCCESS]: { loading: false, recordLabelData: action.payload, error: '' },
    [actionTypes.FETCH_RECORDLABEL_DATA_FAILURE]: { loading: false, recordLabelData: [], error: action.payload },

    [actionTypes.FETCH_LANGUAGE_DATA_REQUEST]: { loading: true },
    [actionTypes.FETCH_LANGUAGE_DATA_SUCCESS]: { loading: false, languageData: action.payload, error: '' },
    [actionTypes.FETCH_LANGUAGE_DATA_FAILURE]: { loading: false, languageData: [], error: action.payload },
    
  };

  const handler = actionHandlers[action.type];
  return handler ? { ...state, ...handler } : state;
};

export default reducer;
