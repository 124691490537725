import { FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createAddress } from '../../_services/Address/insertAddress.service';
import { updateAddress } from '../../_services/Address/updateAddress.service';
import { getCity } from '../../_services/City/getCity.service';
import { getCountry } from '../../_services/Country/getCountry.service';
import { getState } from '../../_services/State/getState.service';
import { fetchUserDetail, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_constants/snackbar.constant';
import SnackbarContainer from '../Snackbar';
import { regexValidator } from '../../_helpers/reusablefunctions/regexValidator';

function LanguageAndRegion(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const [address, setAddress] = useState(userData ? userData.address.local_address : "");
  const [addressError, setAddressError] = useState(false)
  const [addressErrorMsg, setAddressErrorMsg] = useState('')

  const [cityList, setCityList] = useState("");
  const [cityId, setCityId] = useState("");
  const [cityError, setCityError] = useState(false)

  const [zipCode, setZipCode] = useState(userData ? userData.address.zipcode : "");
  const [zipCodeError, setZipCodeError] = useState(false)

  const [stateList, setStateList] = useState("");
  const [stateId, setStateId] = useState("");
  const [stateError, setStateError] = useState(false)

  const [countryList, setCountryList] = useState("");
  const [countryId, setCountryId] = useState("");
  const [countryError, setCountryError] = useState(false)

  useEffect(function () {
    if (userData.studio_id) {
      userData.address.local_address ? setAddress(userData.address.local_address) : setAddress('')
      userData.address.zipcode ? setZipCode(userData.address.zipcode) : setZipCode('')
      userData.address.city ? setCityId(userData.address.city) : setCityId('')
      userData.address.state ? setStateId(userData.address.state) : setStateId(null)
      userData.address.country ? setCountryId(userData.address.country) : setCountryId(null)
    }
  }, [userData.studio_id]);

  useEffect(function () {
    getCity().then(function (response) {
      setCityList(response.data ? response.data.data : "")

    }).catch(function (error) {
      console.log(error);
      return error.response;
    })
    getState().then(function (response) {
      setStateList(response.data ? response.data.data : "")
    }).catch(function (error) {
      console.log(error);
      return error.response;
    })
    getCountry().then(function (response) {
      setCountryList(response.data ? response.data.data : "")
    }).catch(function (error) {
      dispatch(setLoader(false))
      console.log(error);
      return error.response;
    })

  }, []);

  /* Form validation handler */
  const validateForm = () => {
    const isValid = regexValidator(address, 'space')
    if(isValid){
      setAddressError(true)
      setAddressErrorMsg('Space is not allowed')
      return false;
    }
    if (address.trim() === "" || address.trim() == null) {
      setAddressError(true)
      return false;
    }
    else if (cityId === '' || cityId === null) {
      setCityError(true)
      return false
    }
    else if (zipCode === "" || zipCode === null || zipCode <= 0) {
      setZipCodeError(true)
      return false;
    }
    else if (stateId === '' || stateId === null) {
      setStateError(true)
      return false
    }
    else if (countryId === '' || countryId === null) {
      setCountryError(true)
      return false
    }
    else {
      return true
    }
  }
  function getAllState(country) {
    getState(country)
      .then(function (response) {
        setStateList(response.data ? response.data.data : "")
      })
      .catch(function (error) {
        console.log(error); return error.response;
      })
  }
  function handleStateChange(e) {
    const state = e.target.value;
    setStateId(state);
  }
  function handleCountryChange(e) {
    const country = e.target.value;
    setCountryId(country);
    let cd = countryList && countryList.length > 0 && countryList.find(item => item.id === country)
    getAllState(cd.country_code);
  }
  function onSave(e) {
    e.preventDefault();
    //validate input fields before apending the data
    let isValid = validateForm();
    if (isValid) {
      var data = {};
      if (cityId) {
        data["city"] = cityId
      }
      if (address) {
        data["local_address"] = address
      }
      if (zipCode) {
        data["zipcode"] = zipCode
      }
      if (stateId) {
        data["state"] = stateId
      }
      if (countryId) {
        data["country"] = countryId
      }
      data['studio_id'] = userData.studio_id
      dispatch(setSnackBar(true))
      dispatch(setSnackBarMessage(formSnackbar.userAddress.userAddressUpdate))
      dispatch(setSnackBarVariant("info"))
      setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
      if (userData.address.id) {
        updateAddress(data, userData.address.id).then(function (response) {
          if (response.status === 200) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarMessage(formSnackbar.userAddress.userAddressUpdateSuccess))
            dispatch(setSnackBarVariant("success"))
            setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
            dispatch(fetchUserDetail(userData.studio_id))
            //alert('ok')
          }
          else {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
            setTimeout(() => { dispatch(setSnackBar(false)) }, 3000)
            //alert('not ok')
          }
        }).catch(function (error) {
          console.log(error);
          console.log(error.response);
          return error.response;
        })

      } else {
        createAddress(data).then(function (response) {
          if (response.status === 200) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarMessage(formSnackbar.userAddress.userAddressUpdateSuccess))
            dispatch(setSnackBarVariant("success"))
            setTimeout(() => { dispatch(setSnackBar(false)) }, 2000)
            dispatch(fetchUserDetail(userData.studio_id))
            //alert('ok')
          }
          else {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
            setTimeout(() => { dispatch(setSnackBar(false)) }, 3000)
            //alert('not ok')
          }
        }).catch(function (error) {
          console.log(error);
          console.log(error.response);
          return error.response;
        })
      } //Is valid end
    }
  }

  return (
    <div className="py-2">
      <h4 className='mb-4'> Region</h4>
      <div>
        <h6 className='mb-2 text-secondary'>Fill your address detail</h6>
        <form >
          <div className="row">
            <div className="form-group  mt-3 mb-3">
              <FormControl
                name='ADDRESS'
                noValidate
                autoComplete="off"
                fullWidth
              >
                <TextField
                  variant="outlined"
                  label="Enter Address*"
                  value={address}
                  onChange={(e) => setAddress(e.target.value) ? setAddressError(true) : setAddressError(false)}
                  name="address"
                  size="small"
                  type="text"
                  error={addressError}
                  helperText={addressError ? addressErrorMsg : ''}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                />
              </FormControl>
            </div>
            <div className="form-group  mt-3 mb-3">
              <FormControl fullWidth name="CITY" error={cityError} size="small" >
                <TextField
                  variant="outlined"
                  label="City*"
                  value={cityId}
                  onChange={(e) => setCityId(e.target.value) ? setCityError(true) : setCityError(false)}
                  name="cityId"
                  size="small"
                  type="text"
                  error={cityError}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                />
              </FormControl>
            </div>
            <div className="form-group  mt-3 mb-3">
              <FormControl
                name="ZIPCODE"
                autoComplete="off"
                fullWidth
              >
                <TextField
                  type="number"
                  value={zipCode}
                  error={zipCodeError}
                  onChange={(e) => { e.preventDefault(); setZipCode(e.target.value) ? setZipCodeError(true) : setZipCodeError(false); }}
                  name="zipCode"
                  label="Enter Zip Code*" variant="outlined" size='small'
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                />
              </FormControl>
            </div>
            <div className="form-group  mt-3 mb-3">
              <FormControl fullWidth size='small' name="countryId" error={countryError}>
                <InputLabel >Country*</InputLabel>
                <Select
                  value={countryId ? countryId : 'null'}
                  label="Country"
                  name="countryId"
                  error={countryError}
                  onChange={handleCountryChange}
                  MenuProps={{
                    style: {
                      maxHeight: 250,
                    },
                  }}
                >
                  {countryList ? countryList.map(data => (
                    <MenuItem value={data.id}>{data.country_name}</MenuItem>
                  )) : <MenuItem value='null'></MenuItem>}
                </Select>
              </FormControl>
            </div>
            <div className="form-group  mt-3 mb-3">
              <FormControl fullWidth name="stateId" error={stateError} size="small" >
                <InputLabel>State*</InputLabel>
                <Select
                  value={stateId ? stateId : 'null'}
                  name="stateId"
                  error={stateError}
                  label="State"
                  onChange={handleStateChange}
                  MenuProps={{
                    style: {
                      maxHeight: 250,
                    },
                  }}
                >
                  {stateList ? stateList.map(data => (
                    <MenuItem value={data.id}>{data.state_name}</MenuItem>
                  )) : <MenuItem value='null'></MenuItem>}
                </Select>
              </FormControl>
            </div>

            <div className="form-group col-md-12 text-start mt-3 mb-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button type="submit" className='gn-actionbtn' onClick={onSave}>Save</button>
            </div>
          </div>
        </form>
      </div>
      {/*  to show and hide the snackbar messages */}
      <SnackbarContainer />
    </div>
  )
}

export default LanguageAndRegion