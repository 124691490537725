import React, { useState } from 'react'
import { Box, FormControl, IconButton, Stack, TextField, ToggleButton } from '@mui/material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WindowIcon from '@mui/icons-material/Window';
import GridViewIcon from '@mui/icons-material/GridView';
import ReorderIcon from '@mui/icons-material/Reorder';
const SwitcherBtn = ({ layoutComponent, handleLayoutComponent }) => {
  const Icon = layoutComponent ? <WindowIcon className='bg-white' fontSize="small" sx={{ color: '#000000' }} /> : <FormatListBulletedIcon className='bg-white' fontSize="small" sx={{ color: '#000000' }} />
  return (
    <div>
      <ToggleButton onClick={() => { handleLayoutComponent(!layoutComponent) }} value={layoutComponent} variant="outlined" className="switcherButton  bg-white" size="small" aria-label='switchView' sx={{ minWidth: '2.625rem' }}>
        {Icon}
      </ToggleButton>
    </div>
  )
}

export default SwitcherBtn