import React from 'react';
import artist from '../../img/Rectangle 6831.png';
import { Checkbox, useMediaQuery} from '@mui/material';
import { useState } from 'react';


function AddSongToAlbum({onCheckboxChange, songData}) {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const checkboxStyle = {
    backgroundColor: '#fff', 
    padding: '4px', 
  };
  const smScreen = useMediaQuery('(max-width:600px)')

  const [isChecked, setIsChecked] = useState(false); // Local state for checkbox

  const handleCheckbox = (selectedSongId) => {
    const updatedValue = !isChecked;
    setIsChecked(updatedValue);
    // Call the parent component's callback to update the count
    onCheckboxChange(updatedValue,selectedSongId);
  };
  const maxCharacterCount = smScreen ? 50 : 100; // Set the maximum character count based on screen size
  const songTitle = songData && songData.song_name;
  const truncatedTitle = songTitle && songTitle.length > maxCharacterCount ? `${songTitle.slice(0, maxCharacterCount)}...` : songTitle;

  return (
    <div className="col-md-12 my-2 col-sm-12 col-12 rounded-3 border shadow bg-white">
        <div style={{ position: 'relative', width: '100%' }} className='d-flex justify-content-between'>
            <div>
                <img  src={songData && songData.cover_image?process.env.REACT_APP_SONGAPIURL_MEDIA+'/media/'+songData.cover_image:artist} alt="img" style={{ width:smScreen?'5rem': '7rem', height: smScreen?'4rem': '5rem', borderRadius: '10px', padding: '0.5rem' }}/>
                <div style={{ position: 'absolute', top: 0, left: 0 }}>
                    <Checkbox checked={isChecked} onChange={()=>handleCheckbox(songData.song_id)} inputProps={{ 'aria-label': 'Checkbox demo' }} style={checkboxStyle}/>
                </div>
            </div> 
            <div style={{ paddingTop: '0.5rem',width: '100%' }}>
            <div title={songTitle} style={{ fontSize: smScreen ? '12px' : '14px', wordWrap: 'break-word', textTransform: 'uppercase' }}>
              {truncatedTitle}
            </div>
            </div>
        </div>
    </div>
  );
}

export default AddSongToAlbum;
