
import React, { useEffect, useState } from 'react';
import { Box, Button, Menu, MenuItem, Paper, Typography, useMediaQuery } from '@mui/material';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter';
import TableComponent from '../Tablecomponent';
import profileImge from '../../img/header/profileImage.png';
import { useNavigate } from 'react-router-dom';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import ConnectionAction from './ConnectionAction';

export default function ArtistTable({ userRole, data, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage, handleUnlink, totalCount, ...props }) {
  const [rows, setRows] = useState(data);
  const [menuRowId, setMenuRowId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate();

  useEffect(() => {
    setRows(data)
  }, [data])

  const handleMenuClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setMenuRowId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuRowId(null);
  };

  const columns = [
    {
      id: 'name', label: 'Name', headerName: '', flex: 1, template: (row) => {
        return (
          <Box className="d-flex align-items-center gap-2 cursorPointer" onClick={(e) => navigate(`/artistDetail/${row.uid}`)} >
            <img className="rounded-pill" src={row.avatar ? process.env.REACT_APP_CREATOR_MEDIA_URL + '' + row.avatar : profileImge} style={{ width: '40px', height: '40px' }} />
            <Typography variant='span'>{row.name}</Typography>
          </Box>
        )
      }
    },
    {
      id: 'location', label: 'Location', headerName: '', flex: 1, template: (row) => {
        return (
          <Box>{row?.location || '-'}</Box>
        )
      }
    },
    {
      id: 'genres', label: 'Genres', headerName: '', flex: 1, template: (row) => {
        return (
          <Box>
            {row?.genres.join(', ')  || '-'}
          </Box>
        )
      }
    },
    {
      id: 'song_status', label: 'Status', headerName: '', flex: 1, template: (row) => {
        return (
          <Box className='leftStatusCard d-flex' >
            {row.connection_info && row.connection_info.status && row.connection_info.status.toLowerCase() ?
              <Typography variant="body2" className={`status ${row.connection_info.status.toLowerCase()}Status text-light`} style={{ background: getSongStatusBgColor(row.connection_info.status) }} gutterBottom>
                {row.connection_info.status}
              </Typography>
              :
              <Typography variant="body2" className={`status notVerifyStatus text-light`} style={{ background: getSongStatusBgColor('not verified') }} gutterBottom>
                Not Verified
              </Typography>
            }

          </Box>
        )
      }
    },
    {
      id: 'connection', label: 'Revoke Request', headerName: '', flex: 1, template: (row) => {
        return (
          <Box className="cursorPointer">
            {userRole !== 3 ?
              <div>
                <ConnectionAction
                  data={row}
                  handleUnlink={handleUnlink}
                />
              </div> : ''}
          </Box>
        )
      }
    }

  ];



  return (
    <div className="creator-playlist-panel">
      <Paper className="shadow-table" sx={{ width: '100%', mb: '3rem' }}>
        <TableComponent columns={columns} data={rows} page={page} setPage={props.setPage} removeStyle={'removeStyle'} rowsPerPage={rowsPerPage} setRowsPerPage={props.setRowsPerPage} totalCount={totalCount} />
      </Paper>
    </div>
  );
}