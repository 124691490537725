import axios from "axios";
import { SearchAllAssociateArtist } from "../../_constants/api.constant";
import { getSelfToken } from "../../_helpers/auth/secureToken";

export async function SearchAllAssociateArtistList(postData){
    const response = await axios.get(
        SearchAllAssociateArtist.SEARCH,
        {     params:postData,
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            }, 
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}