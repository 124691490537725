import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { postSocialLinkData, updateSocialPlatform,getSocialPlatform } from "../../_services/SocialPlatform/index";
import { formSnackbar } from '../../_constants/snackbar.constant'
import {setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import {useDispatch, useSelector} from 'react-redux'
import Autocomplete from '@mui/material/Autocomplete';
import DeleteModal from '../../components/reusableComponents/DeleteModal';
import { faFacebookSquare,faTwitter,faLinkedin,faYoutubeSquare,faInstagramSquare,faSpotify } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, FormControl, Grid, Icon, InputAdornment, InputLabel, makeStyles, Menu, MenuItem, OutlinedInput, Select, styled, TextField, Typography } from '@mui/material'

const TextFieldGrid = styled(Grid)(({ theme }) => ({
    maxHeight: '50vh',
    overflowY: 'auto',
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
  }));
  const InputIcon = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

const SocialLink = ({linkId,linkData,index,linkDeleteHandler,disableHandler,socialTypes,currentUserId,reRenderSocialLinks,selectedSocialType}) => {

  const textFieldRef = React.useRef(null);
  const [selectedType, setSelectedType] = React.useState(selectedSocialType);
  const [url, setURL] = React.useState(linkData&&linkData.url?linkData.url:'');
  const dispatch = useDispatch()
  const [disable, setDisable] = React.useState(null)
  const ref = React.useRef();
  const [urlError, setUrlError] = React.useState(false)
  const [urlErrorText,setURLErrorText] = React.useState('')
  const [socialTypeIcon, setSocialTypeIcon] = React.useState();
  const [enteredUrls, setEnteredUrls] = React.useState([]);

  React.useEffect(()=>{
    linkId?setDisable(true):setDisable(false)
    if(socialTypes && JSON.stringify(linkData) !== '{}' && linkData.socialType){
      let matchedArrayIndex = socialTypes && socialTypes.length>0?socialTypes.findIndex(currentItem=>currentItem.id ===linkData.socialType.id):null
      setSelectedType(socialTypes[matchedArrayIndex])
    }
  },[])

  const getSocialTypeIcon = React.useCallback((socialTypeC)=>{
    switch(socialTypeC)
    {
        case 'facebook_1':{
            return faFacebookSquare
        }
        case 'instagram_2':{
            return faInstagramSquare
        }
        case 'twitter_3':{
            return faTwitter
        }
        case 'linkedin_4':{
            return faLinkedin
        }
        case 'youtube_5':{
            return faYoutubeSquare
        }
        case 'spotify_6':{
            return faSpotify
        }
        case 'website':{
            return faGlobe
        }
        default:
            return;    
    }
  },[selectedSocialType])

  function checkUrlString(string) {
    let expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    let regex = new RegExp(expression);
    try {
      if (string.match(regex)) {
        return true
      } else {
        return false
      }
    }
    catch (err) {
      return false
    }
  }

  function checkUrlProtocol(string) {
    try {
      const url = new URL(string);
      if (url.protocol === 'http:' || url.protocol === 'https:') {

        if(url.hostname.substring(0,1) !== '.')
            return { isTrue: true, modifiedURl: url.href }
        else
            return { isTrue: false, modifiedURl: url.href }
        }
      else {
        return { isTrue: false, modifiedURl: url.href }
      }
    }
    catch (err) {
      return { isTrue: false, modifiedURl: undefined}
    }
  }

  const socialLinksSaveHandler = (platform,linkUrl) =>{
      dispatch(setSnackBar(true))
      dispatch(setSnackBarMessage(formSnackbar.socialLink.socialLinkCreate))
      dispatch(setSnackBarVariant('info'))
      let socialTypeId = platform?platform.id:null
      let postData = {}
      if(socialTypeId)
      postData['socialType'] = socialTypeId
      if(linkUrl)
      postData['url'] = linkUrl
      postData['name'] = platform&&platform.socialType
      postData['studio_id'] = currentUserId
      postSocialLinkData(postData)
      .then(function (response) {
        if(response.status===200){
            reRenderSocialLinks();
            disableHandler(false,'SAVE')
            dispatch(setSnackBarVariant('success'))
            dispatch(setSnackBarMessage(formSnackbar.socialLink.socialLinkSuccess))
            setTimeout(()=>dispatch(setSnackBar(false)),2000)
            setDisable(true)
        }else{
          dispatch(setSnackBarVariant('error'))
            setTimeout(()=>dispatch(setSnackBar(false)),2000)
            let responseData = response.data;
            if(responseData.url && responseData.url.length>0)
            {
              dispatch(setSnackBarMessage(formSnackbar.errors.urlError));
            }
            else
            {
              dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
            } 
        }   
      })
      .catch((error)=>{
        console.log(error)
        return error
      })

  }

  const editHandler = () =>{
    setDisable(false)
  }

  const urlChangeHandler = (event) =>{
        setURL(event.target.value)
  }
  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter" && !event.shiftKey) {
  //     event.preventDefault();  // Prevent default behavior (newline)
  //         let valid = checkUrlString(url)
  //         if(valid)
  //         {
  //           let validProtocol = checkUrlProtocol(url)
  //           if(validProtocol.isTrue)
  //               {
  //                   setUrlError(false)
  //                   setURLErrorText('')
  //                   //value = validProtocol.modifiedURl
  //                   let urlValue = validProtocol.modifiedURl
  //                   if (url !== validProtocol.modifiedURl) {
  //                     if (linkId === undefined || linkId === null || linkId === '') {
  //                       socialLinksSaveHandler(selectedType, validProtocol.modifiedURl);
  //                     } else {
  //                       socialLinkEditHandler(selectedType, validProtocol.modifiedURl);
  //                     }
  //                     setURL(validProtocol.modifiedURl); // Update the original URL
  //                   }
  //                     // Blur the text field when "Enter" key is pressed
  //                     if (textFieldRef.current) {
  //                       textFieldRef.current.blur();
  //                     }

  //               }
  //           else
  //               {
  //                   setURLErrorText('Please enter a valid URL')
  //                   setUrlError(true)
  //               }
  //         }
  //         else{
  //           setURLErrorText('Please enter a valid URL.')
  //           setUrlError(true)
  //         }
  //   }
  // };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent default behavior (newline)
  
      // Check if the URL is valid
      let valid = checkUrlString(url);
      if (valid) {
        let validProtocol = checkUrlProtocol(url);
        if (validProtocol.isTrue) {
          setUrlError(false);
          setURLErrorText('');
  
          // If the URL has changed, handle link creation or editing
          if (url !== validProtocol.modifiedURl) {
            if (linkId === undefined || linkId === null || linkId === '') {
              socialLinksSaveHandler(selectedType, validProtocol.modifiedURl);
            } else {
              socialLinkEditHandler(selectedType, validProtocol.modifiedURl);
            }
            setURL(validProtocol.modifiedURl); // Update the original URL
          }
  
          // Blur the text field when "Enter" key is pressed
          // if (textFieldRef.current) {
          //   textFieldRef.current.blur();
          // }
        } else {
          setURLErrorText('Please enter a valid URL');
          setUrlError(true);
        }
      } else {
        setURLErrorText('Please enter a valid URL.');
        setUrlError(true);
      }
    }
  };
 

  const handleBlur = (event) => {

    if (url !== linkData.url) { // Check if the URL has changed
    let valid = checkUrlString(url)
    if(valid)
    {
      let validProtocol = checkUrlProtocol(url)
      if(validProtocol.isTrue)
          {
              setUrlError(false)
              setURLErrorText('')
              //value = validProtocol.modifiedURl
              let urlValue = validProtocol.modifiedURl
              if(linkId ===undefined || linkId === null || linkId ===''){
                socialLinksSaveHandler(selectedType,urlValue)
              }
              else{
                if(linkData.url !== url)
                  socialLinkEditHandler(selectedType,urlValue)
              }
          }
      else
          {
              setURLErrorText('Please enter a valid URL')
              setUrlError(true)
          }
    }
    else{
      setURLErrorText('Please enter a valid URL.')
      setUrlError(true)
    }
  /*  socialLinkEditHandler(selectedType,url) */
    }
  };
  const socialLinkEditHandler = (platform,linkUrl) =>{
      dispatch(setSnackBar(true))
      dispatch(setSnackBarMessage(formSnackbar.socialLink.socialLinkUpdate))
      dispatch(setSnackBarVariant('info'))
      let socialTypeId  = platform?platform.id:null
      let postData = {}
      if(socialTypeId)
      postData['socialType'] = socialTypeId
      if(linkUrl)
      postData['url'] = linkUrl
      postData['studio_id'] = currentUserId
      postData['id'] = linkId
      postData['name'] = linkData.name
      updateSocialPlatform(postData,linkId)
      .then(function (response) {
        if(response.status===200){
          reRenderSocialLinks();
          disableHandler(false,null)
          dispatch(setSnackBarVariant('success'))
          dispatch(setSnackBarMessage(formSnackbar.socialLink.socialLinkUpdateSuccess))
          setTimeout(()=>dispatch(setSnackBar(false)),2000)
          setDisable(true)
        }  else{
          dispatch(setSnackBarVariant('error'))
            setTimeout(()=>dispatch(setSnackBar(false)),2000)
            let responseData = response.data;
            if(responseData.url && responseData.url.length>0)
            {
              dispatch(setSnackBarMessage(formSnackbar.errors.urlError));
            }
            else
            {
              dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
            } 
        }
      })
      .catch(error=>{
          dispatch(setSnackBarVariant('error'))
            setTimeout(()=>dispatch(setSnackBar(false)),2000)
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        return error;
      })
  }

  return (
    <>
      <div className="row" data-song-id={linkId}>
        <TextFieldGrid container item xs={12} sx={{padding:'0.5rem 1rem'}}>
            <Grid item xs={12}>  
            <TextField
                inputRef={textFieldRef} 
                fullWidth
                shrink
                size="small"
                variant="outlined"
                value={url}
                label={`${selectedType.socialType} Url`}
                placeholder={`${selectedType.socialType} Url`}
                onChange={urlChangeHandler}
                onKeyDown={handleKeyDown}
                // onKeyPress={handleKeyDown}
                onBlur={handleBlur}
                error={urlError}
                helperText={urlErrorText}
                InputProps={{
                startAdornment: (
                    <InputIcon>
                    <FontAwesomeIcon icon={getSocialTypeIcon(selectedType.socialTypeCode)} size='lg' />
                    </InputIcon>
                ),
                endAdornment: (
                  <InputIcon>
                  <button title="Delete" className='gn-btn-icononly' href="#"> {linkData && linkData.id!==undefined?<span id={linkData.id} onClick={()=>ref.current.handleOpen()}> <DeleteIcon className='text-secondary'/></span>:<span id={linkData.id} onClick={()=>linkDeleteHandler(linkId,index)}> <DeleteIcon /></span>}</button>
                  </InputIcon>
              ),
                }}
            />
            </Grid> 
        </TextFieldGrid>
      </div>
      {/* for delete modal */}
      <DeleteModal cardId={linkId && linkId} deleteHandler={(e)=>linkDeleteHandler(linkId,index)} ref={ref}/>
      {/* --------End------- */}
    </>
  )
}

export default SocialLink