import React, { useEffect, useState } from 'react';
import { Grid, TablePagination } from '@mui/material';
import CardLayout from '../../components/reusableComponents/CardLayout';
import ResponsiveCardList from '../../components/reusableComponents/songResponsiveList';
import useMediaQuery from '@mui/material/useMediaQuery';
import NoResults from '../../components/reusableComponents/NoResults';
import { record_limits } from '../../_constants/recordLimitSet.constant';

const SubmitSongCard = ({ songListData,handleChangePage,handleChangeRowsPerPage,page,rowsPerPage }) => {
  const smScreen = useMediaQuery('(max-width:768px)');
  const [songList, setSongList] = useState(songListData && songListData.results?songListData.results:[]);
  const [totalCount,setTotalCount] = useState(songListData?songListData.total_count:0)
  const isNextButtonDisabled = songList.length < rowsPerPage || (page + 1) >= Math.ceil(totalCount / rowsPerPage);
  const isPreviousButtonDisabled = page === 0;


  useEffect(() => {
    if (songListData && songListData.results && songListData.results.length > 0) {
        setSongList(songListData.results);
        setTotalCount(songListData.total_count)
    }
  }, [songListData]);

  return (
    <div>
      {songList && songList.length > 0 ? (
        !smScreen ? (
          <Grid container direction="row" alignItems="center" justify="center" className="d-flex align-items-center">
            {songList.map((song, index) => (
              <div key={song.song_id}>
                <CardLayout cardData={song} />
              </div>
            ))}
            <TablePagination
              rowsPerPageOptions={record_limits}
              colSpan={3}
              sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px' }}
              className="cardPagination"
              component="div"
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              nextIconButtonProps={{ disabled: isNextButtonDisabled }}

            />
          </Grid>
        ) : (
          <React.Fragment>
            {songList.map((song, index) => (
              <div key={song.song_id}>
                <ResponsiveCardList cardData={song} moduleName="songList" />
              </div>
            ))}
                  <div
                className="d-flex justify-content-between mb-5"
                style={{ width: '100%', marginTop: '20px' }}
              >
                <div style={{width:'50%'}}>
                  <button
                    onClick={() => handleChangePage(null, page - 1)}
                    disabled={isPreviousButtonDisabled}
                    className={`pagination-button ${isPreviousButtonDisabled ? 'disabled' : 'enabled'}`}
                  >
                    Previous
                  </button>
                </div>
                <div style={{ width: '20px' }}></div> {/* This creates a gap */}
                <div style={{width:'50%'}}>
                  <button
                    onClick={() => handleChangePage(null, page + 1)}
                    disabled={isNextButtonDisabled}
                    className={`pagination-button ${isNextButtonDisabled ? 'disabled' : 'enabled'}`}
                  >
                    Next
                  </button>
                </div>
              </div>
          </React.Fragment>
        )
      ) : (
        <NoResults />
      )}
    </div>
  );
};

export default SubmitSongCard;
