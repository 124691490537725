import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { SearchAssociateArtist } from '../../_constants/api.constant'
export async function associateArtistSearch(postData,actiontype,limit,offset){
    switch(actiontype)
    {
    case 'SEARCH':
        {
            const response = await axios.get(
                SearchAssociateArtist.SEARCH,
                {   params:{search:postData.searchValue},
                    // limit:limit,offset:offset
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    }, 
            
                   
                }
            ).then(function (response) {
                return response;
            }).catch(function (error) {
                // handle error
                console.log(error);
                console.log(error.response);
                return error.response;
            });
            return response;
        }    
    default:{return}
    }
}