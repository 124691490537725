import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia'; // Import CardMedia
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import defaultImage from '../../img/default image.png';

const MAX_CHAR_LIMIT = 40; 
function truncateText(text, limit) {
  return text.length > limit ? text.substring(0, limit) + '...' : text;
}
export default function ArtistReleaseList({ cardData, moduleName }) {
   
    const showInListView = (module) => {
        switch (module) {
            case 'artistReleaseList': {
                const firstLetter = cardData.song_name.charAt(0).toUpperCase();
                const truncatedRole = truncateText(cardData.role, MAX_CHAR_LIMIT);
                const truncatedReleaseDetails = truncateText(cardData.song_name,MAX_CHAR_LIMIT)
                return (
                    <div className='my-3 responsive-list' style={{ cursor: 'pointer' }}>
                        <Card sx={{ maxWidth: '500px', width: '100%' }}>
                            <div className='d-flex w-100'>
                                <div style={{ paddingTop: '0.5rem',paddingLeft:'0.4rem',paddingRight:'0.3rem',paddingBottom:'0.5rem' }}>
                                    <CardMedia
                                        component="img"
                                        sx={{
                                            width: '60px',
                                            height: '60px',
                                            borderRadius: '4px',
                                            backgroundColor: '#E41116', // You can customize the background color
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                        image={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60'%3E% cx='30' cy='30' r='28' fill='%232196F3' /%3E%3Ctext x='30' y='36' font-size='24' fill='white' font-family='Arial' font-weight='bold' text-anchor='middle'%3E${firstLetter}%3C/text%3E%3C/svg%3E`}
                                        onError={(e) => {
                                            e.target.src = defaultImage;
                                        }}
                                    />
                                </div>
                                <div style={{padding:'0.4rem', flexGrow: 1 }}>
                                    <div title={cardData.song_name}>
                                        <strong className='cardheadingFont overflow-text'>Release Detail : </strong>
                                        <span style={{wordBreak:'break-all'}}>
                                        {truncatedReleaseDetails}
                                        </span>
                                    </div>
                                    <div title={cardData.role}>
                                        <strong>Artist Role : </strong>
                                        <span style={{wordBreak:'break-all'}}>
                                            {truncatedRole}
                                        </span> 
                                    </div>
                                    <div className='mb-2'>
                                    <strong className='cardheadingFont overflow-text' >Status : </strong><span style={{backgroundColor:getSongStatusBgColor(cardData.song_status), minWidth:'72px'}} className='status text-light'>{cardData.song_status}</span>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                );
            }
            default: {
                return null;
            }
        }
    };

    return (
        showInListView(moduleName)
    );
}
