import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { submitAsongURL } from "../../_constants/api.constant";
export async function removeSongTrack(formData){
    return await axios.post(`${submitAsongURL.DELETETRACK}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Token '+getSelfToken()
        },
      });
}