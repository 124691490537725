import React,{useState, useEffect} from 'react'
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import DemoDataTable from './DemoDataTable';
import { Grid, TablePagination, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { submitDemo } from '../../_services/creator/creatorDemo.service';
import { fetchLoginUser, fetchSongReleaseList, fetchUserDetail, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant} from '../../redux';
import ResponsiveCardList from '../../components/reusableComponents/songResponsiveList';
import DemoResponsiveList from '../../components/reusableComponents/DemoResponsiveList';
import { formSnackbar } from '../../_constants/snackbar.constant';
import { Add } from '@mui/icons-material';
import DemoCard from './DemoCard';
import NoResults from '../../components/reusableComponents/NoResults';
import DeleteIcon from '@mui/icons-material/Delete';
import { default_limit, record_limits } from '../../_constants/recordLimitSet.constant';
import { useTheme } from "@mui/material/styles";


function DemoList(props) {
  const dispatch = useDispatch();
  const userData = useSelector(state=>state.userData.userData)
  const userDetail = useSelector((state) => state.userDetail.userDetail);
  let smScreen = useMediaQuery('(max-width:768px)')
  const [demoList, setDemoList] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(default_limit);
  const [totalCount,setTotalCount]=useState()
  const isNextButtonDisabled = demoList.length < rowsPerPage || (page + 1) >= Math.ceil(totalCount / rowsPerPage);
  const isPreviousButtonDisabled = page === 0;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (userData.studio_id) {

        dispatch(fetchLoginUser(userData.studio_id));
    }
  }, [userData.studio_id, dispatch]);

  useEffect(() => {
    if (userData.studio_id) {
      setPage(0);
      if(userData.role === 1){
        demoData(userData.studio_id, 0,rowsPerPage);
     } else{
       if(userData.hasOwnProperty('associated_studio') ){ 
        demoData(userData.associated_studio, 0,rowsPerPage);
        }    
     }
      
    }
  }, [userData.studio_id, rowsPerPage]);

 

 

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    const newPage = Math.min(Math.floor((page * rowsPerPage) / newRowsPerPage), Math.ceil(1000 / newRowsPerPage) - 1);
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
    if(userData.role === 1){
      demoData(userData.studio_id, newPage, newRowsPerPage);
   } else{
     if(userData.hasOwnProperty('associated_studio') ){ 
      demoData(userData.associated_studio, newPage, newRowsPerPage);
    }    
   }
    
  };

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const newOffset = newPage ;
    if(userData.role === 1){
      demoData(userData.studio_id, newPage, rowsPerPage, newOffset);
   } else{
     if(userData.hasOwnProperty('associated_studio') ){ 
      demoData( userData.associated_studio, newPage, rowsPerPage, newOffset);
     }    
   }
   
  };



  const demoData = (userId,page,rowsPerPage,offset) => {
    const limit = rowsPerPage;
    dispatch(setLoader(true));
      submitDemo({'userId':userId},'GET',limit,offset).then(function (response) {
      dispatch(setLoader(false))
      setDemoList(response.data.results)
      setTotalCount(response.data.total_count)
      // setPage(page)
      dispatch(fetchLoginUser(userData.studio_id));

  }).catch(function (error) {
      dispatch(setLoader(false))
      console.log(error);
      console.log(error.response);
      return error.response;
  })
  }

  const resetDemoDataList = () =>{
    setDemoList(null)
    demoData(userData.studio_id,page)
  } 

  const handleDeleteItem=(data)=>
  {
    if(data!==undefined )
    {
      dispatch(setSnackBarVariant('info'))
      dispatch(setSnackBarMessage(formSnackbar.submitDemo.DemoDelete))
      setTimeout(()=>dispatch(setSnackBar(false)),2000)
      dispatch(setSnackBar(true))
      let itemId  = data;
      submitDemo({'itemId':itemId},'DELETE').then(function (response) 
      {
          if(response.status === 204 ||response.status===200) 
          {
            dispatch(setSnackBarVariant('success'))
            dispatch(setSnackBarMessage(formSnackbar.submitDemo.DemoDeleteSuccess))
            dispatch(setLoader(false))
            setTimeout(()=>dispatch(setSnackBar(false)),2000)
            if(userData.role === 1){
              demoData(userData.studio_id, page);
           } else{
             if(userData.hasOwnProperty('associated_studio') ){ 
              demoData( userData.associated_studio, page);
             }    
           }
            //demoData(userData.studio_id,page); // get all records
          }
          else
          {
              dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
              dispatch(setSnackBarVariant('error'))
          }  
      })   
    }  
  }



  if(userData.studio_id ==="")
  return <h1>Loading...</h1>

  return (
    <div >
        <h2>Submit Demo</h2>
        <div className='mt-2'>
           { <button className='gn-actionbtn' onClick={props.handleChange} ><Add />Add Demo</button>}
        </div>
        <div className='my-3'>
        {demoList && demoList.length > 0 ? (
        !smScreen ? (
          <Grid container direction="row" alignItems="center" justify="center" className="d-flex align-items-center">
            {demoList.map((item, index) => (
              <div key={item.id}>
              <DemoCard
                   cardData={item} 
                   moduleName={'demoList'} 
                   dataList={demoList} 
                   getDataList={resetDemoDataList} 
                   handleDeleteItem = {handleDeleteItem}
                   userRole ={userData.role}
              />  
          </div>
            ))}
            <TablePagination
              rowsPerPageOptions={record_limits}
              colSpan={3}
              sx={{ 
                width: '100%',
                marginTop: isMobile ? '20px' : '0',
                padding: isMobile ? '16px' : '32px 0px 32px 32px',
                overflowX: isMobile ? 'hidden' : 'auto', // Hide overflow for mobile
              }}
              className="cardPagination"
              component="div"
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              nextIconButtonProps={{ disabled: isNextButtonDisabled }} 
            />
          </Grid>
        ) : (
          <React.Fragment>
            {demoList.map((item, index) => (
               <div key={item.id} style={{width:'100%'}}>
               <DemoCard
                    cardData={item} 
                    moduleName={'demoList'} 
                    dataList={demoList} 
                    getDataList={resetDemoDataList} 
                    handleDeleteItem = {handleDeleteItem}
               />  
           </div>
            ))}
            <div className="d-grid pagination-container mb-5"
            style={{ width: '100%', marginTop: '20px' }} >
            <div>
              <button onClick={() => handleChangePage(null, page - 1)}
                  disabled={isPreviousButtonDisabled}
                  className={`pagination-button gn-actionbtn ${isPreviousButtonDisabled ? 'disabled' : 'enabled'}`}
                  size='small'>
                  Previous
              </button>
            </div>
            
            <div>
                <button
                onClick={() => handleChangePage(null, page + 1)}
                disabled={isNextButtonDisabled}
                className={`pagination-button gn-actionbtn ${isNextButtonDisabled ? 'disabled' : 'enabled'}`}
                size='small'>
                Next
                </button>
            </div>
            </div>
          </React.Fragment>
        )
      ) : (
        <NoResults />
      )}
 
    </div>
    </div>
  )
}

export default DemoList