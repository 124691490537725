
import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, List, ListItem, ListItemText, Menu, MenuItem, Paper, Typography, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';

export default function SongpitchView() {
  const location = useLocation()
  const navigate = useNavigate();
  const songDataView = location?.state || ''

  const songpitch = [
    {breakValue:'false', label: 'Title/Album:', value: songDataView?.songData?.album_name || '--' },
    {breakValue:'false', label: 'Pitch Date:', value: songDataView?.songData?.pitching_date || '--' },
    {breakValue:'false', label: 'Label Name:', value: songDataView?.songData?.label || '--' },
    {breakValue:'false', label: 'Genre:', value: songDataView?.songData?.genre || '--' },
    {breakValue:'false', label: 'Language:', value: songDataView?.songData?.language || '--' },
    {breakValue:'false', label: 'Singer:', value: songDataView?.songData?.singer || '--' },
    {breakValue:'false', label: 'Lyricist:', value: songDataView?.songData?.lyricist || '--' },
    {breakValue:'false', label: 'Music (i) Music Producer:', value: songDataView?.songData?.music || '--' },
    {breakValue:'false', label: 'ISRC Code:', value: songDataView?.songData?.isrc_code || '--' },
    {breakValue:'false', label: 'UPC Code: ', value: songDataView?.songData?.upc_code || '--' },
    {breakValue:'false', label: 'Status:', value: songDataView?.songData?.pitching_status || '--' },
    {breakValue:'true', label: 'Drive Link:', value: songDataView?.songData?.drive_link || '--' },
    {breakValue:'true', label: 'Artist Overview:', value: songDataView?.songData?.artist_overview || '--' },
    {breakValue:'true', label: 'About Song:', value: songDataView?.songData?.song_description || '--' },
    {breakValue:'true', label: 'Comment:', value: songDataView?.songData?.comment || '--' },
  ]

  return (
    <Box className="creator-playlist-panel">
      <Box className="d-flex align-items-center justify-content-between mb-3">
        <button className="icononly-btn" onClick={() => navigate(-1)}><ArrowBackIcon /> Back</button>
      </Box>
      <Paper className="shadow-table" sx={{ width: '100%', mb: '3rem' }}>
        <Box p={3}>
          <Grid container sx={{ background: ' #F9F9F9', borderRadius: '10px', padding: '15px' }}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" sx={{fontSize:'16px', fontWeight:500}}>Basic Information</Typography>
              <Grid container spacing={2} mt={1}>
                {songpitch.map((item, index) => (
                  <Grid item xs={12} sm={item.breakValue == "true" ? 12 : 6} key={index}>
                    <Typography variant="body1" sx={{fontSize:'14px'}}>
                      <strong>{item.label}</strong>
                      <Typography variant='span' className={item.label == "Status:" && 'rounded-pill px-3 py-2 text-center text-white ms-2'} sx={{ background: item.label == "Status:" && getSongStatusBgColor(item?.value), padding: '10px', color:'#545454', fontWeight:500 }}>{item.value}</Typography>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          {/* <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button variant="contained" color="primary" disabled>
              EDIT
            </Button>
          </Box> */}
        </Box>
      </Paper>
    </Box>
  );
}