import { formSnackbar } from "../../../_constants/snackbar.constant";
import { regexValidator } from "../../../_helpers/reusablefunctions/regexValidator";

export const handleInputChange = (event, payoutData, setPayoutData, setPayoutDataErrors, payoutDataErrors) => {
    const { name, value } = event.target;
    setPayoutData({
        ...payoutData,
        [name]: value
    });
    if (event.type == 'blur') {
        switch (event.target.name) {

            case 'contact_email':
                {
                    const isValid = regexValidator(value, 'email')
                    if (!isValid)
                        setPayoutDataErrors({ ...payoutDataErrors, email: true, emailErr: 'Please enter a valid email.' });
                    else
                        setPayoutDataErrors({ ...payoutDataErrors, email: false, emailErr: false });
                    break;
                }
        
            case 'ifsc_code': {
                const isValid = regexValidator(value, 'IFSC')
                if (!isValid)
                    setPayoutDataErrors({ ...payoutDataErrors, ifsc: true, ifscErr: '11 character IFSC code is required.' });
                else
                    setPayoutDataErrors({ ...payoutDataErrors, ifsc: false, ifscErr: false });
                break;
            }
            case 'zipcode': {
                const isValid = regexValidator(value, 'zipcode')
                if (!isValid)
                    setPayoutDataErrors({ ...payoutDataErrors, zip_code: true, zipcodeErr: 'Please enter a valid ZIP code.' });
                else
                    setPayoutDataErrors({ ...payoutDataErrors, zip_code: false, zipcodeErr: false });
                break;
            }

            default:
                {
                    break;
                }
        }
    } else {
        switch (event.target.name) {
            case 'first_name':
            case 'city':
            case 'account_holder_name':
            case 'bank_name':
            case 'branch_name':
            case 'bank_city':
                {
                    const isValid = regexValidator(value, 'text')
                    const valueErr = event.target.name;
                    if (!isValid) {
                        setPayoutDataErrors({ ...payoutDataErrors, [valueErr]: true, [`${valueErr}Err`]: 'Are you sure you entered value correctly?' });
                    } else {
                        setPayoutDataErrors({ ...payoutDataErrors, [valueErr]: false, [`${valueErr}Err`]: false });
                    }
                    break;
                }
                case 'account_number': {
                    const isValid = regexValidator(value, 'banknumber')
                    if (!isValid)
                        setPayoutDataErrors({ ...payoutDataErrors, bank_number: true, bank_numberErr: 'Please enter a valid Account number(Only digits are allowed and length should be 11-16).' });
                    else
                        setPayoutDataErrors({ ...payoutDataErrors, bank_number: false, bank_numberErr: false });
                    break;
                }
            case 'address':
            case 'bank_address':
                {
                    const isValid = regexValidator(value, 'startSpace')
                    if (!isValid) {
                        setPayoutDataErrors({ ...payoutDataErrors, address: true, addressErr: 'Are you sure you entered value correctly?' });
                    } else {
                        setPayoutDataErrors({ ...payoutDataErrors, address: false, addressErr: false });
                    }
                    break;
                }
            case 'swift': {
                const isValid = regexValidator(value, 'swift')
                if (!isValid)
                    setPayoutDataErrors({ ...payoutDataErrors, swift: true, swiftErr: 'SWIFT code is not valid for: India (8 or 11 characters). Contact your bank to obtain this code.' });
                else
                    setPayoutDataErrors({ ...payoutDataErrors, swift: false, swiftErr: false });
                break;
            }
            default:
                {
                    break;
                }
        }
    }
}

export const handleInputPhoneChange = (event, payoutData, setPayoutData) => {
    setPayoutData({
        ...payoutData,
        'phone_number': event
    });
}

export const isFieldsValid = (errObj) => {
    let array = [];
    for (let key in errObj) {
        array.push(errObj[key])
    }
    if (array.every((item) => item === false)) {
        return true;
    } else {
        return false;
    }
}