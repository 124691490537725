
import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Menu, MenuItem, Paper, Stack, Typography, useMediaQuery } from '@mui/material';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import playlist from '../../img/Frame 48096170.png'
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter';
import TableComponent from '../Tablecomponent';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function ReleaseTable({ albumListData, page, rowsPerPage, setRowsPerPage, setPage, ...props }) {
  const [rows, setRows] = useState(albumListData.results);
  const [totalCount, setTotalCount] = useState('');
  const [menuRowId, setMenuRowId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    setRows(albumListData.results)
    setTotalCount(albumListData?.totalCount)
  }, [albumListData])

  const handleMenuClick = (event, id) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget);
    setMenuRowId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuRowId(null);
  };

  const columns = [
    {
      id: 'album_name', label: 'Release', headerName: '', flex: 1, template: (row) => {
        return (
          <Box className="d-flex align-items-center gap-2 cursorPointer" onClick={(e) => props.handleClick(e, row)} >
            <img className="rounded-pill" src={row.cover_image ? process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' + row.cover_image : playlist} style={{ width: '40px', height: '40px' }} />
            <Typography variant='span'>{row.album_name}</Typography>
          </Box>
        )
      }
    },
    {
      id: 'genre', label: 'Genre', headerName: '', flex: 1, template: (row) => {
        return (
          <Box>
            {props?.genre?.find(item => item.id === row.genre)?.genre}
          </Box>
        )
      }
    },
    {
      id: 'primary_artists', label: 'Artist', headerName: '', flex: 1, template: (row) => {
        return (
          <Box>
            {row?.primary_artists.length ? row.primary_artists.map(p => p.artist_name).join(', ') : "--"}
          </Box>
        )
      }
    },
    {
      id: 'release_date', label: 'Release Date', headerName: '', flex: 1, template: (row) => {
        return (
          <Box>
            {row.release_date == (null || '') ? '--' : getDateAsPerFormat(row.release_date, "DD Mon, YYYY")}
          </Box>
        )
      }
    },
    {
      id: 'song_status', label: 'Status', headerName: '', flex: 1, template: (row) => {
        return (
          <Stack direction="row" spacing={1}>
            <Chip className='p-2 text-white' sx={{ background: getSongStatusBgColor(row?.song_status) }} label={row.song_status == "draft" ? "Draft" : row.song_status} />
          </Stack>
        )
      }
    },
    {
      id: 'action', label: 'Action', headerName: '', flex: 1, template: (row) => {
        return (
          <Box className='text-decoration-none viewDetailgradient cursorPointer' ><Button
            id={`basic-button-${row.album_id}`}
            aria-controls={menuRowId === row.album_id ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuRowId === row.album_id ? 'true' : undefined}
            onClick={(event) => handleMenuClick(event, row.album_id)}
          >
            <MoreVertIcon />
          </Button>
            <Menu
              id={`basic-menu-${row.album_id}`}
              anchorEl={anchorEl}
              open={menuRowId === row.album_id}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': `basic-button-${row.album_id}`,
              }}
            >
              <MenuItem className='px-4 py-2 d-flex gap-3' onClick={(e) => props.handleClick(e, row)} >View Album</MenuItem>
              {row.song_status == "draft" && <MenuItem onClick={(e) => props.deleteAlbum(e, row)} className='px-4 py-2 d-flex gap-3'>Delete Album</MenuItem>}
            </Menu>
          </Box>
        )
      }

    },
  ];



  return (
    <div className="creator-playlist-panel">
      <Paper className="shadow-table" sx={{ width: '100%', mb: '3rem' }}>
        <TableComponent columns={columns} data={rows} page={page} setPage={setPage} removeStyle={'removeStyle'} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} totalCount={totalCount} />
      </Paper>
    </div>
  );
}