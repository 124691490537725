
import ApiService from '../../_services/ApiService';

export const fetchMusicCategoryDetail = (url,type) => {
  return (dispatch) => {
    // Dynamically import the actionTypes object
    import('./musicCategoryTypes').then(({ actionTypes }) => {
      dispatch(fetchMusicCategoryRequest(type, actionTypes));
      ApiService.get(url).then(res => {
        let response = res.data;
      if (response) {
          dispatch(fetchMusicCategorySuccess(type, response, actionTypes));
        }
      }).catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(fetchMusicCategoryFailure(type, error.message, actionTypes));
      });
    });
  };
};

export const fetchMusicCategoryRequest = (type, actionTypes) => {
  return {
    type: actionTypes[`FETCH_${type}_DATA_REQUEST`]
  };
};

export const fetchMusicCategorySuccess = (type, userData, actionTypes) => {
  return {
    type: actionTypes[`FETCH_${type}_DATA_SUCCESS`],
    payload: userData
  };
};

export const fetchMusicCategoryFailure = (type, error, actionTypes) => {
  return {
    type: actionTypes[`FETCH_${type}_DATA_FAILURE`],
    payload: error
  };
};

// export const setTabValue = data => {
//   return {
//     type: SET_TAB_VALUE,
//     payload: data
//   }
// }