import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SnackbarContainer from '../Snackbar';
import CardSekeleton from '../../components/reusableComponents/SkeletonCard';
import NoResults from '../../components/reusableComponents/NoResults';
import AlbumCard from './AlbumCard';
import { Link, useNavigate } from 'react-router-dom';
import { associatedArtistsRequestHandler } from '../../_services/associatedArtists/associatedArtist.service';
import { fetchMusicCategoryDetail, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_constants/snackbar.constant';
import { artistCode } from '../songList/associatedArtist/artistStaticCodes';
import { handleArtistData } from '../songList/helperComponent/helperComponent';
import { fetchReleaseAlbum } from '../../redux/releaseAlbum/releaseAlbumDataActions';
import { useEffect } from 'react';
import CreateAlbumModal from './CreateAlbumModal';
import { TablePagination, useMediaQuery } from '@mui/material';

function AllAlbumSong(props) {
  const userData = useSelector((state) => state.userData.userData);
  const loaderData = useSelector((state) => state.loader.loader);
  const record_limits = [10, 20, 25, 30] //used to set the limits from one signle place
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [primaryArtList, setPrimaryArtList] = React.useState([])
  const [assoArtistList, setAssoArtistList] = React.useState([])
  const [producerList, setProducerList] = React.useState([])
  const [albumList, setAlbumList] = useState([]);
  const [searchInput, setSearchInput] = useState(null)
  const [hasMore, setHasMore] = useState(false)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [flag, setFlag] = useState(false);
  const history = useNavigate()
  const numberOfSkeletonCards = 12
  const genre = useSelector((state) => state.musicCategorgyReducer?.genreData ? state.musicCategorgyReducer?.genreData.data : []);
  const subGenreList = useSelector((state) => state.musicCategorgyReducer?.subGenreData ? state.musicCategorgyReducer?.subGenreData.data : []);

  const skeletonCards = Array.from({ length: numberOfSkeletonCards }, (_, index) => (
    <CardSekeleton key={index} />
  ));

  const albumData = useSelector((state) => state.releaseAlbumReducer?.releaseAlbumData)
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    if (userData.studio_id != undefined) {
      dispatch(setLoader(true))
      if (page == 0) {
        dispatch(fetchReleaseAlbum(userData.studio_id, { limit: 25, offset: page }, [], null));
        getAssociatedArtist();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      if (genre == undefined || genre.length == 0) {
        dispatch(fetchMusicCategoryDetail("genreData", `GENRE`))
      }
      if (subGenreList == undefined || subGenreList.length == 0) {
        dispatch(fetchMusicCategoryDetail("subGenreData", `SUBGENRE`))
      }

    }
  }, [userData.studio_id && genre != undefined && subGenreList != undefined]);
  // Make sure to add albumList and page as dependencies

  useMemo(() => {
    if (albumData.data) {
      dispatch(setLoader(false)) // Check if albumList is empty before setting
      setAlbumList(albumData.data);
    }
  }, [albumData])

  const artistSelectChangeHandler = (name, artistData, isNewArtist) => {
    switch (name) {
      case artistCode.primary_artist:
        handleArtistData(name, primaryArtList, setPrimaryArtList, 'primary_artists', isNewArtist, artistData, artistCode);
        break;
      case artistCode.producer:
        handleArtistData(name, producerList, setProducerList, 'producers', isNewArtist, artistData, artistCode);
        break;

      default:
        break;
    }
  }

  function getArtistName(a) {
    let assoPrimary = a.map(p => setAssodata(p))
    if (a) {
      const matchingArtists = assoArtistList.length > 0 && assoArtistList.filter(artist => assoPrimary.find(item => item.artistCode == artist.artist_code))
    }

  }


  const setAssodata = (d) => {
    let data = {
      'id': d,
      'artistCode': `studio${d}`
    }
    return data
  }

  const getAssociatedArtist = (name, artistData, isNewArtist) => {
    let postData = {}
    if (userData.role === 1) {
      postData['userId'] = userData.studio_id
    } else {
      if (userData.hasOwnProperty('associated_studio')) {
        postData['userId'] = userData.associated_studio
      }
    }
    associatedArtistsRequestHandler(postData, 'GET')
      .then(response => {
        //set the associated artist list
        if (response.data) {
          setAssoArtistList(response.data.results)
          isNewArtist && artistSelectChangeHandler(name, artistData, isNewArtist)  //call the handler when new artist has beed added
        }
      })
      .catch(err => {
        dispatch(setSnackBar(true));
        setTimeout(() => dispatch(setSnackBar(false), 2000));
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      })
  }

  const breadcrumbs = [
    { label: 'Release Song', link: '/releases' },
    { label: 'Album', link: '/releasealbum' },
  ];

  const addAlbum = (newAlbum) => {
    //to rerender the list
    // getAlbumList()
    let d = []
    dispatch(fetchReleaseAlbum(userData.studio_id, { limit: 25, offset: page }, d, null));
  };

  const openDrawer = () => {
    // history("/addrelease")
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div className=" creator-playlist-panel">
      <div className="row m-0">
        <div className="col-12 p-0">
          <div className='d-flex justify-content-between'>
            <h2>Song List</h2>
            <button className="gn-actionbtn" onClick={openDrawer}>
              NEW RELEASE
            </button>
          </div>
          {/* <div className='d-flex justify-content-between'>
            <div className='flex-grow-1' style={{maxWidth:'18rem'}}>
              <SearchBox handleSearchInput={handleSearchInput} value={searchInput}/>
            </div>
        </div> */}
          <>
            <div className="my-3">
              <div className="">
                {loaderData ?
                  <div className="wrapper-card">

                    {skeletonCards}
                  </div> :

                  albumList.length > 0 ?
                    // <InfiniteScroll
                    //   dataLength={albumList != undefined ? albumList.length : []}
                    //   next={fetchMoreData}
                    //   hasMore={true}
                    //   loader={flag == true ? <>Loading........</> : false}
                    // >
                    <div>
                      <div className="wrapper-card">

                        {albumList.map((album, index) => (
                          <AlbumCard
                            key={album.album_id}
                            albumInfo={album}
                            subGenreList={subGenreList}
                            genre={genre}
                            assoArtistList={assoArtistList}
                            getArtistName={getArtistName}
                            albumList={albumList}
                            setAlbumList={setAlbumList}
                          />
                        ))}
                      </div>
                      <TablePagination
                        rowsPerPageOptions={record_limits}
                        colSpan={3}
                        sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px' }}
                        className="cardPagination"
                        component="div"
                        count={albumData?.totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                      />
                    </div>
                    :
                    <NoResults />
                }
              </div>
            </div>
          </>
        </div>
        <CreateAlbumModal isOpen={isDrawerOpen}
          onClose={closeDrawer}
          onCreateAlbum={addAlbum}
          genre={genre}

        />
      </div>
      <SnackbarContainer />
    </div>
  );
}

export default AllAlbumSong;
