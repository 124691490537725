import * as React from 'react';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import playlist from '../../img/Frame 48096170.png'
import { useNavigate } from 'react-router-dom';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter';
import DeleteIcon from '@mui/icons-material/Delete';
import ApiService from '../../_services/ApiService';
import { setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { useDispatch } from 'react-redux';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
export default function AlbumCard({ albumInfo, subGenreList, genre, assoArtistList, getArtistName, albumList, setAlbumList, ...props }) {
  let history = useNavigate();
  const dispatch = useDispatch()
  function compareByKey(assoArtistList, primary_astist, key) {
    let resultMap = new Map();
    assoArtistList.forEach(artist => resultMap.set(artist[key], artist));

    let resultArray = [];
    primary_astist.forEach(primaryArtist => {
      let artist = resultMap.get(primaryArtist[key]);
      if (artist) {
        resultArray.push(artist);
      }
    });

    return resultArray;
  }

  let primary_artistArray = compareByKey(assoArtistList, albumInfo.primary_artists, "artist_code");
  let featuring_artistArray = compareByKey(assoArtistList, albumInfo.featuring_artists, "artist_code");



  const limitPlaylistName = (name, limit) => {
    if (name.length > limit) {
      return name.substring(0, limit) + '...';
    }
    return name;
  };

  const artistDetail = [{ primaryValue: 'Artist Name', secondaryValue: primary_artistArray?.map(p => p?.artist_name).join(',') || '--' },
  { primaryValue: 'Genere', secondaryValue: genre?.find(item => item.id === albumInfo?.genre)?.genre || '--'},
  { primaryValue: 'Release Date', secondaryValue: albumInfo?.release_date == (null || '') ? '--' : getDateAsPerFormat(albumInfo?.release_date, "DD Mon, YYYY") },
  ]


  return (

    <div className="box-album-card table-container shadow-table p-3" onClick={(e) => props.handleClick(e, albumInfo)} role="button" tabIndex="0">
      <div style={{ position: 'relative' }}>
        <div className='position-absolute px-2' style={{ right: '0px', top: '10px' }}>
          <div className='leftStatusCard' style={{ textAlign: "center", background: getSongStatusBgColor(albumInfo.song_status), borderRadius: "15px", color: '#fff', padding: '5px', fontSize: '12px' }}>
            {albumInfo.song_status == "draft" ? "Draft" : albumInfo.song_status}
          </div>

        </div>
        <CardMedia
          component="img"
          style={{
            // height: '160px',
            objectFit: 'fill'
          }}
          className="card-mediaAlbum"
          image={albumInfo.cover_image ? process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' + albumInfo.cover_image : playlist}
        />
      </div>
      <CardContent className='p-2 d-grid'>
        <div className='d-flex justify-content-between'>
          <span className='albumTitle'>{limitPlaylistName(albumInfo.album_name, 26)}</span>
          {albumInfo.song_status == "draft" ? <div>
            <div onClick={(e) => props.deleteAlbum(e, albumInfo)} className=' rounded-pill'>
              <DeleteIcon />
            </div>
          </div> : null}
        </div>
        <List>
          {artistDetail.map((d, i) => (
            <ListItem className='p-0' key={i}>
              <ListItemText className='m-0' primary={<Typography sx={{fontSize:'14px', color:'#616477', fontWeight:'400'}}>{d.primaryValue}</Typography>} secondary={<Typography sx={{fontSize:'14px', color:'#616477', fontWeight:'600'}} variant='span'>{d.secondaryValue}</Typography>} />
            </ListItem>
          ))}
        </List>
      </CardContent >
    </div >
  );
}