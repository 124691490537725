import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import { submitAsongURL } from '../../_constants/api.constant'
export async function deleteSongRequest(songId){
    const response = await axios.delete(
        submitAsongURL.DELETE+songId+'/',
        {   
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;   
}