import {
  FETCH_PAYEEE_BANK_DATA_REQUEST,
  FETCH_PAYEEE_BANK_DATA_FAILURE,
  FETCH_PAYEEE_BANK_DATA_SUCCESS
} from "./payoutBankDataTypes";
import ApiService from "../../_services/ApiService";


  export const fetchPayeeBankData = (url,pagination) => {
    return (dispatch) => {
      dispatch(fetchPayeeBankDataRequest());         
      ApiService.get(url, pagination).then(res => {
        let response = res.data;
      if (response) {
          dispatch(fetchPayeeBankDataSuccess(response));
        }
      }).catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(fetchPayeeBankDataFailure(error.response));
      });
  }
}
  
  
  export const fetchPayeeBankDataRequest = () => {
    return {
      type: FETCH_PAYEEE_BANK_DATA_REQUEST
    }
  }
  
  export const fetchPayeeBankDataSuccess = releaseAlbumData => {
    return {
      type: FETCH_PAYEEE_BANK_DATA_SUCCESS,
      payload: releaseAlbumData
    }
  }
  
  export const fetchPayeeBankDataFailure = error => {
    return {
      type: FETCH_PAYEEE_BANK_DATA_FAILURE,
      payload: error
    }
  }
  