import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, ListItem } from '@mui/material';
import FAQslist from './FAQslist.json';

export default function FAQs() {
    const [expandedCategory, setExpandedCategory] = React.useState(false);
    const [expandedFAQ, setExpandedFAQ] = React.useState(false);

    const handleCategoryChange = (panel) => (event, isExpanded) => {
        setExpandedCategory(isExpanded ? panel : false);
    };

    const handleFAQChange = (panel) => (event, isExpanded) => {
        setExpandedFAQ(isExpanded ? panel : false);
    };

    const renderFAQs = (faqs) => {
        return faqs.map((faq, j) => (
            <Accordion key={j} expanded={expandedFAQ === faq.panel} onChange={handleFAQChange(faq.panel)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${faq.id}bh-content`}
                    id={`panel${faq.id}bh-header`}
                    sx={{ background: 'rgba(0, 0, 0, .03)' }}
                >
                    <Typography>{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {Array.isArray(faq.answer) && faq.answer.length > 0 ? (
                        <ul>
                            {faq.answer.map((d, index) => (
                                <li key={index}>{d}</li>
                            ))}
                        </ul>
                    ) : (
                        <Typography variant="body1">{faq.answer}</Typography>
                    )}
                </AccordionDetails>
            </Accordion>
        ));
    };

    const renderSubHeadingAndFAQs = (category, subHeadingKey, faqsKey) => {
        if (category[subHeadingKey] || category[faqsKey]) {
            return (
                <>
                    {category[subHeadingKey] && (
                        <Typography sx={{ background: 'rgba(0, 0, 0, .03)' }} className=' p-3 fw-bold rounded-2 shadow-sm'>
                            {category[subHeadingKey]}
                        </Typography>
                    )}
                    {category[faqsKey] && renderFAQs(category[faqsKey])}
                </>
            );
        }
        return null;
    };

    return (
        <div className='mb-5'>
            <Typography variant='h5' className='mb-4'>
                Frequently Asked Questions
            </Typography>
            {FAQslist.map((category, i) => (
                <Accordion key={i} expanded={expandedCategory === category.id} onChange={handleCategoryChange(category.id)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${category.id}bh-content`}
                        id={`panel${category.id}bh-header`}
                    >
                        <Typography className='fw-bold'>
                            {category.category}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {renderSubHeadingAndFAQs(category, 'subHeading', 'faqs')}
                        {renderSubHeadingAndFAQs(category, 'subHeading2', 'faqs2')}
                        {renderSubHeadingAndFAQs(category, 'subHeading3', 'faqs3')}
                        {renderSubHeadingAndFAQs(category, 'subHeading4', 'faqs4')}
                        {renderSubHeadingAndFAQs(category, 'subHeading5', 'faqs5')}
                        {renderSubHeadingAndFAQs(category, 'subHeading6', 'faqs6')}
                        {renderSubHeadingAndFAQs(category, 'subHeading7', 'faqs7')}
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}