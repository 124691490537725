import React, { useContext,useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarContext } from '../../App';
import MuiAlert from '@mui/material/Alert';
// import { useSelector, useDispatch } from 'react-redux'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
function SnackbarContainer(props) {
    const { snack, setSnack } = useContext(SnackbarContext);
    let style = {width:'100%'}
    style.backgroundColor = snack.color?'':'#333'
    style.color = snack.color?'':'#fff'
    //  const snackBarData = useSelector((state) => state.snackBar);
    const handleClose = () =>{
        setSnack(false)
    }
    // setTimeout(handleClose(),5000)
    //setSnack({message: '', open: false,vertical:'bottom',horizontal:'right'})

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
             open={snack.open}
             className="snackbarColor"
             //autoHideDuration={AUTODISMISS} 
             key={snack.vertical + snack.horizontal}
             action={snack.action}
        >
        <Alert onClose={handleClose} severity={snack.color} sx={style}>
        {snack.message}
        </Alert>
        </Snackbar>
    );
}

export default SnackbarContainer;