import { Box, Checkbox, Drawer, FormControl, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import SwitchFilter from '../../components/reusableComponents/SwitchFilter'
import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getGenre } from '../../_services/profile';
import moment from 'moment-timezone';
import { recordLabelRequest } from '../../_services/recordLabel/recordLabel.service';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SongFilters({ isFilterApplied, applyAdvanceFilters }) {
  const [songStatusList, setSongStatusList] = useState([
    {title:"Draft", value:'draft'},
    {title:"In Review", value:'in review'},  
    {title:"Ready For Release", value:'ready for release'},
    {title:"Released", value:'released'},   
    {title:"Approved", value:'approved'}, 
    {title:"Reject", value:'reject'},                                                           

  ])
  const [open, setOpen] = useState(false)
  const smallViewport = useMediaQuery('(max-width:768px)');
  const [selectedSongs, setSelectedSongs] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [genres, setGeners] = useState([])
  const [selectedArtist, setSelectedArtist] = useState([]);
  const [selectedAlbums, setSelectedAlbums] = useState([]);
  const [songStatus, setSelectedRecordLabel] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [payloadData, setPayloadData] = useState({})

  const drawerCloseHandler = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (!isFilterApplied) {
      setSelectedSongs([])
      setSelectedGenres([])
      setToDate(null)
      setFromDate(null)
      setSelectedArtist([])
      setSelectedAlbums([])
      setSelectedRecordLabel([])
    }
  }, [isFilterApplied])
  useEffect(() => {
    getGenreList()
  }, [])
  const getGenreList = () => {
    getGenre().then(function (response) {
      setGeners(response.data.data)
    }).catch(function (error) {
      console.log(error);
      console.log(error.response);
      return error.response;
    })
  }

  const handleSongChange = (event) => {
    setSelectedSongs(event.target.value)
  };
  const handleGenreChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedGenres(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const handleArtistChange = (event) => {
    setSelectedArtist(event.target.value)
  };
  const handleAlbumChange = (event) => {
    setSelectedAlbums(event.target.value)
  };
  const handleRecordingStudioChange = (event) => {
    const {
      target: { value },
    } = event;
    console.debug("qshbksbksxq", event)
    setSelectedRecordLabel(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const dateZoneHandler = (date) => {
    const newDate = moment.utc(date.toISOString()).tz("Asia/Kolkata");
    const convertedDate = newDate.format('YYYY-MM-DD HH:mm:ss');
    return convertedDate
  }

  const fromDateChangeHandler = (newValue) => {
    setFromDate(newValue)
    const isReleaseDateInvalid = newValue === null || isNaN(Date.parse(newValue));
    if (!isReleaseDateInvalid) {
      const datePayload = dateZoneHandler(newValue)
      setPayloadData({ ...payloadData, release_date_from: datePayload })
    }
  }

  const toDateChangeHandler = (newValue) => {
    setToDate(newValue)
    const isReleaseDateInvalid = newValue === null || isNaN(Date.parse(newValue));
    if (!isReleaseDateInvalid) {
      const datePayload = dateZoneHandler(newValue)
      setPayloadData({ ...payloadData, release_date_to: datePayload })
    }
  }


  function applyFilter(e) {
    e.preventDefault();
    var postData = {}
    if (selectedGenres && selectedGenres.length > 0) {
      postData['genre'] = selectedGenres.map((value) => genres.find(genre => genre.id === value).genre).join(',')
    }
    if (selectedAlbums && selectedAlbums.length > 0) {
      postData['album_name'] = selectedAlbums.replaceAll(', ', ',')
    }
    if (selectedArtist && selectedArtist.length > 0) {
      postData['artist'] = selectedArtist.replaceAll(', ', ',')
    }
    if (songStatus && songStatus.length > 0) {
      postData['song_status'] = songStatus.join(',')
    }
    if (fromDate) {
      postData['release_date'] = payloadData.release_date_from
    }

    if (selectedSongs.length > 0 || selectedGenres.length > 0 || selectedAlbums.length > 0
      || selectedArtist.length > 0 || songStatus.length > 0 || fromDate != null || toDate != null) {
      applyAdvanceFilters(postData)
      drawerCloseHandler()
    }

    //fire api to get the filtered data
  }
  return (
    <div>
      {/* filter icon button */}
      <Box onClick={() => setOpen(true)} className='mx-2' style={{ padding: 0, borderRadius: 0, fontSize: 0 }}>
        <SwitchFilter />
      </Box>
      <Drawer
        sx={{
          width: '400px',
          flexShrink: 0,
          '& .MuiDrawer-paper':
          {
            width: smallViewport ? '300px' : '400px',
            boxSizing: 'border-box',
            // backgroundColor: '#F6F6F6'
          },
        }}
        anchor="right"
        open={open}>
        <div>
          <div className="p-3 d-flex justify-content-between align-items-center" style={{ backgroundColor: '#0000000D' }}>
            <div>
              <h4>Filters</h4>
            </div>
            <div style={{ position: 'relative', top: '-5px' }}>
              <button title="Close" className="icononly-btn" onClick={drawerCloseHandler}>
                <CloseIcon />
              </button>
            </div>
          </div>
          <div className="p-4">
            <div>
              {/* <form  > */}
              {/* <div>
                <h5 className='mb-2'>Song</h5>
                <FormControl fullWidth size='small'>
                  <TextField
                    label="Enter song name"
                    size="small"
                    value={selectedSongs}
                    onChange={handleSongChange}
                  />
                </FormControl>
              </div> */}
                <div >
                <h5 className='mb-2 mt-3'>Album</h5>
                <FormControl fullWidth size='small'>
                  <TextField
                    label="Enter album name"
                    size="small"
                    value={selectedAlbums}
                    onChange={handleAlbumChange}
                  />
                </FormControl>
              </div>
              <div >
                <h5 className='mb-2 mt-3'>Genre</h5>
                <FormControl fullWidth size='small'>
                  <InputLabel id="demo-multiple-checkbox-label">Select Genre</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedGenres}
                    onChange={handleGenreChange}
                    input={<OutlinedInput label="Select Genre" />}
                    renderValue={(selected) => selected.map((value) => genres.find(genre => genre.id === value).genre).join(',')}
                    MenuProps={MenuProps}
                  >
                    {genres && genres.length > 0 && genres.map((genre) => (
                      <MenuItem key={genre.id} value={genre.id}>
                        <Checkbox checked={selectedGenres.indexOf(genre.id) > -1} />
                        <ListItemText primary={genre.genre} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div >
                <h5 className='mb-2 mt-3'>Artist</h5>
                <FormControl fullWidth size='small'>
                  <TextField
                    label="Enter artist name"
                    size="small"
                    value={selectedArtist}
                    onChange={handleArtistChange}
                  />
                </FormControl>
              </div>
            
              <div >
                <h5 className='mb-2 mt-3'>Song Status</h5>
                <FormControl fullWidth size='small'>
                  <InputLabel id="demo-multiple-checkbox-label">Select Song Status</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    // multiple
                    value={songStatus}
                    onChange={handleRecordingStudioChange}
                    input={<OutlinedInput label="Select Song Status" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                  >
                    {songStatusList.map((value) => (
                      <MenuItem key={value.value} value={value.value}>
                        {/* <Checkbox checked={songStatus.indexOf(value.value) > -1} /> */}
                        <ListItemText primary={value.title} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div>
                <h5 className='mt-3'>Release Date</h5>
                <div className='mt-2' >
                  <FormControl fullWidth>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}  >
                      <DatePicker
                        views={['year', 'month', 'day']}
                        label="Release Date"
                        maxDate={dayjs(toDate)}
                        value={fromDate}
                        onChange={fromDateChangeHandler}
                        renderInput={(params) => <TextField size="small" sx={{ minWidth: "100%", backgroundColor: '#F6F6F6', mt: 1 }} {...params} />}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </div>
              </div>
              <div className='mt-4'>
                <button className='gn-actionbtn' onClick={applyFilter}>Submit</button>
                <button type="button" className='ms-4 gn-actionbtn outlined' onClick={drawerCloseHandler}>Cancel</button>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default SongFilters