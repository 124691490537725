import axios from "axios";
import { submitAgreement } from '../../_constants/api.constant'
import { getSelfToken } from "../../_helpers/auth/secureToken";
export async function postAgreement(postData,actiontype) {
  switch(actiontype)
    {   
        case 'POST':
            {    
            const response=await axios.post(
            submitAgreement.POST,
            postData,
            {
                // params:prarmTempObj,
                headers:{
                    Authorization: 'Token '+getSelfToken(),
                },
            }
            ).then(function(response) {
            return response;
            }).catch(function (error) {
            console.log(error);
            console.log(error.response);
            return error.response;
            });
            return response;
        }
        case 'GET':
            {    
            const response=await axios.get(
            submitAgreement.GET+'?search='+postData.id,
            {
                // params:prarmTempObj,
                headers:{
                    Authorization: 'Token '+getSelfToken(),
                },
            }
            ).then(function(response) {
            return response;
            }).catch(function (error) {
            console.log(error);
            console.log(error.response);
            return error.response;
            });
            return response;
        }
        case 'DELETE':
            {    
            const response=await axios.delete(
            submitAgreement.DELETE+'?id='+postData.id,
            {
                // params:prarmTempObj,
                headers:{
                    Authorization: 'Token '+getSelfToken(),
                },
            }
            ).then(function(response) {
            return response;
            }).catch(function (error) {
            console.log(error);
            console.log(error.response);
            return error.response;
            });
            return response;
        }
        default:{return}    
    }
    
}