// import React from 'react'

// function getPaymentDetail() {



//   return (
//     <div>getPaymentDetail</div>
//   )
// }

// export default getPaymentDetail

import React, { useEffect, useState } from 'react';
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getPaymentDetail } from '../../_services/album/paymentDistribution.service';

const invoiceData = [
  { quantity: 10, description: 'Flatter Theme', unitPrice: 51.8, totalPrice: 518 },
  { quantity: 6, description: 'Flat Icons', unitPrice: 55.9, totalPrice: 335.4 },
  { quantity: 4, description: 'Wordpress version', unitPrice: 95, totalPrice: 380 },
  { quantity: 1, description: 'Server Deployment', unitPrice: 5300, totalPrice: 5300 },
];

const GetPaymentDetail = () => {

    const {orderId} = useParams()
    const [orderDetail, setOrderDetail] = useState()
      
    useEffect(()=>{
        if(orderId){ 
        getPaymentDetail("order_O5e2xtSy8SdjO4").then(res => {
            setOrderDetail(res.data)
        })
        }
    },[orderId])
  return (
    <Box sx={{ maxWidth: '800px', mx: 'auto', p: 4 }}>
      <Typography variant="h4" gutterBottom>
        {/* {orderDetail?.} */}
        INVOICE-1234578
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant="subtitle1">From:</Typography>
          <Typography variant="body2">Dynofy</Typography>
          <Typography variant="body2">350 Rhode Island Street</Typography>
          <Typography variant="body2">Suite 240, San Francisco</Typography>
          <Typography variant="body2">Califomia, 94103</Typography>
          <Typography variant="body2">Phone: 415-767-3600</Typography>
          <Typography variant="body2">Email: contact@dynofy.com</Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1">To:</Typography>
          <Typography variant="body2">John Doe</Typography>
          {/* <Typography variant="body2">425 Market Street</Typography>
          <Typography variant="body2">Suite 2200. San Francisco</Typography>
          <Typography variant="body2">California, 94105</Typography>
          <Typography variant="body2">Phone: 415-676-3600</Typography>
          <Typography variant="body2">Email: johnidoe@com</Typography> */}
        </Box>
      </Box>
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        Payment details
      </Typography>
      <Typography variant="body2">Date: 14 April 2014</Typography>
      <Typography variant="body2">VAT DK888-777</Typography>
      <TableContainer sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Unit Price</TableCell>
              <TableCell align="right">Total Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceData.map((item) => (
              <TableRow key={item.description}>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">{item.unitPrice}</TableCell>
                <TableCell align="right">{item.totalPrice}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Box>
          <Typography variant="body2">Subtotal: $1019</Typography>
          <Typography variant="body2">Discount (10%) $101</Typography>
          <Typography variant="body2">VAT (8%): $73</Typography>
          <Typography variant="h6">Total $991</Typography>
        </Box>
      </Box>
      <Typography variant="body2" sx={{ mt: 2 }}>
        THANK YOU!
      </Typography>
    </Box>
  );
};

export default GetPaymentDetail;

