import { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./admin/header/layout";
import Login from "./admin/Login/login";

const PrivateRoute = ({isAuthenticated}) => {
   //const [isAuthenticated, setIsAuthenticated] = useState(false);
   const navigate = useNavigate();
   //alert(loginStatus)
   /* useEffect(() => {
     const accessToken = localStorage.getItem("token");

     if (!accessToken) {
      // Redirect to the login page if the access token is not present
      navigate("/login");
      setIsAuthenticated(false);
    } else {
      navigate('/dashboard');
      setIsAuthenticated(true);
    }
  }, [navigate]); */

  return isAuthenticated() ?  <Layout /> : <Navigate to="/login" replace />

}
export default PrivateRoute