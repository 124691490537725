import { ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button, Typography, useTheme } from '@mui/material';
import '../../style/customStyle.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { fetchMusicCategoryDetail } from '../../redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});



const Dashboard = () => {
  const navigate = useNavigate();

  const userData = useSelector((state) => state.userData.userData);

  const dispatch = useDispatch()

  const handleClick = () => {
    //console.log("Button clicked");
    navigate('/songList');
    // You can add your own logic here
  };

  useEffect(() => {
    if (userData.studio_id != undefined) {

      dispatch(fetchMusicCategoryDetail("genreData",  `GENRE`))
      
     
      dispatch(fetchMusicCategoryDetail("subGenreData",  `SUBGENRE`))
      

      dispatch(fetchMusicCategoryDetail('moodData', `MOOD`))

      dispatch(fetchMusicCategoryDetail('recordLabelData', `RECORDLABEL`))

      dispatch(fetchMusicCategoryDetail('languageData', `LANGUAGE`))

    }
  }, [userData.studio_id]);
  function CustomButton(props) {
    const theme = useTheme();
    return (
      <Button
        variant="outlined"
        sx={{
          color: '#FFFFFF',
          backgroundColor: 'transparent',
          borderColor: '#FFFFFF',
          minWidth: '120px',
          padding: '8px 16px',
          borderRadius: '20px',
          fontSize: '1rem',
          position: 'absolute',
          bottom: '1rem',
          left: '1rem',
          [theme.breakpoints.down('sm')]: {
            minWidth: '80px',
            padding: '6px 12px',
            borderRadius: '16px',
            fontSize: '0.8rem',
          },
          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: '#FFFFFF',
          },
        }}
        onClick={handleClick}
      >
        Release Song <ArrowForwardIcon size= 'small' />
      </Button>
    );
  }
  return (
    <ThemeProvider theme={theme}>
         <Box className='dashboardbanner' sx={{
        height: '300px',
        borderRadius:'10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
        textAlign: 'start',
        position: 'relative',
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat',
        [theme.breakpoints.down('sm')]: {
          height: '300px',
        },
      }}>
        <Typography variant="h2" sx={{
          background: 'linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.92))',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontSize: '2.938rem',
          fontWeight: 'bold',
          padding:'1rem 1rem 0 1rem',
          [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
          },
        }}
        >
          {userData.studio_name}
          {/* GNTunes Records */}
        </Typography>
        <Typography variant="h6" sx={{
          background: 'linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.92))',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontSize: '1.375rem',
          fontWeight: '500',
          padding:'1rem 1rem 0 1rem',
          [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
          },
        }}
        >
          Welcome to our family
        </Typography>
        <Typography variant="body1" sx={{
          background: 'linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.92))',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontSize: '1.125rem',
          padding:'1rem 1rem 0 1rem',
          maxWidth: '28.125rem',
          [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
          },
        }}
        >
          Our team is dedicated to provide you with the best possible experience.We look forward to seeing what you create
        </Typography>
        {/* <Button className='releaseSongBtn' variant="contained" sx={{
          position: 'absolute',
          bottom: '1rem',
          left: '1rem',
          [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
          },
        }}>
          Release Song
        </Button> */}
        <ThemeProvider theme={theme}>
          {/* <CustomButton onclick={handleClick} /> */}
        </ThemeProvider>
      </Box>

    </ThemeProvider>
  );
};

export default Dashboard


