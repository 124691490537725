import axios from "axios";
import { userPostSocialLinkApi } from '../../_constants/api.constant'
import { getSelfToken } from '../../_helpers/auth/secureToken'
export async function getSocialPlatform(id) {
    let prarmTempObj = {};
    prarmTempObj.search = id;
    const response = await axios.get(
        userPostSocialLinkApi.GET, 
        {   
            params: prarmTempObj,
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        // handle error


        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}