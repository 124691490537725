import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton, Typography, Tooltip, tooltipClasses } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import userAdminImtype from "../../img/thumbnail.png";
import FacebookImg from '../../img/socialImgs/facebook.png';
import InstagramImg from '../../img/socialImgs/instagram.png';
import TwitterImg from '../../img/socialImgs/twitter.png';
import YoutubeImg from '../../img/socialImgs/youtube.png';
import LinkedinImg from '../../img/socialImgs/linkedIn.png';
import SpotifyImg from '../../img/socialImgs/spotify.png'
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';
import { getArtistDataByUserHandle } from '../../_services/Artist';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


function ArtistDetail() {
    const [artistDetails, setArtistDetails] = React.useState([])
    const navigate = useNavigate();
    const params = useParams();
    const userHandle = params.userhandle;
    /*convert ISO date to dd/mm/yy*/
    const convertDateTime = (dateTime) => {
        let joinedDate = new Date(dateTime);
        let dateString = new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(joinedDate);
        return dateString;
    }

    useEffect(() => {
        getArtistDataByUserHandle(userHandle)
            .then(function (response) {
                if (response.data.data) {
                    let ArtistData = response.data.data.map((data) => {
                        const artistData = {};
                        artistData.id = data.id;
                        artistData.avatar = data.avatar;
                        artistData.name = data.name;
                        artistData.type = data.category ? data.category.map((data, i) => [i > 0 && ", ", data.category]) : 'No data';
                        artistData.genres = data.genres ? data.genres.map((genre, i) => genre.genre) : 'No data';
                        artistData.location = data.city ? data.city : 'No data';
                        artistData.experience = data.experience ? data.experience.map((exp) => [exp.companyName]) : 'No data';
                        artistData.expertise = data.expertiseLevel ? data.expertiseLevel.expertiseLevel : '';
                        artistData.language = data.language ? data.language.map((data, i) => [i > 0 && " ", data.language]) : 'No data';
                        artistData.regDate = data.date_joined ? convertDateTime(data.date_joined) : 'No data';
                        artistData.status = data.verification_status ? data.verification_status.verificationStatus : 'No Status';
                        artistData.about = data.about ? data.about : 'No data';
                        artistData.cntctNum = data.contactNumber;
                        artistData.social = data && data.social && data.social.length > 0 ? data.social : 'No data';
                        artistData.isVerified = data.isVerified;
                        artistData.userHandle = data.userHandle ? data.userHandle : 'No data';
                        artistData.select = false;
                        return artistData;
                    })
                    setArtistDetails(ArtistData)
                }
            }).catch(function (error) {
                return error.response;
            })

    }, [userHandle]);

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
            maxWidth: '13.9rem',
        },
    }));

    const getSocialIcon = (socialTypeCode) => {
        switch (socialTypeCode) {
            case 'facebook_1': {
                return <img style={{ widht: '32px', height: '32px' }} src={FacebookImg} alt="Not found" />
            }
            case 'instagram_2': {
                return <img style={{ widht: '32px', height: '32px' }} src={InstagramImg} alt="Not found" />
            }
            case 'twitter_3': {
                return <img style={{ widht: '32px', height: '32px' }} src={TwitterImg} alt="Not found" />
            }
            case 'linkedin_4': {
                return <img style={{ widht: '32px', height: '32px' }} src={LinkedinImg} alt="Not found" />
            }
            case 'youtube_5': {
                return <img style={{ widht: '32px', height: '32px' }} src={YoutubeImg} alt="Not found" />
            }
            case 'spotify_6': {
                return <img style={{ widht: '32px', height: '32px' }} src={SpotifyImg} alt="Not found" />
            }
            default:
                return
        }
    }
    return (

        <div className="">
            {artistDetails && artistDetails.length > 0 && artistDetails.map(data => (
                <>
                    <div key={data.id}>
                        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                            {/* <Button disableRipple className='closeDrawer ' startIcon={<KeyboardBackspaceIcon />} aria-label="back" onClick={() => { navigate("/List") }} sx={{ color: '#040000', '&:hover': { background: 'transparent' } }}>
                                Back
                            </Button> */}
                        </Stack>
                        <button className="icononly-btn" onClick={() => { navigate("/List") }}><h5><ArrowBackIcon /> Back</h5></button>
                        {/* artist info section */}
                        <div className="d-flex flex-wrap justify-content-between mt-3 mb-4 pb-3" key={data.id}>
                            <div className="verifyCol1 p-4 shadow-sm bg-white rounded">
                                <div key={data.id}>
                                    {/* header row 1 */}
                                    <div className='d-flex align-items-center mb-4'>
                                        <div style={{ width: '30%' }}>
                                            <div class="item" style={{ width: '100px' }}>
                                                <div>
                                                    <Avatar sx={{ width: '6.3125rem', height: '6.3125rem' }} alt='artist-img' src={data.avatar ? process.env.REACT_APP_CREATOR_MEDIA_URL + '/media/' + data.avatar : userAdminImtype} aria-label="artist-profile" />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ width: '70%', marginLeft: '3rem' }}>

                                            <h2>{data.name}</h2>
                                            {data.status.length > 0 && data.status.toLowerCase() === 'pending' ? <div className="pendingStatus d-flex align-items-center justify-content-center w-100 p-1"
                                                style={{ border: 'none', borderRadius: '20px', maxWidth: '10rem', fontSize: '1rem', background: getSongStatusBgColor(data.status) }}>
                                                <strong className='pendingStatus text-light' >{data.status}</strong></div>
                                                : data.status.length > 0 && data.status.toLowerCase() === 'rejected' ? <div className="notVerifyStatus d-flex align-items-center justify-content-center w-100 p-1"
                                                    style={{ border: 'none', borderRadius: '20px', maxWidth: '10rem', fontSize: '1rem', background: getSongStatusBgColor(data.status) }}>
                                                    <strong className='notVerifyStatus text-light' >{data.status}</strong></div>
                                                    : data.status.length > 0 && data.status.toLowerCase() === 'verified' ? <div className="verifyStatus d-flex align-items-center justify-content-center w-100 p-1"
                                                        style={{ border: 'none', borderRadius: '20px', maxWidth: '10rem', fontSize: '1rem', background: getSongStatusBgColor(data.status) }}>
                                                        <strong className='verifyStatus text-light' >{data.status}</strong></div>
                                                        : <div className="notVerifyStatus d-flex align-items-center justify-content-center w-100 p-1"
                                                            style={{ border: 'none', borderRadius: '20px', maxWidth: '10rem', fontSize: '1rem', background: getSongStatusBgColor(data.status) }}>
                                                            <strong className='notVerifyStatus text-light' >Not Verified</strong></div>}
                                        </div>
                                    </div>
                                    {/* body row 2 */}
                                    <div>
                                        {/* Inner row 1 */}
                                        <div className="mb-2">
                                            <div className='d-flex align-items-center'>
                                                <span className="fs-6 text-secondary">About</span>
                                                <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                            </div>
                                            <div className="m-2">
                                                <p className='fs-6 fw-bold'>
                                                    {data.about}
                                                </p>
                                            </div>
                                        </div>
                                        {/* Inner row 2 */}
                                        <div className="mb-2">
                                            <div className='d-flex align-items-center'>
                                                <span className="fs-6 text-secondary">Category</span>
                                                <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                            </div>
                                            <div className="m-2">
                                                <Typography color="inherit" className='me-3 fw-bold' variant="subtitle1" component="span"> {data.type.length > 0 ? data.type : 'No data'} </Typography>
                                            </div>
                                        </div>
                                        {/* Inner Row 3 */}
                                        <div className="mb-2">
                                            <div className='d-flex align-items-center'>
                                                <span className="fs-6 text-secondary">Genre</span>
                                                <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                            </div>
                                            <div className="m-2 d-flex flex-wrap">
                                                <Typography color="inherit" className='mx-1 fw-bold' variant="subtitle1" component="span"> {data.genres.length > 0 ? data.genres.join(',') : 'No data'} </Typography>
                                            </div>
                                        </div>
                                        {/*  Inner row 4 */}
                                        <div className="mb-2">
                                            <div className='d-flex align-items-center'>
                                                <span className="fs-6 text-secondary">Social Platforms</span>
                                                <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                            </div>
                                            <div className="m-2">
                                                <div className="d-flex flex-wrap">
                                                    {data.social !== 'No data'
                                                        ? data.social.map((social) =>
                                                            <BootstrapTooltip title={social.url} ransitioncomponent={Zoom} >
                                                                <IconButton className='m-1 socialLinks text-center' href={social.url} sx={{ padding: 0 }} disableRipple>
                                                                    <span>{getSocialIcon(social.socialType != null ? social.socialType.socialTypeCode : 'No Data')}</span>
                                                                </IconButton>
                                                            </BootstrapTooltip>
                                                        )
                                                        : <Typography variant="body2" className='ms-2 fw-bold' gutterBottom>No data</Typography>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="verifyCol2 p-4 shadow-sm bg-white rounded">
                                <div>
                                    <div>
                                        {/* row 1 */}
                                        <div className="mb-2">
                                            <div className='d-flex align-items-center'>
                                                <span className="fs-6 text-secondary">Experience</span>
                                                <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                            </div>
                                            <div className="m-2">
                                                {data.experience !== undefined
                                                    ? <Typography variant="body2" className='ms-2 fw-bold' gutterBottom>{data.experience.length > 0 ? data.experience : 'No data'}</Typography>
                                                    : ""}
                                            </div>
                                        </div>
                                        {/*  row 2 */}
                                        <div className="mb-2">
                                            <div className='d-flex align-items-center'>
                                                <span className="fs-6 text-secondary">Language</span>
                                                <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                            </div>
                                            <div className="m-2">
                                                <Typography variant="body2" className='ms-2 fw-bold' gutterBottom>{data.language.length > 0 ? data.language : 'No data'}</Typography>
                                            </div>
                                        </div>
                                        {/* row 3 */}
                                        <div className="mb-2">
                                            <div className='d-flex align-items-center'>
                                                <span className="fs-6 text-secondary">Expertise Level</span>
                                                <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                            </div>
                                            <div className="m-2">
                                                <Typography variant="body2" className='ms-2 fw-bold' gutterBottom>{data.expertise ? data.expertise : 'No data'}</Typography>
                                            </div>
                                        </div>
                                        {/* row 4 */}
                                        <div className="mb-2">
                                            <div className='d-flex align-items-center'>
                                                <span className="fs-6 text-secondary">Contact Information</span>
                                                <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                                            </div>
                                            <div className="m-2">
                                                {data.cntctNum ? <span className='fs-6 ms-2 fw-bold'><img src="https://flagcdn.com/in.svg" width="30" alt="South Africa" /> {data.cntctNum} </span> : <span className='fs-6 ms-2 fw-bold'> No Data </span>}
                                            </div>
                                        </div>
                                        {/* row 5 */}
                                        <div className="mt-4">
                                            <div style={{ marginTop: '50px' }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ))}
        </div>

    )

}

export default ArtistDetail


