import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import artist from '../../img/default image.png'
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter' 
import { useNavigate, useParams } from 'react-router-dom';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import userAdminImtype from '../../img/userAdmin.png';
import { tooltipClasses }  from '@mui/material/Tooltip';
import profileImge from '../../img/header/profileImage.png';
import {
  createTheme,
  ThemeProvider,
  Grid,
  IconButton,
  Slider,
  Typography,
  Zoom,
  Tooltip,
  styled,
} from "@mui/material";
import AudioPlayer from './customAudioPlayer';
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    maxWidth: '13.9rem',  
  },
}));
export default function ResponsiveCardList({cardData,moduleName}) {
  const userRoles = (id)=>{
    switch (id) {
        case 1:
            return 'Admin';
            break;
        case 2:
        return 'Editor';
        break;
        case 3:
        return 'Viewer';
        break;
        default:
            return 'User';
            break;
    }    
    
    }
    const history = useNavigate()
    const showInListView = (module)=>{
        switch(module)
        {
          case 'songList':
            {
              return (
                // <div className='my-3' onClick={()=>history(`/submitsong/${cardData.song_id}`)} style={{cursor:'pointer',marginBottom:'2rem'}}>
                <div className='my-3' onClick={()=>history(`/releases/${cardData.song_id}`)} style={{cursor:'pointer'}}>
                <Card sx={{ maxWidth: '500px', width:'100%'}}>        
                  <div className='d-flex w-100'>
                      <div style={{padding:'0.5rem'}}>        
                      <CardMedia
                        component="img"
                        sx={{ width:'60px', height:'60px',borderRadius:'15px'}}
                        image={cardData.cover_image?`${process.env.REACT_APP_GNSONGURL}song/media/${cardData.cover_image}`:artist}
                        />        
                      </div>
                      <div style={{padding:'0.5rem',flexGrow:1}}>
                        <div className='d-flex justify-content-between'>
                            <strong className='cardheadingFont overflow-text' title={cardData.song_name}>{cardData.song_name}</strong>                
                            <div style={{backgroundColor:getSongStatusBgColor(cardData.song_status), minWidth:'72px'}} className='status text-light'>{cardData.song_status}</div>
                            {/* <div style={{backgroundColor:getSongStatusBgColor(cardData.song_status)}} className='status text-light'>{cardData.song_status}</div> */}
                        </div>
                        <div className='cardheadingFont text-secondary cardLine overflow-text' title={cardData.album}>{cardData.album}</div>
                        <div className='cardfont'>{cardData.content_types}</div>
                        <div className={'d-flex justify-content-between'} >
                            <div className='cardfont'>{cardData.genre}</div>
                            <div className='cardfont '>{cardData.release_date?getDateAsPerFormat(cardData.release_date,"DD Mon, YYYY"):''}</div>
                        </div>
                      </div>
                  </div>
                  </Card>
                </div>
              )
            }
            case 'userList':{
              return (
                // <div className='my-3' onClick={()=>history(`/submitsong/${cardData.song_id}`)} style={{cursor:'pointer',marginBottom:'2rem'}}>
                <div className='my-3' >
                <Card key={cardData.studio_id} sx={{ maxWidth:'500px', width:'100%'}}>        
                  <div className='d-flex w-100'>
                      <div style={{padding:'0.5rem'}}>        
                      <CardMedia
                        component="img"
                        sx={{ width:'60px', height:'60px',borderRadius:'15px'}}
                        image={cardData.avatar == null || cardData.avatar === ""  ? userAdminImtype :`${process.env.REACT_APP_BASEURL_MEDIA}/media/${cardData.avatar}` }
                        onError={(e) => { e.target.src = userAdminImtype }} alt="img"
                        />        
                      </div>
                      <div style={{padding:'0.5rem',flexGrow:1}}>
                        <div className='d-flex justify-content-between' title={cardData.name}>
                            <strong className='cardheadingFont overflow-text'>{cardData.name}</strong>                
                            {/* <div style={{backgroundColor:getSongStatusBgColor(cardData.song_status), minWidth:'72px'}} className='status text-light'>{cardData.song_status}</div> */}
                            {/* <div style={{backgroundColor:getSongStatusBgColor(cardData.song_status)}} className='status text-light'>{cardData.song_status}</div> */}
                        </div>
                         <div className='cardheadingFont text-secondary cardLine py-1'>
                         {cardData && cardData.email && cardData.email.length > 18 ? <BootstrapTooltip title={cardData.email} sx={{marginTop:'-8px',width:'auto'}} TransitionComponent={Zoom} ><div className='cardheadingFont text-secondary cardLine py-1'>{cardData.email.slice(0, 10)}...</div></BootstrapTooltip>:
                          <div className='cardheadingFont text-secondary cardLine py-1' >{cardData.email}</div>}
                         </div>
                       
                        <div className='cardfont'>{cardData.phone_number}</div>
                        <div className={'d-flex justify-content-between'} >
                            <div className='cardfont'>{userRoles(cardData.role)}</div>
                            <div className='cardfont '>{getDateAsPerFormat(cardData.registration_date,"DD Mon, YYYY")}</div>
                        </div>
                      </div>
                  </div>
                  </Card>
                </div>
              )
            }
          default:{
            return;
          }
        }
    }
    return (
      showInListView(moduleName)
    )
}